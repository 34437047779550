import React, { useState, useEffect } from 'react';
import FloatingTextBox from '../components/FloatingTextBox.js';
import FloatingShape from '../components/FloatingShape.js';
import { getUsername } from '../utils/TokenUtils';
import { getUser, updateUser } from '../utils/UserUtils';

const walkthruSteps = [
  {
    textBox: {
      top: "50%",
      left: "50%",
      translateCenter: true,
      title: "Welcome to AgroLedger",
      description: [
        "Thank you for registering with AgroLedger. Let's get you started with building your account.",
        "First, we need to create your operation(s) in AgroLedger. These are your business operations defined by the types of activities the operation carries out (i.e. farm, feedlot, etc). You can have more than one operation associated with your account, and defining different operations is important for making sure you can access appropriate AgroLedger activities."
      ],
      prev: false,
      next: true
    }
  },
  {
    textBox: {
      top: "13%",
      left: "25%",
      title: "Creating Operations",
      description: [
        "You can create your AgroLedger operations here."
      ],
      prev: true,
      next: true
    },
    arrow: {
      top: "20%",
      left: "16%",
      polygonPoints: "105,10 105,20 30,20 30,25 0,15 30,5 30,10",
    }
  },
  {
    textBox: {
      top: "50%",
      left: "50%",
      translateCenter: true,
      title: "Premises",
      description: [
        "Now let's add your premises to their associated operations. Premises are locations where various events take place, and you can have multiple premises linked to your different operations. To get started creating your premises, you will need PIDs (Premise Identification Numbers) issued by your provincial government.",
        "Don't have your PIDs yet? Here's where you can find more information about registering with your provincial government: https://www.inspection.gc.ca/animal-health/terrestrial-animals/traceability/premises-identification/eng/1495127375452/1495127376419"
      ],
      prev: true,
      next: true
    }
  },
  {
    textBox: {
      top: "25%",
      left: "23%",
      title: "Creating Premises",
      description: [
        "You can create your premises here."
      ],
      prev: true,
      next: true
    },
    arrow: {
      top: "33%",
      left: "15%",
      polygonPoints: "92,10 92,20 30,20 30,25 0,15 30,5 30,10",
    }
  },
  {
    textBox: {
      top: "50%",
      left: "50%",
      translateCenter: true,
      title: "Animals",
      description: [
        "Next, you can create or enter animals you currently have on your premises.  Creating an animal inventory in the system is not mandatory, but it will help build a starting point for your account and make it easier to generate system reports. You can add animals one at a time or upload an existing inventory file."
      ],
      prev: true,
      next: true
    }
  },
  {
    textBox: {
      top: "13%",
      left: "15%",
      title: "Creating Animals",
      description: [
        "You can add animals to your account here."
      ],
      prev: true,
      next: true
    },
    arrow: {
      top: "20%",
      left: "45%",
      polygonPoints: "15,10 15,20 65,20 65,30 80,15 65,0 65,10"
    }
  },
  {
    textBox: {
      top: "50%",
      left: "50%",
      translateCenter: true,
      title: "Vehicles",
      description: [
        "Reports that include moving animals with vehicles often require adding vehicle identification information.  If you have your own trucks and trailers for moving sheep, you can enter them in the system now to make event reporting easier later."
      ],
      prev: true,
      next: true
    }
  },
  {
    textBox: {
      top: "38%",
      left: "23%",
      title: "Creating Vehicles",
      description: [
        "Add vehicles to your account here."
      ],
      prev: true,
      next: true
    },
    arrow: {
      top: "46%",
      left: "14.5%",
      polygonPoints: "95,10 95,20 30,20 30,25 0,15 30,5 30,10",
    }
  },
  {
    textBox: {
      top: "50%",
      left: "50%",
      translateCenter: true,
      title: "Reports",
      description: [
        "Finally, you can report events involving one or more animals.  Some events involve mandatory reporting under the current regulation, some events will be mandatory to report under future regulations and can be voluntarily reported now, and some events are completely voluntary.  In all cases, keeping track of animals by reporting life events is an invaluable tool both for management and traceability purposes."
      ],
      prev: true,
      next: true
    }
  },
  {
    textBox: {
      top: "26%",
      left: "5%",
      title: "Creating Reports",
      description: [
        "If you know what kind of event you need to report, go directly to the events list here."
      ],
      prev: true,
      next: true
    },
    arrow: {
      top: "33%",
      left: "45%",
      polygonPoints: "20,10 20,20 65,20 65,30 80,15 65,0 65,10"
    }
  },
  {
    textBox: {
      top: "77%",
      left: "5%",
      title: "Help with Reports",
      description: [
        "If you’re unsure of what report is required or possible for an event, visit the AgroLedger event finder here."
      ],
      prev: true,
      finish: true
    },
    arrow: {
      top: "88%",
      left: "45%",
      polygonPoints: "20,10 20,20 65,20 65,30 80,15 65,0 65,10"
    }
  }
];

export default function WalkthroughView() {
  const [stepIdx, setStepIdx] = useState(-1);
  const username = getUsername();

  useEffect(() => {
    getUser(username)
      .then(user => {
        if (
          user.walkthroughStep !== null &&
          user.walkthroughStep >= 0 &&
          user.walkthroughStep < walkthruSteps.length
        ) {
          setStepIdx(user.walkthroughStep);
        }
      });
  }, []);

  const handleNext = () => {
    if (stepIdx+1 < walkthruSteps.length) {
      return updateUser(username, { walkthroughStep: stepIdx+1 })
        .then(() => {
          setStepIdx(prevStepIdx => prevStepIdx+1);
        })
        .catch(() => {
          setStepIdx(prevStepIdx => prevStepIdx+1);
        });
    }
  };

  const handlePrev = () => {
    if (stepIdx > 0) {
      return updateUser(username, { walkthroughStep: stepIdx-1 })
        .then(() => {
          setStepIdx(prevStepIdx => prevStepIdx-1);
        })
        .catch(() => {
          setStepIdx(prevStepIdx => prevStepIdx-1);
        });
    }
  };

  const handleClose = () => {
    return updateUser(username, { walkthroughStep: -1 })
      .then(() => {
        setStepIdx(-1);
      })
      .catch(() => {
        setStepIdx(-1);
      });
  };

  if (stepIdx === -1) return <></>;

  const currStep = walkthruSteps[stepIdx];
  return (<>
    {currStep.textBox && 
      <FloatingTextBox
        top={currStep.textBox.top}
        left={currStep.textBox.left}
        bottom={currStep.textBox.bottom}
        right={currStep.textBox.right}
        translateCenter={currStep.textBox.translateCenter}
        title={currStep.textBox.title}
        description={currStep.textBox.description}
        onPrev={currStep.textBox.prev ? handlePrev : null}
        onNext={currStep.textBox.next ? handleNext : null}
        onFinish={currStep.textBox.finish ? handleClose : null}
        onClose={handleClose}
      />
    }
    {currStep.arrow &&
      <FloatingShape
        top={currStep.arrow.top}
        left={currStep.arrow.left}
        bottom={currStep.arrow.bottom}
        right={currStep.arrow.right}
        polygonPoints={currStep.arrow.polygonPoints}
      />
    }
  </>);
}
