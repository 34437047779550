import React, { useState, useEffect } from 'react';
import { Button } from '../components/CommonComponents.js';
import PageCard from '../components/PageCard.js';
import { Link } from 'react-router-dom';
import { getUsername, getRoles } from '../utils/TokenUtils.js';
import styled from 'styled-components';
import SimpleCard from '../components/SimpleCard.js';
import WalkthroughView from './WalkthroughView.js';
import axios from 'axios';

const ContactAdmin = () => <PageCard title='Welcome to Agroledger'>
	<p>Looks like you&apos;ve just created an account! To finish getting set up, please contact: <a href="mailto:kip@cansheep.ca">kip@cansheep.ca</a></p>
</PageCard>

const HomePage = () => {
	const [currRole, setCurrRole] = useState({}); 

	const fetchCurrentRole = async() => {
		const temp = await axios.get('/api/users/role');
		return temp;
	}

	const fetchAndSetRoleInfo = async () => {
		try {
			let fetchedRole = await fetchCurrentRole();
			setCurrRole(fetchedRole.data);
		} catch (err) {
			
		}
	}

	  
	useEffect(() => {
		fetchAndSetRoleInfo();
	  }, []);


	const roles = getRoles();
	return (
		(roles && roles.length === 0) ? 
			<ContactAdmin /> : 
			<PageCard title='Home' relative>
			<WalkthroughView />
			<WelcomeDiv>Welcome {getUsername()}</WelcomeDiv>
			<hr />

			<LeftDiv>
				<LeftInnerContainer>
					<PanelDiv>
						<SimpleCard
							title="Manage Your Holdings"
						>
							<Link to='/operations'>
								<Button>Operations</Button>
							</Link>
							<br></br>
							<pr>Click the above button to view and edit your operations</pr>
							<br></br><br></br><br></br>
							<Link to='/premises'>
								<Button>Premises</Button>
							</Link>
							<br></br>
							<pr>Click the above button to view and edit your premise</pr>
							<br></br><br></br><br></br>
							<Link to='/vehicles'>
								<Button>Vehicles</Button>
							</Link>
							<br></br>
							<pr>Click the above button to view and edit your vehicles</pr>
							<br></br><br></br>
						</SimpleCard>
					</PanelDiv>
					<br></br>
					<PanelDiv>
						<SimpleCard title="Traceability">
							<Link to='/trace'>
								<Button>Trace Sheep</Button>
							</Link>
							<br></br>
							<pr>Click the above button to trace a sheep's position over time</pr>
							<br></br><br></br>
						</SimpleCard>
					</PanelDiv>
					<br></br>
					<PanelDiv>
						<SimpleCard title="Transaction Verification">
							<Link to='/verify/dashboard'>
								<Button>Verify Transaction</Button>
							</Link>
							<br></br>
							<pr>Click the above button to learn how we verify your transactions</pr>
							<br></br><br></br>
						</SimpleCard>
					</PanelDiv>
					<br></br>
					{(currRole == "admin" 
					) && 
					<PanelDiv>
						<SimpleCard title="View Users">
							<Link to='/users'>
								<Button>Users</Button>
							</Link>
							<br></br>
							<pr>Click the above button to see all users</pr>
							<br></br><br></br>
						</SimpleCard>
					</PanelDiv>
					}

				</LeftInnerContainer>
			</LeftDiv>
			<RightDiv>
				<LeftInnerContainer>
					<PanelDiv>
						<SimpleCard
							title="Manage Your Sheep"
						>
							<Link to='/sheep'>
								<Button>Sheep</Button>
							</Link>
							<br></br>
							<pr>Click the above button to view all the information regarding your sheep</pr>
							<br></br><br></br><br></br>

							
							<Link to='/reports/dash'>
								<Button>Reports</Button>
							</Link>
							<br></br>
							<pr>Click the above button to view and create sheep reports</pr>
							<br></br><br></br>
						</SimpleCard>
					</PanelDiv>
					<br></br>
					<PanelDiv>
					<SimpleCard
							title="Get in Touch"
						>
							<Link to='/feedback/'>
								<Button>Feedback</Button>
							</Link>
							<br></br>
							<pr>Click the above button to submit any feedback you might have on the site</pr>
							<br></br><br></br><br></br>

							<Link to='/contact'>
								<Button>Contact Us</Button>
							</Link>
							<br></br>
							<pr>Click the above button to contact us for any help that you need</pr>
							<br></br><br></br>
						</SimpleCard>
					</PanelDiv>
					<br></br>
					<PanelDiv>
						<SimpleCard title="Help Me">
							<Link to='/help'>
								<Button>Help</Button>
							</Link>
							<br></br>
							<pr>Click the above button if you are unsure on what to do next</pr>
							<br></br><br></br>
						</SimpleCard>
					</PanelDiv>
				</LeftInnerContainer>
			</RightDiv>
		</PageCard>
	);
};

export default HomePage;

const WelcomeDiv = styled.div`
	font-size: 20px;
`;

const LeftDiv = styled.div`
  max-width: 50%;
  width: 50%;
  float: left;
  display: inline-block;
`

const RightDiv = styled.div`
  width: 50%;
  display: inline-block;
  
  
`

const PanelDiv = styled.div`
	padding: .25rem;
	background: grey;
	border-radius: 10px;
`



const LeftInnerContainer = styled.div`
  padding: .25rem;
`;

