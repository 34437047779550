import React, { useState } from 'react';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import DateFnsUtils from '@date-io/date-fns';
import propTypes from 'prop-types';
import {
	FormInputWrapper,
	FormInputLabel,
} from './CommonComponents.js';
import styled from 'styled-components';

const RequiredSpan = styled.span`
  color: red;
`

const TimePicker = styled.div`
  width: 152px;
  background-color: white;
  padding-left: 0.25em;
  border-radius: 5px;
`;

const TimeField = ({
  id,
  labelName,
  onChange,
  value,
  required
}) => {
  const [pickerOpen, setPickerOpen] = useState(false);

  return (
    <FormInputWrapper key={id}>
      <FormInputLabel htmlFor='timepicker'>
        {labelName}
        {required && <>&nbsp;<RequiredSpan>*</RequiredSpan></>}
      </FormInputLabel>
      <TimePicker>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
              margin="small"
              fullWidth
              value={value}
              onChange={onChange}
              keyboardIcon={<FontAwesomeIcon icon={faClock} size="xs" />}
              open={pickerOpen}
              onClick={() => setPickerOpen(true)}
              onClose={() => setPickerOpen(false)}
          />
        </MuiPickersUtilsProvider>
      </TimePicker>
    </FormInputWrapper>
  );
}

TimeField.propTypes = {
  id: propTypes.string,
  labelName: propTypes.string,
  onChange: propTypes.func,
  value: propTypes.node,
  required: propTypes.bool
}

export default TimeField;
