const { default: DecisionCard } = require("../components/DecisionCard")

const decision = {
  id: 'TESTINGDECISION',
  prev: 'N/A',
  title: 'Testing Decision',
  description: 'Is this the first approved tag applied to this animal?',
  images: [],
  choices: [
    {
      name: 'Decision A',
      link: '/sheep',
      color: 'danger'
    },
    {
      name: 'Decision B',
      description: 'A decision that\'s not a link',
      next: 'NOTFOUND',
      color: 'green'
    }
  ]
}

const DecisionTestView = () => {

  return <>
    <DecisionCard decision={decision}/>
  </>;
}

export default DecisionTestView;