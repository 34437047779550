// TagActivationDetailView.js
import React from 'react';
import SheepReportDetailView from '../../components/SheepReportDetailView';
import { useParams } from 'react-router';

const TagActivationDetailView = () => {
  const params = useParams();
  const id = params.id;

  return <>
    <SheepReportDetailView
      title={'Tag Activation'}
      verifyLink={`/verify/tagActivation/${id}`}
    />
  </>
}

export default TagActivationDetailView;