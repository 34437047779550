const axios = require('axios');

export const getMoveLogs = () => {
  return axios.get('/api/move')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

//get the move logs for a specific sheep
export const getMoveLogsForSheep = (id) => {
  return axios.get(`/api/move/sheep/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

export const createMoveLog = (data) => {
  return axios.post('/api/move', data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};