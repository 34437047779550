import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TraceDashboard from '../views/TraceDashboard';
import TraceSearch from '../views/TraceSearch';
import TraceSheepDetailsList from '../views/TraceSheepDetailsList';

const TraceRouter = () => (
  <Switch>
    <Route exact path='/trace' component={TraceDashboard} />
    <Route exact path='/trace/search' component={TraceSearch} />
    <Route exact path='/trace/:isoNumber' component={TraceSheepDetailsList} />
  </Switch>
);

export default TraceRouter;