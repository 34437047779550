import React, { useEffect, useState } from 'react';
import { useParams, Link, Redirect } from 'react-router-dom';
import {
	FormContainer,
	Button,
} from '../../components/CommonComponents';
import styled from 'styled-components';

import FormCard from '../../components/FormCard.js';

import {
	updateOperation,
	getOperationById,
} from '../../utils/OperationUtils.js';
import {
	getOperationTypes,
} from '../../utils/OperationTypeUtils';
import DropDown from '../../components/DropDown.js';
import Input from '../../components/Input.js';
import phone from 'phone';
import { createOperationUpdateLog } from '../../utils/OperationUtils.js';
import { toast } from 'react-toastify';
import LoadingCard from '../../components/LoadingCard.js';
import PageCard from '../../components/PageCard.js';
import Loader from 'react-loader-spinner';
import { agGreen } from '../../components/Colors.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin></FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

export default function OperationUpdateView() {
	const [form, setForm] = useState({
	});
	const [fieldErrors, setFieldErrors] = useState({});
	const [operationTypes, setOperationTypes] = useState([]);
	const [messages, setMessages] = useState([]);
	const [redirect, setRedirect] = useState(false);
	const [operation, setOperation] = useState({});
	const [load, setLoad] = useState(true);
	const [submitting, setSubmitting] = useState(false);
	const [submitEnabled, setSubmitEnabled] = useState(true);

	const { id } = useParams();
	
	const formInputs = [
		{
			name: 'Business Name',
			hint: 'Name',
			id: 'businessName',
			isRequired: true
		},
		{
			name: 'Phone Number',
			hint: '647 222 2018',
			id: 'phoneNumber',
			isRequired: false
		},
		{
			name: 'Email',
			hint: 'agroledger@sheep.com',
			id: 'email',
			isRequired: false
		},
	];

	const cvspDropDown = {
		name: <span>CVSP (
			<StyledLink to='/operations/learnMore'>Learn more</StyledLink>)
		</span>,
		id: 'cvsp'
	};

	const operationTypeDropDown = {
		name: 'Operation Type',
		id: 'operationType'
	};

	const sfcpDropDown = {
		name: <span>SFCP (
			<StyledLink to='/operations/learnMore'>Learn More</StyledLink>)
		</span>,
		id: 'sfcp'
	};

	const validateForm = () => {
		if (form.businessName === '') {
			toast.error("Please fill in a business name");
			return false;
		}

		//ensure there is a phone number or email for contact information
		if( 
			(!form.phoneNumber || form.phoneNumber === '') &&
			(!form.email || form.email === '')
		){
			toast.error("You must enter either a phone number or an email");
			return;
		}

		//validate phone number if one is given
		if (form.phoneNumber && form.phoneNumber !== '' && !phone(form.phoneNumber).isValid) {
			toast.error("Invalid phone number");
			return false;
		}

		//validate email if one is given
		const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
		if (
			form.email &&
			form.email !== '' &&
			!emailRegex.test(form.email)
		){
			toast.error("Invalid email");
			return;
		}

		if (form.businessName === operation.businessName &&
				form.phoneNumber === operation.phoneNumber &&
				form.email === operation.email &&
				form.operationType === operation.operationType &&
				form.cvsp === operation.cvsp &&
				form.sfcp === operation.sfcp){
					toast.error("No fields changed");
					return false;
		}
		return true;
	}

	const submitOperation = async (e) => {
		e.preventDefault();
		
		if(validateForm()){
			try {
				const res = updateOperation(form, id);
				console.log({res});
			} catch (err) {
				console.log({err});
				setFieldErrors(err.response.data);
				return;
			}
	
			setSubmitting(true);
			setSubmitEnabled(false);
			toast.info("Submitting update to the backend. This may take a minute. You will be redirected when the update is complete. Please stay on this page.");
			createOperationUpdateLog({
					businessName: operation.businessName,
					phoneNumber: operation.phoneNumber,
					email: operation.email,
					operationType: operation.operationType,
					cvsp: operation.cvsp,
					sfcp: operation.sfcp,
					newBusinessName: form.businessName,
					newPhoneNumber: form.phoneNumber,
					newEmail: form.email,
					newOperationType: form.operationType,
					newCvsp: form.cvsp,
					newSfcp: form.sfcp
				}).then(() => {
					setSubmitting(false);
					toast.success("Operation successfully updated!");
				}).then(() => {
					setRedirect(true);
				}).catch((err) => {
					toast.error(err);
				});
			}
	}

	useEffect(() => {
		const fetchOperation = async () => {
			let fetchedOperation = await getOperationById(id);
			setOperation(fetchedOperation);
			let keys = Object.keys(fetchedOperation);
			keys = keys.filter(k => k.startsWith('_'));
			keys.forEach(k => delete(fetchedOperation[k]));
			delete(fetchedOperation['user']);
			setForm({
				...form,
				...fetchedOperation,	
			});
		};
		fetchOperation().then(() => {
			setLoad(false);
		});
	}, []);

	useEffect(() => {
		getOperationTypes().then((opTypes) => {
			setOperationTypes([
				{
					name: 'abattoir', 
					niceName: 'Abattoir User'},
				{
					
					name: 'extadmin', 
					niceName: 'External Admin'},
				{
				
					name: 'producer', 
					niceName: 'Producer'},
				{
					name: 'extuser',
					niceName: 'External User',
				},
				{
					name: 'cfiaOffice',
					niceName: 'CFIA District Office',
				},
				{
					name: 'commPastureUser',
					niceName: 'Community Pasture User',		
				},
				{
					name: 'accreditedVet',
					niceName: 'Accredited Veterinarian User',
				},
				{
					name: 'quarantineFac',
					niceName: 'Quarantine Facility User',
				},
				{
					name: 'packingPlant',
					niceName: 'Packing Plant User',	
				},
				{
					name: 'nonProducerPart',
					niceName: 'Non-Producer Participant',
				},
				{
					name: 'researchFac',
					niceName: 'Research Facilities User',
				},
				{
					name: 'testStation',
					niceName: 'Test Station User',
				},
				{
					name: 'transporter',
					niceName: 'Transporter',
				},
				{
					name: 'deadStock',
					niceName: 'DeadStock',
				},
				{
					name: 'feedlot',
					niceName: 'Feedlot',
				},
				{
					name: 'auction',
					niceName: 'Auction',
				},
				{
					name: 'assemblyYard',
					niceName: 'Assembly Yard',
				},
				{name: 'exhibition', niceName: 'Exhibition',},
				{name: 'importer', niceName: 'Importer',},
				{name: 'exporter', niceName: 'Exporter',},
				{name: 'vetHospital', niceName: 'Veterinary Hospital',},
				{name: 'programAdmin', niceName: 'Program Administrator',},
				{name: 'embryoCollectionCenter', niceName: 'Insemination/Embryo Collection Centre',},
				{name: 'pathologyLab', niceName: 'Pathology Lab',},
				{name: 'industryOrg', niceName: 'Industry Organization',},
				{name: 'portOfEntry', niceName: 'Port of Entry',},
				{name: 'tagDistributer', niceName: 'Tag Distributer',},
				{name: 'tagDealer', niceName: 'Tag Dealer',},
				{name: 'tagManufacturer', niceName: 'Tag Manufacturer',},
				{name: 'responsibleAdmin', niceName: 'Responsible Administrator',},
				{name: 'zoo', niceName: 'Zoo',},
			...opTypes]);
		});
	}, []);

	if (redirect) {
		return <Redirect to="/operations"/>
	}

	return (
		<>
			<FormCard 
				title='Update an Operation' 
				errors={fieldErrors} 
				messages={messages}
				back
			>
			{!load ? (
				<>
				<FormContainer>
					{formInputs.map(i => <>
						<Input
							id={i.id}
							placeholder={i.hint}
							labelName={i.name}
							onChange={(e) => setForm({
								...form,
								[e.target.name]: e.target.value
							})}
							value={form[i.id]}
							required={i.isRequired}
						/>
					</>)}

					{/* dropdowns */}
					<DropDown
						id={operationTypeDropDown.id}
						name={operationTypeDropDown.name}
						onChange={(e) => setForm({
							...form,
							[e.target.id]: e.target.value
						})}
						value={form[operationTypeDropDown.id]}
						options={
							operationTypes.map(t => ({
								name: t.niceName,
								value: t.name
							}))
						}
						required
					/>
					<DropDown
						id={cvspDropDown.id}
						name={cvspDropDown.name}
						onChange={(e) => setForm({
							...form,
							[e.target.id]: e.target.value
						})}
						value={form[cvspDropDown.id]}
						options={[
							{
								name: 'Yes',
								value: true,
							},
							{
								name: 'No',
								value: false,
							}
						]}
						required
					/>
					<DropDown
						id={sfcpDropDown.id}
						name={sfcpDropDown.name}
						onChange={(e) => setForm({
							...form,
							[e.target.id]: e.target.value
						})}
						value={form[sfcpDropDown.id]}
						options={[
							{
								name: 'Yes',
								value: true,
							},
							{
								name: 'No',
								value: false
							}
						]}
						required
					/>
				</FormContainer>

				<Button type='submit' onClick={submitOperation} disabled={!submitEnabled}>
					Update
				</Button>

				{(submitting) ? <SubmittingSpinner />: <></>}
				</>
				) : (
					<PageCard
					title='Processing your request ...'
					loader={<Loader type='Oval' color={agGreen} height={50} width={150} />}
					>
						<LoadingCard />
					</PageCard>
				)}
				</FormCard>
		</>
	);
}

const StyledLink = styled(Link)`
	color: white;
`;

const SubmittingDiv = styled.div`
  text-align: center;
	color: white;
	margin-bottom: 10px;
`;
