import { useLocation } from "react-router";
import React, { useEffect, useState} from 'react';
import { getBreeds } from '../../utils/BreedUtils';
import axios from 'axios';
import { Button, FormContainer, FormInputLabel, FormInputWrapper, FormSelectInput } from '../../components/CommonComponents';
import DateField from '../../components/DateField.js';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormCard from "../../components/FormCard";

// SheepEditView.js
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const SubmittingDiv = styled.div`
  text-align: center
`;
const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin>

    </FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

const SheepEditView = () => {
  const [breeds, setBreeds] = useState([]);
  const [form, setForm] = useState({
    gender: null,
    breed: null,
    birthdate: null,
  });
  const [redirect, setRedirect] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const query = useQuery();

  useEffect(() => {
    // Fetch the sheep by id
    const sheepID = query.get('id');
  }, [query]);

  useEffect(() => {
    getBreeds()
      .then((breeds) => {
        setBreeds(breeds.map((breed) => ({value: breed.name, name: breed.name})));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();

    // verify the date of birth is not in the future
    if (form.birthdate) {
      const now = new Date();
      const birthDate = new Date(form.birthdate);
      if (now < birthDate) {
        toast.error("Please select a valid date: Date of birth cannot be in the future");
        return;
      }
    }

    const sheepID = query.get('id');
    toast.info("Submitting updates to server. This will take some time, please remain on this page.");
    setSubmitting(true);
    axios.put(`/api/sheep/${sheepID}`, form)
      .then(() => {
        setSubmitting(false);
        toast.success('Your Sheep edit was successful!');
        setRedirect(true);
      })
      .catch((err) => {
        if(err.response !== undefined)
          toast.error(err.response.data.message);
        else
          toast.error(err);

        setSubmitting(false);
        
      });
  };

  // Mutators

  const setGender = (newGender) => {
    if(form.gender === newGender) return;
    setForm({
      ...form,
      gender: newGender,
      subgender: ''
    });
  }

  const subGenderOptions = () => {
    if(form.gender === 'male'){
      return [
        {
          name: 'Other',
          value: null
        },
        {
          name: 'Ram',
          value: 'ram'
        },
        {
          name: 'Wether',
          value: 'wether'
        }
      ]
    }
    if(form.gender === 'female'){
      return [
        {
          name: 'Other',
          value: null
        },
        {
          name: 'Ewe',
          value: 'ewe'
        },
        {
          name: 'Ewelamb',
          value: 'ewelamb'
        }
      ]
    }

    return [];
  }

  if (redirect) return <Redirect to="/reports/sheepedit" />
  return <>
    <FormCard
      back
      title='Edit Sheep'
    >
      <FormContainer onSubmit = {handleUpdate}>
        <FormInputWrapper>
          <FormInputLabel htmlFor='input'>
            Gender
          </FormInputLabel>
          <FormSelectInput
            id = 'input'
            name = 'Gender'
            value = {form.gender}
            onChange={(e) => {setGender(e.target.value)}}
          >
            <option hidden>Select one...</option>
            <option key='male' value='male' >Male</option>
            <option key='female' value='female' > Female </option>
          </FormSelectInput>
        </FormInputWrapper>

        {form.gender ? (<>
          <FormInputWrapper>
            <FormInputLabel htmlFor='input'>
              Sub Gender
            </FormInputLabel>
            <FormSelectInput
              id = 'input'
              name = 'subgender'
              value = {form.subgender}
              onChange={(e) => setForm({...form, subgender: e.target.value})}
            >
              <option hidden>Select one...</option>
              {subGenderOptions().map(opt =>
                <option key = {opt.value} value = {opt.value}>{opt.name}</option>
              )}

            </FormSelectInput>
        </FormInputWrapper>
        </>) : (<></>)}

        <FormInputWrapper>
          <FormInputLabel htmlFor='input'>
            Breed
          </FormInputLabel>
            <FormSelectInput
              id = 'input'
              name='Breed'
              onChange={(e) => setForm({...form, breed: e.target.value})}
              value={form.breed}
            >
            <option hidden>Select one...</option>
            {breeds.map(opt =>
              <option key = {opt.value} value = {opt.value}>{opt.name}</option>
            )}
          </FormSelectInput>
        </FormInputWrapper>

        <DateField
            id="birthdate"
            labelName="Date of Birth"
            value={form.birthdate}
            onChange={(date) => setForm({...form, birthdate: date})}
        />

        <Button disabled = {submitting}>Submit</Button>
        {(submitting) ? <SubmittingSpinner />: <></>}
      </FormContainer>
    </FormCard>
  </>;
}

export default SheepEditView;