// DataList.js
import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from './CommonComponents';
// Need to add some way to set a background 


const DT = styled.dt`
  display: block;
  float: left;
  padding-right: 0.5em;
`

const DlTitle = styled.p`
  font-size: 25px;
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: bold;
`

const DL = styled.dl`
  margin-top: 5px;
  margin-bottom: 0px;
`

const DlDiv = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  padding: ${ props => {
    if(props.color) return '10px;';

    return '0px;';
  }}
  border-radius: 5px;
  background: ${props => props.color || ''}
`

const FlatButton = styled.button`
  color: white;
  background: none;
  border-radius: 10px;
  border-color: ${props => props.color};
  border-style: solid;
  border-color: rgb(242, 59, 59);
  outline: none;
  cursor: pointer;
  margin-top: 5px;
`;

const DataList = ({title, fields, color, remove, onClick}) => <DlDiv color={color}>
  <DlTitle>{title}</DlTitle>
  {(remove) ? <FlatButton onClick={onClick}>Remove</FlatButton> : <></>}
  <DL>{fields.map(f => <>
    <DT><strong>{f.name}:</strong></DT>
    <dd>{(f.value) ? f.value : 'No data'}</dd>
  </>)}</DL>
</DlDiv>;

DataList.propTypes = {
  title: propTypes.string,
  fields: propTypes.node,
  color: propTypes.string,
  onClick: propTypes.func,
  remove: propTypes.node

}

export default DataList;