import React from 'react';
import { Button } from '../components/CommonComponents.js';
import PageCard from '../components/PageCard.js';
import { Link } from 'react-router-dom';
import { getRoles } from '../utils/TokenUtils.js';
import styled from 'styled-components';
import SimpleCard from '../components/SimpleCard.js';

const ContactAdmin = () => <PageCard title='Welcome to Agroledger'>
	<p>Looks like you&apos;ve just created an account! To finish getting set up, please contact: <a href="mailto:kip@cansheep.ca">kip@cansheep.ca</a></p>
</PageCard>

const ReportDashboardView = () => {
	const roles = getRoles();
	return (
		(roles && roles.length === 0) ? 
			<ContactAdmin /> : 
			<PageCard back title='Reports'>
			<hr />
			<WelcomeDiv>Welcome to Reports</WelcomeDiv>


			<LeftDiv>
				<LeftInnerContainer>
					<PanelDiv>
						<SimpleCard
							title="Tag Report"
						>
							<h2>Add Sheep</h2>
							<pr>Add sheep to your premises and view prior reports</pr><br></br><br></br>
							<Link to="/sheep/create/manual">
								<Button>Add Sheep</Button>
							</Link>
							<br></br>

							<h2>Retire a Tag</h2>
							<pr>Retire the tag of sheep in your premises and view prior reports</pr>
							<br></br><br></br><br></br>
							<Link to='/reports/retire/listView'>
								<Button>Retire</Button>
							</Link>
							<br></br>

							<h2>Activate Tags</h2>
							<pr>Activate new tags and view prior reports</pr>
							<br></br><br></br><br></br>
							<Link to="/reports/tagactivation">
								<Button>Activate</Button>
							</Link>
							<br></br>

							<h2>Replace Tags</h2>
							<pr>Replace tags for your sheep and view prior reports</pr>
							<br></br><br></br><br></br>
								<Link to = "/reports/replace/listView">
									<Button>Replace</Button>
								</Link>
							<br></br>
						</SimpleCard>
					</PanelDiv>
					<br></br>
				</LeftInnerContainer>
			</LeftDiv>
			<LeftDiv>
			<LeftInnerContainer>
					<PanelDiv>
						<SimpleCard
							title="Movement Reports"
						>
							<h2>Move-In</h2>
							<pr>Move new sheep into your premises and view prior reports</pr><br></br><br></br>
							<Link to="/reports/movein">
								<Button>Move-In</Button>
							</Link>
							<br></br>
							
							<h2>Move-Out</h2>
							<pr>Move your sheep out of your premises and view prior reports</pr>
							<br></br><br></br><br></br>
							<Link to='/reports/moveout'>
								<Button>Move-Out</Button>
							</Link>
							<br></br>

							<h2>Import</h2>
							<pr>Import sheep into your premises and view prior reports</pr>
							<br></br><br></br><br></br>
							<Link to='/reports/import'>
								<Button>Import</Button>
							</Link>
							<br></br>
							
							<h2>Export</h2>
							<pr>Export sheep out of your premises and view prior reports</pr>
							<br></br><br></br><br></br>
							<Link to = '/reports/importExport'>
								<Button>Export</Button>
							</Link>
							<br></br>

							<h2>Transport</h2>
							<pr>Transport your sheep out of your premises and view prior reports</pr>
							<br></br><br></br><br></br>
							<Link to = '/reports/transport/list'>
								<Button>Transport</Button>
							</Link>
							<br></br>
						</SimpleCard>
					</PanelDiv>
					<br></br>
				</LeftInnerContainer>
			</LeftDiv>
			<MidDiv>
			<LeftInnerContainer>
					<PanelDiv>
						<SimpleCard
							title="Other Reports"
						>
							<h2>Medical</h2>
							<pr>Record medical reports for your sheep and view prior reports</pr><br></br><br></br>
							<Link to="/reports/medical/list">
								<Button>Medical</Button>
							</Link>
							<br></br>

							<h2>Sighting</h2>
							<pr>Record animal sightings and view prior reports</pr>
							<br></br><br></br><br></br>
							<Link to='/reports/sighting/listView'>
								<Button>Sighting</Button>
							</Link>
							<br></br>

							<h2>Dispose Sheep</h2>
							<pr>Dispose of the sheep in your premises and view prior reports</pr>
							<br></br><br></br><br></br>
							<Link to='/reports/disposal'>
								<Button>Dispose</Button>
							</Link>
							<br></br>

							<h2>Edit Sheep</h2>
							<pr>Edit sheep in your premises and view prior reports</pr>
							<br></br><br></br><br></br>
							<Link to='/reports/sheepedit'>
								<Button>Edit Sheep</Button>
							</Link>
							<br></br>


						</SimpleCard>
					</PanelDiv>
				</LeftInnerContainer>
			</MidDiv>
		</PageCard>
	);
};

export default ReportDashboardView;

const WelcomeDiv = styled.div`
	font-size: 20px;
`;

const LeftDiv = styled.div`
  width: 33%;
  float: left;
  display: inline-block;
`

const MidDiv = styled.div`
  width: 34%;
  display: inline-block;
  
`

const PanelDiv = styled.div`
	padding: .25rem;
	background: grey;
	border-radius: 10px;
`



const LeftInnerContainer = styled.div`
  padding: .25rem;
`;