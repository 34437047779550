import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import axios from 'axios';
import PageCard from '../components/PageCard.js';
import SimpleCard from '../components/SimpleCard';
import StripedTable from '../components/StripedTable';
import { FilterSection } from '../components/SheepReportComponents';
import TraceSheepDetails from '../components/TraceSheepDetails.js';

const columns = [
  { name: 'Date of Arrival', id: 'dateOfArrival' },
  { name: 'Date of Departure', id: 'dateOfDeparture' },
  { name: 'Premise', id: 'premise' },
  { name: 'Operation', id: 'operation' },
  { name: 'Owner', id: 'owner' },
  { name: 'Action', id: 'view' }
];

export default function TraceSheepDetailsList(props) {
  const isoNumber = props.match.params.isoNumber;
  const history = useHistory();
  
  const [tracedSheep, setTracedSheep] = useState({});
  const [tracedList, setTracedList] = useState([]);
  const [displayList, setDisplayList] = useState([]);
  const [filteredDisplayList, setFilteredDisplayList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [outside, setOutside] = useState(false);
  const [viewIdx, setViewIdx] = useState(-1);

  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
  });
  const [tempFilter, setTempFilter] = useState({
    startDate: '',
    endDate: '',
  });

  useEffect(() => {
    return axios.get(`/api/trace/sheep/${isoNumber}`)
      .then(({ data }) => {
        setTracedSheep(data.sheep);       
        setTracedList(data.traceList);
        
        const displayData = data.traceList.map((trace, idx) => ({
          dateOfArrival: trace.dateOfArrival !== undefined ? trace.dateOfArrival.split('T')[0] : 'N/A',
          dateOfDeparture: trace.dateOfDeparture !== undefined ? trace.dateOfDeparture.split('T')[0] : 'N/A',
          premise: trace.premise.name !== undefined ? trace.premise.name : "N/A",
          operation: trace.premise.operation !== undefined ? trace.premise.operation.businessName : "N/A",
          owner: trace.userInformation.firstName + ' ' + trace.userInformation.lastName,
          view: <ViewButton onClick={() => setViewIdx(idx)}>View</ViewButton>
        }));
        setDisplayList(displayData);
        setFilteredDisplayList(displayData.reverse());  

        if(displayData.length > 0 && displayData[0].dateOfArrival === 'N/A')
          setOutside(true);

        setLoaded(true);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err);
        }
      });
  }, []);

  useEffect(() => {
    if (filter.startDate && filter.endDate) {
      const startDate = new Date(filter.startDate);
      const endDate = new Date(filter.endDate);
  
      const filtered = displayList.filter(trace => {
        const arrivalDate = new Date(trace.dateOfArrival);
        const departDate = new Date(trace.dateOfDeparture);
        if (
          arrivalDate >= startDate &&
          arrivalDate <= endDate &&
          departDate >= startDate &&
          departDate <= endDate
        ) {
          return true;
        } else {
          return false;
        }
      });
      setFilteredDisplayList(filtered.reverse());
    } else {
      setFilteredDisplayList(displayList.reverse());
    }
  }, [filter]);

  const updateTempFilter = (updatedValue, filterID) => {
    const newFilter = {...tempFilter};
    newFilter[filterID] = updatedValue;
    if(filterID === 'startDate'){
      if(tempFilter.endDate === ''){
        newFilter.endDate = updatedValue;
      } else {
        const startDate = new Date(updatedValue);
        const endDate = new Date(tempFilter.endDate)
        if(startDate > endDate) newFilter.endDate = updatedValue;
      }

      if(updatedValue === '') newFilter.endDate = '';
    }

    if(filterID === 'endDate'){
      if(tempFilter.startDate === ''){ 
        newFilter.startDate = updatedValue;
      } else {
        const startDate = new Date(tempFilter.startDate);
        const endDate = new Date(updatedValue);
        if(endDate < startDate) newFilter.startDate = updatedValue;
      }

      if(updatedValue === '') newFilter.startDate = '';
    }
    setTempFilter(newFilter);
  }

  const filterFields = [
    {
      id: 'startDate',
      name: 'Start Date',
      type: 'date',
      onChange: (e) => {
        updateTempFilter(e.target.value, 'startDate');
      },
      onClick: (e) => {
        e.preventDefault();
        updateTempFilter('', 'startDate');
      },
    },
    {
      id: 'endDate',
      name: 'End Date',
      type: 'date',
      onChange: (e) => {
        updateTempFilter(e.target.value, 'endDate');
      },
      onClick: () => {
        updateTempFilter('', 'endDate');
      }
    },
  ];

  const handleApply = (e) => {
    e.preventDefault();
    const newFilter = {...tempFilter};
    setFilter(newFilter);
  }

  const handleClear = (e) => {
    e.preventDefault();
    const newFilter = {
      startDate: '',
      endDate: '',
    };

    setTempFilter(newFilter);
    setFilter(newFilter);
  }

  const customBack = () => {
    if (viewIdx === -1) {
      history.goBack();
    } else {
      setViewIdx(-1);
    }
  };

  return (
    <PageCard title={`Trace Sheep ${isoNumber}`} back line onBack={customBack}>
      {viewIdx === -1 ?
        <>
          <InfoMessage>
            <SimpleCard title="Search Information" size="md" />
            <p>Click "View" to see more details on the sheep's whereabouts at a certain point</p>
          </InfoMessage>
          <InfoMessage>
            <SimpleCard title="Status" size="md" /> 
              {outside ? <p>This sheep is currently outside our system, we cannot provide the most recent information on it.</p> : 
                <> 
                  {tracedSheep.movedOut? (<p>This sheep is currently in transit</p>) : 
                              (<>
                                {tracedSheep.deathDate ? (<p>{`This sheep died on ${tracedSheep.deathDate.split('T')[0]}`}</p>) 
                                  : (<p>This sheep is currently on a premise</p>)
                                }                                    
                              </>)                                    
                            }
                </>}  
          </InfoMessage>
          <SimpleCard title="Filters">
            <FilterSection
              data={filterFields}
              onApply={handleApply}
              onClear={handleClear}
              values={tempFilter}
            />
          </SimpleCard>
          {loaded ? (<>
            <StripedTable 
              columns={columns}
              rows={filteredDisplayList}
              max={10}
              paginate
            />          
          </>) : (<>Loading...</>)}
      </>
      :
      <TraceSheepDetails
        sheepId={tracedSheep._id}
        dateOfArrival={tracedList[viewIdx].dateOfArrival}
        dateOfDeparture={tracedList[viewIdx].dateOfDeparture}
        premise={tracedList[viewIdx].premise}
        userInfo={tracedList[viewIdx].userInformation}
      />
    }
    </PageCard>
  );
}

const InfoMessage = styled.div`
  margin: 1em auto 2.5em;

  p {
    margin: 1em;
  }
`;

const ViewButton = styled.div`
  width: 3em;
  font-size: 1.1rem;
  padding: 0.25em;
  text-align: center;
  border: 2px solid white;
  border-radius: 5px;
  background-color: grey;
  cursor: pointer;
`;