// ExportReportDetailView.js
import React, { useEffect, useState } from 'react';
import PageCard from '../../components/PageCard';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import SimpleCard from '../../components/SimpleCard';
import propTypes from 'prop-types';
import StripedTable from '../../components/StripedTable';
import { NAField } from '../../components/SheepReportComponents';

const detailExportColumns = [
  {
    id: 'isoNumber',
    name: 'Sheep ISO Number',
    required: true
  },
  {
    id: 'localMgmtNumber',
    name: 'Local Management Number'
  },
  {
    id: 'tattooNumber',
    name: 'Tattoo Number',
    required: true
  },
  {
    id: 'gender',
    name: 'Gender'
  },
  {
    id: 'breed',
    name: 'Breed',
    required: true
  },
  {
    id: 'birthdate',
    name: 'Birth Date'
  }
];

const fetchExportReportInfo = async (id) => {
  try{
    const request = await axios.get(`/api/animalexport/${id}`);
    return request.data;
  } catch (err){
    console.log(err);
    throw new Error(`An error occurred fetching sheep info: ${err.message}`);
  }
}

const Info = ({name, value}) => {
  return <div>
    <span>{name}:&nbsp;</span><span>{value}</span>
  </div>;
}

Info.propTypes = {
  name: propTypes.string,
  value: propTypes.any
}

const ExportReportDetailView = () => {
  const [exportReportInfo, setExportReportInfo] = useState();
  const [exportSheepInfo, setExportSheepInfo] = useState([]);
  const [isTemp, setIsTemp] = useState();
  const [arrival, setArrival] = useState(false);
  const [reportLocation, setReportLocation] = useState(null);
  const params = useParams();
  const id = params.id;
  
  // fetchExportReportInfo
  const fetchAndSetExportReportInfo = async () => {
    try {
      let fetchedExportReportInfo = await fetchExportReportInfo(id);
      
      //this is so we don't get errors if we look at the detail view of old reports that used createdDate
      if (fetchedExportReportInfo.createdAt){
        fetchedExportReportInfo.createdAt = (new Date(fetchedExportReportInfo.createdAt)).toISOString().split('T')[0];
      }
      else{
        fetchedExportReportInfo.createdAt = (new Date(fetchedExportReportInfo.createdDate)).toISOString().split('T')[0];
      }
      
      fetchedExportReportInfo.departureTime = (new Date(fetchedExportReportInfo.departureTime)).toISOString().split('T')[0];
      if(fetchedExportReportInfo.arrivalTime) fetchedExportReportInfo.arrivalTime = (new Date(fetchedExportReportInfo.arrivalTime)).toISOString().split('T')[0];

      setExportSheepInfo(fetchedExportReportInfo.animals);
      setExportReportInfo(fetchedExportReportInfo);
      setIsTemp(fetchedExportReportInfo.isTemp);
      
      if (fetchedExportReportInfo.arrivalTime){
        setArrival(true);
      }

      // if this report has a geolocation, save that to a state as well
      if (
        fetchedExportReportInfo.location &&
        Array.isArray(fetchedExportReportInfo.location.coordinates) &&
        fetchedExportReportInfo.location.coordinates.length === 2
      ) {
        const longlat = fetchedExportReportInfo.location.coordinates;
        setReportLocation([longlat[1], longlat[0]]);
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  useEffect(() =>  {
    fetchAndSetExportReportInfo();
  }, [])

  exportSheepInfo.forEach(s => {
    s.isoNumber = (s.tag.isoNumber) ? s.tag.isoNumber : <NAField />;
    s.tattooNumber = (s.tag.tattooNumber) ? s.tag.tattooNumber : <NAField />;
    s.pid = (s.premise.pid) ? s.premise.pid : <NAField />;
    s.localMgmtNumber = (s.tag.localMgmtNumber) ? s.tag.localMgmtNumber : <NAField />;
    // Should capitalize
    if(!s.gender) s.gender = <NAField />;
    if(!s.subgender) s.subgender = <NAField />;
    if (!s.breed) s.breed = <NAField />;
    if (s.birthdate) s.birthdate = (new Date(s.birthdate)).toISOString().split('T')[0];
    
  });

  return <>
  <PageCard title="Export Report Details" verify={`/verify/export/${id}`} back>
    {(exportReportInfo) ? <>
      <SimpleCard title="Export Report Information" size="md">
      {(isTemp) ? <>
        <Info name="Created At" value={exportReportInfo.createdAt} />
        <Info name="Departure Site" value={exportReportInfo.departurePID} />
        <Info name="Export Country" value={exportReportInfo.destinationLocation} />
        <Info name="Destination PID" value={exportReportInfo.destinationPID} />
        <Info name="Arrival Date" value={exportReportInfo.arrivalTime} />
      </>: <>
        <Info name="Created At" value={exportReportInfo.createdAt} />
        <Info name="Departure Premise ID" value={exportReportInfo.departurePID} />
        <Info name="Export Location or PID" value={exportReportInfo.destinationLocation ? exportReportInfo.destinationLocation : exportReportInfo.destinationPID}  />
        <Info name="Departure Date" value={exportReportInfo.departureTime} />
        {(arrival) ? <>
          <Info name="Arrival Date" value={exportReportInfo.arrivalTime} />
        </>: <> </>}
      </>}
      </SimpleCard>
      {reportLocation &&
        <SimpleCard title="Location" size="md">
          <Info name="Latitude" value={reportLocation[0]} />
          <Info name="Longitude" value={reportLocation[1]} />
        </SimpleCard>
      }
      <SimpleCard title="Transported Sheep" size="md">
      </SimpleCard>
      <StripedTable  
          columns={detailExportColumns}
          rows={exportSheepInfo}
          ShowFilters
          ShowCount
          max={10}
        />
      {/* Operation Info */}
      <SimpleCard title="Vehicle Information" size="sm">
        <Info name="License Plate Number" value={exportReportInfo.vehicleNumber} />
      </SimpleCard>
    </>: <>Loading</>}
  </PageCard>
</>
};

export default ExportReportDetailView;