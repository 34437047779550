// ImportReportDetailView.js
import React, { useEffect, useState } from 'react';
import PageCard from '../../components/PageCard';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import SimpleCard from '../../components/SimpleCard';
import propTypes from 'prop-types';
import styled from 'styled-components';
import StripedTable from '../../components/StripedTable';
import { NAField } from '../../components/SheepReportComponents';

const detailExportColumns = [
  {
  id: 'isoNumber',
    name: 'Sheep ISO Number',
    required: true
  },
];


const getPremiseName = (id) => {
  return axios.get(`/api/premises/name/${id}`)
    .then((res) => {
      return res.data;  
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

const fetchImportInfo = async (id) => {
  try{
    const request = await axios.get(`/api/import/detail/${id}`);
    return request.data;
  } catch (err){
    console.log(err);
    throw new Error(`An error occurred fetching sheep info: ${err.message}`);
  }
}

const Info = ({name, value}) => {
  return <div>
    <span>{name}:&nbsp;</span><span>{value}</span>
  </div>;
}

Info.propTypes = {
  name: propTypes.string,
  value: propTypes.any
}

const ImportReportDetailView = () => {
  const [importReportInfo, setImportReportInfo] = useState();
  const [importSheepInfo, setImportSheepInfo] = useState([]);
  const [abbatoirPremiseName, setAbbatoirPremiseName] = useState([]);
  const [firstDestpremiseName, setFirstDestPremiseName] = useState([]);
  const [reportLocation, setReportLocation] = useState(null);
  const params = useParams();
  const id = params.id;
  
  // fetchExportReportInfo
  const fetchAndSetExportReportInfo = async () => {
    try {

      let fetchedImportReportInfo = await fetchImportInfo(id);
     
      try {
        let fetchedAbattoirPremiseName = await getPremiseName(fetchedImportReportInfo.abattoirPID);
        setAbbatoirPremiseName(fetchedAbattoirPremiseName[0].name);
      } catch{
        
      }

      try {
        let fetchedFirstDestPremiseName = await getPremiseName(fetchedImportReportInfo.firstDestinationPID);
        setFirstDestPremiseName(fetchedFirstDestPremiseName[0].name);
      } catch{

      }
      setImportSheepInfo(fetchedImportReportInfo.animals);
      setImportReportInfo(fetchedImportReportInfo);

      // if this report has a geolocation, save that to a state as well
      if (
        fetchedImportReportInfo.location &&
        Array.isArray(fetchedImportReportInfo.location.coordinates) &&
        fetchedImportReportInfo.location.coordinates.length === 2
      ) {
        const longlat = fetchedImportReportInfo.location.coordinates;
        setReportLocation([longlat[1], longlat[0]]);
      }

    } catch (err) {
      toast.error(err.message);
    }
  }

  useEffect(() =>  {
    fetchAndSetExportReportInfo();
  }, [])

  importSheepInfo.forEach(s => {
    s.isoNumber = (s.tag.isoNumber) ? s.tag.isoNumber : <NAField />;
  });

  if(importReportInfo){
    importReportInfo.departureSite = (importReportInfo.departureSite) ? importReportInfo.departureSite : <NAField />;
  }
 
  return <>
  <PageCard title="Import Report Details" verify={`/verify/import/${id}`} back>
    {(importReportInfo && firstDestpremiseName) ? <>
      <SimpleCard title="Location Information" size="md">
        {/* Location Panel*/}
        <OneThirdDiv>
          <Info name="Export Country" value={importReportInfo.exportingCountry} />  
        </OneThirdDiv>

        <OneThirdDiv>
          <Info name="Abbatoir Premise ID" value={importReportInfo.abattoirPID} />  
        </OneThirdDiv>

        <OneThirdDiv>
          <Info name="First Destination Premise PID" value={importReportInfo.firstDestinationPID} />  
        </OneThirdDiv>
        
        <OneThirdDiv>
          <Info name="Departure Site" value={importReportInfo.departureSite} />  
        </OneThirdDiv>

        <OneThirdDiv>
          <Info name="Abbatoir Premise Name" value={abbatoirPremiseName} />  
        </OneThirdDiv>

        <OneThirdDiv>
          <Info name="First Destination Premise Name" value={firstDestpremiseName} />  
        </OneThirdDiv>
      </SimpleCard>
      <br></br>
      <br></br>

      {/* Transport Sheep Info */}
      <SimpleCard title="Transported Sheep" size="md">
        <StripedTable  
            columns={detailExportColumns}
            rows={importSheepInfo}
            ShowCount
            max={10}
          />
      </SimpleCard>

      {/* Vehicle Info */}
      <SimpleCard title="Vehicle Information" size="sm">
        <Info name="License Plate Number" value={importReportInfo.vehicleIdentification} />
      </SimpleCard>
      <br></br>

      {/* Arrival Date Info */}
      {(importReportInfo.dateOfArrival) ? <>
        <SimpleCard title="Arrival Date" size="sm">
          <Info name="Date" value={importReportInfo.dateOfArrival} />
        </SimpleCard>
      </>: <> </>}
      <br></br>

      {reportLocation &&
        <SimpleCard title="Location" size="sm">
          <Info name="Latitude" value={reportLocation[0]} />
          <Info name="Longitude" value={reportLocation[1]} />
        </SimpleCard>
      }

    </>: <>Loading</>}
  </PageCard>
</>
};

const OneThirdDiv = styled.div`
  width: 33%;
  float: left;
  display: inline-block;
`

export default ImportReportDetailView;