import { useEffect, useState } from "react";
import { getUsername } from "../../utils/TokenUtils";
import axios from 'axios';
import { getOperationsForUser } from "../../utils/OperationUtils";
import SearchTable from "../../components/SearchTable";
import ViewEditDispose from "../../components/ViewEditDispose";
import DescriptionCard from "../../components/DescriptionCard";

const { default: PageCard } = require("../../components/PageCard")

const OperationsDashboard = () => {
  const [operations, setOperations] = useState([]);

  const fetchOperations = async () => {
    const ops = await getOperationsForUser(getUsername());
    console.log({ops})
    ops.forEach(o => {
      o.numPrems = o.premises.length;
      o.sfcpString = o.sfcp ? 'True' : 'False';
      o.cvspString = o.cvsp ? 'True' : 'False';
      o.actions = <ViewEditDispose 
        id={o._id} 
        endpoint={'/operations/'} 
        hideDispose
        hideReplace
        editName='Manage'
      />
    });
    setOperations(ops);
  }

  useEffect(() => {
    fetchOperations();
  }, [operations.length > 1])

  const columns = [
    {
      id: 'businessName',
      name: 'Name'
    },
    {
      id: 'operationType',
      name: 'Type'
    },
    {
      id: 'numPrems',
      name: '# Premises'
    },
    {
      id: 'sfcpString',
      name: 'SFCP'
    },
    {
      id: 'cvspString',
      name: 'CVSP'
    },
    {
      id: 'actions',
      name: 'Actions'
    }
  ]
  return <PageCard
    title='Operations'
    to='/operations/create'
    line
    back
  >
    <DescriptionCard
      page='operation'
      create={true}
      vowel={true} 
    />
    <SearchTable
      columns={columns}
      rows={operations}
      count={10}
      hideFilter
      />
  </PageCard>
}

export default OperationsDashboard;