import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import axios from 'axios';
import PageCard from '../components/PageCard.js';
import SimpleCard from '../components/SimpleCard';
import StripedTable from '../components/StripedTable';
import { FilterSection } from '../components/SheepReportComponents';
import { agGreen } from '../components/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

const columns = [
  { name: 'ISO Number', id: 'isoNumber' },
  { name: 'Date Searched', id: 'dateSearched' },
  { name: 'Action', id: 'searchAgain' }
];

export default function TraceDashboard() {
  const [traceHistory, setTraceHistory] = useState([]);
  const [filteredHistory, setFilteredHistory] = useState([]);
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
  });
  const [tempFilter, setTempFilter] = useState({
    startDate: '',
    endDate: '',
  });
  const [tracePermission, setTracePermission] = useState(true);

  const history = useHistory();

  const handleSearchAgain = (isoNumber) => {
    return axios.post('/api/trace/history', { isoNumber })
      .then(() => {
        history.push(`/trace/${isoNumber}`);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err);
        }
      });
  };

  useEffect(() => {
    return axios.get("/api/trace/history")
      .then(({ data }) => {
        const traces = data.map(hist => ({
          isoNumber: hist.isoNumber,
          dateSearched: hist.dateSearched.split('T')[0],
          searchAgain: 
            <SearchAgainButton onClick={() => handleSearchAgain(hist.isoNumber)}>
              Search again
            </SearchAgainButton>
        }));
        setTraceHistory(traces);
        setFilteredHistory(traces);
      })
      .catch(err => {
        if (err.response.data && err.response.data.message) {
          if (err.response.data.message.includes("permission")) {
            setTracePermission(false);
          } else {
            toast.error(err.response.data.message);
          }
        } else {
          toast.error(err);
        }
      });
  }, []);

  useEffect(() => {
    if (filter.startDate && filter.endDate) {
      const startDate = new Date(filter.startDate);
      const endDate = new Date(filter.endDate);
  
      const filtered = traceHistory.filter(trace => {
        const dateSearched = new Date(trace.dateSearched);
        if (dateSearched >= startDate && dateSearched <= endDate) {
          return true;
        } else {
          return false;
        }
      });
      setFilteredHistory(filtered);
    } else {
      setFilteredHistory(traceHistory);
    }
  }, [filter]);

  const updateTempFilter = (updatedValue, filterID) => {
    const newFilter = {...tempFilter};
    newFilter[filterID] = updatedValue;
    if(filterID === 'startDate'){
      if(tempFilter.endDate === ''){
        newFilter.endDate = updatedValue;
      } else {
        const startDate = new Date(updatedValue);
        const endDate = new Date(tempFilter.endDate)
        if(startDate > endDate) newFilter.endDate = updatedValue;
      }

      if(updatedValue === '') newFilter.endDate = '';
    }

    if(filterID === 'endDate'){
      if(tempFilter.startDate === ''){ 
        newFilter.startDate = updatedValue;
      } else {
        const startDate = new Date(tempFilter.startDate);
        const endDate = new Date(updatedValue);
        if(endDate < startDate) newFilter.startDate = updatedValue;
      }

      if(updatedValue === '') newFilter.startDate = '';
    }
    setTempFilter(newFilter);
  }

  const filterFields = [
    {
      id: 'startDate',
      name: 'Start Date',
      type: 'date',
      onChange: (e) => {
        updateTempFilter(e.target.value, 'startDate');
      },
      onClick: (e) => {
        e.preventDefault();
        updateTempFilter('', 'startDate');
      },
    },
    {
      id: 'endDate',
      name: 'End Date',
      type: 'date',
      onChange: (e) => {
        updateTempFilter(e.target.value, 'endDate');
      },
      onClick: () => {
        updateTempFilter('', 'endDate');
      }
    },
  ];

  const handleApply = (e) => {
    e.preventDefault();
    const newFilter = {...tempFilter};
    setFilter(newFilter);
  }

  const handleClear = (e) => {
    e.preventDefault();
    const newFilter = {
      startDate: '',
      endDate: '',
    };

    setTempFilter(newFilter);
    setFilter(newFilter);
  }

  return (
    <PageCard title='Trace A Sheep' to={tracePermission && '/trace/search'} back line>
      <WelcomeDiv>
        <SimpleCard title="Welcome to the Traceability Dashboard" size="md" />
        <WelcomeMessage>
          <div>
            You can trace the movement of sheep here.
            Find out where a sheep has been, and where the sheep is now.
            Below is all the search results you have made.
          </div>
          {tracePermission &&
            <>
              <FontAwesomeIcon icon={faPlusCircle} color={agGreen} />
              &nbsp;: Click this button to start searching
            </>
          }
        </WelcomeMessage>
      </WelcomeDiv>
      {tracePermission ?
        <>
          <SimpleCard title="Filters">
            <FilterSection
              data={filterFields}
              onApply={handleApply}
              onClear={handleClear}
              values={tempFilter}
            />
          </SimpleCard>
          <StripedTable 
            columns={columns}
            rows={filteredHistory}
            max={10}
            paginate
          />
        </>
        :
        <NoPermissionBlock>
          You do not have permission to trace sheep.
        </NoPermissionBlock>
      }
    </PageCard>
  );
}

const WelcomeDiv = styled.div`
  border-radius: 7px;
  background: rgb(165, 165, 165);
  color: white;
  width: 800px;
  margin: 20px auto;
  padding: 0.5em;
  box-shadow: 0 10px 7px rgb(25, 25, 25);
`;

const WelcomeMessage = styled.div`
  font-style: italic;
  font-size: 18px;
  padding: 0.25em 1em 1em;

  div {
    margin-bottom: 0.75em;
  }
`;

const SearchAgainButton = styled.button`
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  padding: 0.5em;
  background-color: #66ad57;
  cursor: pointer;
`;

const NoPermissionBlock = styled.div`
  width: max-content;
  margin: 3em auto;
  font-size: 1.5rem;
  padding: 1.5em 3em;
  border-radius: 5px;
  border: 2px solid white;
  background-color: rgb(255, 88, 88);
`;