// SheepRouter.js
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SheepCsvImportView from '../views/Sheep/SheepCsvImportView';
import SheepCreateManual from '../views/Sheep/SheepCreateManualView';
import SheepDetailView from '../views/Sheep/SheepDetailView';
import SheepDashboardView from '../views/Sheep/SheepDashboardView';
import SheepOperationView from '../views/Sheep/SheepOperationView';
import SheepPremiseView from '../views/Sheep/SheepPremiseView';
import SheepDisposeView from '../views/Sheep/SheepDisposeView';
import SheepReplaceView from '../views/Sheep/SheepReplaceView';
import SheepEditView from '../views/Sheep/SheepEditView';
import SheepLostTagView from '../views/Sheep/SheepLostTagView'; 
const SheepRouter = () => (
  <Switch>
    <Route
      exact
      path='/sheep'
      // redirect='/403'
      // restricted={restrictedCheck(createAnimal)}
      component={SheepDashboardView}
    />
    <Route
      exact
      path='/sheep/dispose'
      component={SheepDisposeView}
    />
    <Route
      exact
      path='/sheep/replace'
      component={SheepReplaceView}
    />
    <Route
      exact
      path='/sheep/edit'
      component={SheepEditView}
    />
    <Route
      exact
      path='/sheep/operation/:id'
      component={SheepOperationView}
    />
    <Route
      exact
      path='/sheep/premise/:id'
      component={SheepPremiseView}
    />
    <Route
      exact
      path="/sheep/create/csv"
      component={SheepCsvImportView}
    />
    <Route exact path='/sheep/:id' component={SheepDetailView} />
    <Route path='/sheep/create/manual' component={SheepCreateManual} />
    <Route path='/sheep/create/lostTag' component={SheepLostTagView} />
    
  </Switch>
);

export default SheepRouter;
