import React, { useState, useEffect } from 'react';
import PageCard from '../../components/PageCard';
import SimpleCard from '../../components/SimpleCard';
import StripedTable from '../../components/StripedTable';
import ViewEditDispose from '../../components/ViewEditDispose';
import axios from 'axios';
import { FilterSection } from '../../components/SheepReportComponents';
import ReportDescriptionCard from '../../components/ReportDescriptionCard.js';

const getEditReports = () => {
  return axios.get('/api/sheep/edit/sheep')
    .then((res) => {
      return res.data;  
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

const tableColumns = [
  {
    id: 'isoNumber',
    name: 'Sheep Identifier',
    required: true
  },
  {
    id: 'createdAt',
    name: 'Edited Date',
    required: true
  },
  {
    id: 'actions',
    name: 'Actions'
  }
];

const SheepEditList = () => {
  const [editReport, setEditReport] = useState([]);
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
  });

  const [tempFilter, setTempFilter] = useState({
    startDate: '',
    endDate: '',
  });
  
  let filterFields = [
    {
      id: 'startDate',
      name: 'Start Date',
      type: 'date',
      onChange: (e) => {
        updateTempFilter(e.target.value, 'startDate');
      },
      onClick: (e) => {
        e.preventDefault();
        updateTempFilter('', 'startDate');
      },
    },
    {
      id: 'endDate',
      name: 'End Date',
      type: 'date',
      onChange: (e) => {
        updateTempFilter(e.target.value, 'endDate');
      },
      onClick: () => {
        updateTempFilter('', 'endDate');
      }
    },
  ]

  const handleApply = (e) => {
    e.preventDefault();
    const newFilter = {...tempFilter};
    setFilter(newFilter);
  }

  const handleClear = (e) => {
    e.preventDefault();
    const newFilter = {
      startDate: '',
      endDate: '',
    };

    setTempFilter(newFilter);
    setFilter(newFilter);
  }

  const fetchEditReports = async () => {
    let fetchedReport = await getEditReports();
  
    fetchedReport.forEach(r => {
      r.actions = <ViewEditDispose 
        id={r._id} 
        endpoint={'/reports/sheepedit/'}
        hideDispose
        hideEdit
        hideReplace
      />;
      r.createdAt = (new Date(r.createdAt)).toISOString().split('T')[0];
    });
    setEditReport(fetchedReport.reverse());
  }

  useEffect(() => {
    fetchEditReports();
  }, []);


  if (editReport){
    editReport.forEach(s => {
      if (s.animal){
        if (s.animal.tag.isoNumber){
          s.isoNumber = s.animal.tag.isoNumber;
        } else if (s.animal.tag.tattooNumber){
          s.isoNumber = s.animal.tag.tattooNumber;
        } else if (s.animal.tag.localMgmtNumber){
          s.isoNumber = s.animal.tag.localMgmtNumber;
        }
      }
  
    });
  }


  const updateTempFilter = (updatedValue, filterID) => {
    const newFilter = {...tempFilter};
    newFilter[filterID] = updatedValue;
    if(filterID === 'startDate'){
      if(tempFilter.endDate === ''){
        newFilter.endDate = updatedValue;
      } else {
        const startDate = new Date(updatedValue);
        const endDate = new Date(tempFilter.endDate)
        if(startDate > endDate) newFilter.endDate = updatedValue;
      }

      if(updatedValue === '') newFilter.endDate = '';
    }

    if(filterID === 'endDate'){
      if(tempFilter.startDate === ''){ 
        newFilter.startDate = updatedValue;
      } else {
        const startDate = new Date(tempFilter.startDate);
        const endDate = new Date(updatedValue);
        if(endDate < startDate) newFilter.startDate = updatedValue;
      }

      if(updatedValue === '') newFilter.startDate = '';
    }
    setTempFilter(newFilter);
  }

  const filteredReports = editReport.filter(s => {
    let filterResult = true;
  
    const filterKeys = Object.keys(filter);
    filterKeys.forEach(key => {
      if(filter[key] === '') return;
      if(key.includes('Date')) return;
      if(s[key] !== filter[key]) filterResult = false;
    });
    
    const creationDate = new Date(s['createdAt']);
    const startDate = new Date(filter.startDate);
    const endDate = new Date(filter.endDate);
    if(creationDate < startDate) return false;
    if(creationDate > endDate) return false;

    return filterResult;
  });


  return <>
    <PageCard title=" Sheep Edit Reports" to='/reports/edit/selectView' line back>
    <ReportDescriptionCard page='sheep edit report'></ReportDescriptionCard>

      <SimpleCard
        title="Filters"
        description='Use filters to find reports by date of their creation'
      >
        <FilterSection
          data={filterFields}
          onApply={handleApply}
          onClear={handleClear}
          values={tempFilter}
        />
      </SimpleCard>

      <SimpleCard >
        <StripedTable  
          columns={tableColumns}
          rows={filteredReports}
          ShowFilters
          ShowCount
          max={10} 
          paginate
        />
      </SimpleCard>
    </PageCard>
  </>
};

export default SheepEditList;