import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import FormCard from '../../components/FormCard.js';
import { Button,
  FormInputWrapper,
	FormInputLabel,
	FormSelectInput, } from '../../components/CommonComponents';
import DropDown from '../../components/DropDown.js';
import DateField from '../../components/DateField.js';
import Input from '../../components/Input.js';
import SimpleCard from '../../components/SimpleCard';
import StripedTable from '../../components/StripedTable';
import { extractDataFromHeaders } from '../../utils/CSVUtils';
import { getPremisesForUser } from '../../utils/PremiseUtils';
import { getUsername } from '../../utils/TokenUtils';
import { createBulkTempExportReport } from '../../utils/ExportUtils';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import propTypes from 'prop-types';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { countries } from '../../utils/Countries';

const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin>

    </FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

const CheckBox = ({onChange, checked}) => {
  return <input 
    style={{cursor: 'pointer'}} 
    type='checkbox' 
    checked={checked} 
    onChange={onChange} />;
}

CheckBox.propTypes = {
  onChange: propTypes.func,
  checked: propTypes.bool
}

function TempExportForm({ data, headers }) {
	const [exportSheep, setExportSheep] = useState([]);
	const [selectedSheeps, setSelectedSheeps] = useState([]);
	const [exportToCountry, setExportToCountry] = useState(false);
	const [form, setForm] = useState({
    departurePremiseID: '',
    destinationPremiseID: '',
    destinationLocation: '',
    vehicleID: '',
    departureDate: new Date(),
    arrivalDate: new Date()
  });
	const [myPremises, setMyPremises] = useState([]);
  const [vehicles, setVehicles] = useState([]);
	const [redirect, setRedirect] = useState('INITIAL');

	const fetchVehicles = async() => {
    const username = getUsername();
    const result = await axios.get(`/api/vehicles/user/${username}`);
    
    const fetchedVehicleIDs = result.data.map(v => ({
      id: v.licensePlateNum,
      name: v.name
    }));

    setVehicles(fetchedVehicleIDs);
    return fetchedVehicleIDs;
  };

  useEffect(() => {
    getPremisesForUser(getUsername())
			.then((userPremises) => {
				setMyPremises(userPremises);
				return fetchVehicles()
					.then((fetchedVehicleIDs) => {
						setForm({
							...form,
							departurePremiseID: userPremises.length > 0 ? userPremises[0].pid : '',
							destinationPremiseID: userPremises.length > 0 ? userPremises[0].pid : '',
							vehicleID: fetchedVehicleIDs.length > 0 ? fetchedVehicleIDs[0].id : ''
						});
					});
			})
			.catch(() => {
				toast.error("An error occurred while fetching the data. Please reload this page.");
			});
  }, []);

	useEffect(() => {
		setExportSheep(extractDataFromHeaders(data, headers));
	}, [data, headers]);
	
	if (redirect === 'DONE') {
		return <Redirect to='/reports/importExport' push />;
	}

	const handleSubmit = () => {
    const dateNow = new Date();
    if (dateNow < form.departureDate){
      toast.error("The departure date cannot be in the future");
      return;
    }
    if (form.destinationLocationID === ''){
      toast.error("Please enter a destination location");
      return;
    }
    if (selectedSheeps.length < 1){
      toast.error("Please select at least one sheep to export");
      return;
    }

      //setRedirect('PROCESSING');
      toast.info("Submitting export report to the backend. It may take up to a minute. You will be redirected when the report has been created. Please stay on this page.");
      
      // remove spaces and commas from the ISO numbers
			const formattedIsoNumbers = selectedSheeps.map(s => {
        return s.isoNumber.replace(/[\s|,]/g, '');
      });
      
      createBulkTempExportReport({
        destinationPID: form.destinationLocationID ? form.destinationLocationID : null,
        destinationType: form.destinationType,
        location: form.location,
        animals: formattedIsoNumbers,
        departureTime: form.departureDate,
        vehicleNumber: form.vehicleID,
        arrivalTime: form.arrivalDate,
        departurePID: form.departurePremiseID,
      }).then(() =>{
        setRedirect('INITIAL');
        toast.success("Success: Report created");
        setRedirect('DONE');
      }).catch ((err) => {
        setRedirect('INITIAL');
        toast.error(err);
      });
    
  };

	const submitEnabled = () => {
    if (exportToCountry) {
			return (
				form.departurePremiseID &&
				form.destinationLocation &&
				form.departureDate &&
				form.vehicleID &&
				selectedSheeps.length > 0
			);
		} else {
			return (
				form.departurePremiseID &&
				form.destinationPremiseID &&
				form.departureDate &&
				form.arrivalDate &&
				form.vehicleID &&
				selectedSheeps.length > 0
			);
		}
  };

	const departurePremiseDropDown = {
    name: 'Departure Site',
    id: 'departurePremiseID'
  };


  const vehicleDropDown = {
    name: 'Vehicle',
    id: 'vehicleID'
  };

  const destLocationInput = [
    {
      name: 'PID of Destination Site',
      id: 'destinationLocationID'
    }
  ];

  const departureDateField = {
    labelName: 'Departure Date',
    id: 'departureDate',
    onChange: (date) => dateChange(date, 'departureDate'),
    value: form.departureDate
  };

  const arrivalDateField = {
    labelName: 'Arrival Date',
    id: 'arrivalDate',
    onChange: (date) => dateChange(date, 'arrivalDate'),
    value: form.arrivalDate
  };

  const dateChange = (date, fieldName) => {
    setForm({
      ...form,
      [fieldName]: date
    })
  };

	const baseColumns = [
    {
      id: 'isoNumber',
      name: 'ISO Number'
    }
  ];

  const columns = [
    {
      id: 'check',
      name: '' 
    },
    ...baseColumns
  ];

	const SelectAllSection = () => <CenteredButtonDiv>
    <Button onClick={(e) => {
      e.preventDefault();
      setSelectedSheeps(exportSheep);
    }}>Select All</Button>
    {(selectedSheeps.length > 0) ? 
      <Button onClick={(e) => {
        e.preventDefault();
        setSelectedSheeps([]);
      }}>Clear All</Button>
    : <></>}
  </CenteredButtonDiv>;
  const sheepRowsWithCheck = exportSheep.map(s => ({
    ...s,
    check: <CheckBox 
      checked={selectedSheeps.filter(selectedSheep => selectedSheep.dummyID === s.dummyID).length > 0}
      onChange={() => {
        const alreadySelected = selectedSheeps.filter(selectedSheep => selectedSheep.dummyID === s.dummyID).length > 0;
        if(alreadySelected){
          const newSelectedSheeps = selectedSheeps.filter(sheep => sheep.dummyID !== s.dummyID);
          setSelectedSheeps(newSelectedSheeps)
        } else {
          const newSelectedSheeps = [
            exportSheep.filter(sheep => sheep.dummyID === s.dummyID)[0],
            ...selectedSheeps
          ];
          setSelectedSheeps(newSelectedSheeps);
        }
      }}
    />
  }));

	const selectedSheepColumns = [
    ...baseColumns,
    {
      id: 'remove',
      name: ''
    }
  ];

  const selectedSheepRowsWithRemove = selectedSheeps.map(s => ({
    ...s,
    remove: <button
      onClick={(e) => {
        e.preventDefault();
        const newSelectedSheep = selectedSheeps.filter(sheep => sheep.dummyID !== s.dummyID);
        setSelectedSheeps(newSelectedSheep);
      }}
    style={{cursor: 'pointer'}} 
    >Remove</button>
  }))

	return (
		<FormCard title='Add Exported Sheep' back>
			<ExportContainer>
				
			<DropDown
						id={departurePremiseDropDown.id}
						name={departurePremiseDropDown.name}
						onChange={(e) => {
							setForm({
								...form,
								departurePremiseID: e.target.value
							});
						}}
						value={form[departurePremiseDropDown.id]}
						options={
							myPremises.map(o => ({
								name: o.name,
								value: o.pid,
							}))
						}
						required
					/>
          <FormInputWrapper>
            <FormInputLabel>
              Location
              <>&nbsp;<RequiredSpan>*</RequiredSpan></>
            </FormInputLabel>
            <FormSelectInput
              value={form['location']}
              onChange={(e) => {
              
                setForm({
                  ...form,
                  location: e.target.value
                })
                console.log(form)
              }}
            > 
              <option hidden > Add a Country </option>
              {countries.map((country, idx) => (
                <option key={idx} value={country}>
                  {country}
                </option>
              ))}
            </FormSelectInput>
          </FormInputWrapper>
					
					{destLocationInput.map(i => <>
						<Input
							id={i.id}
							placeholder={i.hint}
							labelName={i.name}
							onChange={(e) => setForm({
								...form,
								destinationLocationID: e.target.value
							})}
							value={form[i.id]}
							required
						/>
					</>)}

          <RadioButtonContainer>
            Destination Type: <>&nbsp;<RequiredSpan>*</RequiredSpan></><br></br>
            <input 
              type='radio' 
              name='destType' 
              checked={form.destinationType === 'Abattoir'} 
              onChange={() => setForm({...form, destinationType: 'Abattoir'})} 
              style={{cursor: 'pointer'}} />
						  Abattoir <br></br>
						<input 
              type='radio' 
              name='destType' 
              checked={form.destinationType === 'Community Pasture'} 
              onChange={() => setForm({...form, destinationType: 'Community Pasture'})} 
              style={{cursor: 'pointer'}} />
						  Community Pasture <br></br>
            <input 
              type='radio' 
              name='destType' 
              checked={form.destinationType === 'Other'} 
              onChange={() => setForm({...form, destinationType: 'Other'})} 
              style={{cursor: 'pointer'}} />
						  Other <br></br>
          </RadioButtonContainer>


					<DropDown
						id={vehicleDropDown.id}
						name={vehicleDropDown.name}
						onChange={(e) => {
							setForm({
								...form,
								vehicleID: e.target.value
							});
						}}
						value={form[vehicleDropDown.id]}
						options={
							vehicles.map(o => ({
								name: o.name,
								value: o.id,
							}))
						}
						required
					/>

          
					<DateField
						id={departureDateField.id}
						labelName={departureDateField.labelName}
						value={departureDateField.value}
						onChange={departureDateField.onChange}
						required
					/>

          <DateField
            id={arrivalDateField.id}
            labelName={arrivalDateField.labelName}
            value={arrivalDateField.value}
            onChange={arrivalDateField.onChange}
            required
          />


				<SimpleCard title='Select Sheep'>
					<StripedTable
						columns={columns}
						rows={sheepRowsWithCheck}
						ShowCount
						max={10}
						paginate
					/>
					<SelectAllSection />
				</SimpleCard>
				{(selectedSheeps.length > 0) && 
					<SimpleCard title='Sheep selected to create'>
						<StripedTable
							columns={selectedSheepColumns}
							rows={selectedSheepRowsWithRemove}
							max={10}
							paginate
							ShowCount
						/>
						{submitEnabled() && 
							<CenteredButtonDiv>
								<Button onClick={handleSubmit} disabled={redirect !== 'INITIAL'}>
									Export {selectedSheeps.length} sheep
								</Button>
								{redirect === 'PROCESSING' && <SubmittingSpinner />}
							</CenteredButtonDiv>
						}
					</SimpleCard>
      	}
			</ExportContainer>
		</FormCard>
	);
}

TempExportForm.propTypes = {
	data: propTypes.any,
	headers: propTypes.any
}

export default TempExportForm;

const ExportContainer = styled.div`
	width: fit-content;
	max-width: 700px;
	color: white;
	margin: 0;
	padding: 10px;
	display: flex;
	flex-direction: column;
`;


const SubmittingDiv = styled.div`
  text-align: center;
	color: white;
`;

const RadioButtonContainer = styled.div`
  margin-bottom: 20px;
  margin-left: 25px;
`;

const RequiredSpan = styled.span`
  color: red;
`

const CenteredButtonDiv = styled.div`
  text-align: center;
  margin-top: 2rem;
`;