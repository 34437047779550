import React from 'react';
import PageCard from '../../components/PageCard.js';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function AnimalImportView(){
    return(
        <PageCard title='Importing Animals into Canada' back>
            <div>&nbsp;</div>
            <span style={{ fontSize: 20 }}> 
                All animals that are imported into Canada must be reported. If you are importing animals for direct slaughter,
                only the PID of the Canadian abattoir needs to be provided (the animal identification numbers do not need to be given as per
                the current regulation). If you are not importing animals for direct slaughter, the first destination PID needs to
                be provided, along with all the identification numbers for the imported sheep. The identification numbers must be 
                approved equivalent indicators to Canadian tag numbers, and currently, only US tag numbers meet this equivalence
                requirement. If foreign indicators that are not considered equivalent approved indicators are currently
                applied to your imported sheep, it is required that they have approved indicators applied when they reach their 
                first destination site in Canada. Any sheep imported must be reported within 7 days of entering Canada.
            </span>
            <div>&nbsp;</div>
            <span style={{ fontSize: 20 }}> 
                Click the link below to access your Imports dashboard!
            </span>
            <div>&nbsp;</div>
            <StyledLink to='/events/animalImport'>Imports Dashboard</StyledLink>
        </PageCard>
    );
}

const StyledLink = styled(Link)`
	color: #FAF886;
    font-style: italic;
    font-size: 30px;
`;