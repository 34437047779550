import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PageCard from '../components/PageCard.js';
import { StyledTableCurved } from '../components/TableComponents.js';

export default function LogViewer() {
	const location = useLocation();
	const [data, setData] = useState({});
	const [load, setLoad] = useState(false);
	const [txn, setTxID] = useState('');

	useEffect(() => {
		setLoad(true);
		// eslint-disable-next-line no-unused-vars
		const { _id, __v, username, txID, ...rest } = location.state;
		setTxID(txID);
		console.log(rest);
		setData(rest);
		// TODO: Format according to the event type
		// TODO: If the request has an attachment, allow the user to download/view it
	}, [location.state]);

	return (
		<PageCard title='Log Viewer' back>
			{!load ? (
				<>Loading...</>
			) : (
				<div style={{ margin: '10px' }}>
					<StyledTableCurved>
						<thead>
							<tr>
								<th>Data</th>
								<th>Value</th>
							</tr>
						</thead>
						<tbody>
							{Object.keys(data).map((mapKey) => (
								<tr key={mapKey.id}>
									<td>{mapKey}</td>
									<td>{data[mapKey]}</td>
								</tr>
							))}
							<tr>
								<td>Blockchain confirmation</td>
								<td>
									<a href={`https://ropsten.etherscan.io/tx/${txn}`} target='_blank' rel='noopener noreferrer'>
										View on Ropsten
									</a>
								</td>
							</tr>
						</tbody>
					</StyledTableCurved>
				</div>
			)}
		</PageCard>
	);
}
