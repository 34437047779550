// SlaughterReportListView.js
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PageCard from '../../components/PageCard.js';
import TableComponent from '../../components/TableComponent';
import {
  getSlaughterReports,
} from '../../utils/SlaughterReportUtils';

const SlaughterReportListView = () => {
  const [slaughterReports, setSlaughterReports] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [redirect, setRedirect] = useState('');
  
  const fetchSlaughterReports = async() => {
    const result = await getSlaughterReports();
    setSlaughterReports(result);
  };

  useEffect(() => {
    fetchSlaughterReports();
  }, []);

  if(redirect !==''){
    return <Redirect push to={redirect} />;
  }

  const reportFields  = [
    { name: 'Departure Premise', id: 'departurePremiseID' },
    { name: 'Destination Premise', id: 'destinationPremiseID' },
    { name: 'Departure Date / Time', id: 'departureDateTime' },
    { name: 'Death Date / Time', id: 'deathDateTime' },
    { name: 'Animal ID', id: 'animalID' },
    { name: 'Vehicle ID', id: 'vehicleID' },
  ];

  return (
    <PageCard title='Slaughter Reports' to='/slaughterReport/create' back>
      <TableComponent
        headings={reportFields}
        data={slaughterReports}
        actions
      />
    </PageCard>
  );
};

export default SlaughterReportListView;
