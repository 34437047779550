import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import SimpleCard from '../components/SimpleCard';
import propTypes from 'prop-types';

const CsvButtonContainer = styled.div`
  width: 100%;
  display: inline-block;
  text-align: center;
`;

const CsvButton = styled.button`
  background-color: #17a737;
  border: none;
  border-radius: 10px;
  padding: 1rem;
  font-size: 1rem;
  color: white;
  cursor: pointer;
`;

const CsvCreateSection = ({ title, description, link, buttonText }) => {
  return <>
    <SimpleCard
      title={title}
      description={description}
    >
      <CsvButtonContainer>
        <Link to={link}>
          <CsvButton>{buttonText}</CsvButton>
        </Link>
      </CsvButtonContainer>
    </SimpleCard>
  </>
};

CsvCreateSection.propTypes = {
  title: propTypes.string,
  description: propTypes.string,
  link: propTypes.string,
  buttonText: propTypes.string
};

export default CsvCreateSection;