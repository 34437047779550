import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	FormContainer,
  Button,
  FormTextInput,
  FormInputWrapper,
  FormInputLabel,
} from '../../components/CommonComponents.js';
import FormCard from '../../components/FormCard.js';
import { useParams, Redirect } from 'react-router-dom';
import { getUser } from '../../utils/UserUtils';
import { getUsername } from '../../utils/TokenUtils';

const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

const validateInput = (form, email) => {
  var error = null;

  if(email == undefined && !emailRegex.test(form.newEmail)){
    error = 'Your new email must be in a valid email format';
  } else {
        if(!emailRegex.test(form.newEmail))
            error = 'Your new email must be in a valid email format';
        else if(form.newEmail == email)
            error = 'Your new email cannot be the same as your old email';
  }

  return error;
}

const validateForm = (form) => {
  if (form == undefined){
    return false;
  }

  return true;
}

//Submitting spinners
const SubmittingDiv = styled.div`
  text-align: center
`;

const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin>
    </FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

const EditEmail = () => {
  const [submitting, setSubmitting] = useState(false);
  const [verifyUser, setVerifyUser] = useState(false);
  const [form, setForm] = useState();
  const [redirect, setRedirect] = useState('');
  const [user, setUser] = useState();
  const params = useParams();
  const username = params.username;

	const fetchCurrentRole = async () => {
		const temp = await axios.get('/api/users/role');
		return temp;
	}

	const fetchUserName = async () => {
		let name = await getUsername();
		let role = await fetchCurrentRole();
	
		if(username == name || role.data == 'admin')
			setVerifyUser(true);
	}

  const fetchUser = async() => {
    let u = await getUser(username);

    let transformedUser = {
        email: u.email
    };

    setUser(transformedUser);
}

useEffect(() => {
  fetchUserName();
    fetchUser();
}, []);

  const handleSubmit = async(e) => {
    e.preventDefault();

    var err = validateInput(form, user.email);

    const send = {
        newEmail: form.newEmail
    };
    
    if(err == null){
      try {
        await axios.put(`/api/users/${username}/email`, send);
        toast.success('Your email has been updated');
        setRedirect(`/users/${username}`);
      } catch (err) {
          if (err.message == "Request failed with status code 400"){
            toast.error(`An error has occurred: Information is wrong or already exist, please refresh and try again.`)
          } else {
            toast.error(`${err.message}`)
          }
          setSubmitting(false);
      }
    }
    else{
      toast.error(err);
    }
  }

  if(redirect !== ''){
    return <Redirect to={redirect} />;
  }

  return <>
    <FormCard title={<> <>Edit Email</> </>} back >
        <FormContainer onSubmit={handleSubmit} >
          {user!= undefined ? (<> 
            {verifyUser ? (<>
              <FormInputWrapper>
                <FormInputLabel htmlFor = 'input'>
                    New Email
                </FormInputLabel>
                <FormTextInput
                    type = 'text'
                    placeholder = 'agroledger2@sheep.com'
                    id = 'input'
                    name = 'newEmail'

                    onChange={(e) => {
                    if (e.target.value === '') {
                        // eslint-disable-next-line no-unused-vars
                        const { ['newEmail']: _, ...updatedState } = form;
                        setForm(updatedState);
                    } else setForm({ ...form, [e.target.name]: e.target.value });
                    }}
                />
                </FormInputWrapper>                

                <Button disabled = {!validateForm(form)}>Submit</Button>
                {(submitting) ? <SubmittingSpinner />: <></>}
            
            </>) : (<>
              <NoPermissionBlock>
                  You do not have permission to edit this profile.
              </NoPermissionBlock>
            </>)}  
                
            </>) : (<>Loading...</>)}
        </FormContainer>
		</FormCard>
   </>
};

const NoPermissionBlock = styled.div`
  width: max-content;
  margin: 3em auto;
  font-size: 17px;
  padding: 1.5em 3em;
  border-radius: 5px;
  border: 2px solid white;
  background-color: rgb(255, 88, 88);
`;

//export
export default EditEmail;