import React, { useState, useEffect } from 'react';
import PageCard from '../../components/PageCard';
import SimpleCard from '../../components/SimpleCard';
import StripedTable from '../../components/StripedTable';
import ViewEditDispose from '../../components/ViewEditDispose';
import axios from 'axios';
import { FilterSection, sortReportsByDate } from '../../components/SheepReportComponents';
import ReportDescriptionCard from '../../components/ReportDescriptionCard.js';

const getReplaceReports = () => {
  return axios.get('/api/sheep/tag/replace')
    .then((res) => {
      return res.data;  
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

const tableColumns = [
  {
    id: 'oldIsoNumber',
    name: 'Old Identifier',
    required: true
  },
  {
    id: 'newIsoNumber',
    name: 'New Identifier',
    required: true
  },
  {
    id: 'actions',
    name: 'Actions'
  }
];

const SheepReplaceListView = () => {
  const [replaceReport, setReplaceReport] = useState([]);
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
  });

  const [tempFilter, setTempFilter] = useState({
    startDate: '',
    endDate: '',
  });
  
  let filterFields = [
    {
      id: 'startDate',
      name: 'Start Date',
      type: 'date',
      onChange: (e) => {
        updateTempFilter(e.target.value, 'startDate');
      },
      onClick: (e) => {
        e.preventDefault();
        updateTempFilter('', 'startDate');
      },
    },
    {
      id: 'endDate',
      name: 'End Date',
      type: 'date',
      onChange: (e) => {
        updateTempFilter(e.target.value, 'endDate');
      },
      onClick: () => {
        updateTempFilter('', 'endDate');
      }
    },
  ]

  const handleApply = (e) => {
    e.preventDefault();
    const newFilter = {...tempFilter};
    setFilter(newFilter);
  }

  const handleClear = (e) => {
    e.preventDefault();
    const newFilter = {
      startDate: '',
      endDate: '',
    };

    setTempFilter(newFilter);
    setFilter(newFilter);
  }

  const fetchReplaceReport = async () => {
    let fetchedReport = await getReplaceReports();

    fetchedReport.forEach(r => {
      r.actions = <ViewEditDispose 
        id={r._id} 
        endpoint={'/reports/replace/'}
        hideDispose
        hideEdit
        hideReplace
      />;
      r.reportDate = (new Date(r.reportDate)).toISOString().split('T')[0];

      if (
        r.location &&
        r.location.coordinates &&
        r.location.coordinates.length === 2
      ) {
        r.location = r.location.coordinates[1] + ", " + r.location.coordinates[0];
      } else {
        r.location = "";
      }
    });
    setReplaceReport(sortReportsByDate(fetchedReport));
  }

  useEffect(() => {
    fetchReplaceReport();
  }, []);

  replaceReport.forEach(s => {

    if (s.oldIsoNumber){
      
    } else if (s.oldTattooNumber){
      s.oldIsoNumber = s.oldTattooNumber;
    } else if (s.oldLocalMgmtNumber){
      s.oldIsoNumber = s.oldLocalMgmtNumber;
    }

    if (s.newIsoNumber){
      
    } else if (s.newTattooNumber){
      s.newIsoNumber = s.newTattooNumber;
    } else if (s.newLocalMgmtNumber){
      s.newIsoNumber = s.newLocalMgmtNumber;
    }
  });

  const updateTempFilter = (updatedValue, filterID) => {
    const newFilter = {...tempFilter};
    newFilter[filterID] = updatedValue;
    if(filterID === 'startDate'){
      if(tempFilter.endDate === ''){
        newFilter.endDate = updatedValue;
      } else {
        const startDate = new Date(updatedValue);
        const endDate = new Date(tempFilter.endDate)
        if(startDate > endDate) newFilter.endDate = updatedValue;
      }

      if(updatedValue === '') newFilter.endDate = '';
    }

    if(filterID === 'endDate'){
      if(tempFilter.startDate === ''){ 
        newFilter.startDate = updatedValue;
      } else {
        const startDate = new Date(tempFilter.startDate);
        const endDate = new Date(updatedValue);
        if(endDate < startDate) newFilter.startDate = updatedValue;
      }

      if(updatedValue === '') newFilter.startDate = '';
    }
    setTempFilter(newFilter);
  }

  const filteredReports = replaceReport.filter(s => {
    let filterResult = true;
    const filterKeys = Object.keys(filter);
    filterKeys.forEach(key => {
      if(filter[key] === '') return;
      if(key.includes('Date')) return;
      if(s[key] !== filter[key]) filterResult = false;
    });
    
    const creationDate = new Date(s['reportDate']);
    const startDate = new Date(filter.startDate);
    const endDate = new Date(filter.endDate);
    if(creationDate < startDate) return false;
    if(creationDate > endDate) return false;
    if (s['action'] != 'TagReplace') return false;

    return filterResult;
  });


  return <>
    <PageCard title=" Tag Replacement Reports" to='/reports/replace/selectView' line back>
    <ReportDescriptionCard page='tag replacement report'></ReportDescriptionCard>

      <SimpleCard
        title="Filters"
        description='Use filters to find reports by date of their creation'
      >
        <FilterSection
          data={filterFields}
          onApply={handleApply}
          onClear={handleClear}
          values={tempFilter}
        />
      </SimpleCard>

      <SimpleCard >
        <StripedTable  
          columns={tableColumns}
          rows={filteredReports}
          ShowFilters
          ShowCount
          max={10} 
          paginate
        />
      </SimpleCard>
    </PageCard>
  </>
};

export default SheepReplaceListView;