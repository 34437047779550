import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PageCard from '../../components/PageCard.js';
import axios from 'axios';
import DescriptionCard from '../../components/DescriptionCard.js';
import TableComponent from '../../components/TableComponent';
import { getUsername } from '../../utils/TokenUtils.js';
import TableComponentMini from '../../components/TableComponentMini';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import SimpleCard from '../../components/SimpleCard';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

const OperationsListComponent = () => {
	const [operations, setOperations] = useState([]);
	const [transformedOperations, setTransformedOperations] = useState([]);
	const [load, setLoad] = useState(false);
	const [redirect, setRedirect] = useState();
	const [windowSize, setWindowSize] = useState(0);
	const [admin, setAdmin] = useState(false);

	const transformOperations = () => {
		const transformed = operations.map(o => ({
			...o,
			cvsp: o.cvsp ? 'Yes' : 'No',
			sfcp: o.sfcp ? 'Yes' : 'No'
		}))
		return transformed;
	}

	useEffect(() => {
		axios
			.get(`/api/operations/user/${getUsername()}`)
			.then(function (response) {
				setOperations(response.data);
				setLoad(true);
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	useEffect(() => {
		setTransformedOperations(transformOperations);
	}, [operations]);

	if (window.innerWidth !== windowSize) {
		setWindowSize(window.innerWidth);
	}

	const deleteOperation = ({ _id }) => {
		axios
			.delete(`/api/operations/${_id}`)
			.then(function (response) {
				console.log(response.data);
				setOperations(operations.filter((element) => element._id !== _id));
			})
			.catch(function (error) {
				const errMsg = error.response.data.message
				toast.error(errMsg);
			});
	};

	const viewOperation = ({ _id }) => {
		setRedirect(`operations/${_id}`);
	};

	const editOperation = ({ _id }) => {
		setRedirect(`operations/${_id}/update`);
	};

	const fetchCurrentRole = async() => {
		return await axios.get('/api/users/role');
	}

	const fetchAndSetRoleInfo = async () => {
		try {
			let fetchedRole = await fetchCurrentRole();			
			if(fetchedRole.data == 'admin')
				setAdmin(true);
			else
				setAdmin(false);

		} catch (err) {

		}
	}

	useEffect(() => {
		fetchAndSetRoleInfo();
	}, []);

	const tableHeadings = [
		{ name: 'Business Name', id: 'businessName' },
		{ name: 'Type', id: 'operationType' },
		{ name: 'Contact', id: 'phoneNumber' },
		{ name: 'CVSP', id: 'cvsp' },
		{ name: 'SFCP', id: 'sfcp' },
	];

	const operationFields = [
		{ name: 'Business Name', id: 'businessName' },
		{ name: 'Type', id: 'operationType' },
		{ name: 'Contact', id: 'phoneNumber' },
		{ name: 'CVSP', id: 'cvsp' },
		{ name: 'SFCP', id: 'sfcp' },
	];

	if (redirect) return <Redirect push to={redirect}/>
	return (
		<>
		<OperationsDiv>
			<DescriptionCard page='operation' create={true} vowel={true}></DescriptionCard>
			{!load ? (
				<>Loading...</>
			) : (
				<>
					{admin ? (
						<>
							{windowSize < 1300 ? (
								<TableComponentMini
									fields={operationFields}
									list={transformedOperations}
									deleteCB={deleteOperation}
									viewCB={viewOperation}
									editCB={editOperation}
								/>
								) : (
								<TableComponent
									headings={tableHeadings}
									data={transformedOperations}
									view={{ cb: viewOperation, title: 'View' }}
									edit={{ cb: editOperation, title: 'Edit' }}
									remove={{ cb: deleteOperation, title: 'Delete' }}
									actions
								/>
							)}
						</>
					) : (
						<>
							{windowSize < 1300 ? (
								<TableComponentMini
									fields={operationFields}
									list={transformedOperations}
									viewCB={viewOperation}
									editCB={editOperation}
								/>
								) : (
								<TableComponent
									headings={tableHeadings}
									data={transformedOperations}
									view={{ cb: viewOperation, title: 'View' }}
									edit={{ cb: editOperation, title: 'Edit' }}
									actions
								/>
							)}
						</>								
					)}
				</>
			)}
		</OperationsDiv>
		</>
	);
};

const ReportSection = ({ title, description, to }) => {
  return <ReportSectionDiv>
    <ReportSectionTitle>{title}</ReportSectionTitle>

    <ReportDescription>{description}</ReportDescription>

    <ReportButtonDiv>
      <Link to={to}>
				<ReportButton>View {title}</ReportButton>
      </Link>
    </ReportButtonDiv>
  </ReportSectionDiv>
}

ReportSection.propTypes = {
  title: propTypes.string,
  description: propTypes.string,
  to: propTypes.any
}

const OperationsReportsComponent = () => {
  return <>
		<ReportsDiv>
			<SimpleCard
				title="Reports"
			>
				<ReportSection
					title="Recent Operation Updates"
					description="Click here to view update reports"
					to="/reports/updateReports"
				/>
			</SimpleCard>
		</ReportsDiv>
  </>
}

const OperationsView = () => {

  return <PageCard
    title="Operations"
    to='/operations/create'
    back
  >
    <OperationsDashboardDiv>
      <OperationsListComponent />
			<OperationsReportsComponent />
    </OperationsDashboardDiv>
  </PageCard>;
};

const OperationsDashboardDiv = styled.div`
  width: 100%;
`
const OperationsDiv = styled.div`
  max-width: 59%;
  width: 80%;
  float: left;
  display: inline-block;
`
const ReportsDiv = styled.div`
  width: 40%;
  display: inline-block;
	margin-left: 1%;
`
const ReportSectionDiv = styled.div`
  margin-bottom: 2rem;
`

const ReportSectionTitle = styled.span`
  font-size: 1.25rem;
  font-weight: bold;
`

const ReportButtonDiv = styled.div`
  text-align: center;
`

const ReportButton = styled.button`
  padding: .5rem;
  margin-left: 1rem;
  color: white;
  border-style: solid;
  width: 80%;
	background-color: #17a737;
	border: none;
  border-radius: 10px;
	font-size: 1rem;
	cursor: pointer;
`
const ReportDescription = styled.div`
  margin: 0.5rem;
`

export default OperationsView;
