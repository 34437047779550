// ViewEditDelete.js
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';


const ViewTransfer = ({ 
  
  id, 
  endpoint, 
  transferName,
  createName,
  hideTransfer,
  hideCreate,

}) => {
  if(!transferName) {
    transferName = 'Transfer'
  }
  if(!createName) {
    createName = 'Move In'
  }
  const viewLink = `${endpoint}${id}`;
  const editLink = `${endpoint}transfer/${id}`;
  const createLink = `${endpoint}moveIn/${id}`;
  return <ViewTransferDiv>
    <Link to={viewLink}><FlatButton color="white">View</FlatButton></Link> 
    {(hideTransfer) ? <></> :
      <>&nbsp;|&nbsp;
        <Link to={editLink} ><FlatButton color="#fefdb1">{transferName}</FlatButton></Link> 
      </>
    }
    {(hideCreate) ? <></> :
      <>&nbsp;|&nbsp;
        <Link to={createLink} ><FlatButton color="#f23b3b">{createName}</FlatButton></Link> 
      </>
    }
  </ViewTransferDiv>
}

ViewTransfer.propTypes = {
  id: propTypes.any,
  endpoint: propTypes.any,
  hideTransfer: propTypes.any,
  hideCreate: propTypes.any
};

const ViewTransferDiv = styled.div`
  color: white;
`;

const FlatButton = styled.button`
  color: white;
  background: none;
  border-radius: 10px;
  border-color: ${props => props.color};
  border-style: solid;
  outline: none;
  cursor: pointer;
`;

export default ViewTransfer;