import React, { useEffect, useState } from 'react';
import PageCard from '../../components/PageCard';
import SearchTable from '../../components/SearchTable';
import SimpleCard from '../../components/SimpleCard';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { getUsername } from '../../utils/TokenUtils';
import axios from 'axios';
import ViewEditDispose from '../../components/ViewEditDispose';
import { Button } from '../../components/CommonComponents';

// Functional Components

const fetchSheep = async () => {
  try {
    const username = getUsername();
    const request = await axios.get(`/api/sheep?username=${username}&premise=true`);
    const fetchedSheep = request.data;
    return fetchedSheep;
  } catch (err) {
    throw new Error(`An error occurred fetching sheep: ${err.message}`, err);
  }
}

const SheepListByPremiseComponent = () => {
  const [premises, setPremises] = useState([]);
  
  // Fetch premises and transform data as necessary
  const fetchAndTransformSheepData = async () => {
    try {
      var fetchedSheep = await fetchSheep();
      fetchedSheep = fetchedSheep.filter((sheep) => {
        return (
          !sheep.isExported &&
          !sheep.movedOut &&
          !sheep.onAbattoir
        );
      });

      let premiseNames = [];
      let tempPremises = [];

      // Simplify
      const simplifiedSheep = fetchedSheep.map((s) => {
        const simpleSheep = {
          id: s._id,
          premiseName: s.premise.name,
          premiseId: s.premise._id,
          pid: s.premise.pid,
          actions: 
            <ViewEditDispose 
              id={s._id} 
              endpoint={'/sheep/'} 
              hideDispose
              hideEdit
            />
        };

        if(s.tag.tattooNumber) simpleSheep.identifier= s.tag.tattooNumber
        if(s.tag.localMgmtNumber) simpleSheep.identifier = s.tag.localMgmtNumber;
        if(s.tag.isoNumber) simpleSheep.identifier = s.tag.isoNumber

        premiseNames.push(simpleSheep.premiseName);

        return simpleSheep;
      });

      // Group into premises
      let uniquePremiseNames = [];
      premiseNames.forEach(p => {
        if (!uniquePremiseNames.includes(p)) {
          uniquePremiseNames.push(p)
        }
      });

      tempPremises = uniquePremiseNames.map(n => {
        const premiseSheep = simplifiedSheep.filter(s => s.premiseName === n);
        const premise = {
          name: n,
          id: premiseSheep[0].premiseId,
          sheep: premiseSheep,
          pid: premiseSheep[0].pid
        };

        return premise;
      });
      setPremises(tempPremises);
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  }

  useEffect(() => {
    fetchAndTransformSheepData();
  }, []);
  
  return <>
    <PremisesDiv>
      <PremisesInnerContainer>
        {premises.map((p, idx) => {
          // Columns
          const columns = [
            {
              id: 'identifier',
              name: 'Identifier (iso #, local management # or tattoo #)'
            },
            {
              id: 'actions',
              name: 'Actions'
            }
          ];

          const rows = p.sheep;

          return <div key={idx}>
            <SimpleCard title={`${p.name}` + ' - ' + `${p.pid}`}>
              <SearchTable
                to={`/sheep/premise/${p.id}`}
                placeholder="Enter the identifier you wish to search for"
                filterField="identifier"
                columns={columns}
                rows={rows}
                count={10}
              />
            </SimpleCard>
          </div>
        })}
      </PremisesInnerContainer>
    </PremisesDiv>
  </>
}

const LostSheepComponent = () => {
  return (
  <SimpleCard
    title="Lost Tag Creation"
    description="Click the button to create a tag for animal that has lost their tag"
  >
    <Link to= "/sheep/create/lostTag">
      <Button>
          Create
      </Button>
    </Link >
  </SimpleCard>)
}

const SheepReplaceSelectView = () => {

  return <PageCard
    title="Select a sheep for tag replacement"
    line
    back
  >
    <SheepDashboardDiv>
      <LostSheepComponent />
      <SheepListByPremiseComponent />
    </SheepDashboardDiv>
  </PageCard>;
};

// Styled components
const SheepDashboardDiv = styled.div`
  width: 100%;
`
const PremisesDiv = styled.div`
  width: 100%;
  float: left;
  display: inline-block;
`
const PremisesInnerContainer = styled.div`
  padding: .25rem;
`;

export default SheepReplaceSelectView;