import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router';
import propTypes from 'prop-types';
import { getUsername } from '../../utils/TokenUtils';
import { getPremisesForUser } from '../../utils/PremiseUtils';
import { countries } from '../../utils/Countries';
import { extractDataFromHeaders } from '../../utils/CSVUtils';
import { createBulkImportReport } from '../../utils/ImportUtils';
import {
	FormInputWrapper,
	FormInputLabel,
	FormTextInput,
	FormSelectInput,
  Button,
} from '../../components/CommonComponents';
import DateField from '../../components/DateField';
import PageCard from '../../components/PageCard';
import SimpleCard from '../../components/SimpleCard';
import StripedTable from '../../components/StripedTable';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SubmittingDiv = styled.div`
  text-align: center
`;
const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin>

    </FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

const CheckBox = ({onChange, checked}) => {
  return <input 
    style={{cursor: 'pointer'}}
    type='checkbox' 
    checked={checked} 
    onChange={onChange} />;
}

CheckBox.propTypes = {
  onChange: propTypes.func,
  checked: propTypes.bool
}

const CenteredButtonDiv = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

const RequiredSpan = styled.span`
  color: red;
`;

const ImportSheep = ({data, headers}) => {
  const [sheeps, setSheeps] = useState([]);
  const [selectedSheeps, setSelectedSheeps] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState('');
	const [myPremises, setMyPremises] = useState([]);
	const [form, setForm] = useState({
    exportingCountry: 'Canada',
    departureSite: '',
    abattoirPID: '',
    firstDestinationPID: '',
    dateOfArrival: new Date(),
    vehicleIdentification: ''
  });

  const submitEnabled = () => {
    return (
      form.exportingCountry &&
      form.firstDestinationPID &&
      form.dateOfArrival &&
      form.vehicleIdentification &&
      selectedSheeps.length > 0
    );
  };

  useEffect(() => {
    setSheeps(extractDataFromHeaders(data, headers));
		getPremisesForUser(getUsername())
      .then((premises) => {
        setMyPremises(premises);
        setForm({
          ...form,
          firstDestinationPID: premises.length > 0 ? premises[0].pid : '',
        });
      })
      .catch((err) => {
				console.log(err);
        toast.error("An error occurred while fetching the data. Please reload this page.");
      });
  }, []);

  const baseColumns = [
    {
      id: 'isonum',
      name: 'ISO Number'
    }
  ];

  const columns = [
    {
      id: 'check',
      name: '' 
    },
    ...baseColumns
  ];

  const handleSubmit = async(e) => {
    try {
      e.preventDefault();
      toast.info("Submitting the import report to the server. This may take up to a minute.")
			setSubmitting(true);

      // remove spaces and commas from the ISO numbers
      const formattedIsoNumbers = selectedSheeps.map(s => {
        return s.isonum.replace(/[\s|,]/g, '');
      });

			await createBulkImportReport({
				exportingCountry: form.exportingCountry,
				departureSite: form.departureSite ? form.departureSite : null,
				abattoirPID: form.abattoirPID ? form.abattoirPID : null,
				firstDestinationPID: form.firstDestinationPID,
				dateOfArrival: form.dateOfArrival,
				animals: formattedIsoNumbers,
				vehicleIdentification: form.vehicleIdentification
			});
      setRedirect('/reports/import');
    } catch (err) {
      setSubmitting(false);
      if (!err.message){
        toast.error('Something went wrong with creating the bulk import');
      } else {
        toast.error(err.message);
      }
    }
  }

  const SelectAllSection = () => <CenteredButtonDiv>
    <Button onClick={(e) => {
      e.preventDefault();
      setSelectedSheeps(sheeps);
    }}>Select All</Button>
    {(selectedSheeps.length > 0) ? 
      <Button onClick={(e) => {
        e.preventDefault();
        setSelectedSheeps([]);
      }}>Clear All</Button>
    : <></>}
  </CenteredButtonDiv>;
  const sheepRowsWithCheck = sheeps.map(s => ({
    ...s,
    check: <CheckBox 
      checked={selectedSheeps.filter(selectedSheep => selectedSheep.dummyID === s.dummyID).length > 0}
      onChange={() => {
        const alreadySelected = selectedSheeps.filter(selectedSheep => selectedSheep.dummyID === s.dummyID).length > 0;
        if(alreadySelected){
          const newSelectedSheeps = selectedSheeps.filter(sheep => sheep.dummyID !== s.dummyID);
          setSelectedSheeps(newSelectedSheeps)
        } else {
          const newSelectedSheeps = [
            sheeps.filter(sheep => sheep.dummyID === s.dummyID)[0],
            ...selectedSheeps
          ];
          setSelectedSheeps(newSelectedSheeps);
        }
      }}
    />
  }));

  const selectedSheepColumns = [
    ...baseColumns,
    {
      id: 'remove',
      name: ''
    }
  ];

  const selectedSheepRowsWithRemove = selectedSheeps.map(s => ({
    ...s,
    remove: <button
      onClick={(e) => {
        e.preventDefault();
        const newSelectedSheep = selectedSheeps.filter(sheep => sheep.dummyID !== s.dummyID);
        setSelectedSheeps(newSelectedSheep);
      }}
      style={{cursor: 'pointer'}}
    >Remove</button>
  }))

  if(redirect !== '') return <Redirect to={redirect} />;
  return <div style={{ maxWidth: '700px' }}>
    <PageCard line back title='Bulk Import'>
			<FormInputWrapper>
				<FormInputLabel>
					Exporting Country
					<>&nbsp;<RequiredSpan>*</RequiredSpan></>
				</FormInputLabel>
				<FormSelectInput
					value={form['exportingCountry']}
					onChange={(e) => {
						setForm({
							...form,
							exportingCountry: e.target.value
						})
					}}
				>
					{countries.map((country, idx) => (
						<option key={idx} value={country}>
							{country}
						</option>
					))}
				</FormSelectInput>
			</FormInputWrapper>

			<FormInputWrapper>
				<FormInputLabel>
					Departure Site
				</FormInputLabel>
				<FormTextInput 
					value={form['departureSite']}
					onChange={(e) => setForm({
						...form,
						departureSite: e.target.value
					})}
				/>
			</FormInputWrapper>

			<FormInputWrapper>
				<FormInputLabel>
					Abattoir Premise
				</FormInputLabel>
				<FormSelectInput
					value={form['abattoirPID']}
					onChange={(e) => setForm({
						...form,
						abattoirPID: e.target.value
					})}
				>
					<option value=''></option>
					{myPremises.map((premise, idx) => (
						<option key={idx} value={premise.pid}>
							{premise.name}
						</option>
					))}
				</FormSelectInput>
			</FormInputWrapper>

			<FormInputWrapper>
				<FormInputLabel>
					First Destination Premise
					<>&nbsp;<RequiredSpan>*</RequiredSpan></>
				</FormInputLabel>
				<FormSelectInput
					value={form['firstDestinationPID']}
					onChange={(e) => setForm({
						...form,
						firstDestinationPID: e.target.value
					})}
				>
					{myPremises.map((premise, idx) => (
						<option key={idx} value={premise.pid}>
							{premise.name}
						</option>
					))}
				</FormSelectInput>
			</FormInputWrapper>

			<DateField
				id="dateOfArrival"
				labelName="Date of Arrival"
				value={form.dateOfArrival}
				onChange={(date) => setForm({
					...form,
					'dateOfArrival': date
				})}
				required
			/>

			<FormInputWrapper>
				<FormInputLabel>
					Vehicle Identifier (License Plate Number)
					<>&nbsp;<RequiredSpan>*</RequiredSpan></>
				</FormInputLabel>
				<FormTextInput 
					value={form["vehicleIdentification"]}
					onChange={(e) => {
						setForm({
							...form,
							vehicleIdentification: e.target.value
						})
					}}/>
			</FormInputWrapper>

      <SimpleCard title='Select Sheep'>
        <StripedTable
          columns={columns}
          rows={sheepRowsWithCheck}
          ShowCount
          max={10}
          paginate
        />
        <SelectAllSection />
      </SimpleCard>
      {(selectedSheeps.length > 0) && 
        <SimpleCard title='Sheep selected to create'>
          <StripedTable
            columns={selectedSheepColumns}
            rows={selectedSheepRowsWithRemove}
            max={10}
            paginate
            ShowCount
          />
          {submitEnabled() && <CenteredButtonDiv>

            <Button onClick={handleSubmit} disabled={submitting}>
              Submit {`${selectedSheeps.length}`} Sheep
            </Button>
            {submitting && <SubmittingSpinner />}
            
            </CenteredButtonDiv>
          }
        </SimpleCard>
      }
    </PageCard>
  </div>
}

ImportSheep.propTypes = {
  data: propTypes.arrayOf(propTypes.string),
  headers: propTypes.arrayOf(propTypes.string)
}

export default ImportSheep;