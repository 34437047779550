import { useState } from 'react';
import DecisionCard from '../components/DecisionCard';

const ReceivePremiseDecisionView = () => {
  const [currentId, setCurrentId] = useState('INIT');

  const EventDecisions = [
    {
      id: 'INIT',
      backToHelp: true,
      title: 'Receiving at my Premises',
      description: 'All parties are required to report the receipt fo animals, ' + 
                   'except community pasture operators who are not required to file ' + 
                   'movement reports. You can report the receipt of animals here.',
      choices: [
        {
          name: 'Create Move-in Report',
          link: '/reports/movein/new'
        }
      ]
    }
  ]

  const getDecision = (id) => {
    const decisions = EventDecisions.filter(d => d.id == id);
    if(decisions.length == 0) {
      setCurrentId('INIT');
      alert('Invalid decision id');
      return EventDecisions[0];
    }

    return decisions[0];
  }

  return <DecisionCard decision={getDecision(currentId)} />;
}

export default ReceivePremiseDecisionView;