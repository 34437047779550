import React, { useState, useEffect } from 'react';
import PageCard from '../../components/PageCard';
import { getMedicalReports } from '../../utils/MedicalReportUtils';
import ViewEditDispose from '../../components/ViewEditDispose';
import ReportDescriptionCard from '../../components/ReportDescriptionCard.js';
import ReportListDateFilter from '../../components/ReportListDateFilter';
import { sortReportsByDate } from '../../components/SheepReportComponents';

const columns = [
  {
    id: 'premiseID',
    name: 'Sheep Premise ID'
  },
  {
    id: 'treatmentReason',
    name: 'Reason for Treatment'
  },
  {
    id: 'numberOfAnimals',
    name: 'Number of Sheep Treated',
  },
  {
    id: 'firstTreatmentDate',
    name: 'First Treatment Date'
  },
  {
    id: 'finalTreatmentDate',
    name: 'Final Treatment Date'
  },
  {
    id: 'createdAt',
    name: 'Date Created'
  },
  {
    id: 'actions',
    name: 'Actions'
  }
];

const MedicalReportListView = () => {
  const [medicalReports, setMedicalReports] = useState([]);

  const fetchMedicalReports = async () => {
    let fetchedReports = await getMedicalReports();

    fetchedReports.forEach(r => {
        r.actions = <ViewEditDispose 
          id={r._id} 
          endpoint={'/reports/medical/'} 
          hideDispose
          hideEdit
          hideReplace
        />;
        r.createdAt = (new Date(r.createdAt)).toISOString().split('T')[0];
        r.firstTreatmentDate = (new Date(r.firstTreatmentDate)).toISOString().split('T')[0];
        r.finalTreatmentDate = (new Date(r.finalTreatmentDate)).toISOString().split('T')[0];
    });
    setMedicalReports(sortReportsByDate(fetchedReports));
  }

  useEffect(() => {
    fetchMedicalReports();
  }, []);

  return <>
    <PageCard title="Medical Reports" to='/reports/medical/create' line back>
      <ReportDescriptionCard page='medical report'></ReportDescriptionCard>

        {medicalReports.length > 0 ? 
        <ReportListDateFilter 
          columns={columns}
          reports={medicalReports} 
        /> 
        : <></>}
    </PageCard>
  </>
};

export default MedicalReportListView;