import React, { useEffect, useState } from 'react';
import { Button } from '../components/CommonComponents.js';
import PageCard from '../components/PageCard.js';
import styled from 'styled-components';
import { viewColor } from "../components/Colors.js"
import { useParams } from 'react-router';
import axios from 'axios';
import { PrettyPrintJson } from '../components/SheepReportComponents';
import sha256 from 'js-sha256';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

//set the request depending on the report type
const setRequest = (reportType, id) => {
  let request = ``;
    switch(reportType){
      case 'medical':
        request = `/api/animalmedical/${id}`;
      break;

      case 'sighting':
        request = `/api/animalsighting/${id}`;
      break;

      case 'tagActivation':
      case 'raw':
        request = `/api/sheepreports/${id}`;
      break;

      case 'export':
        request = `/api/animalexport/${id}`;
      break;

      case 'retire':
      case 'replace':
        request = `/api/sheep/tag/replace/${id}`;
      break;

      case 'import':
        request = `/api/import/detail/${id}`;
      break;
      
      case 'movein':
      case 'moveout':
        request = `/api/move/${id}`;
      break;

      case 'transport':
        request = `/api/transport/${id}`
      break;

      case 'sheepedit':
        request = `/api/sheep/edit/sheepverify/${id}`;
      break;
    }
    return request;
}

const fetchReport = async (reportType, id) => {
  try{
    const report = await axios.get(setRequest(reportType, id));
    return report.data;
  } catch (err){
    throw new Error(`An error occurred fetching report info: ${err.message}`);
  }
};

const fetchBulkReport = async (id) => {
  try{
    const report = await axios.get(`/api/sheep/bulk/${id}`);
    return report.data;
  } catch (err){
    throw new Error(`An error occurred fetching report info: ${err.message}`);
  }
};

const getTransaction = async (hash) => {
  try{
    const tx = await axios.get(`/api/transaction/${hash}`);
    return tx.data;
  } catch (err){
    throw new Error(`An error occurred fetching transaction: ${err.message}`);
  }
};

const TransactionVerifyView = () => {
  const [reportInfo, setReportInfo] = useState();
  const [transaction, setTransaction] = useState();
  const [hash, setHash] = useState();
  const [isOldHash, setIsOldHash] = useState(false);
  const params = useParams();
  const reportType = params.reportType;
  const id = params.id;

  useEffect(() =>  {
    return fetchReport(reportType, id).then((report) => {
      if(Array.isArray(report)){
        setReportInfo(report[0]);
      }
      else{
        setReportInfo(report);
      }
    }).catch((err) => {
      toast.error(err);
    });
  }, [])

  useEffect(() => {
    if(reportInfo){
      getTransaction(reportInfo.txId).then((tx) => {
        setTransaction(tx);
      }).catch((err) => {
        toast.error(err);
      });
    }
  }, [reportInfo])

  //set the info to be hashed depending on the report type
  const setHashInfo = async (reportType) => {
    let info;

    switch(reportType){
      case 'medical':
        info = {
          userInformation: {
            username: reportInfo.username,
            firstName: reportInfo.firstName,
            lastName: reportInfo.lastName
          },
          premiseID: reportInfo.premiseID,
          animalWeight: reportInfo.animalWeight,
          numberOfAnimals: reportInfo.numberOfAnimals,
          firstTreatmentDate: reportInfo.firstTreatmentDate,
          finalTreatmentDate: reportInfo.finalTreatmentDate,
          treatmentReason: reportInfo.treatmentReason,
          medicine: reportInfo.medicine,
          expiryDate: reportInfo.expiryDate,
          dosage: reportInfo.dosage,
          isPrescription: reportInfo.isPrescription,
          route: reportInfo.route,
          meatWithdrawalDate: reportInfo.meatWithdrawalDate,
          milkWithdrawalDate: reportInfo.milkWithdrawalDate,
          animalTags: reportInfo.animalTags
        }
        break;

      case 'sighting':
        if(reportInfo.bulkReport){
          info = reportInfo.bulkReport;
        }
        else{
          info = {
            userInformation: {
              username: reportInfo.userInformation.username,
              firstName: reportInfo.userInformation.firstName,
              lastName: reportInfo.userInformation.lastName
            },
            location: reportInfo.location,
            reporterName: reportInfo.reporterName,
            reportDate: reportInfo.reportDate
          }
          if(reportInfo.isoNumber) {
            info.isoNumber = reportInfo.isoNumber;
          }
          if (reportInfo.localMgmtNumber){
            info.localMgmtNumber = reportInfo.localMgmtNumber;
          }
          if (reportInfo.tattooNumber){
            info.tattooNumber = reportInfo.tattooNumber;
          }
          if (reportInfo.usScrapieId){
            info.usScrapieId = reportInfo.usScrapieId;
          } 
          if (reportInfo.geolocation.type){
            info.geolocation = reportInfo.geolocation;
          }
        }
      break;

      case 'tagActivation':
        if(reportInfo.bulkReport){
          info = await fetchBulkReport(reportInfo.bulkReport);
        }
        else{
          info = {
            pid: reportInfo.pid,
            userInformation: {
              username: reportInfo.userInformation.username,
              firstName: reportInfo.userInformation.firstName,
              lastName: reportInfo.userInformation.lastName
            }
          }
          if(reportInfo.isoNumber) {
            info.isoNumber = reportInfo.isoNumber;
          }
          if (reportInfo.localMgmtNumber){
            info.localMgmtNumber = reportInfo.localMgmtNumber;
          }
          if (reportInfo.tattooNumber){
            info.tattooNumber = reportInfo.tattooNumber;
          }
          if (reportInfo.usScrapieId){
            info.usScrapieId = reportInfo.usScrapieId;
          }
          if (reportInfo.gender){
            info.gender = reportInfo.gender;
          }
          if (reportInfo.subgender){
            info.subgender = reportInfo.subgender;
          }
        }
      break;

      case 'export':
        if (reportInfo.isTemp){
          info ={
            userInformation: {
              username: reportInfo.userInformation.username,
              firstName: reportInfo.userInformation.firstName,
              lastName: reportInfo.userInformation.lastName
            },
            isTemp: true,
            departureTime: reportInfo.departureTime,
            arrivalTime: reportInfo.arrivalTime,
            departurePID: reportInfo.departurePID,
            vehicleNumber: reportInfo.vehicleNumber,
            animalTags: reportInfo.animalTags
          }
          if (reportInfo.destinationPID){
            info.destinationPID = reportInfo.destinationPID;
          }
          if (reportInfo.destinationLocation){
            info.destinationLocation = reportInfo.destinationLocation;
          }
          if (reportInfo.destinationType){
            info.destinationType = reportInfo.destinationType;
          }
          info.animals = reportInfo.animals.map((sheep) => {
            return sheep._id;
          });
        }
        else{
          info ={
            userInformation: {
              username: reportInfo.userInformation.username,
              firstName: reportInfo.userInformation.firstName,
              lastName: reportInfo.userInformation.lastName
            },
            isTemp: false,
            departurePID: reportInfo.departurePID,
            departureTime: reportInfo.departureTime,
            vehicleNumber: reportInfo.vehicleNumber,
            animalTags: reportInfo.animalTags
          }
          if (reportInfo.destinationPID){
            info.destinationPID = reportInfo.destinationPID;
          }
          if (reportInfo.destinationLocation){
            info.destinationLocation = reportInfo.destinationLocation;
          }
          if (reportInfo.arrivalTime){
            info.arrivalTime = reportInfo.arrivalTime;
          }
        }
      break;

      case 'replace':
        info ={
            userInformation: {
              username: reportInfo.userInformation.username,
              firstName: reportInfo.userInformation.firstName,
              lastName: reportInfo.userInformation.lastName
            },
            replacementDate: reportInfo.replacementDate
        }
        if (reportInfo.oldIsoNumber){
          info.oldIsoNumber = reportInfo.oldIsoNumber;
        }
        if (reportInfo.newIsoNumber){
          info.newIsoNumber = reportInfo.newIsoNumber;
        }
        if (reportInfo.oldLocalMgmtNumber){
          info.oldLocalMgmtNumber = reportInfo.oldLocalMgmtNumber;
        }
        if (reportInfo.newLocalMgmtNumber){
          info.newLocalMgmtNumber = reportInfo.newLocalMgmtNumber;
        }
        if (reportInfo.oldTattooNumber){
          info.oldTattooNumber = reportInfo.oldTattooNumber;
        }
        if (reportInfo.newTattooNumber){
          info.newTattooNumber = reportInfo.newTattooNumber;
        }
        if (reportInfo.oldUsScrapieId){
          info.oldUsScrapieId = reportInfo.oldUsScrapieId;
        }
        if (reportInfo.newUsScrapieId){
          info.newUsScrapieId = reportInfo.newUsScrapieId;
        }
        if (reportInfo.vehicle){
          info.vehicle = reportInfo.vehicle;
        }
      break;

      case 'import':
        info = {
          userInformation: {
            username: reportInfo.userInformation.username,
            firstName: reportInfo.userInformation.firstName,
            lastName: reportInfo.userInformation.lastName
          },
          exportingCountry: reportInfo.exportingCountry,
          firstDestinationPID: reportInfo.firstDestinationPID,
          dateOfArrival: reportInfo.dateOfArrival,
          animalIdentifications: reportInfo.animalIdentifications,
          vehicleIdentification: reportInfo.vehicleIdentification
        }
        if (reportInfo.departureSite) info.departureSite = reportInfo.departureSite;
        if (reportInfo.abattoirPID) info.abattoirPID = reportInfo.abattoirPID;
      break;

      case 'movein':
        info = {
          moveOut: reportInfo.moveOut,
          userInformation: {
            username: reportInfo.userInformation.username,
            firstName: reportInfo.userInformation.firstName,
            lastName: reportInfo.userInformation.lastName
          },
          departurePID: reportInfo.departurePID,
          departureTime: reportInfo.departureTime,
          destinationPID: reportInfo.destinationPID,
          receivedTime: reportInfo.receivedTime,
          unloadTime: reportInfo.unloadTime,
          animalTags: reportInfo.animalTags
        }
        if (reportInfo.vehicleNumber) info.vehicleNumber = reportInfo.vehicleNumber;
      break;

      case 'moveout':
        info = {
          moveOut: reportInfo.moveOut,
          userInformation: {
            username: reportInfo.userInformation.username,
            firstName: reportInfo.userInformation.firstName,
            lastName: reportInfo.userInformation.lastName
          },
          departurePID: reportInfo.departurePID,
          departureTime: reportInfo.departureTime,
          loadTime: reportInfo.loadTime,
          animalTags: reportInfo.animalTags
        }
        if (reportInfo.destinationPID) info.destinationPID = reportInfo.destinationPID;
        if (reportInfo.destinationType) info.destinationType = reportInfo.destinationType;
        if (reportInfo.vehicleNumber) info.vehicleNumber = reportInfo.vehicleNumber;
      break;

      case 'transport':
        info = {
          userInformation: {
            username: reportInfo.userInformation.username,
            firstName: reportInfo.userInformation.firstName,
            lastName: reportInfo.userInformation.lastName
          },
          departurePID: reportInfo.departurePID,
          destinationPID: reportInfo.destinationPID,
          departureDate: reportInfo.departureDate,
          departureTime: reportInfo.departureTime,
          vehicleNumber: reportInfo.vehicleNumber,
          animalTags: reportInfo.animalTags
        }
      break;

      case 'sheepedit':
        info = {
          user: reportInfo.user,
          animal: reportInfo.animal._id,
        }
        if(reportInfo.gender) info.gender = reportInfo.gender;
        if(reportInfo.newGender) info.newGender = reportInfo.newGender;
        if(reportInfo.breed) info.breed = reportInfo.breed;
        if(reportInfo.newBreed) info.newBreed = reportInfo.newBreed;
        if(reportInfo.subgender) info.subgender = reportInfo.subgender;
        if(reportInfo.newSubGender) info.newSubGender = reportInfo.newSubGender;
        if(reportInfo.birthdate) info.birthdate = reportInfo.birthdate;
        if(reportInfo.newBirthdate) info.newBirthdate = reportInfo.newBirthdate;
      break;

      //these are disposal and tag retirement reports
      case 'raw':
      case 'retire':
        info = await fetchBulkReport(reportInfo.bulkReport);
      break;
    }
    return info;
  }

  const generateHash = async () =>{
    const info = await setHashInfo(reportType);

    let infoStringified;
    if (
      reportType === 'raw' || 
      reportType === 'retire' ||
      reportType === 'tagActivation' && reportInfo.bulkReport
    ){
      infoStringified = JSON.stringify(info);
    }
    else if (reportType === 'sighting' && reportInfo.bulkReport){
      infoStringified = info;
    }
    else{
      infoStringified = JSON.stringify(info, Object.keys(info).sort());
    }

    var hashStr = `0x${sha256(infoStringified)}`;

    /**
     * Many of our previous reports had their report data stringified twice before a
     * hash was generated. This double stringify was removed, but in order to get the
     * correct hash for older reports, we must stringify twice here as well
    */
    if (hashStr !== transaction.input){
      hashStr = `0x${sha256(JSON.stringify(infoStringified))}`;
      setIsOldHash(true);
    }
    setHash(hashStr);
  }

  return <>
    <PageCard title='Verify Transaction' line back>

      <TextDiv>
        Here is your report&apos;s raw data:
      </TextDiv>

      <Code> 
        <PrettyPrintJson data={reportInfo}/>
      </Code>

      <TextDiv>
        Based on this raw data, we can generate a hash. 
        You can generate one in real time by clicking the button below.
      </TextDiv>

      <ButtonDiv>
        <Button type='submit' onClick={generateHash}>
          Generate Hash
        </Button>
      </ButtonDiv>

      <ExplanationDiv>{hash ? hash : 'Your generated hash will appear here'}</ExplanationDiv>

      <TextDiv>
        Here is the hash we have stored in the blockchain:
      </TextDiv>

      <ExplanationDiv>{transaction ? transaction.input : 'Loading...'}</ExplanationDiv>

      <TextDiv>
        You can verify this online by navigating to Ropsten and entering 
        the txId from the raw data above into the search bar on the site. 
        Alternatively, you can click on the direct link to it below.
      </TextDiv>

      <ButtonDiv>
        <Button onClick={() => window.open('https://ropsten.etherscan.io/')}>
          Go to Ropsten
        </Button>
        <Button onClick={() => window.open(`https://ropsten.etherscan.io/tx/${reportInfo.txId}`)}
          style={{backgroundColor: viewColor}}>
          Direct Link
        </Button>
        <Link to='/'>
          <Button style={{backgroundColor: 'darkCyan'}}>
            Back to Home
          </Button>
        </Link>
      </ButtonDiv>
      <br></br>
      {hash ? 
      <TextDiv>
        If you want to verify the hash yourself, first you must take the raw data and 
        undo the pretty print formatting. Then you must stringify the raw data
        {isOldHash ? ' twice' : ' once'}. Finally, run the data through a SHA-256 hash 
        function. Your hash should match the hashes displayed above.
      </TextDiv>
      : <></>}
    </PageCard>
  </>
}
export default TransactionVerifyView;

const TextDiv = styled.div`
  margin-bottom: 10px;
  line-height: 150%;
  font-size: 1rem;
`;
const ExplanationDiv = styled.div`
	padding: 1rem;
	background: grey;
	border-radius: 10px;
  margin-bottom: 20px;
  line-height: 150%;
  font-size: 1.25rem;
`;
const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Code = styled.code`
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  margin-left: 2.5%;
  margin-right: 2.5%;
  max-width: 95%;
  max-height: 400px;
  overflow: auto;
  padding: 0.5em 0.5em;
  display: block;
  word-wrap: break-word;
` 