// SheepImportNew.js
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PageCard from '../../components/PageCard';
import SimpleCard from '../../components/SimpleCard';
import { getUsername } from '../../utils/TokenUtils';
import axios from 'axios';
import {
  Button,
  FormTextInput,
  FormInputWrapper,
	FormInputLabel
} from '../../components/CommonComponents.js';
import styled from 'styled-components';
import StripedTable from '../../components/StripedTable';
import { extractDataFromHeaders } from '../../utils/CSVUtils';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import propTypes from 'prop-types';

const SubmittingDiv = styled.div`
  text-align: center
`;
const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin>

    </FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

const CheckBox = ({onChange, checked}) => {
  return <input type='checkbox' checked={checked} onChange={onChange} />;
}

CheckBox.propTypes = {
  onChange: propTypes.any,
  checked: propTypes.any
}

const extractDateFromFormat = (date, format) => {
  const formatSplit = format.split(/[-/]/);
  const dateSplit = date.split(/[-/]/);
  const month = dateSplit[formatSplit.findIndex(format => format === 'MM')];
  const day = dateSplit[formatSplit.findIndex(format => format === 'DD')];
  const year = dateSplit[formatSplit.findIndex(format => format === 'YYYY')];
  return month + "/" + day + "/" + year;
};

const buildSheepForSubmit = (sheeps, dateFormat) => {
  let sheepsForSubmit = [];
  sheeps.forEach(s => {
    // remove any spaces and commas from the ISO number
    const formattedIsoNumber = s.isoNumber ? s.isoNumber.replace(/[\s|,]/g, '') : undefined;

    const newSheep = {
      tag: {
        localMgmtNumber: s.localMgmtNumber,
        isoNumber: formattedIsoNumber,
        activeDate: s.activeDate ? extractDateFromFormat(s.activeDate, dateFormat) : null,
      },
      gender: s.gender,
      subgender: s.subgender ? s.subgender.toLowerCase() : undefined,
      birthdate: s.birthdate ? extractDateFromFormat(s.birthdate, dateFormat) : null,
      isExported: false
    };
    Object.keys(newSheep.tag).forEach(key => newSheep.tag[key] === undefined && delete newSheep.tag[key]);
    Object.keys(newSheep).forEach(key => newSheep[key] === undefined && delete newSheep[key]);
    sheepsForSubmit.push(newSheep);
  });
  return sheepsForSubmit;
}

const bulkSheepSubmit = async (submissionObject) => {
  try {
    toast.info("Submitting sheep to the backend. It may take up to a minute. You will be redirected when the sheep have been created. Please stay on this page.");
    await axios.post(`/api/sheep/bulk`, submissionObject);
    toast.success('Sheep created successfully');
    return;
  } catch(err) {
    throw err.response.data;
  }
}

const fetchPremises = async() => {
  try {
    const username = getUsername();
    const request = await axios.get(`/api/premises/user/${username}`);
    const fetchedPremises = request.data;
    return fetchedPremises;
  } catch(err) {
    throw new Error(`An error occurred fetching premises: ${err.message}`, err);
  }
}

const PremiseSelectDiv = styled.div`
  background-color: #707070;
  padding: .5rem;
  padding-top: 1rem;
  text-align: center;
  border-radius: 5px;
`
const DropdownDiv = styled.div`
  margin-bottom: 1rem;
  font-size: 2rem;
`

const PremiseLabel = styled.label`
  margin-right: 1rem;
`

const PremiseSelect = styled.select`
  height: 3rem;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
`

const CenteredButtonDiv = styled.div`
  text-align: center;
  margin-top: 2rem;
`

const SheepImport = ({data, headers}) => {
  const [sheeps, setSheeps] = useState([]);
  const [selectedSheeps, setSelectedSheeps] = useState([]);
  const [premises, setPremises] = useState([]);
  const [selectedPremise, setSelectedPremise] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState('');

  const fetchAndSetPremises = async () => {
    const fetchedPremises = await fetchPremises();
    setPremises(fetchedPremises);
  }

  useEffect(() => {
    setSheeps(extractDataFromHeaders(data, headers));
    fetchAndSetPremises();
  }, [data, headers]);

  const baseColumns = [
    {
      id: 'isoNumber',
      name: 'Isonumber'
    },
    {
      id: 'localMgmtNumber',
      name: 'Local Management Number'
    },
    {
      id: 'gender',
      name: 'Gender'
    },
    {
      id: 'subgender',
      name: 'Sub Gender'
    },
    {
      id: 'birthdate',
      name: 'DOB'
    },
    {
      id: 'activeDate',
      name: 'ActiveDate',
    }
  ];

  const columns = [
    {
      id: 'check',
      name: '' 
    },
    ...baseColumns
  ];

  const handleSubmit = async(e) => {
    try {
      e.preventDefault();
      setSubmitting(true);

      const sheepsToSubmit = buildSheepForSubmit(selectedSheeps, headers.dateFormat);
      const submissionObject = {
        sheep: sheepsToSubmit,
        premise: selectedPremise
      };

      await bulkSheepSubmit(submissionObject);
      setRedirect('/sheep');
    } catch (err) {
      setSubmitting(false);
      if (err.message.includes("ISO number") && err.message.includes("already exists")) {
        const dupIsoNumber = err.message.match(/[0-9]{15}/);
        toast.error(`An error occurred because the following ISO number is taken: ${dupIsoNumber}`);
      } else if (err.message.includes("ISO number") && err.message.includes("is duplicated")) {
        const dupIsoNumber = err.message.match(/[0-9]{15}/);
        toast.error(`An error occurred because the following ISO number is duplicated: ${dupIsoNumber}`);
      } else {
        toast.error(err.message);
      }
    }
  }

  const SelectAllSection = () => <CenteredButtonDiv>
    <Button onClick={(e) => {
      e.preventDefault();
      setSelectedSheeps(sheeps);
    }}>Select All</Button>
    {(selectedSheeps.length > 0) ? 
      <Button onClick={(e) => {
        e.preventDefault();
        setSelectedSheeps([]);
      }}>Clear All</Button>
    : <></>}
  </CenteredButtonDiv>;
  const sheepRowsWithCheck = sheeps.map(s => ({
    ...s,
    check: <CheckBox 
      checked={selectedSheeps.filter(selectedSheep => selectedSheep.dummyID === s.dummyID).length > 0}
      onChange={() => {
        const alreadySelected = selectedSheeps.filter(selectedSheep => selectedSheep.dummyID === s.dummyID).length > 0;
        if(alreadySelected){
          const newSelectedSheeps = selectedSheeps.filter(sheep => sheep.dummyID !== s.dummyID);
          setSelectedSheeps(newSelectedSheeps)
        } else {
          const newSelectedSheeps = [
            sheeps.filter(sheep => sheep.dummyID === s.dummyID)[0],
            ...selectedSheeps
          ];
          setSelectedSheeps(newSelectedSheeps);
        }
      }}
    />
  }));

  const selectedSheepColumns = [
    ...baseColumns,
    {
      id: 'remove',
      name: ''
    }
  ];

  const selectedSheepRowsWithRemove = selectedSheeps.map(s => ({
    ...s,
    remove: <button
      onClick={(e) => {
        e.preventDefault();
        const newSelectedSheep = selectedSheeps.filter(sheep => sheep.dummyID !== s.dummyID);
        setSelectedSheeps(newSelectedSheep);
      }}
    >Remove</button>
  }))

  if(redirect !== ''){
    return <Redirect to={redirect} />;
  }

  return <>
    <PageCard
      line
      back
      title='Sheep Bulk Import'
    >
      <SimpleCard
        title='Premise Selection'
      >
        <PremiseSelectDiv>
          <DropdownDiv>
            <PremiseLabel>Premise for Sheep:</PremiseLabel>
            <PremiseSelect
              value={selectedPremise}
              onChange={(e) => {
                setSelectedPremise(e.target.value);
              }}
            >
              <option
                value=''
                hidden
              >Select an option</option>
              {premises.map(p => <>
                <option
                  value={p._id}
                  key={p._id}
                >{`${p.name} - ${p.pid}`}</option>
              </>)}
            </PremiseSelect>
          </DropdownDiv>
        </PremiseSelectDiv>
      </SimpleCard>
      <SimpleCard
        title='Select Sheep'
      >
        <StripedTable
          columns={columns}
          rows={sheepRowsWithCheck}
          ShowCount
          max={10}
          paginate
        />
        <SelectAllSection />
      </SimpleCard>
      {(selectedSheeps.length > 0) ? 
        <SimpleCard
          title='Sheep selected to create'
        >
          <StripedTable
            columns={selectedSheepColumns}
            rows={selectedSheepRowsWithRemove}
            max={10}
            paginate
            ShowCount
          />
          {(selectedPremise) ? <CenteredButtonDiv>

            <Button onClick={handleSubmit} disabled={submitting}>
              Submit {`${selectedSheeps.length}`} Sheep
            </Button>
            {submitting && <SubmittingSpinner />}
            
           </CenteredButtonDiv>
          :<></>}
        </SimpleCard> : <></> }
    </PageCard>
  </>
}

SheepImport.propTypes = {
  data: propTypes.arrayOf(propTypes.string),
  headers: propTypes.arrayOf(propTypes.string)
}

export default SheepImport;