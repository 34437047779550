// OperationRouter.js
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import OperationsView from '../views/Operations/OperationsView';
import OperationCreate from '../views/Operations/OperationCreate';
import LearnMore from '../views/LearnMore';
import OperationUpdate from '../views/Operations/OperationUpdate';
import OperationView from '../views/Operations/OperationView';
import OperationsDashboard from '../views/Operations/OperationsDashboard';
import OperationDetail from '../views/Operations/OperationDetail';

const OperationRouter = () => (
  <Switch>
    <Route exact path='/operations/dashboard' component={OperationsDashboard} />
    <Route exact path='/operations' component={OperationsView} />
    <Route exact path='/operations/create' component={OperationCreate} />
    <Route exact path='/operations/learnMore' component={LearnMore} />
    <Route path='/operations/:id/update' component={OperationUpdate} />
    <Route exact path='/operations/detail/:id' component={OperationDetail} />
    <Route exact path='/operations/:id' component={OperationView} />
  </Switch>
);

export default OperationRouter;
