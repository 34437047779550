// VehicleCreate.js
import React, { useState, useEffect } from 'react';
import FormCard from '../../components/FormCard.js';
import {
	FormContainer,
	Button 
} from '../../components/CommonComponents.js';
import Input from '../../components/Input.js';
import { getAllStateAbbrv, getStateNameFromAbbrv } from '../../utils/USAStates.js';
import { getAllProvinceAbbrv, getProvinceNameFromAbbrv } from '../../utils/CAProvinces.js';
import Multiselect from '../../components/Multiselect.js';
import { createVehicle } from '../../utils/VehicleUtils.js';
import { Redirect } from 'react-router-dom';
import DropDown from '../../components/DropDown.js';
import axios from 'axios';
import { getUsername } from '../../utils/TokenUtils.js';
import { toast } from 'react-toastify';



const VehicleCreateView = () => {
	const [operations, setOperations] = useState([]);
	const [form, setForm] = useState({
		licensePlateNum: '',
		name: '',
		provinceOrState: '',
		operation: '',
	});
	const [proceedBtn, setProceedBtn] = useState(false);

	const fetchOperations = async () => {
		const result = await axios.get(`/api/operations/user/${getUsername()}`);
		const reducedOperations = result.data.map(o => ({
			id: o._id,
			name: o.businessName,
		}));

		setForm({
			...form,
			operation: reducedOperations[0].id,
		});

		setOperations(reducedOperations);
	}

	useEffect(() => {
		if(operations.length === 0) fetchOperations();
	}, []);

	// eslint-disable-next-line no-unused-vars
	const [redirect, setRedirect] = useState('');

	const submitVehicle = async (e) => {
		e.preventDefault();
		try {
			await createVehicle(form);
			setRedirect(`/vehicles/`);
		} catch(err){
			toast.error(err.response.data.message);
		}
		
		
	}

	const submitVehicleCorrectPlate = async (form) => {

		try {
			await createVehicle(form);
			setRedirect(`/vehicles/`);
		} catch(err){
			toast.error(err.response.data.message);
		}
	}

	const handleProvinceOrStateClick = (provinceOrState) => {
		setForm({
			...form,
			provinceOrState: provinceOrState
		});
	};

	const USStateDropDownData = () => {
		const states = getAllStateAbbrv();
		return states.map(s => ({
			id: s,
			label: getStateNameFromAbbrv(s),
			checked: (form.provinceOrState === s),
			onClick: () => handleProvinceOrStateClick(s)
		}));
	}

	const CAProvinceDropDownData = () => {
		const provinces = getAllProvinceAbbrv();
		return provinces.map(p => ({
			id: p,
			label: getProvinceNameFromAbbrv(p),
			checked: (form.provinceOrState === p),
			onClick: () => handleProvinceOrStateClick(p)
		}));
	}

	const formInputs = [
		{
			name: 'Vehicle Name',
			hint: "John's Pickup Truck",
			id: 'name',
		},
		{
			name: 'License Plate, Vin Number or Description',
			hint: 'ABCD 123',
			id: 'licensePlateNum'
		}
	]

	const operationDropDown = {
		name: 'Operation Name',
		id: 'operation'
	};

	if(redirect !== ''){
		return <Redirect to={redirect} />
	}

	return <>
		<FormCard title='Create a Vehicle' back>
			<FormContainer>
				{formInputs.map(i => <>
					<Input
						id={i.id}
						placeholder={i.hint}
						labelName={i.name}
						onChange={(e) => setForm({
							...form,
							[e.target.name]: e.target.value
						})}
						value={form[i.id]}
						required
					/>
				</>)}
				<Multiselect name='provinces' title='Provinces' options={CAProvinceDropDownData()} />
				<Multiselect name='vehicles' title='States' options={USStateDropDownData()} />
				<DropDown
					id={operationDropDown.id}
					name={operationDropDown.name}
					onChange={(e) => {
						setForm({
							...form,
							[e.target.id]: e.target.value
						});
					}}
					value={form[operationDropDown.id]}
					options={
						operations.map(o => ({
							name: o.name,
							value: o.id,
						}))
					}
					required
				/>


				<Button onClick={submitVehicle}>Submit</Button>
				
			</FormContainer>


		</FormCard>
	</>
}


export default VehicleCreateView;
