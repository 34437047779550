import React, { useState, useEffect } from 'react';
import PageCard from '../../components/PageCard.js';
import { agGreen, viewColor, deleteColor } from '../../components/Colors.js';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { EditButton as Button } from '../../components/CommonComponents';
import { StyledTable } from '../../components/TableComponents.js';
import { getUsers, deleteUser } from '../../utils/UserUtils.js';
import SimpleCard from '../../components/SimpleCard';
import propTypes from 'prop-types';
import axios from 'axios';

export default function UsersView() {
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(true);

	const [currRole, setCurrRole] = useState({}); 

	const fetchCurrentRole = async() => {

		const temp = await axios.get('/api/users/role');
		return temp;
	}

	const fetchAndSetRoleInfo = async () => {
		try {
			let fetchedRole = await fetchCurrentRole();
			setCurrRole(fetchedRole.data);
		} catch (err) {
			
		}
	}

	  
	useEffect(() => {
		fetchAndSetRoleInfo();
	  }, []);


	useEffect(() => {
		getUsers().then((res) => {
			setLoading(false);
			setUsers(res);
		});
	}, []);

	const deleteUsername = (username) => {
		deleteUser(username);
		const deleted = users.filter((user) => user.username !== username);
		setUsers(deleted);
	};

	const DisplayUser = ({ user }) => (
		<tr>
			<td>{user.username}</td>
			<td>{user.firstName}</td>
			<td>{user.lastName}</td>
			<td>
				<ButtonContainer>
					<Link to={`/users/${user.username}`}>
						<ViewButton>View</ViewButton>
					</Link>
					<DeleteButton onClick={() => deleteUsername(user.username)}>Delete</DeleteButton>
				</ButtonContainer>
			</td>
		</tr>
	);

	DisplayUser.propTypes = {
		user: propTypes.object
	}

	return (
		<PageCard title='All Users' back>

			{currRole == 'admin' ? (
				<StyledTable>
					<thead>
						<tr>
							<th>Username</th>
							<th>First Name</th>
							<th>Last Name</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{loading ? <>Loading...</> : users.map((user) => <DisplayUser key={user.id} user={user} />)}
					</tbody>
				</StyledTable>
			 ) : (
				<SimpleCard>
					<h2>You do not have the Permission to view this page, please click the button below to be redirected back</h2>
					
					<Link to= '/'>
						<Button>Home</Button>
					</Link>
				</SimpleCard>
				
				)}	

		</PageCard>
	);
}

const ButtonContainer = styled.div`
	display: flex;
`;

const ViewButton = styled(Button)`
	background-color: ${viewColor};
`;

const DeleteButton = styled(Button)`
	background-color: ${deleteColor};
`;

const EditButton = styled(Button)`
	background-color: ${agGreen};
`;
