// TagActivationListView.js
import React from 'react';
import { FetchTagActivationReports } from '../../components/SheepReportComponents';
import SheepReportListComponent from '../../components/SheepReportListComponent';

const TagActivationListView = () => {
  return <>
    <SheepReportListComponent
      title="Tag Activation Reports"
      name="tag activation report"
      fetchReportsFunction={FetchTagActivationReports}
      detailEndpoint={'/reports/tagactivation/'}
    />
  </>
}

export default TagActivationListView;