import axios from 'axios';


export const getExportReports = () => {
  return axios.get('/api/animalexport')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

//get the export reports for a specific sheep
export const getExportReportsForSheep = (id) => {
  return axios.get(`/api/animalexport/sheep/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

export const createTempExportReport = (data) => {
  return axios.post('/api/animalexport/temp', data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.error;
    });
};

export const createExportReport = (data) => {
  return axios.post('/api/animalexport/permanent', data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.error;
    });
};

export const createBulkExportReport = (data) => {
  return axios.post('/api/animalexport/bulk', data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.error;
    });
};

export const createBulkTempExportReport = (data) => {
  return axios.post('/api/animalexport/bulkTemp', data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.error;
    });
};