import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { rowColorA, rowColorB, agGreen, viewColor, deleteColor } from './Colors.js';
import { EditButton, FormSelectInput } from '../components/CommonComponents';
import propTypes from 'prop-types';

const GROUP_NUM = 2; // How many pages to the left/right of each page
const PAGE_SIZES = [10, 25, 50, 100]; // Allowable user settable page sizes
export default function TableComponent({
  headings,
  data,
  view,
  edit,
  remove,
  check,
  actions,
  filters,
}) {
  const [empty, setEmpty] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState({});
  const [selected, setSelected] = useState(0);

  //Pagination:
  // TODO: Disable pagination if on mobile (for better user xp)
  const [currentPage, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [pageNum, setPageNum] = useState(0);

  const DisplayData = ({ element }) => {
    return (
      <tr>
        {headings.map((heading) => (
          <td key={heading.id}>{element[heading.id]}</td>
        ))}
        {actions && (
          <td>
            <ButtonContainer>
              {typeof check === 'object' && (
                <EditButton
                  bColor={viewColor}
                  onClick={() => {
                    const diff = element.checked ? selected + 1 : selected - 1;
                    setSelected(diff);
                    check.cb(element, !element.checked);
                  }}
                >
                  {element.checked ? 'Unselect' : 'Select'}
                </EditButton>
              )}
              {typeof view === 'object' && (
                <EditButton bColor={viewColor} onClick={() => view.cb(element)}>
                  {view.title}
                </EditButton>
              )}
              {typeof edit === 'object' && (
                <EditButton bColor={agGreen} onClick={() => edit.cb(element)}>
                  {edit.title}
                </EditButton>
              )}
              {typeof remove === 'object' && (
                <EditButton bColor={deleteColor} onClick={() => remove.cb(element)}>
                  {remove.title}
                </EditButton>
              )}
            </ButtonContainer>
          </td>
        )}
      </tr>
    );
  };

  useEffect(() => {
    setEmpty(!data.length);
    setFiltered(data);
  }, [data]);

  useEffect(() => {
    setFiltered(
      data.filter((element) => {
        for (const [key, value] of Object.entries(search)) {
          if (
            //!element.hasOwnProperty(key) ||
            !Object.prototype.hasOwnProperty.call(element, "key") ||
            !String(element[key]).toLowerCase().includes(value.toLowerCase())
          ) {
            return false;
          }
        }
        return true;
      })
    );
  }, [search, data]);

  useEffect(() => {
    setEmpty(!filtered.length);
    setPageNum(Math.ceil(filtered.length / pageSize));
  }, [filtered, pageSize]);

  useEffect(() => {
    setPageNum(Math.ceil(filtered.length / pageSize));
    setPage(0);
  }, [pageSize, filtered.length]);

  return (
    <>
      {empty ? (
        <NoData>There seems to be nothing here.</NoData>
      ) : (
        <>
          <StyledTable>
            <thead>
              <tr>
                {headings.map((heading) => (
                  <th key={heading.id}>{heading.name}</th>
                ))}
                {actions && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {(filters || check?.checkType === 'multiple') && (
                <tr>
                  {filters &&
                    headings.map((heading) => (
                      <td key={heading.id}>
                        <input
                          placeholder={`Filter ${heading.name}`}
                          value={search[heading.id]}
                          onChange={(e) => {
                            if (e.target.value === '') {
                              // eslint-disable-next-line no-unused-vars
                              const { [heading.id]: _, ...updatedState } = search;
                              setSearch(updatedState);
                            } else setSearch({ ...search, [heading.id]: e.target.value });
                          }}
                        />
                      </td>
                    ))}

                  {check?.type === 'multiple' && (
                    <td>
                      <EditButton
                        bColor={viewColor}
                        onClick={() => {
                          check.cb(null, selected !== filtered.length);
                          setSelected(selected === filtered.length ? 0 : filtered.length);
                        }}
                      >
                        {selected === filtered.length ? 'Unselect All' : 'Select All'}
                      </EditButton>
                    </td>
                  )}
                </tr>
              )}
              {filtered
                .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                .map((element) => (
                  <DisplayData key={element._id} element={element} />
                ))}
            </tbody>
          </StyledTable>
          <PageNumbers>
            <span disabled={currentPage === 0} onClick={() => setPage((page) => page - 1)}>
              &larr;
            </span>

            <span className={currentPage === 0 && 'active'} onClick={() => setPage(0)}>
              1
            </span>

            {currentPage > GROUP_NUM + 1 && <span className='arrow'>&hellip;</span>}

            {Array.from(
              {
                length:
                  Math.min(pageNum - 2, currentPage + GROUP_NUM) -
                  Math.max(1, currentPage - GROUP_NUM) +
                  1,
              },
              (_, i) => Math.max(1, currentPage - GROUP_NUM) + i + 1
            ).map((page) => (
              <span key={page}
                className={currentPage + 1 === page && 'active'}
                onClick={() => setPage(page - 1)}
              >
                {page}
              </span>
            ))}

            {pageNum - currentPage > GROUP_NUM + 2 && <span className='arrow'>&hellip;</span>}

            {pageNum > 1 && (
              <span
                className={currentPage + 1 === pageNum && 'active'}
                onClick={() => setPage(pageNum - 1)}
              >
                {pageNum}
              </span>
            )}

            <span
              disabled={currentPage + 1 === pageNum}
              onClick={() => setPage((page) => page + 1)}
            >
              &rarr;
            </span>

            <PageSize>
              Records per page:
              <FormSelectInput value={pageSize} onChange={(e) => setPageSize(e.target.value)}>
                {PAGE_SIZES.map((o) => (
                  <option value={o} key={o}>{o}</option>
                ))}
              </FormSelectInput>
            </PageSize>
          </PageNumbers>
        </>
      )}
    </>
  );
}

TableComponent.propTypes = {
  headings: propTypes.any,
  view: propTypes.any,
  data: propTypes.any,
  edit: propTypes.any,
  remove: propTypes.any,
  check: propTypes.any,
  actions: propTypes.any,
  filters: propTypes.any,
  element: propTypes.any
}

const PageNumbers = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;

  span {
    background-color: #333333;
    padding: 8px 16px;
    border: 1px solid #fff;

    &.active {
      color: black;
      background-color: ${agGreen};
      border: 1px solid ${agGreen};
    }

    &[disabled] {
      pointer-events: none;
    }

    &:hover:not(.active):not(.arrow):not([disabled]) {
      cursor: pointer;
      background-color: #fff;
      color: black;
    }

    &:first-of-type {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-of-type {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
`;

const PageSize = styled.div`
  display: flex;
  white-space: nowrap;
  height: 100%;
  align-items: center;

  margin: 0 10px;

  select {
    margin-left: 10px;
  }
`;

const StyledTable = styled.table`
  border-bottom: 2px solid ${agGreen};
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  border: none;
  background-color: ${rowColorA};

  tr button {
    margin: 5px;
    margin-bottom: 7px;
  }

  th,
  td {
    padding-left: 10px;

    font-size: 0.85vw;

    > input {
      border: none;
      border-radius: 10px;
      padding: 3px 6px;
      margin: 3px 0;
    }
  }

  th {
    padding-top: 10px;

    font-size: 0.85vw;

    text-transform: uppercase;
    text-align: left;
    background-color: ${agGreen};
  }

  tr:nth-child(even) {
    background-color: ${rowColorB};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const NoData = styled.div`
  background-color: white;
  color: black;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  text-align: center;
`;
