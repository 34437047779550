import React from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { Button } from './CommonComponents';

const options = ['easy', 'simple', 'fast'];

export default function Landing() {
	return (
		<LandingContainer>
			<CenterDiv>
				<Text>
					Tracking sheep, made&nbsp;
				</Text>
				
				<TextOptionsDiv>
					{options.map((option, i) => (
						<TextOptions key={i} delay={i}>
							{option}.
						</TextOptions>
					))}
				</TextOptionsDiv>
			</CenterDiv>

			<ButtonGroup>
				<Link to='/register'>
					<Button style={{color: '#F5DEB3'}}>Join Now</Button>
				</Link>
				<Link to='/login'>
					<BorderedButton>Login</BorderedButton>
				</Link>
			</ButtonGroup>
		</LandingContainer>
	);
}

const BorderedButton = styled(Button)`
	background-color: #F5DEB3;
	border: 3px solid #17a737;
	color: #17a737;
	font-weight: bold;
`;

const LandingContainer = styled.div`
	margin: auto;
`;

const ButtonGroup = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;

	Button {
		margin: 5px;
	}
`;

const Text = styled.div`
	font-size: 30px;
	text-align: center;
	margin-bottom: 20px;
	display: inline-block;
`;

const CenterDiv = styled.div`
	text-align: center;
	margin-left: -60px;
	display: block;
	font-weight: bold;
`;

const DisappearKeyframe = keyframes`
	0%,${100 / options.length}% {
		opacity: 1;
	}
	${100 / options.length + 0.01}%,100% {
		opacity: 0;
	}
`;

const TextOptionsDiv = styled.div`
	font-size: 30px;
	display: inline-block;
	vertical-align: top;
`;

const TextOptions = styled.span`
	font-size: inherit;
	position: fixed;
	color: ForestGreen;

	animation: ${DisappearKeyframe} ${options.length}s infinite;
	animation-delay: ${(props) => props.delay}s;
`;
