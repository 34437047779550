import React, { useState, useEffect } from 'react';
import PageCard from '../components/PageCard.js';
import TableComponent from '../components/TableComponent';
import { getUsername } from '../utils/TokenUtils';
import { toast } from 'react-toastify';
import StripedTable from '../components/StripedTable';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const axios = require('axios');
const moment = require('moment');

const getNotifications = () => {
	return axios.get('/api/notifications/user')
	  .then((res) => {
		return res.data;  
	  })
	  .catch((err) => {
		throw err.response.data.message;
	  });
  };



const moveInColumns = [
	{
	  id: 'sender',
	  name: 'From'
	},
	{
	  id: 'reportDate',
	  name: 'Date of Reception'
	},
	{
		id: 'read',
		name: 'Read'
	},
	{
		id: 'actions',
		name: 'Actions'
	}
  ];

export default function Notifications() {
	const [messages, setMessages] = useState([]);
	const [notificationList, setNotificationList] = useState([]);


	const fetchNotificationReport = async () => {
		let fetchedReport = await getNotifications();
		fetchedReport.forEach(r => {
			r.actions = <ViewDelete 
			id={r._id} 
			endpoint={'/reports/notification/'}
			hideEdit
			hideReplace
			/>;
			r.read =  r.read ? 'Yes' : 'No',
			r.reportDate = (new Date(r.date)).toISOString().split('T')[0];
			r.sender = r.senderInformation.username;
		});
		setNotificationList(fetchedReport.reverse());
	}  
	
	useEffect(() => {
		fetchNotificationReport();
	}, []);
	


	const tableHeadings = [
		{ name: 'Date', id: 'date' },
		{ name: 'Message', id: 'message' },
		{ name: 'Sender', id: 'sender' },
	];

	const viewNotification = () => {};
	const dismissNotification = ({ _id }) => {
		axios.delete(`/api/notifications/${getUsername()}/${_id}`).then(
			() => {
				setMessages(messages.filter((element) => element._id !== _id));
			},
			(err) => {
				toast.error(`An error has occurred during deletion: ${err}`);
			}
		);
	};

	const deleteNotification = (data) => {
		return axios.delete(`/api/notifications/delete/${data}`)
		.then((res) => {
		  fetchNotificationReport();
		  return res.data;  
		})
		.catch((err) => {
		  throw err.response.data.message;
		});
		
		
	}
	
	const ViewDelete = ({ 
	  
		
		id, 
		endpoint, 
		disposeName,
		editName,
		hideDispose,
	  }) => {
		
		if(!disposeName) disposeName = 'Delete';
		if(!editName) editName = 'Edit'
		const viewLink = `${endpoint}${id}`;
		const editLink = `${endpoint}edit?id=${id}`;
		const disposeLink = `${endpoint}dispose?id=${id}`;
		const replaceLink = `${endpoint}replace?id=${id}`;
		return <ViewDeleteDiv>
		  <Link to={viewLink}><FlatButton color="white">View</FlatButton></Link> 
		  {(hideDispose) ? 
			<></> : 
			<>&nbsp;|&nbsp;
				<FlatButton color="#f23b3b" onClick={() => deleteNotification(id)}> {disposeName}</FlatButton>
			</>
		  }
		</ViewDeleteDiv>
	  }
	  
	  ViewDelete.propTypes = {
		id: propTypes.any,
		endpoint: propTypes.any,
		disposeName: propTypes.any,
		disposeLink: propTypes.any,
		hideDispose: propTypes.any,
	  };
	  
	  const ViewDeleteDiv = styled.div`
		color: white;
	  `;
	  
	  const FlatButton = styled.button`
		color: white;
		background: none;
		border-radius: 10px;
		border-color: ${props => props.color};
		border-style: solid;
		outline: none;
		cursor: pointer;
	  `;
	

	return (
		<PageCard back title='Notifications'>
		<StripedTable
          columns={moveInColumns}
          rows={notificationList}
          ShowCount
          max={10}
          paginate
        />
			
		</PageCard>
	);
}


