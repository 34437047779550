import { useState } from "react";
import DecisionCard from "../../components/DecisionCard";

const AnimalTaggingDecisionView = () => {
  const [state, setState] = useState({
    currentId: 'INIT'
  });

  const EventDecisions = [
    {
      id: 'INIT',
      backToHelp: true,
      title: 'Animal Tagging - First Tag',
      description: "Did you tag the animal while it was still on its farm of origin?",
      choices: [
        {
          name: 'Yes',
          update: () => {
            console.log('clicked!')
            setState({...state, firstTag: true, currentId: 'ORIGIN'});
          },
        },
        {
          name: 'No',
          color: 'secondary',
          update: () => {
            setState({...state, firstTag: false, currentId: 'LOST'});
          }
        }
      ]
    },
    {
      id: 'ORIGIN',
      backToHelp: true,
      prev: 'INIT',
      onBack: () => setState({...state, currentId: 'INIT' }),
      title: 'Animal Tagging - Farm of Origin',
      description: 'Is this the first approved tag applied to this animal?',
      choices: [
        {
          name: 'Yes',
          update: () => {
            setState({...state, origin: true, currentId: 'CREATE'});
          },
        },
        {
          name: 'No',
          color: 'secondary',
          update: () => {
            setState({...state, firstTag: false, currentId: 'REPLACEMENTAPPROVE'});
          }
        }
        
      ]
    },
    {
      id: 'LOST',
      backToHelp: true,
      prev: 'INIT',
      onBack: () => setState({...state, currentId: 'INIT' }),
      title: 'Animal Tagging - Lost Tag',
      description: 'Was the animal received having lost a previously applied approved tag?',
      choices: [
        {
          name: 'Yes',
          update: () => {
            setState({...state, lost: true, currentId: 'REPLACEMENT'})
          }
        },
        {
          name: 'No',
          color: 'secondary',
          update: () => {
            setState({...state, firstTag: false, currentId: 'LOSTACTIVATE'});
          }
        }
      ]
    },
    {
      id: 'REPLACEMENT',
      backToHelp: true,
      prev: 'LOST',
      onBack: () => setState({...state, currentId: 'LOST' }),
      title: 'Animal Tagging - Tag Replacement',
      description: 'You are required to report the replacement of a lost approved tag of animals that you receive. You can report a tag replacement here. Return to the dashboard, identify the original tag, and select \'Replace\'',
      choices: [
        {
          name: 'Go to Sheep Dashboard',
          link:'/sheep'
        }
      ]
    },
    {
      id: 'REPLACEMENTAPPROVE',
      backToHelp: true,
      prev: 'LOST',
      onBack: () => setState({...state, currentId: 'LOST' }),
      title: 'Animal Tagging - Tag Replacement',
      description: 'You are required to report the application of an approved tag to an animal that has lost an approved tag, whose previous tag has been revoked, or whose approved tag has malfunctioned (only permitted by direction of the CFIA).  You can report a tag replacement event here.',
      choices: [
        {
          name: 'Go to Sheep Dashboard',
          link:'/sheep'
        }
      ]
    },
    {
      id: 'LOSTACTIVATE',
      backToHelp: true,
      prev: 'LOST',
      onBack: () => setState({...state, currentId: 'LOST' }),
      title: 'Animal Tagging - Tag Active',
      description: 'You are required to report applying an approved tag to an animal that you received that has never had an approved tag.  You can report a tag activation event here',
      choices: [
        {
          name: 'Go to \'Create a new sheep\'',
          link: '/sheep/create/manual'
        }
      ]
    },
    {
      id: 'CREATE',
      backToHelp: true,
      prev: 'ORIGIN',
      onBack: () => setState({...state, currentId: 'ORIGIN' }),
      title: 'Animal Tagging - Create a new sheep',
      description: 'You are not required to report that you have applied an approved tag to an animal (tag activation) for the first time while it is still on its farm of origin, but doing so allows you to track animals in your own inventory and add attributes to the animal.  You can voluntarily report a tag activation event here',
      choices: [
        {
          name: 'Go to \'Create a new sheep\'',
          link: '/sheep/create/manual'
        }
      ]
    }
  ]

  const getDecision = (id) => {
    const decisions = EventDecisions.filter(d => d.id == id);
    if(decisions.length == 0) {
      setState({...state, currentId: 'INIT'})
      alert('Invalid decision id');
      return EventDecisions[0];
    }

    return decisions[0];
  }

  return <>
    <DecisionCard decision={getDecision(state.currentId)}>

    </DecisionCard>
  </>;
}

export default AnimalTaggingDecisionView;