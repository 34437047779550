import React, { useState, useEffect } from 'react';
import PageCard from '../../components/PageCard';
import { getImportReports } from '../../utils/ImportUtils';
import ReportDescriptionCard from '../../components/ReportDescriptionCard.js';
import ReportListDateFilter from '../../components/ReportListDateFilter';
import ViewEditDispose from '../../components/ViewEditDispose';
import { NAField } from '../../components/SheepReportComponents';

const importColumns = [
  {
    id: 'exportingCountry',
    name: 'Exporting Country'
  },
  {
    id: 'departurePID',
    name: 'Departure Site'
  },
  {
    id: 'animalIdentifications',
    name: 'Imported Animals (Tag Info)'
  },
  {
    id: 'dateOfArrival',
    name: 'Date of Arrival'
  },
  {
    id: 'actions',
    name: 'Actions'
  }
];

const ImportReportListView = () => {
  const [importReports, setImportReports] = useState([]);

  useEffect(() => {
    getImportReports()
      .then(reports => {
        reports.forEach(r => {
          r.actions = <ViewEditDispose 
          id={r._id} 
          endpoint={'/reports/import/'}
          hideDispose
          hideEdit
          hideReplace
          />;
          r.dateOfArrival = (new Date(r.dateOfArrival)).toISOString().split('T')[0];
        });
        setImportReports(reports.reverse());
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  importReports.forEach(s => {
    if(!s.departurePID) s.departurePID = <NAField />;
  });

  return <>
    <PageCard title="Import Reports" line back maxContent={true} to='/reports/import/create/manual'>
      <ReportDescriptionCard page='import report'></ReportDescriptionCard>
        
      {importReports.length > 0 ? 
        <ReportListDateFilter 
          columns={importColumns}
          reports={importReports} 
        /> 
      : <></>}
    </PageCard>
  </>
};

export default ImportReportListView;