import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import SimpleCard from './SimpleCard';
import { agGreen, viewColor } from './Colors';

const ButtonContainer = styled.div`
  width: 100%;
  text-align: right;

  button {
    margin: 0 0.5em;
    border: none;
    border-radius: 20px;
    padding: 0.3em 0.9em;
    cursor: pointer;
    color: white;
  }
`;

const NextButton = styled.button`
  background-color: ${agGreen};
`;

const PrevButton = styled.button`
  background-color: ${viewColor};
`;

const CloseButtonContainer = styled.div`
  width: 100%;
  position: relative;
  top: -0.5em;
  right: -0.5em;
`;

const CloseButton = styled.div`
  border-radius: 0 8px 0 0;
  height: max-content;
  width: max-content;
  padding: 2px 20px;
  cursor: pointer;
  position: absolute;
  right: 0;

  &:hover {
    background: red;
  }

  &::before {
    content: "X";
  }
`;

// regex taken from https://stackoverflow.com/questions/1500260
function splitOnLink(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex);
}

export default function FloatingTextBox({
  top,
  left,
  bottom,
  right,
  translateCenter,
  title,
  description,
  onNext,
  onPrev,
  onFinish,
  onClose
}) {

  const FloatingContainer = styled.div`
    position: absolute;
    top: ${top};
    left: ${left};
    bottom: ${bottom};
    right: ${right};
    ${translateCenter && 'transform: translate(-50%, -50%);'};
    min-width: 40ch;
    max-width: 40%;
    border-radius: 8px;
    background-color: rgb(54, 54, 54);
    border-bottom: 2.5px solid rgb(79, 79, 79);
    box-shadow: 0 12px 10px rgb(66, 66, 66);
    padding: 0.5em;

    p {
      font-size: 1.05rem;
      margin: 1em 0.25em;
      line-height: 1.6;
      white-space: pre-line;
    }

    a {
      font-size: 1.05rem;
      color: #62B6CB;
    }
  `;

  return (
    <FloatingContainer>
      <CloseButtonContainer>
        <CloseButton onClick={onClose} />
      </CloseButtonContainer>
      <SimpleCard title={title} size="lg">
        {description.map(block => {
          const sections = splitOnLink(block);
          return (
            <div>
              {sections.map(section => {
                if (section.startsWith("http")) {
                  return <a href={section}>{section}</a>
                } else {
                  return <p>{section}</p>;
                }
              })}
            </div>
          );
        })}
        <ButtonContainer>
          {onPrev &&
            <PrevButton onClick={onPrev}>
              Previous
            </PrevButton>
          }
          {onNext &&
            <NextButton onClick={onNext}>
              Next
            </NextButton>
          }
          {onFinish &&
            <NextButton onClick={onFinish}>
              Finish
            </NextButton>
          }
        </ButtonContainer>
      </SimpleCard>
    </FloatingContainer>
  )
}

FloatingTextBox.propTypes = {
  top: propTypes.number || propTypes.string,
  left: propTypes.number || propTypes.string,
  bottom: propTypes.number || propTypes.string,
  right: propTypes.number || propTypes.string,
  translateCenter: propTypes.bool,
  title: propTypes.string,
  description: propTypes.arrayOf(propTypes.string),
  onNext: propTypes.func,
  onPrev: propTypes.func,
  onFinish: propTypes.func,
  onClose: propTypes.func
}