import React from 'react';
import { 
	FormInputWrapper, 
	FormInputLabel, 
	FormTextInput, 
	EditButton
} from './CommonComponents';
import { deleteColor } from './Colors.js';
import propTypes from 'prop-types';

const GeolocationInput = ({ location, getLocation, clearLocation }) => {
  return (
    <FormInputWrapper>
      <FormInputLabel style={{ float: 'left', clear: 'left' }}>
        Location (Latitude, Longitude):
        <FormTextInput 
          style={{ width: '90%' }}
          value={location && location.length === 2 ? location[1] + ", " + location[0] : ""}
          disabled
        />
      </FormInputLabel>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <EditButton type="button" style={{ maxWidth: 'max-content' }} onClick={getLocation}>
          Get current location
        </EditButton>
        <EditButton type="button" bColor={deleteColor} onClick={clearLocation}>
          Clear location
        </EditButton>
      </div>
    </FormInputWrapper>
  );
};

GeolocationInput.propTypes = {
  location: propTypes.array,
  getLocation: propTypes.func,
  clearLocation: propTypes.func
};

export default GeolocationInput;