// VehicleView.js
import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import PageCard from '../../components/PageCard.js';
import { agGreen, deleteColor } from '../../components/Colors.js';
import { 
	getVehicleById,
	deleteVehicleById,
} from '../../utils/VehicleUtils.js';
import DetailTable from '../../components/DetailTable.js';
import { getStateNameFromAbbrv } from '../../utils/USAStates.js';
import { getProvinceNameFromAbbrv } from '../../utils/CAProvinces.js';

export default function VehicleView() {
	const params = useParams();
	const vehicleId = params.vehicleId;
	const [vehicle, setVehicle] = useState();
	const [redirect, setRedirect] = useState('');

	const fetchAndSetVehicle = async () => {
		const result = await getVehicleById(vehicleId);
		const province = getProvinceNameFromAbbrv(result.provinceOrState);
		const state = getStateNameFromAbbrv(result.provinceOrState);
		const reducedVehicle = {
			name: result.name,
			licensePlateNum: result.licensePlateNum,
			province: province ? province : 'N/A',
			state: state ? state : 'N/A',
			operationId: result.operation._id,
			operationName: result.operation.businessName,
		};
		setVehicle(reducedVehicle);
	}

	useEffect(() => {
		if(!vehicle) fetchAndSetVehicle();
	}, []);

	const vehicleFields = [
		{ name: 'Vehicle Name', id: 'name' },
		{ name: 'Vehicle Identification', id: 'licensePlateNum' },
		{ name: 'Operation', id: 'operationName' },
		{ name: 'Province', id: 'province' },
		{ name: 'State', id: 'state' },
	];

	const buttons = [
		{
			name: 'Edit',
			onClick: () => setRedirect(`/vehicles/${vehicleId}/update`),
			color: agGreen,
		},
		{
			name: 'Delete',
			onClick: async () => {
				await deleteVehicleById(vehicleId);
				await setRedirect('/vehicles');
			},
			color: deleteColor,
		}
	];

	if(redirect !== '') return <Redirect push to={redirect} />;	

	return (
		<>
			<PageCard title='View Details' back>
				<DetailTable data={vehicle} fields={vehicleFields} buttons={buttons} />
			</PageCard>
		</>
	);
}
