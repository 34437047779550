import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import FormCard from '../../components/FormCard.js';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import LoadingCard from '../../components/LoadingCard.js';
import PageCard from '../../components/PageCard.js';
import { agGreen } from '../../components/Colors.js';
import { getUsername } from '../../utils/TokenUtils';
import Input from '../../components/Input.js';
import DateField from '../../components/DateField.js';
import styled from 'styled-components';
import DropDown from '../../components/DropDown.js';
import { getPremisesForUser } from '../../utils/PremiseUtils';
import { getAllSheeps } from '../../utils/SheepUtils';
import { createMedicalReport } from '../../utils/MedicalReportUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StripedTable from '../../components/StripedTable';
import SimpleCard from '../../components/SimpleCard';
import { FormInputWrapper, Button } from '../../components/CommonComponents';
import GeolocationInput from '../../components/GeolocationInput';
import propTypes from 'prop-types';

const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin></FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

const selectSheepsColumn = [
  {
    id: 'check',
    name: 'Select' 
  },
  {
    id: 'isoNumber',
    name: 'ISO Number'
  },
  {
    id: 'localMgmtNumber',
    name: 'Local Management Number'
  },
  {
    id: 'tattooNumber',
    name: 'Tattoo Number'
  },
  {
    id: 'breed',
    name: 'Breed'
  },
  {
    id: 'gender',
    name: 'Gender'
  }
];

const routes = [
  'IW - In the water',
  'IF - In the feed',
  'TT - Topical Treatment',
  'OR - Oral',
  'SQ - Subcutaneous',
  'IM - Intramuscular',
  'IV - Intravenous',
  'IMM - Intramammary'
];

const MedicalReportCreateView = () => {
  const [load, setLoad] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState(false);

  //form information that is used in the move log
  const [form, setForm] = useState({
    premiseID: '',
    animalWeight: '',
    firstTreatmentDate: new Date(),
    finalTreatmentDate: new Date(),
    treatmentReason: '',
    medicine: [],
    expiryDate: new Date(),
    dosage: [],
    isPrescription: [],
    route: [],
    meatWithdrawalDate: new Date(),
    milkWithdrawalDate: new Date(),
    location: null
  });
  const animals = useRef([]);
  const checked = useRef({});

  //state vars for retrieving and displaying dropdown options
  const [myPremises, setMyPremises] = useState([]);
  const [allAnimals, setAllAnimals] = useState([]);
  const [availableAnimals, setAvailableAnimals] = useState([]);

  //state vars for treatments
  const [currTreatment, setCurrTreatment] = useState({});
  const [treatments, setTreatments] = useState([]);

  const validateTreatmentFields = () =>{
    return (
      currTreatment.product && 
      currTreatment.product !== '' &&
      currTreatment.dosage && 
      currTreatment.dosage !== ''
    )
  }
  const convertDate = (date) =>{
    return (new Date(date)).toISOString().split('T')[0];
  }

  useEffect(() => {
    getPremisesForUser(getUsername())
      .then((userPremises) => {
        setMyPremises(userPremises);
        return getAllSheeps()
          .then((allSheeps) => {
            setAllAnimals(allSheeps.map((sheep) => ({
              ...sheep,
              isoNumber: sheep.tag.isoNumber,
              localMgmtNumber: sheep.tag.localMgmtNumber,
              tattooNumber: sheep.tag.tattooNumber,
              check: <input type='checkbox' 
                checked={checked.current[sheep._id]}
                onChange={() => {
                  if (checked.current[sheep._id]) {
                    animals.current = animals.current.filter(animalId => animalId !== sheep._id);
                    checked.current[sheep._id] = false;
                  } else {
                    animals.current = [...animals.current, sheep._id];
                    checked.current[sheep._id] = true;
                  }
                }}
                style={{cursor: 'pointer'}} 
              />
            })));
            return;
          })
          .then(() => {
            setForm({
              ...form,
              premiseID: userPremises.length > 0 ? userPremises[0].pid : '',
            });
            setCurrTreatment({
                product: '',
                dosage: '',
                expiryDate: new Date(),
                type: true,
                route: routes[0]
            })
            setLoad(false);
          });
      })
      .catch(() => {
        toast.error("An error occurred while fetching the data. Please reload this page.");
      });
  }, []);

  useEffect(() => {
    const animalPremise = myPremises.find((premise) => premise.pid === form.premiseID);
    if (animalPremise) {
      var premiseAnimals = allAnimals.filter((animal) => animal.premise === animalPremise._id);
      premiseAnimals = premiseAnimals.filter((animal) => animal.isExported === false);
      setAvailableAnimals(premiseAnimals);
    }
    animals.current = [];
    checked.current = {};
  }, [form.premiseID, allAnimals]);

  const premiseDropDown = {
    name: 'Select sheep premise',
    id: 'premiseID'
  };

  const treatmentDateFields = [
    {
      labelName: 'First Treatment Date',
      id: 'firstTreatmentDate',
      onChange: (date) => dateChange(date, 'firstTreatmentDate'),
      value: form.firstTreatmentDate
    },
    {
      labelName: 'Final Treatment Date',
      id: 'finalTreatmentDate',
      onChange: (date) => dateChange(date, 'finalTreatmentDate'),
      value: form.finalTreatmentDate
    }
  ];

  const withdrawalDateFields = [
    {
      labelName: 'Meat Withdrawal Date',
      id: 'meatWithdrawalDate',
      onChange: (date) => dateChange(date, 'meatWithdrawalDate'),
      value: form.meatWithdrawalDate
    },
    {
      labelName: 'Milk Withdrawal Date',
      id: 'milkWithdrawalDate',
      onChange: (date) => dateChange(date, 'milkWithdrawalDate'),
      value: form.milkWithdrawalDate
    }
  ];

  const dateChange = (date, fieldName) => {
    setForm({
      ...form,
      [fieldName]: date
    })
  };

  const weightAndReasonFields = [
    {
      name: 'Estimated Sheep Weight',
      hint: '70 kg',
      id: 'animalWeight'
    },
    {
      name: 'Reason for Treatment',
      hint: 'Pneumonia',
      id: 'treatmentReason'
    }
  ];

  const Info = ({name, value}) => {
    return <div>
      <span>{name}:&nbsp;</span><span>{value}</span>
    </div>;
  }

  Info.propTypes = {
    name: propTypes.string,
    value: propTypes.any
  }

  //check that required form values are present
  const createBtnEnabled = () => {
    return (
      !submitting && 
      form.animalWeight !== '' &&
      form.treatmentReason !== '' &&
      form.firstTreatmentDate &&
      form.finalTreatmentDate &&
      form.meatWithdrawalDate &&
      form.milkWithdrawalDate &&
      (validateTreatmentFields() || treatments.length > 0)
    );
  };

  const setGeolocation = () => {
		const onSuccess = (position) => {
			const location = [position.coords.longitude, position.coords.latitude];
			setForm({ ...form, location });
		};

		const onError = () => {
			toast.info("Could not get the geolocation of your device");
		}

		if (!navigator.geolocation) {
			toast.info("Geolocation is not supported by this browser");
		} else {
			navigator.geolocation.getCurrentPosition(onSuccess, onError);
		}
	};

	const clearLocation = () => {
		setForm({ ...form, location: null });
	}

  const createReport = (treatmentList) => {
    const medicineList = treatmentList.map(m => m.product);
    const dosageList = treatmentList.map(d => d.dosage);
    const expiryList = treatmentList.map(e => e.expiryDate);
    const typeList = treatmentList.map(t => t.type);
    const routeList = treatmentList.map(r => r.route);

    setSubmitting(true);
    toast.info("Submitting export report to the backend. It may take up to a minute. You will be redirected when the report has been created. Please stay on this page.");
    createMedicalReport({
      premiseID: form.premiseID,
      animals: animals.current,
      animalWeight: form.animalWeight,
      numberOfAnimals: animals.current.length,
      firstTreatmentDate: form.firstTreatmentDate,
      finalTreatmentDate: form.finalTreatmentDate,
      treatmentReason: form.treatmentReason,
      medicine: medicineList,
      expiryDate: expiryList,
      dosage: dosageList,
      isPrescription: typeList,
      route: routeList,
      meatWithdrawalDate: form.meatWithdrawalDate,
      milkWithdrawalDate: form.milkWithdrawalDate,
      location: form.location
    }).then(() =>{
      setSubmitting(false);
      toast.success("Success: Report created");
      setRedirect(true);
    }).catch ((err) => {
      setSubmitting(false);
      toast.error(err);
    });
  }

  const handleSubmit = () => {
    if (animals.current.length < 1){
      toast.error("Please select at least one sheep");
      return;
    }
    if (form.finalTreatmentDate < form.firstTreatmentDate){
      toast.error("The first treatment date must be before the final treatment date");
      return;
    }
    if (treatments.length === 0){
      const treatmentList = [currTreatment];
      createReport(treatmentList);
    }
    else if (treatments.length > 0 && !validateTreatmentFields()){
      createReport(treatments);
    }
    else if (treatments.length > 0 && validateTreatmentFields()){
      let treatmentList = treatments;
      treatmentList.push(currTreatment);
      createReport(treatmentList);
    }
  }

  if (redirect) return <Redirect to='/reports/medical/list'/>
  return (
		<ReportContainer>
        {!load ? (
				<FormCard title='Create a new medical report' back>
          <InstructionContainer>
            Enter the following medical information.
          </InstructionContainer>
	
          <DropDown
            id={premiseDropDown.id}
            name={premiseDropDown.name}
            onChange={(e) => {
              setForm({
                ...form,
                premiseID: e.target.value
              });
            }}
            value={form[premiseDropDown.id]}
            options={
              myPremises.map(o => ({
                name: o.name,
                value: o.pid,
              }))
            }
            required
          />
          
          <SimpleCard title='Select sheep'>
            <StripedTable
              columns={selectSheepsColumn}
              rows={availableAnimals}
              max={10}
              paginate
            />
          </SimpleCard>

          {weightAndReasonFields.map(i => <>
            <Input
              id={i.id}
              placeholder={i.hint}
              labelName={i.name}
              onChange={(e) => setForm({
                ...form,
                [i.id]: e.target.value
              })}
              value={form[i.id]}
              required
            />
            </>
          )}

          {treatmentDateFields.map(i => <>
            <DateField
            id={i.id}
            labelName={i.labelName}
            value={i.value}
            onChange={i.onChange}
            required
            />
            </>
          )}

          <FormInputWrapper>
            <TreatmentSectionDiv>
              <TreatmentLabel style={{ marginBottom: '20px', marginTop: '10px', fontSize: '18px'}}>
                Treatment Information
                <>&nbsp;<RequiredSpan>*</RequiredSpan></>
              </TreatmentLabel>
              {treatments.map((treatment, idx) => (
                <TreatmentDiv>
                  <TreatmentInfoDiv>
                    <TreatmentNumLabel>Treatment {idx+1}</TreatmentNumLabel>
                    <Info name="Product Name" value={treatment.product} />
                    <Info name="Suggested Dosage" value={treatment.dosage} />
                    <Info name="Expiry Date" value={convertDate(treatment.expiryDate)}/>
                    <Info name="Prescription" value={treatment.type ? 'Yes' : 'No'} />
                    <Info name="Route" value={treatment.route} />
                  </TreatmentInfoDiv>

                  <TreatmentRemoveDiv>
                    <RemoveSheepButton onClick={() => {
                      setTreatments([...treatments.slice(0, idx), ...treatments.slice(idx+1)]);
                    }}>
                      X
                    </RemoveSheepButton>
                    <br></br>
                    <br></br>
                    <br></br>
                  </TreatmentRemoveDiv>
                </TreatmentDiv>
              ))}
              <TreatmentLabel for="name">Product Name</TreatmentLabel>
              <FormTextInput id='name' 
                placeholder='XYZ Medication' 
                value={currTreatment.product} 
                onChange={(e) => setCurrTreatment({...currTreatment, product: e.target.value})} 
              />
              
              <TreatmentLabel for="dosage">Suggested Dosage</TreatmentLabel>
              <FormTextInput 
                id='dosage' 
                placeholder='3cc/45kg' 
                value={currTreatment.dosage} 
                onChange={(e) => setCurrTreatment({...currTreatment, dosage: e.target.value})} 
              />
              
              <DateField
                id='expiryDate'
                labelName='Medicine Expiry Date'
                value={currTreatment.expiryDate}
                onChange={(date) => setCurrTreatment({
                  ...currTreatment,
                  'expiryDate': date
                })}
              />

              <RadioButtonContainer>
                Medicine Type: <br></br>
                <input 
                  type='radio' 
                  name='medicineType' 
                  checked={currTreatment.type} 
                  onChange={() => setCurrTreatment({...currTreatment, type: true})} 
                  style={{cursor: 'pointer'}} />
                  Prescription <br></br>
                <input 
                  type='radio' 
                  name='medicineType' 
                  checked={!currTreatment.type} 
                  onChange={() => setCurrTreatment({...currTreatment, type: false})} 
                  style={{cursor: 'pointer'}} />
                  Non-Prescription <br></br>
              </RadioButtonContainer>

              <DropDown
                id='route'
                name='Select route'
                onChange={(e) => {
                  setCurrTreatment({
                    ...currTreatment,
                    route: e.target.value
                  });
                }}
                value={currTreatment.route}
                options={
                  routes.map(o => ({
                    name: o,
                    value: o
                  }))
                }
              />

              <AddTreatmentButton onClick={() => {
                if (validateTreatmentFields()) {
                  setTreatments([...treatments, currTreatment]);
                  
                  setCurrTreatment({
                    product: '',
                    dosage: '',
                    expiryDate: new Date(),
                    type: true,
                    route: routes[0]
                  });
                }
                else{
                  toast.error("Please fill in all treatment information fields before adding another treatment")
                }
              }}>
                Add Another Treatment
              </AddTreatmentButton>
            </TreatmentSectionDiv>
          </FormInputWrapper>

          {withdrawalDateFields.map(i => <>
            <DateField
            id={i.id}
            labelName={i.labelName}
            value={i.value}
            onChange={i.onChange}
            required
            />
            </>
          )}

          <GeolocationInput
            location={form.location}
            getLocation={setGeolocation}
            clearLocation={clearLocation}
          />

          <Button type='button' disabled={!createBtnEnabled()} onClick={handleSubmit} >
            Create
          </Button>

          {(submitting) ? <SubmittingSpinner />: <></>}
        </FormCard>

        ) : (
				<PageCard
					title='Processing your request ...'
					loader={<Loader type='Oval' color={agGreen} height={50} width={150} />}
				>
					<LoadingCard />
				</PageCard>
			)}
		</ReportContainer>
	);
}

const ReportContainer = styled.div`
  width: fit-content;
  max-width: 700px;
	color: white;
	position: relative;
	margin: 0;
	padding: 10px;
	display: flex;
	flex-direction: column;
`;
const InstructionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
`;
const RadioButtonContainer = styled.div`
  margin-bottom: 20px;
  margin-left: 25px;
`;

const SubmittingDiv = styled.div`
  text-align: center
`;

const RequiredSpan = styled.span`
  color: red;
`;
const RemoveSheepButton = styled.button`
  height: 25px;
  width: 25px;
  border: none;
  border-radius: 100px;
  font-weight: 500;
  cursor: pointer;
  background-color: rgb(226, 68, 68);
  vertical-align: middle;

  :hover {
    color: white;
  }
`;
const AddTreatmentButton = styled.button`
  font-weight: 100;
  padding: 0 10px;
  margin-left: 25px;
  margin-bottom: 10px;
  margin-right: 10px;
  text-decoration: none;
  border: 1px solid white;
  border-radius: 5px;
  height: 25px;
  font-size: 18px;
  background-color: #3b8f42;
  color: white;
  cursor: pointer;
  &:hover {
    font-weight: 300;
    border: 1px solid #3b8f42;
    background-color: white;
    color: #3b8f42;
  }
`;
const FormTextInput = styled.input`
	height: 30px;
	border-radius: 10px;
	background: ${(props) => (props.invalid ? '#DE3434' : '#C2C6CA')};
	font-family: Inter-Regular;
	font-size: 14px;
	outline: none;
	border: none;
	padding: 2px 10px;

  margin: 10px;
  margin-left: 25px;
  margin-right: 25px;

	&:disabled {
		background-color: silver;
		color: dimgray;
		cursor: not-allowed;
	}
`;
const TreatmentLabel = styled.label`
  font-size: 15px;
  margin: 0 0 0 25px;
`;
const TreatmentNumLabel = styled.label`
  font-weight: bold;
  text-decoration: underline
`;
const TreatmentInfoDiv = styled.div`
  width: 90%;
  display: inline-block;
`;
const TreatmentRemoveDiv = styled.div`
  width: 10%;
  display: inline-block;
`;
const TreatmentDiv = styled.div`
	padding: .25rem;
	background: grey;
	border-radius: 10px;
  margin-bottom: 15px;
  margin-left: 25px;
  margin-right: 25px;
`;
const TreatmentSectionDiv = styled.div`
	width: 435px;
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
`;

export default MedicalReportCreateView;