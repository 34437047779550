import React, { useState, useEffect, useRef } from 'react';
import FormCard from '../../components/FormCard.js';
import Loader from 'react-loader-spinner';
import LoadingCard from '../../components/LoadingCard.js';
import PageCard from '../../components/PageCard.js';
import { agGreen } from '../../components/Colors.js';
import DateField from '../../components/DateField.js';
import TimeField from '../../components/TimeField.js';
import styled from 'styled-components';
import {
	FormInputWrapper,
	FormInputLabel,
	FormTextInput,
  FormTextInputSelection,
	FormSelectInput,
  Button,
} from '../../components/CommonComponents';
import { getUsername } from '../../utils/TokenUtils.js';
import { getPremises, getPremisesForUser } from '../../utils/PremiseUtils';
import { getAllSheeps } from '../../utils/SheepUtils';
import { createMoveLog } from '../../utils/MoveUtils';
import { Redirect } from 'react-router';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StripedTable from '../../components/StripedTable';
import SimpleCard from '../../components/SimpleCard';
import CsvCreateSection from '../../components/CsvCreateSection';
import GeolocationInput from '../../components/GeolocationInput';


const SubmittingDiv = styled.div`
  text-align: center
`;
const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin></FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
};

const SheepTableCheckbox = ({sheepId, checked, setAnimals}) => {
  return (
    <input type='checkbox' 
      checked={checked}
      onChange={() => {
        if (checked) {
          setAnimals(currAnimals => currAnimals.filter(animal => animal !== sheepId));
        } else {
          setAnimals(currAnimals => [...currAnimals, sheepId]);
        }
      }}
    />
  );
};

const SheepAllTableCheckbox = ({sheepId, checked, setAnimals}) => {
  setAnimals(currAnimals => [...currAnimals, sheepId]);
  return (
    <input type='checkbox' 
      checked={checked}
      onChange={() => {
        if (checked) {
          setAnimals(currAnimals => currAnimals.filter(animal => animal !== sheepId));
        } else {
          setAnimals(currAnimals => [...currAnimals, sheepId]);
        }
      }}
      style={{cursor: 'pointer'}}
    />
  );
};

const selectSheepsColumn = [
  {
    id: 'check',
    name: 'Select' 
  },
  {
    id: 'isoNumber',
    name: 'ISO Number'
  },
  {
    id: 'localMgmtNumber',
    name: 'Local Management Number'
  },
  {
    id: 'tattooNumber',
    name: 'Tattoo Number'
  },
  {
    id: 'breed',
    name: 'Breed'
  },
  {
    id: 'gender',
    name: 'Gender'
  }
];

const MoveInReportCreateView = () => {
  const [load, setLoad] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState(false);
  
  const [noMoveOut, setNoMoveOut] = useState(false); 
  
  // states to store the information entered into the form
  const [form, setForm] = useState({
    departurePremiseID: '',
    destinationPremiseID: '',
    vehicleID: '',
    departureDate: new Date(),
    receivedDate: new Date(),
    unloadTime: new Date(),
    location: null
  });
  const [animals, setAnimals] = useState([]);
  // states to store the dropdown options
  const [allPremises, setAllPremises] = useState([]);
  const [myPremises, setMyPremises] = useState([]);
  const [allAnimals, setAllAnimals] = useState([]);
  const [availableAnimals, setAvailableAnimals] = useState([]);
  const [premiseOptions, setPremiseOptions] = useState([]);
  const departurePremiseInputRef = useRef(null);

  // checks if create button is enabled
  const createEnabled = () => {
    return (
      !submitting && 
      form.departurePremiseID &&
      form.destinationPremiseID &&
      animals.length > 0 &&
      form.departureDate &&
      form.receivedDate &&
      form.unloadTime
    );
  };

  const setGeolocation = () => {
		const onSuccess = (position) => {
			const location = [position.coords.longitude, position.coords.latitude];
			setForm({ ...form, location });
		};

		const onError = () => {
			toast.info("Could not get the geolocation of your device");
		}

		if (!navigator.geolocation) {
			toast.info("Geolocation is not supported by this browser");
		} else {
			navigator.geolocation.getCurrentPosition(onSuccess, onError);
		}
	};

	const clearLocation = () => {
		setForm({ ...form, location: null });
	}

  const handleSubmit = () => {
    // verify the departure date is before the received date
    if (form.departureDate > form.receivedDate) {
      toast.error("The departure date cannot be later than the received date");
      return;
    }
    if (form.departurePremiseID.trim() === form.destinationPremiseID.trim()){
      toast.error("The departure and destination premises cannot be the same");
      return;
    }

    
    setSubmitting(true);
    toast.info('Submitting move-in report to the server. This may take up to a minute.');
    createMoveLog({
      moveOut: false,
      noMoveOut: form.noMoveOut? form.noMoveOut : null,
      departurePID: form.departurePremiseID,
      destinationPID: form.destinationPremiseID,
      animals: animals,
      vehicle: form.vehicle ? form.vehicle : null,
      departureTime: form.departureDate,
      receivedTime: form.receivedDate,
      unloadTime: form.unloadTime.getHours() + ":" + form.unloadTime.getMinutes(),
      location: form.location
    }).then(() => {
      setSubmitting(false);
      toast.success("Success: Report created");
      setRedirect(true);
    }).catch((err) => {
      setSubmitting(false);
      toast.dismiss();
      if (err == "No corresponding move-out report"){
        setNoMoveOut(true);
      }
    });
  };

  const handleWarningSubmit = () => {
    form.noMoveOut = true;
    handleSubmit();
  }

  // gets all the premises and sheeps from the server for users to choose
  useEffect(() => {
    getPremises().then((premises) => {
      setAllPremises(premises);
      return getPremisesForUser(getUsername())
        .then((userPremises) => {
          setMyPremises(userPremises);
          setForm({
            ...form,
            destinationPremiseID: userPremises.length > 0 ? userPremises[0].pid : ''
          });
          return getAllSheeps();
        })
        .then((sheeps) => {
          setAllAnimals(sheeps.map((sheep) => ({
            ...sheep,
            isoNumber: sheep.tag.isoNumber,
            localMgmtNumber: sheep.tag.localMgmtNumber,
            tattooNumber: sheep.tag.tattooNumber,
          })));
          setLoad(false);
        })
        .catch(() => {
          toast.error("An error occurred while fetching the data. Please reload this page.");
        });
    });
  }, []);

  // finds the available animals every time the user changes the departure premise
  useEffect(() => {
    const departurePremise = allPremises.find((premise) => premise.pid === form.departurePremiseID);
    if (departurePremise) {
      const premiseAnimals = allAnimals.filter((animal) => (
        animal.premise === departurePremise._id &&
        !animal.isExported && 
        animal.tag.isoNumber
      ));
      setAvailableAnimals(premiseAnimals.map(sheep => ({
        ...sheep,
        check:<SheepTableCheckbox 
                sheepId={sheep._id}
                checked={animals.findIndex(animal => animal === sheep._id) !== -1} 
                setAnimals={setAnimals}
              />
      })));
    }
    setAnimals([]);
  }, [form.departurePremiseID]);

  // reload checkboxes
  useEffect(() => {
    setAvailableAnimals(currAvailableAnimals => {
      return currAvailableAnimals.map(sheep => ({
        ...sheep,
        check:<SheepTableCheckbox 
                sheepId={sheep._id}
                checked={animals.findIndex(animal => animal === sheep._id) !== -1} 
                setAnimals={setAnimals}
              />
      }));
    });
  }, [animals]);


  if (redirect) return <Redirect to="/reports/movein"/>
  return (
		<ReportContainer>
      {!noMoveOut ? (
        <FormCard title='Create a new move-in report' back>
          <CsvCreateSection 
            title="Bulk Create"
            description="Use a CSV to create move-in reports"
            link="/reports/movein/new/csv"
            buttonText="Create reports from a CSV"
          />

          <SimpleCard
            title="Manual Create"
            description="Enter the following information about the move."
          />

          <FormInputWrapper>
            <FormInputLabel>
              Departure Premise ID
              <>&nbsp;<RequiredSpan>*</RequiredSpan></>
            </FormInputLabel>
            
            <FormTextInput
              ref={departurePremiseInputRef}
              onChange={(e) => {
                if (e.target.value === '') {
                  setPremiseOptions([]);
                } else {
                  const options = allPremises.filter(premise => (
                    premise.pid.includes(e.target.value) || premise.name.includes(e.target.value)
                  ));
                  setPremiseOptions(options);

                  if (options.length === 1 && 
                      options[0].pid === departurePremiseInputRef.current.value) {
                    setForm({ ...form, departurePremiseID: options[0].pid });
                  }
                }
              }}
            />
            
            <div style={{ maxHeight: '150px', overflowY: 'scroll', overflowX: 'hidden' }}>
              {premiseOptions.map((premise, idx) => (
                <FormTextInputSelection 
                  key={idx}
                  onClick={() => {
                    setPremiseOptions([]);
                    setForm({ ...form, departurePremiseID: premise.pid });
                    departurePremiseInputRef.current.value = premise.pid;
                  }}
                >
                  <h4 style={{ margin: 0 }}>{premise.pid}</h4>
                  <div>{premise.name}</div>
                </FormTextInputSelection>
              ))}
            </div>
          </FormInputWrapper>

          

          <FormInputWrapper>
            <FormInputLabel>
              Destination Premise Name
              <>&nbsp;<RequiredSpan>*</RequiredSpan></>
            </FormInputLabel>
            <FormSelectInput
							value={form['destinationPremiseID']}
							onChange={(e) => setForm({
                ...form,
                destinationPremiseID: e.target.value
              })}
						>
              {myPremises.map((premise, idx) => (
                <option key={idx} value={premise.pid}>
                  {premise.name}
                </option>
              ))}
						</FormSelectInput>
          </FormInputWrapper>

          <SimpleCard 
            title='Select sheep to move'
            description='Only sheep with ISO numbers can be moved. If you do not see your sheep, make sure it has an ISO number.'
          >
            <StripedTable
              columns={selectSheepsColumn}
              rows={availableAnimals}
              max={10}
              paginate
            />
          </SimpleCard>

          <CenteredButtonDiv>
            <Button onClick={(e) => {
              e.preventDefault();
              setAvailableAnimals(animals => {
                return animals.map(sheep => ({
                  ...sheep,
                  check:<SheepTableCheckbox 
                          sheepId={sheep._id}
                          checked={true} 
                          setAnimals={setAnimals}
                        />
                }));
              });
              }}>Select All
            </Button>
              {(animals.length > 0) ? 
              <Button onClick={(e) => {
              e.preventDefault();
              setAnimals([]);
              }}>Clear All
            </Button>
            : <></>}
          </CenteredButtonDiv>

          <FormInputWrapper>
            <FormInputLabel>
              Vehicle License Plate Number
            </FormInputLabel>
            <FormTextInput 
              value={form["vehicleID"]}
              onChange={(e) => {
                setForm({
                  ...form,
                  vehicleID: e.target.value
                })
              }}/>
          </FormInputWrapper>

          <DateField
            id="departureDate"
            labelName="Departure Date"
            value={form.departureDate}
            onChange={(date) => setForm({
              ...form,
              'departureDate': date
            })}
            required
          />

          <DateField 
            id="receivedDate"
            labelName="Received Date"
            value={form.receivedDate}
            onChange={(date) => setForm({
              ...form,
              'receivedDate': date
            })}
            required
          />

          <TimeField
            id="unloadTime"
            labelName="Unload time"
            value={form.unloadTime}
            onChange={(time) => setForm({
              ...form,
              'unloadTime': time
            })}
            required
          />

          <GeolocationInput 
            location={form.location}
            getLocation={setGeolocation}
            clearLocation={clearLocation}
          />

          <Button type="button" disabled={!createEnabled()} onClick={handleSubmit}>
            Create
          </Button>
          {(submitting) ? <SubmittingSpinner />: <></>}
        </FormCard>
      ) : (
        <PageCard>
          <Text>
          <span style={{ color: 'red', fontSize: 'inherit', lineHeight: '150%' }}>Warning - </span>
          You are attempting to create a move-in report with information that differs from the corresponding move-out report. 
          Are you sure the information you provided is correct? Circumstances can change in transit and it is possible that 
          revised information has to be reported at move-in. Please double check the information you have entered and hit 'submit' 
          if the new information is correct.
          </Text>
          <Button 
          onClick={handleWarningSubmit} disabled={submitting}>
            Submit
          </Button>
          {(submitting) ? <SubmittingSpinner />: <></>}
        </PageCard>

			)}
		</ReportContainer>
	);
}

const ReportContainer = styled.div`
  width: fit-content;
	max-width: 700px;
	color: white;
	position: relative;
	margin: 0;
	padding: 10px;
	display: flex;
	flex-direction: column;
`;

const InstructionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
`;

const CenteredButtonDiv = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

const RequiredSpan = styled.span`
  color: red;
`
const Text = styled.div`
  margin-left: 10px;
  margin-bottom: 10px;
  display: inline-block;
`
export default MoveInReportCreateView;