import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import SimpleCard from './SimpleCard';
import { Redirect } from 'react-router';
import { library } from '@fortawesome/fontawesome-svg-core';
import propTypes from 'prop-types';
import axios from 'axios';
import { getAllStateAbbrv } from '../utils/USAStates';
import { getBreeds } from '../utils/BreedUtils';
import {
	FormInputWrapper,
	FormInputLabel,
	FormTextInput,
	FormContainer,
	FormSelectInput,
  Button,
} from '../components/CommonComponents';
import { faSpinner} from '@fortawesome/free-solid-svg-icons';
library.add(faSpinner);

const FormContainerDescription = styled.div`
  margin-bottom: 20px;

  ul {
    margin: 0 0 10px 0;
  }

  li.created {
    text-decoration: line-through;
  }

  li.creating {
    color: yellow;
    text-decoration: underline;
  }

  li.creating ~ li.creating {
    color: white;
    text-decoration: none;
  }
`;

const FormHint = styled.label`
  margin-top: 5px;
  color: orange;
  font-size: 12px;
`;

const SubmittingDiv = styled.div`
  text-align: center
`;
const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin></FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

const validateForm = (form) => {
  let hasId = false;
  if(!form.tag) return false;
  if(
    (form.tag.isoNumber && form.tag.isoNumber !== '') ||
    (form.tag.localMgmtNumber && form.tag.localMgmtNumber !== '') ||
    (form.tag.tattooNumber && form.tag.tattooNumber !== '')
  ) hasId = true;

  if(!form.premise) return false;
  
  return hasId;
}

// ensures that the date of birth is not in the future
const validateDateOfBirth = (dob) => {
  const now = new Date();
  const birthDate = new Date(dob);
  return now >= birthDate;
};

// ensures the US Scrapie ID is valid
const validateScraipeID = (scrapieId) => {
  const usStates = getAllStateAbbrv();
  return /^[A-Z]{2}\d{7}$/.test(scrapieId) && usStates.includes(scrapieId.substring(0, 2));
}

const sheepFields = [
  { name: 'Premise', type: 'text', id: 'premise', disabled: true },
  { name: 'Gender', type: 'dropdown', id: 'gender', disabled: false },
  { name: 'Sub Gender', type: 'dropdown', id: 'subgender', disabled: false },
  { name: 'Breed', type: 'dropdown', id: 'breed', disabled: false },
  { name: 'Date of Birth', type: 'date', id: 'birthdate', disabled: false },
];

const tagFields = [
  {
    name: 'ISO Number',
    id: 'isoNumber',
    disabled: true
  },
  {
    name: 'Local Management Number',
    id: 'localMgmtNumber',
    disabled: false
  },
  {
    name: 'Tattoo Number',
    id: 'tattooNumber',
    disabled: false
  },
  {
    name: 'US Scrapie ID',
    id: 'usScrapieId',
    hints: [
      "US Scrapie ID must start with a US state abbreviation, followed by 7 digits.",
      "eg. NY1083612"
    ],
    disabled: false
  }
];

export default function ImportSheepForm({ premise, sheepsToCreate }) {
  const [form, setForm] = useState({
    premise: premise.name,
    tag: {
      isoNumber: sheepsToCreate[0]
    },
    gender: 'male',
    subgender: 'ram',
    breed: 'Other'
  });
  const [submitting, setSubmitting] = useState(false);
  const [sheepsCreatedList, setSheepsCreatedList] = useState([]);
  const [sheepsCreateList, setSheepsCreateList] = useState(sheepsToCreate);
  const [breeds, setBreeds] = useState([]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      // validates the date of birth
      if (form.birthdate && !validateDateOfBirth(form.birthdate)) {
        toast.error("Please select a valid date: Date of birth cannot be in the future");
        return;
      }
      // validates the US Scrapie ID
      if (form.tag.usScrapieId && !validateScraipeID(form.tag.usScrapieId)) {
        toast.error("Please enter a valid US Scraipe ID");
        return;
      }
      
      setSubmitting(true);
      toast.info("Submitting sheep to server. It may take up to a minute.")
      await axios.post('/api/sheep', {
        ...form,
        premise: premise._id
      });
      
      // finished submitting, setup next sheep to create
      setSubmitting(false);
      setForm({
        premise: premise.name,
        tag: {
          isoNumber: sheepsCreateList[1],
        },
        gender: 'male',
        subgender: 'ram',
        breed: 'Other',
      });
      setSheepsCreatedList([...sheepsCreatedList, sheepsCreateList[0]]);
      setSheepsCreateList([...sheepsCreateList.slice(1)]);
      window.scrollTo(0, 0);
    } catch (err){
      setSubmitting(false);
      if (err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(`An error occured while submitting the sheep`);
      }
    }
  }

  useEffect(() => {
    getBreeds().then((breeds) => {
      setBreeds(breeds);
    }).catch(() => {
      toast.error("An error occurred with getting the breeds. Please refresh the page.");
    });
  }, []);

  useEffect(() => {
    if (form.gender === 'male') {
      form.subgender = 'ram';
    } else if (form.gender === 'female') {
      form.subgender = 'ewe';
    }
  }, [form.gender]);
  
	let dropdowns = {
    breeds: breeds,
    genders: [{ name: 'Male', value: 'male' }, { name: 'Female', value: 'female' }],
  };

	if (form.gender === 'male') {
		dropdowns.subgenders = [{ name: 'Ram', value: 'ram' }, { name: 'Wether', value: 'wether' }];
	} else if (form.gender === 'female') {
		dropdowns.subgenders = [{ name: 'Ewe', value: 'ewe'}, { name: 'Ewe Lamb', value: 'ewelamb' }];
	} else {
    dropdowns.subgenders = [];
  }
	
  if (sheepsCreateList.length === 0) return <Redirect to="/sheep" />;
	return (
		<FormContainer>
      <FormContainerDescription>
        <div>The following imported sheep are not in our system yet:</div>
        <ul>
          {sheepsCreatedList.map((sheep, idx) => <li key={idx} className="created">{sheep}</li>)}
          {sheepsCreateList.map((sheep, idx) => <li key={idx} className="creating">{sheep}</li>)}
        </ul>
        <div>Please enter the following information for each sheep.</div>
      </FormContainerDescription>
      
      <SimpleCard title="Tag Information">
        {tagFields.map((f) => <>
          <FormInputWrapper>
            <FormInputLabel htmlFor='input'>
              {f.name}
            </FormInputLabel>
            <FormTextInput
              placeholder={f.placeholder}
              type='text'
              id='input'
              value={form.tag[f.id]}
              onChange={(e) => {
                setForm({...form, tag: {
                    ...form.tag,
                    [f.id]: e.target.value
                  }
                })
              }}
              disabled={f.disabled}
            />
            {f.hints && f.hints.map((hint, idx) => (
              <FormHint key={idx} htmlFor='input'>
                {hint}
              </FormHint>
            ))}
          </FormInputWrapper>
        </>)}
      </SimpleCard>
      
      <SimpleCard title="Sheep Information">
			{sheepFields.map((element) => (
				<FormInputWrapper key={element._id}>
					<FormInputLabel htmlFor='input'>
            {element.name}
          </FormInputLabel>
					{element.type === 'dropdown' ? (
						<FormSelectInput
							value={form[element.id]}
							onChange={(e) =>
								setForm({
									...form,
									[element.id]: e.target.value,
								})
							}
						> 
							{dropdowns[`${element.id}s`] &&
								dropdowns[`${element.id}s`].map((option) => (
									<option key={option._id} value={element.altvalue ? option._id : (option.value) ? option.value : option.name}>
										{option.name}
									</option>
								))}
						</FormSelectInput>
					) : (
						<FormTextInput
              disabled={element.disabled}
              placeholder={element.hint}
							type={element.type}
							id='input'
							name={element.id}
							value={form[element.id] || ''}
							onChange={(e) => {
								if (e.target.value === '') {
									// eslint-disable-next-line no-unused-vars
									const { [element.id]: _, ...updatedState } = form;
									setForm(updatedState);
								} else setForm({ ...form, [e.target.name]: e.target.value });
							}}
						/>
					)}
				</FormInputWrapper>
			))}
      </SimpleCard>
      
      <Button type='submit' onClick={handleSubmit} disabled={!validateForm(form) || submitting}>
        Submit
      </Button>
      {(submitting) ? <SubmittingSpinner />: <></>}
		</FormContainer>
	);
}

ImportSheepForm.propTypes = {
  premise: propTypes.string,
  sheepsToCreate: propTypes.array
};
