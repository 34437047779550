// ViewEditDelete.js
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';


const ViewEditDispose = ({ 
  
  id, 
  endpoint, 
  disposeName,
  editName,
  hideDispose,
  hideReplace,
  hideEdit,
}) => {
  if(!disposeName) disposeName = 'Dispose';
  if(!editName) editName = 'Edit'
  const viewLink = `${endpoint}${id}`;
  const editLink = `${endpoint}edit?id=${id}`;
  const disposeLink = `${endpoint}dispose?id=${id}`;
  const replaceLink = `${endpoint}replace?id=${id}`;
  return <ViewEditDisposeDiv>
    <Link to={viewLink}><FlatButton color="white">View</FlatButton></Link> 
    {(hideEdit) ? <></> :
      <>&nbsp;|&nbsp;
        <Link to={editLink} ><FlatButton color="#fefdb1">{editName}</FlatButton></Link> 
      </>
    }
    {(hideDispose) ? 
      <></> : 
      <>&nbsp;|&nbsp;
        <Link to={disposeLink}>
          <FlatButton color="#f23b3b">{disposeName}</FlatButton>
        </Link>
      </>
    }
    {(hideReplace)?
      <></> :
      <>&nbsp;|&nbsp;
        <Link to={replaceLink}>
          <FlatButton color="#FFA500">Replace</FlatButton>
        </Link>
      </>
    }
  </ViewEditDisposeDiv>
}

ViewEditDispose.propTypes = {
  id: propTypes.any,
  endpoint: propTypes.any,
  disposeName: propTypes.any,
  disposeLink: propTypes.any,
  hideDispose: propTypes.any,
  hideReplace: propTypes.any,
  hideEdit: propTypes.any
};

const ViewEditDisposeDiv = styled.div`
  color: white;
`;

const FlatButton = styled.button`
  color: white;
  background: none;
  border-radius: 10px;
  border-color: ${props => props.color};
  border-style: solid;
  outline: none;
  cursor: pointer;
`;

export default ViewEditDispose;