import styled from 'styled-components';

export const PageHeader = styled.span`
	font-size: 50px;
	font-weight: bold;
`;

export const PageCardContainer = styled.div`
	padding: 10px;
	${props => (props.form === 'true') ? '' : 'min-height: 500px;'}
	color: white;
	max-width: 1200px;
	${props => (props.maxContent) ? 'max-width: max-content;' : ''}
  ${props => (props.form === 'true') ? 'max-width: 600px;' : ''}
	background: rgba(0, 0, 0, 0.6);
	border-radius: 10px;
	${props => (props.relative) ? 'position: relative;' : ''}
`;

export const PageContainer = styled.div`
	margin: 10px auto;
	justify-content: center;
	width: 100%;
	min-height: 500px;

	display: flex;
	flex: 1;

	> * {
		flex-grow: 1;
	}
`;
