import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import PageCard from './PageCard';
import SimpleCard from './SimpleCard';
import { getUsername } from '../utils/TokenUtils';
import { getPremises, getPremisesForUser, getPremisesById } from '../utils/PremiseUtils';
import { getAllSheeps } from '../utils/SheepUtils';
import axios from 'axios';
import {
	FormInputWrapper,
	FormInputLabel,
	FormTextInput,
  FormTextInputSelection,
	FormSelectInput,
  Button,
} from '../components/CommonComponents';
import DateField from './DateField.js';
import TimeField from './TimeField.js';
import styled from 'styled-components';
import StripedTable from './StripedTable';
import { extractDataFromHeaders } from '../utils/CSVUtils';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import propTypes from 'prop-types';

const SubmittingDiv = styled.div`
  text-align: center
`;
const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin>

    </FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

const CheckBox = ({onChange, checked}) => {
  return <input 
    style={{cursor: 'pointer'}} 
    type='checkbox' 
    checked={checked} 
    onChange={onChange} />;
}

CheckBox.propTypes = {
  onChange: propTypes.func,
  checked: propTypes.bool
}

const bulkMoveIn = async (submissionObject) => {
  try {
    toast.info("Submitting move-in report to server. It may take up to several minutes.");
    await axios.post(`/api/move/bulk`, submissionObject);
    return;
  } catch(err) {
    throw err.response.data;
  }
}

const CenteredButtonDiv = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

const RequiredSpan = styled.span`
  color: red;
`;

const CsvMoveInSheepSelect = ({data, headers}) => {
  const [sheeps, setSheeps] = useState([]);
  const [selectedSheeps, setSelectedSheeps] = useState([]);
  const [allPremises, setAllPremises] = useState([]);
  const [myPremises, setMyPremises] = useState([]);
  const [form, setForm] = useState({
    departurePremiseID: '',
    destinationPremiseID: '',
    vehicleID: '',
    departureDate: new Date(),
    receivedDate: new Date(),
    unloadTime: new Date()
  });
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState('');
  const [premiseOptions, setPremiseOptions] = useState([]);
  const departurePremiseInputRef = useRef(null);

  const submitEnabled = () => {
    return (
      form.departurePremiseID &&
      form.destinationPremiseID &&
      form.departureDate &&
      form.receivedDate &&
      form.unloadTime &&
      selectedSheeps.length > 0
    );
  };

  useEffect(() => {
    setSheeps(extractDataFromHeaders(data, headers));
    getPremises().then((premises) => {
      setAllPremises(premises);
      return getPremisesForUser(getUsername())
        .then((userPremises) => {
          setMyPremises(userPremises);

          setForm({
            ...form,
            destinationPremiseID: userPremises.length > 0 ? userPremises[0].pid : ''
          });
        })
        .catch(() => {
          toast.error(`An error occurred while fetching data: ${err}`);
        });
    });
  }, []);

  const baseColumns = [
    {
      id: 'isonum',
      name: 'ISO Number'
    }
  ];

  const columns = [
    {
      id: 'check',
      name: '' 
    },
    ...baseColumns
  ];

  const handleSubmit = async(e) => {
    try {
      e.preventDefault();
      setSubmitting(true);
      
      // remove spaces and commas from the ISO numbers
      const formattedIsoNumbers = selectedSheeps.map(s => {
        return s.isonum.replace(/[\s|,]/g, '');
      });

      // create the bulk move in
      await bulkMoveIn({
        moveOut: false,
        departurePID: form.departurePremiseID,
        destinationPID: form.destinationPremiseID ? form.destinationPremiseID.trim() : null,
        animals: formattedIsoNumbers,
        vehicleNumber: form.vehicleID !== '' ? form.vehicleID : null,
        departureTime: form.departureDate,
        receivedTime: form.receivedDate,
        unloadTime: form.unloadTime.getHours() + ":" + form.unloadTime.getMinutes()
      });
      setRedirect('/sheep');
    } catch (err) {
      setSubmitting(false);
      toast.dismiss();
      if (!err.message){
        toast.error('Something went wrong with creating the bulk move-in');
      } else if (err.message.includes("ISO number") && err.message.includes("already exists")) {
        const dupIsoNumber = err.message.match(/[0-9]{15}/);
        toast.error(`An error occurred because the following ISO number is taken: ${dupIsoNumber}`);
      } else if (err.message.includes("ISO number") && err.message.includes("is duplicated")) {
        const dupIsoNumber = err.message.match(/[0-9]{15}/);
        toast.error(`An error occurred because the following ISO number is duplicated: ${dupIsoNumber}`);
      } else {
        toast.error(err.message);
      }
    }
  }

  const SelectAllSection = () => <CenteredButtonDiv>
    <Button onClick={(e) => {
      e.preventDefault();
      setSelectedSheeps(sheeps);
    }}>Select All</Button>
    {(selectedSheeps.length > 0) ? 
      <Button onClick={(e) => {
        e.preventDefault();
        setSelectedSheeps([]);
      }}>Clear All</Button>
    : <></>}
  </CenteredButtonDiv>;
  const sheepRowsWithCheck = sheeps.map(s => ({
    ...s,
    check: <CheckBox 
      checked={selectedSheeps.filter(selectedSheep => selectedSheep.dummyID === s.dummyID).length > 0}
      onChange={() => {
        const alreadySelected = selectedSheeps.filter(selectedSheep => selectedSheep.dummyID === s.dummyID).length > 0;
        if(alreadySelected){
          const newSelectedSheeps = selectedSheeps.filter(sheep => sheep.dummyID !== s.dummyID);
          setSelectedSheeps(newSelectedSheeps)
        } else {
          const newSelectedSheeps = [
            sheeps.filter(sheep => sheep.dummyID === s.dummyID)[0],
            ...selectedSheeps
          ];
          setSelectedSheeps(newSelectedSheeps);
        }
      }}
    />
  }));

  const selectedSheepColumns = [
    ...baseColumns,
    {
      id: 'remove',
      name: ''
    }
  ];

  const selectedSheepRowsWithRemove = selectedSheeps.map(s => ({
    ...s,
    remove: <button
      onClick={(e) => {
        e.preventDefault();
        const newSelectedSheep = selectedSheeps.filter(sheep => sheep.dummyID !== s.dummyID);
        setSelectedSheeps(newSelectedSheep);
      }}
      style={{cursor: 'pointer'}}
    >Remove</button>
  }))

  if(redirect !== '') return <Redirect to={redirect} />;
  return <div style={{ maxWidth: '700px' }}>
    <PageCard line back title='Bulk Move-In'>
      <FormInputWrapper>
        <FormInputLabel>
          Departure Premise ID
          <>&nbsp;<RequiredSpan>*</RequiredSpan></>
        </FormInputLabel>
        
        <FormTextInput
          ref={departurePremiseInputRef}
          onChange={(e) => {
            if (e.target.value === '') {
              setPremiseOptions([]);
            } else {
              const options = allPremises.filter(premise => (
                premise.pid.includes(e.target.value) || premise.name.includes(e.target.value)
              ));
              setPremiseOptions(options);

              if (options.length === 1 && 
                  options[0].pid === departurePremiseInputRef.current.value) {
                setForm({ ...form, departurePremiseID: options[0].pid });
              }
            }
          }}
        />
        <div style={{ maxHeight: '150px', overflowY: 'scroll', overflowX: 'hidden' }}>
          {premiseOptions.map((premise, idx) => (
            <FormTextInputSelection 
              key={idx}
              onClick={() => {
                setPremiseOptions([]);
                setForm({ ...form, departurePremiseID: premise.pid });
                departurePremiseInputRef.current.value = premise.pid;
              }}
            >
              <h4 style={{ margin: 0 }}>{premise.pid}</h4>
              <div>{premise.name}</div>
            </FormTextInputSelection>
          ))}
        </div>
      </FormInputWrapper>

      <FormInputWrapper>
        <FormInputLabel>
          Destination Premise Name
          <>&nbsp;<RequiredSpan>*</RequiredSpan></>
        </FormInputLabel>
        <FormSelectInput
          value={form['destinationPremiseID']}
          onChange={(e) => setForm({
            ...form,
            destinationPremiseID: e.target.value
          })}
        >
          {myPremises.map((premise, idx) => (
            <option key={idx} value={premise.pid}>
              {premise.name}
            </option>
          ))}
        </FormSelectInput>
      </FormInputWrapper>

      <FormInputWrapper>
        <FormInputLabel>
          Vehicle License Plate Number
        </FormInputLabel>
        <FormTextInput 
          value={form["vehicleID"]}
          onChange={(e) => {
            setForm({
              ...form,
              vehicleID: e.target.value
            })
          }}/>
      </FormInputWrapper>

      <DateField
        id="departureDate"
        labelName="Departure Date"
        value={form.departureDate}
        onChange={(date) => setForm({
          ...form,
          'departureDate': date
        })}
        required
      />

      <DateField 
        id="receivedDate"
        labelName="Received Date"
        value={form.receivedDate}
        onChange={(date) => setForm({
          ...form,
          'receivedDate': date
        })}
        required
      />

      <TimeField
        id="unloadTime"
        labelName="Unload time"
        value={form.unloadTime}
        onChange={(time) => setForm({
          ...form,
          'unloadTime': time
        })}
        required
      />

      <SimpleCard title='Select Sheep'>
        <StripedTable
          columns={columns}
          rows={sheepRowsWithCheck}
          ShowCount
          max={10}
          paginate
        />
        <SelectAllSection />
      </SimpleCard>
      {(selectedSheeps.length > 0) && 
        <SimpleCard title='Sheep selected to create'>
          <StripedTable
            columns={selectedSheepColumns}
            rows={selectedSheepRowsWithRemove}
            max={10}
            paginate
            ShowCount
          />
          {submitEnabled() && <CenteredButtonDiv>

            <Button onClick={handleSubmit} disabled={submitting}>
              Submit {`${selectedSheeps.length}`} Sheep
            </Button>
            {submitting && <SubmittingSpinner />}
            
            </CenteredButtonDiv>
          }
        </SimpleCard>
      }
    </PageCard>
  </div>
}

CsvMoveInSheepSelect.propTypes = {
  data: propTypes.arrayOf(propTypes.string),
  headers: propTypes.arrayOf(propTypes.string)
}

export default CsvMoveInSheepSelect;