import React, { useState } from 'react';
import { FilterSection } from './SheepReportComponents';
import MoveOutSearchTable from './MoveOutSearchTable';
import SimpleCard from './SimpleCard';
import propTypes from 'prop-types';

//create a report list with creation date filters
const ReportListDateFilter = ({reports, columns}) => {
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: ''
  });
  const [tempFilter, setTempFilter] = useState({
    startDate: '',
    endDate: ''
  })
  const theseReports = reports;

  let filterFields = [
    {
      id: 'startDate',
      name: 'Start Date',
      type: 'date',
      onChange: (e) => {
        updateTempFilter(e.target.value, 'startDate');
      },
      onClick: (e) => {
        e.preventDefault();
        updateTempFilter('', 'startDate');
      },
    },
    {
      id: 'endDate',
      name: 'End Date',
      type: 'date',
      onChange: (e) => {
        updateTempFilter(e.target.value, 'endDate');
      },
      onClick: () => {
        updateTempFilter('', 'endDate');
      }
    }
  ];

  const updateTempFilter = (updatedValue, filterID) => {
    const newFilter = {...tempFilter};
    newFilter[filterID] = updatedValue;
    if(filterID === 'startDate'){
      if(tempFilter.endDate === ''){
        newFilter.endDate = updatedValue;
      } else {
        const startDate = new Date(updatedValue);
        const endDate = new Date(tempFilter.endDate);
        if(startDate > endDate) newFilter.endDate = updatedValue;
      }

      if(updatedValue === '') newFilter.endDate = '';
    }

    if(filterID === 'endDate'){
      if(tempFilter.startDate === ''){ 
        newFilter.startDate = updatedValue;
      } else {
        const startDate = new Date(tempFilter.startDate);
        const endDate = new Date(updatedValue);
        if(endDate < startDate) newFilter.startDate = updatedValue;
      }

      if(updatedValue === '') newFilter.startDate = '';
    }
    setTempFilter(newFilter);
  };

  const handleApply = (e) => {
    e.preventDefault();
    const newFilter = {...tempFilter};
    setFilter(newFilter);
  };

  const handleClear = (e) => {
    e.preventDefault();
    const newFilter = {
      startDate: '',
      endDate: ''
    };

    setTempFilter(newFilter);
    setFilter(newFilter);
  };

  const filteredReports = theseReports.filter(r => {
    let filterResult = true;
    const filterKeys = Object.keys(filter);
    filterKeys.forEach(key => {
      if(filter[key] === '') return;
      if(key.includes('Date')) return;
      if(r[key] !== filter[key]) filterResult = false;
    })
    const reportDate = new Date(r['createdAt']);
    const startDate = new Date(filter.startDate);
    const endDate = new Date(filter.endDate);
    endDate.setDate(endDate.getDate() + 1);
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    if(reportDate < startDate) return false;
    if(reportDate > endDate) return false;
    return filterResult;
  });

  return (<>
    <SimpleCard
      title="Filters"
      size='md'
      description="Use these filters to narrow down reports based on their creation date"
    ></SimpleCard>

    <FilterSection 
      data={filterFields} 
      onApply={handleApply}
      onClear={handleClear}
      values={tempFilter}
    />
    <MoveOutSearchTable  
          placeholder="Enter the identifier of the sheep you wish to search for"
          filterField= "animalTags"
          columns={columns}
          rows={filteredReports}
          ShowFilters
          ShowCount
          max={10}
          paginate
        />
  </>)
}

ReportListDateFilter.propTypes = {
  reports: propTypes.any,
  columns: propTypes.any
};

export default ReportListDateFilter;