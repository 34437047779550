const axios = require('axios');

export const getAllSheeps = (inactive=false) => {
  if (inactive) {
    return axios.get('/api/sheep/?inactive=true')
      .then((res1) => {
        const inactiveSheep = res1.data;
        return axios.get('/api/sheep')
          .then((res2) => {
            const activeSheep = res2.data;
            return [...activeSheep, ...inactiveSheep];
          });
      })
      .catch((err) => {
        throw new Error(`An error occurred fetching sheep: ${err.message}`);
      });
  }
  else {
    return axios.get('/api/sheep')
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(`An error occurred fetching sheep: ${err.message}`);
      });
  }
};

export const getSheep = async(id) => {
  axios.get(`/api/sheep/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(`An error occurred fetching the sheep: ${err.message}`);
    });
}