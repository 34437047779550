// RawSheepReportsListView.js
import React from 'react';
import { 
  FetchAllSheepReports, 
} from '../../components/SheepReportComponents';
import RawSheepReportListComponent from '../../components/RawSheepReportListComponent';

const RawSheepReportsListView = () => {
  return <>
    <RawSheepReportListComponent
      title="Raw Sheep Reports"
      description="Find all the reports for your sheep below"
      fetchReportsFunction={FetchAllSheepReports}
      detailEndpoint={'/reports/raw/'}
    />
  </>
}


export default RawSheepReportsListView;