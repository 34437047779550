import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
	FormContainer,
	Button,
	FormInputWrapper,
	FormInputLabel,
	FormTextInput,
	FormSelectInput,
} from '../../components/CommonComponents.js';
import FormCard from '../../components/FormCard.js';
import { getUsername } from '../../utils/TokenUtils.js';
import phone from 'phone';
import { toast } from 'react-toastify';
import axios from 'axios';

const baseOperationForm = {
	businessName: '',
	phoneNumber: '',
	email: '',
	operationType: '',
	cvsp: false,
	sfcp: false,
};

const basePremiseForm = {
	pid: '',
	name: '',
	address: '',
	municipality: '',
	province: '',
	postalCode: '',
	description: '',
};

const OperationCreate = () => {
	// eslint-disable-next-line no-unused-vars
	const [operationComplete, setOperationComplete] = useState(false);
	const [operationForm, setOperationForm] = useState(baseOperationForm);
	const [premiseForm, setPremiseForm] = useState(basePremiseForm);
	const [operationErrors, setOperationErrors] = useState([]);
	const [redirect, setRedirect] = useState(false);

	const isOperationFormComplete = (op) => {
		if(op.businessName !== '' && op.operationType !== '') {
			if (op.phoneNumber === '' || phone(op.phoneNumber.isValid)) {
				return true;
			} 
			else {
				if(op.email !== '') return true;
				else return false;
			}
		}
		return false;
	}

	const isPremiseFormComplete = (prem) => {
		if(
			prem.name !== ''
			&& prem.address !== ''
			&& prem.municipality !== ''
			&& prem.province !== ''
			&& prem.postalCode !== ''
		) return true;

		return false;
	}

	const handleSubmit = async (e) => {
		e.preventDefault();

		//ensure there is a phone number or email for contact information
		if( 
			operationForm.phoneNumber === '' &&
			operationForm.email === ''
		){
			toast.error("You must enter either a phone number or an email");
			return;
		}

		//validate phone number if one is given
		if (
			operationForm.phoneNumber !== '' &&
			!phone(operationForm.phoneNumber).isValid
		){
			toast.error("Invalid phone number");
			return;
		}

		//validate email if one is given
		const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
		if (
			operationForm.email !== '' &&
			!emailRegex.test(operationForm.email)
		){
			toast.error("Invalid email");
			return;
		}

		const body = {
			operation: operationForm,
			premise: premiseForm
		};

		// if no pid is provided, generate a random pid
		if (body.premise.pid === '') {
			body.premise.pid = body.premise.province + Math.random().toString().substring(2);
		}

		body.operation.user = getUsername();
		try {
			await axios.post('/api/operations', body);
			setRedirect(true);
			toast.success('Operation successfully created!');
		} catch (err) {
			toast.error(`${err.response.data.message}`);
		}
	}

	if(redirect){
		return <Redirect to='/operations'/>;
	}

	return <>
		<FormCard 
			title='Create a new Operation'
			errors={operationErrors}
			back
		>
			<FormContainer>
				<h3>Operation Information</h3>
					<p>
						In order to create a new operation, please enter the following information.
					</p>
				<FormInputWrapper key='businessName'>
					<FormInputLabel htmlFor='input'>
						Business Name <span style={{color:'red'}}>*</span>
					</FormInputLabel>
					<FormTextInput
						placeholder='Your business name'
						type='text'
						id='input'
						name='businessName'
						value={operationForm['businessName']}
						onChange={(e) => {
							const newOperationForm = {
								...operationForm,
								businessName: e.target.value
							}
							setOperationForm(newOperationForm);
							setOperationComplete(
								isOperationFormComplete(newOperationForm)
							)
						}}
					/>
				</FormInputWrapper>
				<FormInputWrapper key='phoneNumber'>
					<FormInputLabel htmlFor='input'>
						Phone Number
					</FormInputLabel>
					<FormTextInput
						placeholder='3072776286'
						type='tel'
						id='input'
						name='phoneNumber'
						value={operationForm['phoneNumber']}
						onChange={(e) => {
							const newOperationForm = {
								...operationForm,
								phoneNumber: e.target.value
							};
							setOperationForm(newOperationForm);
							setOperationComplete(
								isOperationFormComplete(newOperationForm)
							);
						}}
					/>
				</FormInputWrapper>
				<FormInputWrapper key='email'>
					<FormInputLabel htmlFor='input'>
						Email
					</FormInputLabel>
					<FormTextInput
						placeholder='agroledger@sheep.com'
						type='text'
						id='input'
						name='email'
						value={operationForm['email']}
						onChange={(e) => {
							const newOperationForm = {
								...operationForm,
								email: e.target.value
							};
							setOperationForm(newOperationForm);
							setOperationComplete(
								isOperationFormComplete(newOperationForm)
							);
						}}
					/>
				</FormInputWrapper>
				<FormInputWrapper>
					<FormInputLabel htmlFor='input'>
						Operation Type <span style={{color:'red'}}>*</span>
					</FormInputLabel>
					<FormSelectInput
						value={operationForm['operationType']}
						onChange={(e) => {
							const newOperationForm = {
								...operationForm,
								operationType: e.target.value
							};
							setOperationForm(newOperationForm);
							setOperationComplete(
								isOperationFormComplete(newOperationForm)
							);
						}}
					>
						<option hidden>Select one...</option>
						<option
							key='abattoir'
							value='abattoir'
						>
							Abattoir
						</option>

						<option
							key='producer'
							value='producer'
						>
							Producer
						</option>
						<option
							key='extadmin'
							value='extadmin'
						>
							External Admin
						</option>
						<option
							key='extuser'
							value='extuser'
						>
							External User
						</option>
						<option
							key='cfiaOffice'
							value='cfiaOffice'
						>
							CFIA District Office
						</option>
						<option
							key='commPastureUser'
							value='commPastureUser'
						>
							Community Pasture
						</option>
						<option
							key='quarantineFac'
							value='quarantineFac'
						>
							Quarantine Facility
						</option>
						<option
							key='accreditedVet'
							value='accreditedVet'
						>
							Accredited Veterinarian
						</option>
						<option
							key='packingPlant'
							value='packingPlant'
						>
							Packing Plant
						</option>
						<option
							key='nonProducerPart'
							value='nonProducerPart'
						>
							Non-Producer Participant
						</option>

						<option
							key='researchFac'
							value='researchFac'
						>
							Research Facilities
						</option>
						<option
							key='testStation'
							value='testStation'
						>
							Test Station
						</option>
						<option
							key='transporter'
							value='transporter'
						>
							Transporter
						</option>
						<option
							key='deadStock'
							value='deadStock'
						>
							Dead Stock
						</option>
						<option
							key='feedlot'
							value='feedlot'
						>
							Feedlot
						</option>
						<option
							key='auction'
							value='auction'
						>
							Auction
						</option>
						<option
							key='assemblyYard'
							value='assemblyYard'
						>
							Assembly Yard
						</option>
						<option key='exhibition' value='exhibition' > Exhibition  </option>
						<option key='importer' value='importer' > Importer  </option>
						<option key='exporter' value='exporter' > Exporter  </option>
						<option key='vetHospital' value='vetHospital' > Veterinary Hospital  </option>
						<option key='programAdmin' value='programAdmin' > Program Administrator  </option>
						<option key='embryoCollectionCenter' value='embryoCollectionCenter' > Insemination/Embryo Collection Centre  </option>
						<option key='pathologyLab' value='pathologyLab' > Pathology Lab   </option>
						<option key='industryOrg' value='industryOrg' > Industry Organization   </option>
						<option key='portOfEntry' value='portOfEntry' > Port of Entry   </option>
						<option key='tagDistributer' value='tagDistributer' > Tag Distributer   </option>
						<option key='tagDealer' value='tagDealer' > Tag Dealer   </option>
						<option key='tagManufacturer' value='tagManufacturer' > Tag Manufacturer   </option>
						<option key='responsibleAdmin' value='responsibleAdmin' > Responsible Administrator  </option>
						<option key='zoo' value='zoo' > Zoo  </option>
					</FormSelectInput>
				</FormInputWrapper>
					<p>
						By checking the program boxes, you are indicating your participation in the program(s)
					</p>
				<FormInputWrapper>
					<FormInputLabel htmlFor='input'>
						CVSP
					</FormInputLabel>
					<input 
						type='checkbox'
						name='cvsp'
						value='cvsp'
						checked={operationForm.cvsp}
						onClick={() => {
							setOperationForm({
								...operationForm,
								cvsp: !operationForm.cvsp
							});
						}}
					/>
				</FormInputWrapper>
				<FormInputWrapper>
					<FormInputLabel htmlFor='input'>
						SFCP
					</FormInputLabel>
					<input 
						type='checkbox'
						name='sfcp'
						value='sfcp'
						checked={operationForm.sfcp}
						onClick={() => {
							setOperationForm({
								...operationForm,
								sfcp: !operationForm.sfcp
							});
						}}
					/>
				</FormInputWrapper>
			</FormContainer>
			{(isOperationFormComplete(operationForm)) ? <>
			<FormContainer>
				<h3>Premise Information</h3>
				<p>
					Every operation created needs at least one premise.
				</p>
				<FormInputWrapper key='pid'>
					<FormInputLabel htmlFor='input'>
						Premise ID (Leave blank if one has not yet been assigned)
					</FormInputLabel>
					<FormTextInput
						placeholder='Your premise id number'
						id='input'
						name='pid'
						value={premiseForm.pid}
						onChange={(e) => {
							setPremiseForm({
								...premiseForm,
								pid: e.target.value
							});
						}}
					/>
				</FormInputWrapper>
				<FormInputWrapper key='name'>
					<FormInputLabel htmlFor='input'>
						Name <span style={{color:'red'}}>*</span>
					</FormInputLabel>
					<FormTextInput
						placeholder='Add a descriptive name for your premise'
						id='input'
						name='name'
						value={premiseForm.name}
						onChange={(e) => {
							setPremiseForm({
								...premiseForm,
								name: e.target.value
							});
						}}
					/>
				</FormInputWrapper>
				<FormInputWrapper key='address'>
					<FormInputLabel htmlFor='input'>
						Address <span style={{color:'red'}}>*</span>
					</FormInputLabel>
					<FormTextInput
						placeholder='123 Internet Street'
						id='input'
						name='address'
						value={premiseForm.address}
						onChange={(e) => {
							setPremiseForm({
								...premiseForm,
								address: e.target.value
							});
						}}
					/>
				</FormInputWrapper>
				<FormInputWrapper key='municipality'>
					<FormInputLabel htmlFor='input'>
						Municipality or City <span style={{color:'red'}}>*</span>
					</FormInputLabel>
					<FormTextInput
						placeholder='Toronto'
						id='input'
						name='municipality'
						value={premiseForm.municipality}
						onChange={(e) => {
							setPremiseForm({
								...premiseForm,
								municipality: e.target.value
							});
						}}
					/>
				</FormInputWrapper>
				<FormInputWrapper key='province'>
					<FormInputLabel htmlFor='input'>
						Province <span style={{color:'red'}}>*</span>
					</FormInputLabel>
					<FormTextInput
						placeholder='E.g. ON'
						id='input'
						name='province'
						value={premiseForm.province}
						onChange={(e) => {
							setPremiseForm({
								...premiseForm,
								province: e.target.value
							})
						}}
					/>
				</FormInputWrapper>
				<FormInputWrapper key='postalCode'>
					<FormInputLabel htmlFor='input'>
						Postal Code <span style={{color:'red'}}>*</span>
					</FormInputLabel>
					<FormTextInput
						id='input'
						name='postalCode'
						value={premiseForm.postalCode}
						onChange={(e) => {
							setPremiseForm({
								...premiseForm,
								postalCode: e.target.value
							});
						}}
					/>
				</FormInputWrapper>
				<FormInputWrapper key='description'>
					<FormInputLabel htmlFor='input'>
						Description
					</FormInputLabel>
					<FormTextInput
						id='input'
						placeholder='Enter a short description...'
						name='description'
						value={premiseForm.description}
						onChange={(e) => {
							setPremiseForm({
								...premiseForm,
								description: e.target.value
							})
						}}
					/>
				</FormInputWrapper>
				
			</FormContainer>
			</> : <></>}
			<Button type='submit' onClick={handleSubmit} disabled={!(isPremiseFormComplete(premiseForm) && isOperationFormComplete(operationForm))}>
					Submit
			</Button>
		</FormCard>

	</>
}

export default OperationCreate;
