import React, { useState, useEffect } from 'react';
import PageCard from '../components/PageCard';
import SimpleCard from '../components/SimpleCard';
import { useParams } from 'react-router';
import ViewEditDispose from '../components/ViewEditDispose';
import axios from 'axios';
import { sortReportsByDate } from '../components/SheepReportComponents';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { StyledTable } from '../components/TableComponents.js';
import { EditButton } from '../components/CommonComponents.js';
import { Button } from '../components/CommonComponents.js';
import { Link } from 'react-router-dom';

const getNotification = (id) => {
  return axios.get(`/api/notifications/user/${id}`)
    .then((res) => {
      return res.data;  
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};


const changeRead = (id) => {
  return axios.post(`/api/notifications/read/${id}`)
    .then((res) => {
      return res.data;  
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

const replaceFields = [
  { name: 'Old Identification Numbers', id: 'oldIsoNumber', localMan: 'oldLocalMgmtNumber', tattoo: 'oldTattooNumber', Scrapie: 'oldUsScrapieId'},
  { name: 'New Identification Numbers', id: 'newIsoNumber', localMan: 'newLocalMgmtNumber', tattoo: 'newTattooNumber', Scrapie: 'newUsScrapieId'},
]

const NotificationDetailView = () => {
  const [notification, setNotification] = useState([]);
  const params = useParams();
  const id = params.id;

  const fetchNotification = async () => {
    let fetchedReport = await getNotification(id);
    fetchedReport.forEach(r => {
      r.actions = <ViewEditDispose 
        id={r._id} 
        endpoint={'/reports/replace/'}
        hideDispose
        hideEdit
        hideReplace
      />;


      if (
        r.location &&
        r.location.coordinates &&
        r.location.coordinates.length === 2
      ) {
        r.location = r.location.coordinates[1] + ", " + r.location.coordinates[0];
      } else {
        r.location = "";
      }
    });
    setNotification(sortReportsByDate(fetchedReport));
  }

  useEffect(() => {
    fetchNotification();
    changeRead(id);
  }, []);


  return <>
    <PageCard title=" Notification Details" line back>
      {(notification[0]) ? <>
      <Info 
        name="Message" 
        value={notification[0].message} 
      />
    
      {(notification[0].link) ? <> 
        <Link to= {notification[0].link}>
          <Button>Link</Button>
       </Link>
      </>: <></>} 
  

      </>: <>Loading</>} 
    </PageCard>
  </>
};

const ButtonsDiv = styled.div`
	display: flex;
	justify-content: space-between;
`;

const DetailTable = ({data, fields, buttons}) => {
	return <>
		<StyledTable style={{marginTop: '10px', tableLayout: 'fixed'}} >
			<thead>
				<tr>
					<th>Parameters</th>
					<th>ISO Number</th>
          <th>Local Management Num</th>
          <th>Tattoo Number</th>
          <th>US Scrapie ID</th>
				</tr>
			</thead>
			<tbody>
				{fields.map((field) => <>
					<tr>
						<td>{field.name}</td>
						<td>{(data) ? (data[field.id]) ? data[field.id].toString() : '' : ''}</td>
            <td>{(data) ? (data[field.localMan]) ? data[field.localMan].toString() : '' : ''}</td>
            <td>{(data) ? (data[field.tattoo]) ? data[field.tattoo].toString() : '' : ''}</td>
            <td>{(data) ? (data[field.Scrapie]) ? data[field.Scrapie].toString() : '' : ''}</td>
					</tr>
				</>)}
				<tr>
					<td colSpan='2'>
						<ButtonsDiv>
							{(buttons && buttons.length > 0) ? buttons.map((button) => <>
								<EditButton bColor={button.color} onClick={button.onClick}>{button.name}</EditButton>
							</>) : <></>}
						</ButtonsDiv>
					</td>
				</tr>
			</tbody>
		</StyledTable>
	</>
}

DetailTable.propTypes = {
  data: propTypes.node,
  fields: propTypes.node,
  buttons: propTypes.node
};

const Info = ({name, value}) => {
  return <div>
    <span>{name}:&nbsp;</span><span>{value}</span>
  </div>;
}

Info.propTypes = {
  name: propTypes.string,
  value: propTypes.any
}

const HalfDiv = styled.div`
  width: 50%;
  float: left;
  display: inline-block;
  margin-bottom: 20px;
`

export default NotificationDetailView;