// SheepDashboard.js
import React, { useEffect, useState } from 'react';
import PageCard from '../../components/PageCard';
import SearchTable from '../../components/SearchTable';
import SimpleCard from '../../components/SimpleCard';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { getUsername } from '../../utils/TokenUtils';
import axios from 'axios';
import { Button } from '../../components/CommonComponents.js';
import ViewEditDispose from '../../components/ViewEditDispose';
import propTypes from 'prop-types';

// Functional Components

/** OPERATIONS **/
const fetchSheep = async () => {
  try {
    const username = getUsername();
    const request = await axios.get(`/api/sheep?username=${username}&premise=true`);
    const fetchedSheep = request.data;
    return fetchedSheep;
  } catch (err) {
    throw new Error(`An error occurred fetching sheep: ${err.message}`, err);
  }
}

const PremisesComponent = () => {
  const [premises, setPremises] = useState([]);
  
  // Fetch premises and transform data as necessary
  const fetchAndTransformSheepData = async () => {
    try {
      var fetchedSheep = await fetchSheep();
      fetchedSheep = fetchedSheep.filter((sheep) => {
        return (
          !sheep.isExported &&
          !sheep.movedOut &&
          !sheep.onAbattoir
        );
      });

      let premiseNames = [];
      let tempPremises = [];

      // Simplify
      const simplifiedSheep = fetchedSheep.map((s) => {
        const simpleSheep = {
          id: s._id,
          premiseName: s.premise.name,
          premiseId: s.premise._id,
          pid: s.premise.pid,
          actions: <ViewEditDispose id={s._id} endpoint={'/sheep/'} />
        };

        if(s.tag.tattooNumber) simpleSheep.identifier= s.tag.tattooNumber
        if(s.tag.localMgmtNumber) simpleSheep.identifier = s.tag.localMgmtNumber;
        if(s.tag.isoNumber) simpleSheep.identifier = s.tag.isoNumber

        premiseNames.push(simpleSheep.premiseName);

        return simpleSheep;
      });

      // Group into premises
      let uniquePremiseNames = [];
      premiseNames.forEach(p => {
        if (!uniquePremiseNames.includes(p)) {
          uniquePremiseNames.push(p)
        }
      });

      tempPremises = uniquePremiseNames.map(n => {
        const premiseSheep = simplifiedSheep.filter(s => s.premiseName === n);
        const premise = {
          name: n,
          id: premiseSheep[0].premiseId,
          sheep: premiseSheep,
          pid: premiseSheep[0].pid
        };

        return premise;
      });
      setPremises(tempPremises);
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  }

  useEffect(() => {
    fetchAndTransformSheepData();
  }, []);
  
  return <>
    <OperationsDiv>
      <OperationsInnerContainer>
        {premises.map((p, idx) => {
          // Columns
          const columns = [
            {
              id: 'identifier',
              name: 'Identifier (iso #, local management # or tattoo #)'
            },
            {
              id: 'actions',
              name: 'Actions'
            }
          ];

          const rows = p.sheep;

          return <div key={idx}>
            <SimpleCard title={`${p.name}` + ' - ' + `${p.pid}`}>
              <SearchTable
                to={`/sheep/premise/${p.id}`}
                placeholder="Enter the identifier you wish to search for"
                filterField="identifier"
                columns={columns}
                rows={rows}
                count={10}
              />
            </SimpleCard>
          </div>
        })}
      </OperationsInnerContainer>
    </OperationsDiv>
  </>
}

/** REPORTS */
const ReportSection = ({ title, description, to, btnText}) => {
  return <>
    <ColumnDiv>
      <SimpleCard
        title={title}
        size='md'
        description={description}
      >
        <AddSheepButtonContainer>
          <Link to={to} >
            <AddSheepButton>{btnText}</AddSheepButton>
          </Link>
        </AddSheepButtonContainer>
      </SimpleCard>
    </ColumnDiv>
  </>
}

ReportSection.propTypes = {
  title: propTypes.string,
  description: propTypes.string,
  to: propTypes.any,
  btnText: propTypes.any
}

const ReportsComponent = () => {
  return <>
    <SimpleCard 
        title="Reports">
    </SimpleCard>

    <ReportSection
      title="Add Sheep"
      description="Click the button to add sheep"
      to='/sheep/create/manual'
      btnText="Add Sheep"
    />
    <ReportSection
      title="Replace Tag"
      description="Click the button to replace tags"
      to='/reports/replace/listView'
      btnText="Replace Tags"
    />
    <ReportSection
      title="Dispose Sheep"
      description="Click the button to dispose of sheep"
      to='/reports/disposal'
      btnText="Dispose"
    />
    <ReportSection
      title="Transport Sheep"
      description="Click the button to transport sheep"
      to='/reports/transport/list'
      btnText="Transport"
    />
    <ReportSection
      title="Move-In Sheep"
      description="Click the button to move sheep into a premise"
      to='/reports/movein'
      btnText="Move-In"
    />
    <ReportSection
      title="Move-Out Sheep"
      description="Click the button to move sheep out of a premise"
      to='/reports/moveout'
      btnText="Move-Out"
    />
    <ReportSection
      title="Import Sheep"
      description="Click the button to import sheep"
      to='/reports/import'
      btnText="Import"
    />
    <ReportSection
      title="Export Sheep"
      description="Click the button to export sheep"
      to='/reports/importexport'
      btnText="Export"
    />
    <ReportSection
      title="Medical Reports"
      description="Click the button to create medical reports"
      to='/reports/medical/list'
      btnText="Medical"
    />
    <ReportSection
      title="Sheep Sightings"
      description="Click the button to record sheep sightings"
      to='/reports/sighting/listView'
      btnText="Sightings"
    />

    <SimpleCard 
        title="Help"
        description="Need help? Click the button below to get help on anything that you may need related to sheep"
    >
    <Link to='/help'>
      <Button>Sheep Help</Button>
    </Link>
    </SimpleCard>
  </>
}

/** EXPORTED COMPONENT */
const SheepDashboardView = () => {

  return <PageCard
    title="Sheep Dashboard"
    line
    back
  >
    <SheepDashboardDiv>
      <PremisesComponent />
      <ActionsDiv>
        <ReportsComponent/>
      </ActionsDiv>
    </SheepDashboardDiv>
  </PageCard>;
};

// Styled components
const AddSheepButton = styled.button`
  background-color: #17a737;
  border: none;
  border-radius: 10px;
  padding: 1rem;
  font-size: 1rem;
  color: white;
  cursor: pointer;
`
const AddSheepButtonContainer = styled.div`
  display: inline-block;
  text-align: center;
`
const SheepDashboardDiv = styled.div`
  width: 100%;
`
const OperationsDiv = styled.div`
  max-width: 60%;
  width: 80%;
  float: left;
  display: inline-block;
`
const OperationsInnerContainer = styled.div`
  padding: .25rem;
`;
const ActionsDiv = styled.div`
  width: 40%;
  display: inline-block;
`
const ColumnDiv = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: top;
`

export default SheepDashboardView;