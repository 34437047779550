import React, { useEffect, useState, useReducer } from 'react';
import { Redirect } from 'react-router-dom';
import {
	FormContainer,
	FormInputLabel,
	Button,
	FormRow,
	FormSelectInput,
	FormTextList,
	FormTextListItem,
	FormTextInput
} from '../../components/CommonComponents.js';
import FormCard from '../../components/FormCard.js';
import { getUsername } from '../../utils/TokenUtils.js';
import styled from 'styled-components';
import { EditButton } from '../../components/CommonComponents';
import axios from 'axios';

export const countries = [
	"United States of America (the)",
	"Canada",
	"Afghanistan",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas (the)",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia (Plurinational State of)",
	"Bonaire, Sint Eustatius and Saba",
	"Bosnia and Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory (the)",
	"Brunei Darussalam",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cabo Verde",
	"Cambodia",
	"Cameroon",
	"Cayman Islands (the)",
	"Central African Republic (the)",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (Keeling) Islands (the)",
	"Colombia",
	"Comoros (the)",
	"Congo (the Democratic Republic of the)",
	"Congo (the)",
	"Cook Islands (the)",
	"Costa Rica",
	"Croatia",
	"Cuba",
	"Curaçao",
	"Cyprus",
	"Czechia",
	"Côte d'Ivoire",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic (the)",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Eswatini",
	"Ethiopia",
	"Falkland Islands (the) [Malvinas]",
	"Faroe Islands (the)",
	"Fiji",
	"Finland",
	"France",
	"French Guiana",
	"French Polynesia",
	"French Southern Territories (the)",
	"Gabon",
	"Gambia (the)",
	"Georgia",
	"Germany",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guernsey",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Heard Island and McDonald Islands",
	"Holy See (the)",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran (Islamic Republic of)",
	"Iraq",
	"Ireland",
	"Isle of Man",
	"Israel",
	"Italy",
	"Jamaica",
	"Japan",
	"Jersey",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Korea (the Democratic People's Republic of)",
	"Korea (the Republic of)",
	"Kuwait",
	"Kyrgyzstan",
	"Lao People's Democratic Republic (the)",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macao",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands (the)",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia (Federated States of)",
	"Moldova (the Republic of)",
	"Monaco",
	"Mongolia",
	"Montenegro",
	"Montserrat",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands (the)",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger (the)",
	"Nigeria",
	"Niue",
	"Norfolk Island",
	"Northern Mariana Islands (the)",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestine, State of",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines (the)",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Republic of North Macedonia",
	"Romania",
	"Russian Federation (the)",
	"Rwanda",
	"Réunion",
	"Saint Barthélemy",
	"Saint Helena, Ascension and Tristan da Cunha",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Martin (French part)",
	"Saint Pierre and Miquelon",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Sint Maarten (Dutch part)",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia and the South Sandwich Islands",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"Sudan (the)",
	"Suriname",
	"Svalbard and Jan Mayen",
	"Sweden",
	"Switzerland",
	"Syrian Arab Republic",
	"Taiwan",
	"Tajikistan",
	"Tanzania, United Republic of",
	"Thailand",
	"Timor-Leste",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks and Caicos Islands (the)",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates (the)",
	"United Kingdom of Great Britain and Northern Ireland (the)",
	"United States Minor Outlying Islands (the)",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela (Bolivarian Republic of)",
	"Viet Nam",
	"Virgin Islands (British)",
	"Virgin Islands (U.S.)",
	"Wallis and Futuna",
	"Western Sahara",
	"Yemen",
	"Zambia",
	"Zimbabwe",
	"Åland Islands"
];

function reducer(state, action) {
	switch (action.type) {
		case 'append': {
			var flag = false;
			console.log(state); //logs {provinces: []}
			console.log(action.value); //logs whatever value you select
			console.log(action.array); // logs 'provinces'

			if (state[action.array].includes(action.value)) {
				flag = true;
			}
			return {
				...state,
				[action.array]:
					flag === false ? [...state[action.array], action.value] : [...state[action.array]],
				dynamicAdd: {
					...state.dynamicAdd,
					[action.array]: [...state[action.array]],
				},
			};
		}
		case 'remove': {
			const filtered = state[action.array].filter((item) => item !== action.value);
			console.log(filtered);
			return {
				...state,
				[action.array]: filtered,
				dynamicAdd: {
					...state.dynamicAdd,
					[action.array]: [...state.dynamicAdd[action.array], { name: action.value }],
				},
			};
		}
		case 'init':
			return action.payload;
		default:
			throw new Error();
	}
}

export default function ImportCreate() {
	const [form, setForm] = useState({
		exportingCountry: '',
		departureSite: '',
		firstDestinationPID: '',
        dateOfArrival: '',
		vehicleIdentifier: '',
	});

	const [redirected, setRedirected] = useState(false);
	const [fieldErrors, setFieldErrors] = useState({});
	const [sheepImport, setSheepImport] = useState([]);
	const [user, setUser] = useState([]);
	const [data, dispatch] = useReducer(reducer, {});
	const [foo, setFoo] = useState('');
	const [reqd, setReqd] = useState(false);
	const [windowSize, setWindowSize] = useState(0);

	const account = [
		{ name: 'Departure Site:', hint: 'New York', id: 'departureSite' },
		{ name: <div>
				First Destination PID - Is the location an Abattoir?
				<span style={{color: 'red'}}>*</span>
				<EditButton type='button' onClick = {() => {
					if (data.isonum){
						data.isonum.map((option) => dispatch({ type: 'remove', array: 'isonum', value: option }));
					}
					setReqd(false);
				}}>Yes</EditButton>
				<EditButton type='button' onClick = {() => {
					setReqd(true);
				}}>No</EditButton></div>, hint: 'ON1234567', id: 'firstDestinationPID', reqd: true },
        { name: <span>Date of Arrival<span style={{color: 'red'}}>*</span></span>, hint: '2020-08-10', id: 'dateOfArrival', type: 'date' },
        { name: <span>Vehicle Identifier<span style={{color: 'red'}}>*</span></span>, hint: 'ABC123', id: 'vehicleIdentifier' },
	];

	const dynamicAdd = [{ name: 
	<>
		<span>Animal Identifiers</span>
		<EditButton type='button' onClick={() => {
			foo === '' ? 
				alert('Cannot be blank')
				: 
				dispatch({ type: 'append', array: 'isonum', value: foo })
				setFoo('')
		}}>Add</EditButton>
	</>, id: 'isonum' }];
	const dropDown = [[{ name: <span>Exporting Country<span style={{color: 'red'}}>*</span></span>, id: 'exportingCountry' }]];

	//For animal identifiers
	useEffect(() => {
		const payload = {
			isonum: [],
		};

		dispatch({
			type: 'init',
			payload,
		});
	}, []);

	// run when submit button is clicked
	const submitImport = (e) => {
		var valid = true;
		// eslint-disable-next-line no-unused-vars
		const { dynamicAdd, ...imp } = data;
		form.isonum = imp.isonum;

		if (form.isonum.length === 0 && reqd === true){
			valid = false;
		}

		/*
		if (form.isonum.length > 0 && reqd === false){
			form.isonum = [];
		}
		*/

		if (valid) {
			axios
				.post(`/api/import/${user._id}/register`, form).then(
					(res) => {
						setSheepImport(res.data._id);
						setRedirected(true);
					},
					(err) => {
						console.log(err.response.data);
						console.log('error posting occured');
						setFieldErrors(err.response.data);
					}
				);
		}
		else {
			alert('If your First Destination PID is not an abattoir, you need to fill in the Animal Identifiers!');
			
		}
		e.preventDefault();
	};

	//To get the _id of the current user logged in
	useEffect(() => {
		axios
			.get(`/api/users/${getUsername()}`)
			.then(function (response) {
				console.log(response.data);
				setUser(response.data);
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	if(window.innerWidth !== windowSize){
		setWindowSize(window.innerWidth);
		console.log(windowSize);
	}

	return (
		<>
			<FormCard title='Add Imported Sheep' errors={fieldErrors} back>
			{windowSize < 1300 ?
				<FormContainer onSubmit={submitImport}>
					<StyledSpan><StyledSpan1 style={{color: 'red'}}>*</StyledSpan1> mandatory fields</StyledSpan>
					{dropDown.map((row) => {
						return (
							<FormRow key={row}>
								{row.map((element) => {
									return (
										<FormInputWrapper1 key={element.id}>
											<FormInputLabel htmlFor='input'>{element.name}</FormInputLabel>
											<FormTextList>
												<FormSelectInput
													name={element.id}
													value={form[element.id]}
													onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
												>
													<option hidden>Select one</option>
													{countries.map((value) => {
														return <option key={value.name}>{value}</option>;
													})}
												</FormSelectInput>
											</FormTextList>
										</FormInputWrapper1>
									);
								})}
							</FormRow>
						);
					})}
					{account.map((element) => {
						return (
							<FormInputWrapper1 key={element.id}>
								<FormInputLabel htmlFor='input'>{element.name}</FormInputLabel>
								<FormTextInput
									placeholder={element.hint}
									id='input'
									type={element.type ? element.type : 'text'}
									valid={true}
									name={element.id}
									value={form[element.id]}
									onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} // react replaces the whole object so go to add ...form,
								></FormTextInput>
							</FormInputWrapper1>
						);
					})}
					{dynamicAdd.map((element) => (
						<FormInputWrapper1 key={element.id}>
							<FormInputLabel htmlFor='input'>{element.name}</FormInputLabel>
								{data[element.id] &&
									data[element.id].map((option) => (
										<FormTextListItem
											key={element.id}
											remove
											onClick={() => dispatch({ type: 'remove', array: element.id, value: option })}
										>
											{option}
										</FormTextListItem>
									))}
								<FormTextInput value={foo} disabled={reqd === false} onChange={(e)=>setFoo(e.target.value)}>
								</FormTextInput>
						</FormInputWrapper1>
					))}
					<div style={{ textAlign: 'center' }}>
						<Button>Create</Button>
					</div>
				</FormContainer>
				:
				<FormContainer onSubmit={submitImport}>
					<StyledSpan><StyledSpan1 style={{color: 'red'}}>*</StyledSpan1> mandatory fields</StyledSpan>
					{dropDown.map((row) => {
						return (
							<FormRow key={row}>
								{row.map((element) => {
									return (
										<FormInputWrapper key={element.id}>
											<FormInputLabel htmlFor='input'>{element.name}</FormInputLabel>
											<FormTextList>
												<FormSelectInput
													style={{"width": "450px"}}
													name={element.id}
													value={form[element.id]}
													onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
												>
													<option hidden>Select one</option>
													{countries.map((value) => {
														return <option key={value.name}>{value}</option>;
													})}
												</FormSelectInput>
											</FormTextList>
										</FormInputWrapper>
									);
								})}
							</FormRow>
						);
					})}
					{account.map((element) => {
						return (
							<FormInputWrapper key={element.id}>
								<FormInputLabel htmlFor='input'>{element.name}</FormInputLabel>
								<FormTextInput
									placeholder={element.hint}
									id='input'
									type={element.type ? element.type : 'text'}
									valid={true}
									name={element.id}
									value={form[element.id]}
									onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} // react replaces the whole object so go to add ...form,
								></FormTextInput>
							</FormInputWrapper>
						);
					})}
					{dynamicAdd.map((element) => (
						<FormInputWrapper key={element.id}>
							<FormInputLabel htmlFor='input'>{element.name}</FormInputLabel>
								{data[element.id] &&
									data[element.id].map((option) => (
										<FormTextListItem
											key={element.id}
											remove
											onClick={() => dispatch({ type: 'remove', array: element.id, value: option })}
										>
											{option}
										</FormTextListItem>
									))}
								<FormTextInput value={foo} disabled={reqd === false} onChange={(e)=>setFoo(e.target.value)}>
								</FormTextInput>
						</FormInputWrapper>
					))}
					<div style={{ textAlign: 'center' }}>
						<Button>Create</Button>
					</div>
				</FormContainer>}
				{redirected ? <Redirect push to={`/events/animalImport/${sheepImport}`} /> : null}
			</FormCard>
		</>
	);
}

const StyledSpan = styled.span`
	padding-bottom: 10px;
	padding-left: 25px;
	font-size: 10px;
`;

const StyledSpan1 = styled.span`
	font-size: 15px;
`;

const FormInputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 25px;
	margin-top: 0;
	width: 450px;
`;

const FormInputWrapper1 = styled.div`
	display: flex;
	flex-direction: column;
	margin: 25px;
	margin-top: 0;
	width: 200px;
`;