import React, { useEffect, useState } from 'react';
import PageCard from '../../components/PageCard';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import SimpleCard from '../../components/SimpleCard';
import propTypes from 'prop-types';
import StripedTable from '../../components/StripedTable';
import { NAField } from '../../components/SheepReportComponents';
import styled from 'styled-components';

const sheepColumns = [
  {
    id: 'isoNumber',
    name: 'Sheep ISO Number'
  },
  {
    id: 'localMgmtNumber',
    name: 'Local Management Number'
  },
  {
    id: 'tattooNumber',
    name: 'Tattoo Number'
  },
  {
    id: 'gender',
    name: 'Gender'
  },
  {
    id: 'breed',
    name: 'Breed'
  }
];

const fetchReportInfo = async (id) => {
  try{
    const request = await axios.get(`/api/transport/${id}`);
    return request.data;
  } catch (err){
    console.log(err);
    throw new Error(`An error occurred fetching report info: ${err.message}`);
  }
}

const Info = ({name, value}) => {
  return <div>
    <span>{name}:&nbsp;</span><span>{value}</span>
  </div>;
}

Info.propTypes = {
  name: propTypes.string,
  value: propTypes.any
}

const convertToAmPm = (time) => {
  const hourMinute = time.split(":");
  let hr = +hourMinute[0];
  let min = +hourMinute[1];
  const ampm = hr >= 12 ? 'pm' : 'am';
  hr = hr % 12;
  hr = hr > 0 ? hr : 12; // 0 hr should be 12
  min = min < 10 ? '0'+min : min;
  return `${hr}:${min}${ampm}`;
};

const TransportReportDetailView = () => {
  const [reportInfo, setReportInfo] = useState();
  const [sheepInfo, setSheepInfo] = useState([]);
  const params = useParams();
  const id = params.id;

  const fetchAndSetReportInfo = async () => {
    try {
      let fetchedReportInfo = await fetchReportInfo(id);
      
      fetchedReportInfo.createdAt = (new Date(fetchedReportInfo.createdAt)).toISOString().split('T')[0];
      fetchedReportInfo.departureDate = (new Date(fetchedReportInfo.departureDate)).toISOString().split('T')[0];
      
      setSheepInfo(fetchedReportInfo.animals);
      setReportInfo(fetchedReportInfo);
      
    } catch (err) {
      toast.error(err.message);
    }
  }

  useEffect(() =>  {
    fetchAndSetReportInfo();
  }, [])

  sheepInfo.forEach(s => {
    s.isoNumber = (s.tag.isoNumber) ? s.tag.isoNumber : <NAField />;
    s.localMgmtNumber = (s.tag.localMgmtNumber) ? s.tag.localMgmtNumber : <NAField />;
    s.tattooNumber = (s.tag.tattooNumber) ? s.tag.tattooNumber : <NAField />;
    s.breed = (s.breed) ? s.breed : <NAField />;
    s.gender = (s.gender) ? s.gender : <NAField />;
  });

  return <>
  <PageCard title="Transport Report Details" verify={`/verify/transport/${id}`} back>
    {(reportInfo) ? <>
      <SimpleCard title="Report Information">
        <InfoDiv>
          <Info name="Created At" value={reportInfo.createdAt} />
          <Info name="Departure Premise ID" value={reportInfo.departurePID} />
          <Info name="Destination Premise ID" value={reportInfo.destinationPID} />
        </InfoDiv>
        <InfoDiv>
          <Info name="Departure Date" value={reportInfo.departureDate} />
          <Info name="Departure Time" value={convertToAmPm(reportInfo.departureTime)} />
          <Info name="License Plate Number" value={reportInfo.vehicleNumber} />
        </InfoDiv>
      </SimpleCard>
      
      <SimpleCard title="Transported Sheep" size="md"></SimpleCard>
        <StripedTable  
          columns={sheepColumns}
          rows={sheepInfo}
          ShowCount
          max={10}
          paginate
        />
    </>: <>Loading</>}
  </PageCard>
</>
}

const InfoDiv = styled.div`
  width: 50%;
  display: inline-block;
  margin-bottom: 10px;
`;

export default TransportReportDetailView;