const axios = require('axios');

export const getMedicalReports = () => {
  return axios.get('/api/animalmedical')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

//get the medical reports for a specific sheep
export const getMedicalReportsForSheep = (id) => {
  return axios.get(`/api/animalmedical/sheep/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

export const createMedicalReport = (data) => {
  return axios.post('/api/animalmedical', data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};