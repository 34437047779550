// ReportRouter.js
import React from 'react';
import { Route, Switch} from 'react-router-dom';
import CarcassDisposalListView from '../views/Sheep/CarcassDisposalListView';
import DisposalListView from '../views/Sheep/DisposalListView';
import RawDetailView from '../views/Sheep/RawDetailView';
import RawSheepReportsListView from '../views/Sheep/RawSheepReportsListView';
import SlaughterListView from '../views/Sheep/SlaughterListView';
import TagActivationDetailView from '../views/Sheep/TagActivationDetailView';
import TagActivationListView from '../views/Sheep/TagActivationListView';
import MoveOutReportListView from '../views/MoveOut/MoveOutReportListView';
import MoveInReportListView from '../views/MoveIn/MoveInReportListView';
import MoveOutReportCreateView from '../views/MoveOut/MoveOutReportCreateView';
import MoveInReportCreateView from '../views/MoveIn/MoveInReportCreateView';
import MoveOutReportCsvView from '../views/MoveOut/MoveOutReportCsvView';
import MoveInReportCsvView from '../views/MoveIn/MoveInReportCsvView';
import TempExportView from '../views/Export/TempExportView';
import TempExportCSVCreateView from '../views/Export/TempExportCSVCreateView';
import ImportManualCreateView from '../views/Import/ImportManualCreateView';
import ImportCSVCreateView from '../views/Import/ImportCSVCreateView';
import ExportReportListView from '../views/Export/ExportReportListView';
import ExportReportDetailView from '../views/Export/ExportReportDetailView';
import ImportReportListView from '../views/Import/ImportReportListView';
import ExportManualCreateView from '../views/Export/ExportManualCreateView';
import ExportCSVCreateView from '../views/Export/ExportCSVCreateView';
import OperationUpdateReportView from '../views/OperationUpdateReportView';
import SightingReportCreateView from '../views/Sighting/SightingReportCreateView';
import SightingReportListView from '../views/Sighting/SightingReportListView';
import SightingReportDetailView from '../views/Sighting/SightingReportDetailView';
import TagReplaceDetailView from '../views/Sheep/TagReplaceDetailView';
import TagRetireDetailView from '../views/Sheep/TagRetireDetailView';
import SightingCSVCreateView from '../views/Sighting/SightingCSVCreateView';
import MedicalReportCreateView from '../views/MedicalReport/MedicalReportCreateView';
import MedicalReportListView from '../views/MedicalReport/MedicalReportListView';
import MedicalReportDetailView from '../views/MedicalReport/MedicalReportDetailView';
import ReportDashboardView from '../views/ReportDashboardView.js';
import SheepReplaceListView from '../views/Sheep/SheepReplaceListView';
import SheepReplaceSelectView from '../views/Sheep/SheepReplaceSelectView';
import ImportReportDetailView from '../views/Import/ImportReportDetailView';
import MoveInReportDetailView from '../views/MoveIn/MoveInReportDetailView';
import TagRetireListView from '../views/Sheep/TagRetireListView';
import MoveOutReportDetailView from '../views/MoveOut/MoveOutReportDetailView';
import TransportReportListView from '../views/Transport/TransportReportListView';
import TransportReportCreateView from '../views/Transport/TransportReportCreateView';
import TransportReportDetailView from '../views/Transport/TransportReportDetailView';
import TransportReportTransferView from '../views/Transport/TransportReportTransferView';
import MoveInFromTransportView from '../views/Transport/MoveInFromTransportView';
import NotificationDetailView from '../views/NotificationDetailView'
import CreateMoveInFromMoveOut from '../views/MoveIn/CreateMoveInFromMoveOut';
import SheepEditList from '../views/SheepEditReport/SheepEditList';
import SheepEditSelectView from '../views/SheepEditReport/SheepEditSelectView';
import SheepEditDetailView from '../views/SheepEditReport/SheepEditDetailView';

const ReportRouter = () => <>
  <Switch>
    <Route
      exact
      path="/reports/tagactivation"
      component={TagActivationListView}
    />
    <Route
      exact
      path="/reports/tagactivation/:id"
      component={TagActivationDetailView}
    />
    <Route
      exact
      path="/reports/carcassdisposal"
      component={CarcassDisposalListView}
    />
    <Route
      exact
      path="/reports/slaughter"
      component={SlaughterListView}
    />
    <Route
      exact
      path="/reports/disposal"
      component={DisposalListView}
    />
    <Route
      exact
      path="/reports/rawsheep"
      component={RawSheepReportsListView}
    />
    <Route
      exact
      path='/reports/raw/:id'
      component={RawDetailView}
    />
    <Route
      exact
      path='/reports/movein'
      component={MoveInReportListView}
    />
    <Route
      exact
      path='/reports/moveout'
      component={MoveOutReportListView}
    />
    <Route
      exact
      path='/reports/dash'
      component={ReportDashboardView}
    />
    <Route
      exact
      path='/reports/moveout/new'
      component={MoveOutReportCreateView}
    />
    <Route
      exact
      path='/reports/movein/new'
      component={MoveInReportCreateView}
    />
    <Route
      exact
      path='/reports/moveout/new/csv'
      component={MoveOutReportCsvView}
    />
    <Route
      exact
      path='/reports/movein/new/csv'
      component={MoveInReportCsvView}
    />
    <Route 
      exact 
      path='/reports/updateReports' 
      component={OperationUpdateReportView} 
    />
    <Route 
      exact 
      path='/reports/sightingReportCreate' 
      component={SightingReportCreateView} 
    />
    <Route 
      exact 
      path='/reports/sighting/listView' 
      component={SightingReportListView} 
    />
    <Route 
      exact 
      path='/reports/sighting/csvCreate' 
      component={SightingCSVCreateView} 
    />
    <Route 
      exact 
      path='/reports/replace/listView' 
      component={SheepReplaceListView} 
    />
    <Route 
      exact 
      path='/reports/retire/listView' 
      component={TagRetireListView} 
    />
    <Route 
      exact 
      path='/reports/replace/selectView' 
      component={SheepReplaceSelectView} 
    />
    <Route exact path='/reports/sighting/:id' component={SightingReportDetailView} />
    <Route exact path='/reports/import/:id' component={ImportReportDetailView} />
    <Route exact path='/reports/replace/:id' component={TagReplaceDetailView} />
    <Route exact path='/reports/retire/:id' component={TagRetireDetailView} />
    <Route exact path='/reports/moveIn/:id' component={MoveInReportDetailView} />  
    <Route exact path='/reports/moveOut/:id' component={MoveOutReportDetailView} />  
    <Route exact path='/reports/moveInFromMoveOut/:id' component={CreateMoveInFromMoveOut} />  
    <Route exact path='/reports/notification/:id' component={NotificationDetailView} />  
    <Route
      exact
      path="/reports/import"
      component={ImportReportListView}
    />
    <Route
      exact
      path='/reports/import/create/manual'
      component={ImportManualCreateView}
    />
    <Route
      exact
      path="/reports/importExport/export/manual"
      component={ExportManualCreateView}
    />
    <Route
      exact
      path="/reports/importExport/export/csv"
      component={ExportCSVCreateView}
    />
    <Route
      exact
      path='/reports/import/create/csv'
      component={ImportCSVCreateView}
    />
    <Route
      exact
      path="/reports/importExport/temp"
      component={TempExportView}
    />
    <Route
      exact
      path="/reports/importExport/bulk"
      component={TempExportCSVCreateView}
    />
    <Route
      exact
      path="/reports/importExport"
      component={ExportReportListView}
    />
    <Route
      exact
      path="/reports/medical/create"
      component={MedicalReportCreateView}
    />
    <Route
      exact
      path="/reports/medical/list"
      component={MedicalReportListView}
    />
    <Route
      exact
      path="/reports/medical/:id"
      component={MedicalReportDetailView}
    />
    <Route
      exact
      path="/reports/transport/list"
      component={TransportReportListView}
    />
    <Route
      exact
      path="/reports/transport/create"
      component={TransportReportCreateView}
    />
    <Route
      exact
      path="/reports/transport/:id"
      component={TransportReportDetailView}
    />
    <Route
      exact
      path='/reports/transport/transfer/:id'
      component={TransportReportTransferView}
    />
    <Route
      exact
      path='/reports/transport/moveIn/:id'
      component={MoveInFromTransportView}
    />
     <Route
      exact
      path='/reports/sheepedit'
      component={SheepEditList}
    />
    <Route 
      exact 
      path='/reports/sheepedit/:id' 
      component={SheepEditDetailView} 
    />
    <Route 
      exact 
      path='/reports/edit/selectView' 
      component={SheepEditSelectView} 
    />
    <Route exact path='/reports/:id' component={ExportReportDetailView} />
  </Switch>
</>;

export default ReportRouter;