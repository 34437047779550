import axios from 'axios';

export const getImportReports = () => {
  return axios.get('/api/import/all')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.error;
    });
};

//get the import reports for a specific sheep
export const getImportReportsForSheep = (id) => {
  return axios.get(`/api/import/sheep/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

export const createImportReport = (data) => {
  return axios.post('/api/import/', data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

export const createBulkImportReport = (data) => {
  return axios.post('/api/import/bulk', data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};