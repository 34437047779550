import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import SimpleCard from './SimpleCard';
import StripedTable from './StripedTable';
import { agGreen, viewColor } from "./Colors"
import { getUser } from '../utils/UserUtils';

const Info = ({name, value}) => {
  return <InfoDiv>
    <FieldSpan>{name}</FieldSpan><ValueSpan>{value}</ValueSpan>
  </InfoDiv>;
}

const sheepTableColumns = [
  {
    id: 'isoNumber',
    name: 'ISO Number'
  },
  {
    id: 'localMgmtNumber',
    name: 'Local Management Number'
  },
  {
    id: 'tattooNumber',
    name: 'Tattoo Number'
  }
];

const formatDate = (date) => {
  const formattedDate = new Date(date).toISOString().split('T')[0];
  return formattedDate;
}

export default function TraceSheepDetails(props) {
  const {
    sheepId,
    dateOfArrival,
    dateOfDeparture,
    premise,
    userInfo
  } = props;
  const [user, setUser] = useState({});
  const [sheepStatus, setSheepStatus] = useState("Alive");
  const [samePremiseSheep, setSamePremiseSheep] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if(premise != "N/A"){
    getUser(userInfo.username)
      .then(fetchedUser => {
        setUser(fetchedUser);
        return axios.get(`/api/sheepreports/sheep/${sheepId}`);
      })
      .then(({ data }) => {
        // get the disposal reports (slaughter and carcassdisposal)
        const disposeReports = data.filter(report => {
          return (
            report.action === 'SLAUGHTER' ||
            report.action === 'CARCASSDISPOSAL'
          );
        });
        // based on the disposal reports, determine the sheep's status
        if (disposeReports.length > 0) {
          const disposeDate = new Date(disposeReports[0].reportDate);
          let departDate = new Date();
          if (dateOfDeparture) {
            departDate = new Date(dateOfDeparture);
          }

          if (disposeDate < departDate) {
            setSheepStatus("Dead");
          }
        }
        
        const path = `/api/trace/sheep/premise/${premise.pid}?arrivalDate=${dateOfArrival}` +
                     (dateOfDeparture ? `&departureDate=${dateOfDeparture}` : "");
        return axios.get(path);
      })
      .then(({ data }) => {
        if (dateOfArrival !== dateOfDeparture) {
    
          const aliveSheep = data.filter(sheep => sheep.deathDate == undefined || sheep.deathDate == null || (sheep.deathDate >= dateOfArrival && sheep.deathDate <= dateOfDeparture) );//Filter sheep so that the sheep that appear are either not dead or died after the searched sheep arrived

          const premiseSheep = (aliveSheep.map(sheep => ({
            _id: sheep._id,
            isoNumber: sheep.tag.isoNumber ? sheep.tag.isoNumber : "N/A",
            localMgmtNumber: sheep.tag.localMgmtNumber ? sheep.tag.localMgmtNumber : "N/A",
            tattooNumber: sheep.tag.tattooNumber ? sheep.tag.tattooNumber : "N/A"
          })));
          setSamePremiseSheep(premiseSheep.filter(sheep => sheep._id !== sheepId));
          setLoaded(true);
        }
      })
      .catch(err => {
        console.error(err);
      });
    } else {
      setLoaded(true);
    }
  }, []);

  let premiseLocation;
  if (
    premise.location &&
    premise.location.coordinates &&
    premise.location.coordinates.length === 2
  ) {
    premiseLocation = premise.location.coordinates[1] + ", " + premise.location.coordinates[0];
  } else {
    premiseLocation = "N/A";
  }

  return (
    <DetailContainer>
      <SimpleCard title="Status" size="md">
        <Info 
          name="Sheep Status"
          value={
            userInfo.firstName == "N/A" ? (<>This sheep is not in our system at this point in time, we are unaware of its status</>) : 
                                          ( sheepStatus === 'Alive' ?
                                            <AliveText>{sheepStatus}</AliveText> :
                                            <DeadText>{sheepStatus}</DeadText>
                                          )
          }

        />
      </SimpleCard>

      <SimpleCard title="Date Information" size="md">
        <Info name="Date of Arrival at Location" value={dateOfArrival !== undefined ? formatDate(dateOfArrival) : 'N/A'} />
        <Info name="Date of Departure From Location" value={dateOfArrival === undefined ? 'N/A' : dateOfDeparture !== undefined ? formatDate(dateOfDeparture) : "This sheep has not departed yet"} />
      </SimpleCard>

      <SimpleCard title="Owner Information" size="md">
        <Info name="Owner Name" value={userInfo.firstName == "N/A" ? "This user is not in our system" : userInfo.firstName + " " + userInfo.lastName} />
        <Info name="Email" value={user.email ? user.email : "N/A"} />
        <Info name="Phone Number" value={user.phone ? user.phone : "N/A"} />
      </SimpleCard>

      <SimpleCard title="Premise Information" size="md">
        <Info name="Premise ID" value={premise.pid ? premise.pid : "This premise is not in our system"} />
        <Info name="Premise Name" value={premise.name ? premise.name : "N/A"} />
        <Info name="Location (Latitude, Longitude)" value={premiseLocation ? premiseLocation : "N/A"} />
        <Info name="Associated Operation" value={premise.operation ? premise.operation.businessName : "N/A"} />
      </SimpleCard>

      <SimpleCard title="Sheep Information" size="md">
        <SectionDescription>
          See all the sheep that were on the premise at the same time as the sheep you searched for
        </SectionDescription>
        <SectionTableContainer>
          {samePremiseSheep.length > 0 ?
            <StripedTable
              columns={sheepTableColumns}
              rows={samePremiseSheep}
              max={10}
              paginate
            />
            : (<>
                {loaded ? (<>
                    <h4>
                      No records of other sheep being on the same premise during this time
                    </h4>
                  </>) : 
                  (<>Loading ...</>)
                }
              </>)
          }
        </SectionTableContainer>
      </SimpleCard>

      <ButtonContainer>
        <Link to="/" push>
          <button style={{ background: agGreen }}>Return to Home Page</button>
        </Link>
        <Link to="/trace" push>
          <button style={{ background: viewColor }}>Return to Trace A Sheep Dashboard</button>
        </Link>
      </ButtonContainer>
    </DetailContainer>
  );
}

const DetailContainer = styled.div`
  & > div {
    margin: 1rem auto 2rem;
  }
`;

const InfoDiv = styled.div`
  font-size: 1.2rem;
  margin: 0.7em;
`;

const FieldSpan = styled.span`
  display: inline-block;
  width: 30ch;
  font-weight: 500;
`;

const ValueSpan = styled.span`
  font-style: italic;
`;

const ButtonContainer = styled.div`
  margin: 0 auto;
  width: max-content;
  
  button {
    margin: 0 1em;
    border: none;
    border-radius: 8px;
    padding: 0.7em 1.2em;
    color: white;
    font-size: 1.15rem;
    cursor: pointer;
  }
`;

const AliveText = styled.div`
  background-color: green;
  border-radius: 8px;
  padding: 0.5em;
  width: max-content;
  display: inline-block;
`;

const DeadText = styled.div`
  background-color: rgb(211, 0, 0);
  border-radius: 8px;
  padding: 0.5em;
  width: max-content;
  display: inline-block;
`;

const SectionDescription = styled.div`
  margin: 1em;
  font-size: 1.1rem;
`;

const SectionTableContainer = styled.div`
  width: 100ch;
  margin: 1em auto 5em;

  h4 {
    padding: 1em;
    text-align: center;
    font-size: 1.5rem;
    border-radius: 4px;
    background-color: rgb(255, 88, 88);
  }
`;