import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	FormContainer,
  Button,
  FormTextInput,
  FormInputWrapper,
  FormInputLabel,
} from '../../components/CommonComponents.js';
import FormCard from '../../components/FormCard.js';
import { useParams, Redirect } from 'react-router-dom';
import { getUser } from '../../utils/UserUtils';
import phone from 'phone';
import { getUsername } from '../../utils/TokenUtils';

const validateInput = (form, number, orgNumber) => {
  var error = null;
  if(number == undefined && !phone(form.newNumber).isValid){
    error = 'Your new phone number must be in a valid format';
  } else {
       if(!phone(form.newNumber).isValid)
            error = 'Your new phone number must be in a valid format';
        else if(form.newNumber == number || form.newNumber == orgNumber)
            error = 'Your new phone number cannot be the same as your old one';
  }

  return error;
}

const validateForm = (form) => {
  if (form == undefined){
    return false;
  }

  return true;
}

//Submitting spinners
const SubmittingDiv = styled.div`
  text-align: center
`;

const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin>
    </FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

const EditNumber = () => {
  const [submitting, setSubmitting] = useState(false);
  const [verifyUser, setVerifyUser] = useState(false);
  const [form, setForm] = useState();
  const [redirect, setRedirect] = useState('');
  const [user, setUser] = useState();
  const params = useParams();
  const username = params.username;

	const fetchCurrentRole = async () => {
		const temp = await axios.get('/api/users/role');
		return temp;
	}

	const fetchUserName = async () => {
		let name = await getUsername();
		let role = await fetchCurrentRole();
	
		if(username == name || role.data == 'admin')
			setVerifyUser(true);
	}

  const fetchUser = async() => {
    let u = await getUser(username);
    let transformedUser;

    if(u.phone != undefined)
        transformedUser = {
            orgNumber: u.phone,
            number: u.phone.slice(-10)
        };
    else
        transformedUser = {
            number: u.phone
        };

    setUser(transformedUser);
}

useEffect(() => {
    fetchUserName();
    fetchUser();
}, []);

  const handleSubmit = async(e) => {
    e.preventDefault();

    var err = validateInput(form, user.number, user.orgNumber);

    const send = {
        newNumber: form.newNumber
    };
    
    if(err == null){
      try {
        await axios.put(`/api/users/${username}/number`, send);

        toast.success('Your phone number has been updated');
        setRedirect(`/users/${username}`);
      } catch (err) {
          if (err.message == "Request failed with status code 400"){
            toast.error(`An error has occurred: Information is wrong or already exist, please refresh and try again.`)
          } else {
            toast.error(`${err.message}`)
          }
          setSubmitting(false);
      }
    }
    else{
      toast.error(err);
    }
  }

  if(redirect !== ''){
    return <Redirect to={redirect} />;
  }

  return <>
    <FormCard title={<> <>Edit Phone Number</> </>} back >
        <FormContainer onSubmit={handleSubmit} >
             {user!= undefined ? (<>   
                {verifyUser ? (<> 
                    <FormInputWrapper>
                    <FormInputLabel htmlFor = 'input'>
                        New Phone Number
                    </FormInputLabel>
                    <FormTextInput
                        type = 'tel'
                        placeholder = '3073073074'
                        id = 'input'
                        name = 'newNumber'

                        onChange={(e) => {
                        if (e.target.value === '') {
                            // eslint-disable-next-line no-unused-vars
                            const { ['newNumber']: _, ...updatedState } = form;
                            setForm(updatedState);
                        } else setForm({ ...form, [e.target.name]: e.target.value });
                        }}
                    />
                    </FormInputWrapper>

                    <Button disabled = {!validateForm(form)}>Submit</Button>
                    {(submitting) ? <SubmittingSpinner />: <></>}
                
                </>) : <>
                  <NoPermissionBlock>
                    You do not have permission to edit this profile.
                </NoPermissionBlock>
                </>}          
            </>) : (<>Loading...</>)}
        </FormContainer>
		</FormCard>
   </>
};

const NoPermissionBlock = styled.div`
  width: max-content;
  margin: 3em auto;
  font-size: 17px;
  padding: 1.5em 3em;
  border-radius: 5px;
  border: 2px solid white;
  background-color: rgb(255, 88, 88);
`;


//export
export default EditNumber;