// SimpleCard.js
import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { agGreen } from './Colors.js';

const Title = styled.span`
  font-size: ${props => props.size};
  font-weight: bold;
  margin-bottom: 0px;
  display: inline-block
`;

const Line = styled.hr`
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
`

const Description = styled.div`
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
`

const SimpleCardDiv = styled.div`
  margin: 10px;
`
const CreateSpan = styled.span`
	margin-left: 10px;
`;

const CreateButton = ({ to }) => (
	<CreateSpan>
		<Link to={to}>
			<FontAwesomeIcon icon={faPlusCircle} size='2x' color={agGreen} />
		</Link>
	</CreateSpan>
);

CreateButton.propTypes = {
  to: propTypes.any
}

const SimpleCard = ({ title, size, description, children, to }) => {
  let titleSize = '2em';
  if (size) {
    switch (size) {
      case 'xl':
        titleSize = '2.5rem';
        break;
      case 'lg':
        titleSize = '2rem';
        break;
      case 'md':
        titleSize = '1.5rem';
        break;
      case 'sm':
        titleSize = '1rem';
        break;
      default:
    }
  }
  return <SimpleCardDiv>
    {/* Title */}
    <Title size={titleSize}>{title}</Title>
    {to && <CreateButton to={to} />}
    {/* Line */}
    <Line />
    {/* Description */}
    <Description>{description}</Description>
    {/* Children */}
    <>{children}</>
  </SimpleCardDiv>
};

SimpleCard.propTypes = {
  title: propTypes.any,
  size: propTypes.any,
  description: propTypes.any,
  children: propTypes.any,
  to: propTypes.any,
};

export default SimpleCard;