import React, { useState, useEffect } from 'react';
import PageCard from '../../components/PageCard';
import SimpleCard from '../../components/SimpleCard';
import { useParams } from 'react-router';
import ViewEditDispose from '../../components/ViewEditDispose';
import axios from 'axios';
import { sortReportsByDate } from '../../components/SheepReportComponents';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { StyledTable } from '../../components/TableComponents.js';
import { EditButton } from '../../components/CommonComponents.js';

const getReplaceReports = (id) => {

  return axios.get(`/api/sheep/tag/replace/${id}`)
    .then((res) => {
      return res.data;  
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

const replaceFields = [
  { name: 'Old Identifiers', id: 'oldIsoNumber', localMan: 'oldLocalMgmtNumber', tattoo: 'oldTattooNumber', Scrapie: 'oldUsScrapieId'},
  { name: 'New Identifiers', id: 'newIsoNumber', localMan: 'newLocalMgmtNumber', tattoo: 'newTattooNumber', Scrapie: 'newUsScrapieId'},
]

const TagRetireDetailView = () => {
  const [replaceReport, setReplaceReport] = useState([]);
  const params = useParams();
  const id = params.id;

  const fetchReplaceReport = async () => {
    let fetchedReport = await getReplaceReports(id);
    fetchedReport.forEach(r => {
      r.actions = <ViewEditDispose 
        id={r._id} 
        endpoint={'/reports/replace/'}
        hideDispose
        hideEdit
        hideReplace
      />;
      r.reportDate = (new Date(r.reportDate)).toISOString().split('T')[0];

      if (
        r.location &&
        r.location.coordinates &&
        r.location.coordinates.length === 2
      ) {
        r.location = r.location.coordinates[1] + ", " + r.location.coordinates[0];
      } else {
        r.location = "";
      }
    });
    setReplaceReport(sortReportsByDate(fetchedReport));
  }

  useEffect(() => {
    fetchReplaceReport();
  }, []);

  if (replaceReport[0]){
    if(!replaceReport[0].oldIsoNumber) replaceReport[0].oldIsoNumber = 'N/A';
    if(!replaceReport[0].newIsoNumber) replaceReport[0].newIsoNumber = 'N/A';

    if(!replaceReport[0].oldLocalMgmtNumber) replaceReport[0].oldLocalMgmtNumber = 'N/A';
    if(!replaceReport[0].newLocalMgmtNumber) replaceReport[0].newLocalMgmtNumber = 'N/A';

    if(!replaceReport[0].oldTattooNumber) replaceReport[0].oldTattooNumber = 'N/A';
    if(!replaceReport[0].newTattooNumber) replaceReport[0].newTattooNumber = 'N/A';

    if(!replaceReport[0].oldUsScrapieId) replaceReport[0].oldUsScrapieId = 'N/A';
    if(!replaceReport[0].newUsScrapieId) replaceReport[0].newUsScrapieId = 'N/A';
  }

  return <>
    <PageCard title=" Tag Replacement Report Details" verify={`/verify/replace/${id}`} line back>
      
      <SimpleCard title = 'Tag Information' size="md">
        <DetailTable
            data={replaceReport[0]}
            fields={replaceFields}
        />
      </SimpleCard>

      {(replaceReport[0]) ? <>
        <SimpleCard title="Report Information" size="md">
          <HalfDiv>
            <Info 
              name="Replacement Date" 
              value={(new Date(replaceReport[0].replacementDate)).toISOString().split('T')[0]} 
            />
            <Info 
              name="PID" 
              value={replaceReport[0].pid ? replaceReport[0].pid : 'N/A'} 
            />
          </HalfDiv>
          <HalfDiv>
            {replaceReport[0].location  &&
            <Info name='Geolocation (Latitude, Longitude)' value={replaceReport[0].location} />}
          </HalfDiv>
        </SimpleCard>
      </>: <>Loading</>}
    
    </PageCard>
  </>
};

const ButtonsDiv = styled.div`
	display: flex;
	justify-content: space-between;
`;

const DetailTable = ({data, fields, buttons}) => {
	return <>
		<StyledTable style={{marginTop: '10px', tableLayout: 'fixed'}} >
			<thead>
				<tr>
					<th>Parameters</th>
					<th>ISO Number</th>
          <th>Local Management Num</th>
          <th>Tattoo Number</th>
          <th>US Scrapie ID</th>
				</tr>
			</thead>
			<tbody>
				{fields.map((field) => <>
					<tr>
						<td>{field.name}</td>
						<td>{(data) ? (data[field.id]) ? data[field.id].toString() : '' : ''}</td>
            <td>{(data) ? (data[field.localMan]) ? data[field.localMan].toString() : '' : ''}</td>
            <td>{(data) ? (data[field.tattoo]) ? data[field.tattoo].toString() : '' : ''}</td>
            <td>{(data) ? (data[field.Scrapie]) ? data[field.Scrapie].toString() : '' : ''}</td>
					</tr>
				</>)}
				<tr>
					<td colSpan='2'>
						<ButtonsDiv>
							{(buttons && buttons.length > 0) ? buttons.map((button) => <>
								<EditButton bColor={button.color} onClick={button.onClick}>{button.name}</EditButton>
							</>) : <></>}
						</ButtonsDiv>
					</td>
				</tr>
			</tbody>
		</StyledTable>
	</>
}

DetailTable.propTypes = {
  data: propTypes.node,
  fields: propTypes.node,
  buttons: propTypes.node
};

const Info = ({name, value}) => {
  return <div>
    <span>{name}:&nbsp;</span><span>{value}</span>
  </div>;
}

Info.propTypes = {
  name: propTypes.string,
  value: propTypes.any
}

const HalfDiv = styled.div`
  width: 50%;
  float: left;
  display: inline-block;
  margin-bottom: 20px;
`

export default TagRetireDetailView;