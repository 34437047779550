import React, { useState, useEffect } from 'react';
import PageCard from '../components/PageCard';
import SimpleCard from '../components/SimpleCard';
import StripedTable from '../components/StripedTable';
import styled from 'styled-components';
import { getOperationUpdateLogs } from '../utils/OperationUtils';

const columns = [
  {
    id: 'businessName',
    name: 'Business Name'
  },
  {
    id: 'newBusinessName',
    name: 'New Business Name'
  },
  {
    id: 'phoneNumber',
    name: 'Phone Number'
  },
  {
    id: 'newPhoneNumber',
    name: 'New Phone Number'
  },
  {
    id: 'operationType',
    name: "Type"
  },
  {
    id: 'newOperationType',
    name: "New Type"
  },
  {
    id: 'cvsp',
    name: 'CVSP'
  },
  {
    id: 'newCvsp',
    name: 'New CVSP'
  },
  {
    id: 'sfcp',
    name: 'SFCP'
  },
  {
    id: 'newSfcp',
    name: 'New SFCP'
  }
];

let tableLogs = [];

const OperationUpdateReportView = () => {
  const [load, setLoad] = useState(false);
  
  useEffect(() => {
    setLoad(true);

    //convert bools in updateLogs to strings so they display properly
    getOperationUpdateLogs().then((updateLogs) => {
      tableLogs = [];

      updateLogs.forEach(function(log){
        let modifiedLogs = {
          businessName: log.businessName,
          newBusinessName: log.newBusinessName,
          phoneNumber: log.phoneNumber,
          newPhoneNumber: log.newPhoneNumber,
          operationType: log.operationType,
          newOperationType: log.newOperationType
        }

        if (log.cvsp) {modifiedLogs.cvsp = 'Yes'}
        else {modifiedLogs.cvsp = 'No'}

        if (log.newCvsp) {modifiedLogs.newCvsp = 'Yes'}
        else {modifiedLogs.newCvsp = 'No'}

        if (log.sfcp) { modifiedLogs.sfcp = 'Yes'}
        else {modifiedLogs.sfcp = 'No'}
        
        if (log.newSfcp) {modifiedLogs.newSfcp = 'Yes'}
        else { modifiedLogs.newSfcp = 'No'}

        tableLogs.push(modifiedLogs);
      })
      setLoad(false);
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  return <>
    <PageCard title="View Operation Update Reports" line back>
      <Description>
        <div>Operation update reports contain information about operations that have had their information modified.</div>
      </Description>
      {load ? (
				<>Loading...</>
			) : (
				<>
					<SimpleCard>
            <StripedTable 
              columns={columns}
              rows={tableLogs}
              ShowCount
              max={10}
              paginate
            />
          </SimpleCard>
				</>
			)}
    </PageCard>
  </>
};

const Description = styled.div`
  margin-bottom: 50px;
  line-height: 25px;
`;

export default OperationUpdateReportView;