//SheepReplaceViews
//Imports
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	FormContainer,
  Button,
  FormTextInput,
  FormInputWrapper,
  FormInputLabel,
  FormParagraphInput
} from '../components/CommonComponents.js';
import Input from '../components/Input.js';
import FormCard from '../components/FormCard.js';
import { Redirect, useLocation} from 'react-router-dom';

const validateForm = (form) => {
  if (!form){
    return false;
  } 

  if (form.data == undefined){
    return false;
  }

  return true;
}

//Submitting spinners
const SubmittingDiv = styled.div`
  text-align: center
`;
const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin>
    </FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

const FeedbackView = () => {
  const [submitting, setSubmitting] = useState(false);
  const [form, setForm] = useState();
  const [redirect, setRedirect] = useState('');
  
  //const, list all the form inputs
  const formInputs = [
		{
			name: 'Provide Feedback',
			hint: "Please give us suggestions for improvements.",
			id: 'data',
		},
  ]


  const handleSubmit = async(e) => {
    e.preventDefault();

    try {
      await axios.post('/api/feedback', form);

      toast.success('Suggestions submitted!');
      setRedirect(`/`);
    } catch (err) {
        if (err.message == "Request failed with status code 400"){
          toast.error(`An error has occurred: Information is wrong or already exist, please refresh and try again.`)
        } else {
          toast.error(`An error has occurred: ${err.message}.`)
        }
        setSubmitting(false);
    }
  }

  if(redirect !== ''){
    return <Redirect to={redirect} />;
  }

  return <>
    <FormCard 
    title={<>
      <>Please provide any suggestion </>
    </>}
    back
    >
			<FormContainer onSubmit={handleSubmit} >
        {formInputs.map(i => <>
          <FormInputWrapper>
            <FormInputLabel htmlFor='input'>
              {i.name}
            </FormInputLabel>
            <FormParagraphInput
                disabled={i.unique && form.disabled}
                placeholder={i.hint}
                type={i.type ? i.type : 'text'}
                id='input'
                name={i.id}
    
                onChange={(e) => {
                  if (e.target.value === '') {
                    // eslint-disable-next-line no-unused-vars
                    const { [i.id]: _, ...updatedState } = form;
                    setForm(updatedState);
                  } else setForm({ ...form, [e.target.name]: e.target.value });
                }}
              />
            </FormInputWrapper>
        </>)}
				<Button disabled = {!validateForm(form)} >Submit</Button>
        {(submitting) ? <SubmittingSpinner />: <></>}
			</FormContainer>
		</FormCard>
   </>
};

//export
export default FeedbackView;