// RoleAddView
import React, {useState, useEffect} from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { 
    FormInputWrapper,
    FormContainer, 
    FormInputLabel, 
    FormSelectInput,
    FormTextList,
    Button
} from '../../components/CommonComponents';
import FormCard from '../../components/FormCard';
import axios from 'axios';

const getRoleTypes = async () => {
    return await axios.get('/api/roleTypes');
}

const RoleAddView = () => {
    
    const params = useParams();
    const username = params.username;
    const [form, setForm] = useState({
        username,
        roleType: ''
    });
    const [roleTypes, setRoleTypes] = useState([]);
    const [redirect, setRedirect] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const allRoles = await axios.get(`api/roles/${username}`);

            for (var i = 0; i < allRoles.data.length; i ++){
                if (form.roleType == allRoles.data[i].roleType){
                    toast.error("Error: duplicated role, please choose another one");
                    return{}
                }
               
            }
            await axios.post('/api/roles', form);
            toast.success('Role added successfully!');
            
            setRedirect(`/roles/${username}`);
        } catch (err) {
            if (err.message == 'Request failed with status code 403') {
                toast.error(`An error occurred: permission denied`); 
            } else {
                toast.error(`An error occurred: ${err}`);   
            }
            
        }

    }

    useEffect(() => {
        (async () => {
            const r = await getRoleTypes();
            setRoleTypes(r.data);
        })();
    }, [])

    if(redirect !== '') return <Redirect to={redirect} />;
    return <>
        {(username) ? <><FormCard title={`Add role for ${username}`} back>
            <FormContainer onSubmit={handleSubmit}>
                <FormInputWrapper>
                    <FormInputLabel htmlFor='input'>Role</FormInputLabel>
                    <FormTextList>
                        <FormSelectInput
                            name='roleType'
                            value={form['roleType']}               
                            onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value})}>
                                <option hidden>Select one...</option>
                                {roleTypes.map(v => {
                                    return <option key={v.name} value={v.name}>{v.niceName}</option>
                                })}                        
                        </FormSelectInput>
                    </FormTextList>
                </FormInputWrapper>
                <Button>Submit</Button>
            </FormContainer>
        </FormCard></> : <>Loading...</>}
    </>
}

export default RoleAddView;