import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import {
	FormContainer,
  Button,
  FormTextInput,
  FormInputWrapper,
  FormInputLabel,
  FormPassPeek,
  FormPasswordContainer
} from '../../components/CommonComponents.js';
import FormCard from '../../components/FormCard.js';
import { useParams, Redirect } from 'react-router-dom';
import { getUser } from '../../utils/UserUtils';
import { getUsername } from '../../utils/TokenUtils';

const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,24}$/;

const validateInput = (form) => {
  var error = null;

  if(!form.oldPassword || !form.newPassword || !form.reNewPassword)
    error = 'Please fill in all fields';
  else if(form.oldPassword == form.newPassword)
    error = 'Your new and old passwords cannot be the same';
  else if(!passRegex.test(form.newPassword))
    error = 'Your new password does not meet complexity requirements';
  else if(form.newPassword != form.reNewPassword)
    error = 'Your new password fields do not match';

  return error;
}

const validateForm = (form) => {
  if (form == undefined){
    return false;
  }

  return true;
}

//Submitting spinners
const SubmittingDiv = styled.div`
  text-align: center
`;

const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin>
    </FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

const EditPassword = () => {
  const [submitting, setSubmitting] = useState(false);
  const [verifyUser, setVerifyUser] = useState(false);
  const [form, setForm] = useState();
  const [redirect, setRedirect] = useState('');
  const [user, setUser] = useState();
  const [peek, setPeek] = useState(false);
  const params = useParams();
  const username = params.username;

  const fetchCurrentRole = async () => {
    const temp = await axios.get('/api/users/role');
    return temp;
}

const fetchUserName = async () => {
    let name = await getUsername();
    let role = await fetchCurrentRole();

    if(username == name || role.data == 'admin')
        setVerifyUser(true);
}

  const fetchUser = async() => {
    let u = await getUser(username);
    setUser(u);
  } 

    useEffect(() => {
        fetchUserName();
        fetchUser();
    }, []);

  const handleSubmit = async(e) => {
    e.preventDefault();

    var err = validateInput(form);

    const send = {
        oldPassword: form.oldPassword,
        newPassword: form.newPassword,
    };
    
    if(err == null){
      try {
        await axios.put(`api/users/${username}/password`, send);
        toast.success('Your password has been updated');
        setRedirect(`/users/${username}`);

      } catch (err) {
          if (err.message == "Request failed with status code 400"){
            toast.error(`Your old password is incorrect`)
          } else {
            toast.error(`${err.message}`)
          }
          setSubmitting(false);
      }
    }
    else{
      toast.error(err);
    }
  }

  if(redirect !== ''){
    return <Redirect to={redirect} />;
  }

  return <>
    <FormCard title={<> <>Edit Password</> </>} back >
        <FormContainer onSubmit={handleSubmit} >
             {user!= undefined ? (<>   
                {verifyUser ? (<>
                    <FormInputWrapper>
                        <FormInputLabel htmlFor = 'input'>
                            Old Password
                        </FormInputLabel>
                        <FormPasswordContainer>
                            <FormTextInput
                                type = 'password'
                                id = 'input'
                                name = 'oldPassword'
                                type={peek ? 'text' : 'password'}
                                onChange={(e) => {
                                if (e.target.value === '') {
                                    // eslint-disable-next-line no-unused-vars
                                    const { ['oldPassword']: _, ...updatedState } = form;
                                    setForm(updatedState);
                                } else setForm({ ...form, [e.target.name]: e.target.value });
                                }}
                            />
                            <FormPassPeek onClick={() => setPeek(!peek)}>
                                <FontAwesomeIcon icon={peek ? faEyeSlash : faEye} color='gray' />
                            </FormPassPeek>
                        </FormPasswordContainer>
                    </FormInputWrapper>
    
                    <FormInputWrapper>
                        <FormInputLabel htmlFor = 'input'>
                            New Password
                        </FormInputLabel>
                        <FormPasswordContainer>
                            <FormTextInput
                                type = 'password'
                                id = 'input'
                                name = 'newPassword'
                                type={peek ? 'text' : 'password'}
                                onChange={(e) => {
                                if (e.target.value === '') {
                                    // eslint-disable-next-line no-unused-vars
                                    const { ['newPassword']: _, ...updatedState } = form;
                                    setForm(updatedState);
                                } else setForm({ ...form, [e.target.name]: e.target.value });
                                }}
                            />
                            <FormPassPeek onClick={() => setPeek(!peek)}>
                                <FontAwesomeIcon icon={peek ? faEyeSlash : faEye} color='gray' />
                            </FormPassPeek>
                        </FormPasswordContainer>
                    </FormInputWrapper>       

                    <FormInputWrapper>
                        <FormInputLabel htmlFor = 'input'>
                            Re-enter New Password
                        </FormInputLabel>
                        <FormPasswordContainer>
                            <FormTextInput
                                type = 'password'
                                id = 'input'
                                name = 'reNewPassword'
                                type={peek ? 'text' : 'password'}
                                onChange={(e) => {
                                if (e.target.value === '') {
                                    // eslint-disable-next-line no-unused-vars
                                    const { ['reNewPassword']: _, ...updatedState } = form;
                                    setForm(updatedState);
                                } else setForm({ ...form, [e.target.name]: e.target.value });
                                }}
                            />
                            <FormPassPeek onClick={() => setPeek(!peek)}>
                                <FontAwesomeIcon icon={peek ? faEyeSlash : faEye} color='gray' />
                            </FormPassPeek>
                        </FormPasswordContainer>
                    </FormInputWrapper>       

                    <FormHint>Passwords must be between 8 and 24 characters with at least one number and at least one lower and uppercase letter</FormHint>     

                    <Button disabled = {!validateForm(form)}>Submit</Button>
                    {(submitting) ? <SubmittingSpinner />: <></>}
                </>) : (<>
                    <NoPermissionBlock>
                        You do not have permission to edit this profile.
                    </NoPermissionBlock>
                </>)}                
            </>) : (<>Loading...</>)}
        </FormContainer>
		</FormCard>
   </>
};

const FormHint = styled.label`
margin-top: 5px;
color: orange;
font-size: 12px;
padding: 0px 20px 20px 20px;
`;

const NoPermissionBlock = styled.div`
  width: max-content;
  margin: 3em auto;
  font-size: 17px;
  padding: 1.5em 3em;
  border-radius: 5px;
  border: 2px solid white;
  background-color: rgb(255, 88, 88);
`;

//export
export default EditPassword;