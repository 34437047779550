import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HelpDashboard from '../views/HelpDashboard';
import AnimalTaggingDecisionView from '../views/Sheep/AnimalTaggingDecisionView';
import AnimalDeathDecisionView from '../views/Sheep/AnimalDeathDecisionView';
import LeavePremiseDecisionView from '../views/LeavePremiseDecisionView';
import ReceivePremiseDecisionView from '../views/ReceivePremiseDecisionView';
import AnimalMoveDecisionView from '../views/MoveOut/AnimalMoveDecisionView';
import ImportDecisionView from '../views/Import/ImportDecisionView';
import ExportDecisionView from '../views/Export/ExportDecisionView';

const HelpRouter = () => (
  <Switch>
    <Route exact path='/help' component={HelpDashboard} />
    <Route exact path='/help/animaltagging' component={AnimalTaggingDecisionView} />
    <Route exact path='/help/animaldeath' component={AnimalDeathDecisionView} />
    <Route exact path='/help/leavepremise' component={LeavePremiseDecisionView} />
    <Route exact path='/help/receivepremise' component={ReceivePremiseDecisionView} />
    <Route exact path='/help/animalmove' component={AnimalMoveDecisionView} />
    <Route exact path='/help/import' component={ImportDecisionView} />
    <Route exact path='/help/export' component={ExportDecisionView} />
  </Switch>
);

export default HelpRouter;