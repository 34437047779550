import React, { useState } from 'react';
import FormCard from '../../components/FormCard.js';
import styled from 'styled-components';
import { parseHeaders } from '../../utils/CSVUtils';
import TableComponent from '../../components/TableComponent';
import {
	FormContainer,
	FormInputWrapper,
	FormInputLabel,
	FormSelectInput,
	Text,
	Button,
} from '../../components/CommonComponents';
import ImportSheep from './ImportSheep';

export default function ImportEventHeaders() {
	const [preview, setPreview] = useState([]);
	const fields = [
		{ name: 'CSIP Number:', id: 'isonum' },
		{ name: 'Date:', id: 'date' },
	];
	const [headers, setHeaders] = useState({});
	const [file, setFile] = useState();
	const [next, setNext] = useState(false);

	const fileUpload = (e) => {
		setFile(e.target.files[0]);
		parseHeaders(e.target.files[0], (results) =>
			setPreview([
				Object.keys(results.data[0]).map((x) => {
					return { name: x, id: x };
				}),
				results.data[0],
			])
		);
	};

	return (
		<>
			{next ? (
				<ImportSheep file={file} headers={headers} />
			) : (
				<FormCard title='Add Imported Sheep' back>
					<FileContainer>
						<input type='file' accept='.csv' onChange={fileUpload} />
					</FileContainer>
					{preview.length > 0 && (
						<PreviewContainer>
							<Title>Preview:</Title>
							<TableComponent headings={preview[0]} data={[preview[1]]} />

							<Title>Required Headers:</Title>
							<HeaderForm>
								{fields.map((element) => (
									<FormInputWrapper key={element.id}>
										<FormInputLabel>{element.name}</FormInputLabel>
										<FormSelectInput
											value={headers[element.id]}
											onChange={(e) =>
												setHeaders({
													...headers,
													[element.id]: e.target.value,
												})
											}
										>
											<option hidden>Select</option>
											{preview &&
												preview[0].map((option) => (
													<option key={option.id} value={option.name}>
														{option.name}
													</option>
												))}
										</FormSelectInput>
									</FormInputWrapper>
								))}
								<FormInputWrapper>
									<FormInputLabel>Date Format:</FormInputLabel>
									<FormSelectInput
										value={headers.dateFormat}
										onChange={(e) =>
											setHeaders({
												...headers,
												dateFormat: e.target.value,
											})
										}
									>
										<option hidden>Select</option>
										<option>DD/MM/YYYY</option>
										<option>MM/DD/YYYY</option>
									</FormSelectInput>
								</FormInputWrapper>
								<SubmitButton
									type='submit'
									onClick={(e) => {
										e.preventDefault();
										setNext(true);
									}}
									disabled={Object.keys(headers).length !== 3}
								>
									Select Headers
								</SubmitButton>
							</HeaderForm>
						</PreviewContainer>
					)}
				</FormCard>
			)}
		</>
	);
}

const FileContainer = styled.div`
	width: 500px;
	display: flex;
	padding: 20px 20px 10px;
	color: white;
`;

const PreviewContainer = styled.div`
	margin: 0 20px 10px;
`;

const HeaderForm = styled(FormContainer)`
	height: fit-content;
	max-width: 500px;
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;

	> div {
		flex-basis: 40%;
	}
`;

const SubmitButton = styled(Button)`
	margin: auto;
`;

const Title = styled(Text)`
	color: white;
	font-size: 25px;
	text-align: left;
	margin: 10px 0;
`;
