// RoleRouter.js
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RoleDetailView from '../views/User/RoleDetailView';
import RoleAddView from '../views/User/RoleAddView';

const RoleRouter = () => <>
  <Switch>
    <Route
      exact
      path='/roles/:username' component={RoleDetailView}
    />
    <Route
      exact
      path='/roles/:username/add' component={RoleAddView}
    />
  </Switch>
</>

export default RoleRouter;