import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import FormCard from '../../components/FormCard.js';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import LoadingCard from '../../components/LoadingCard.js';
import PageCard from '../../components/PageCard.js';
import { agGreen } from '../../components/Colors.js';
import Input from '../../components/Input.js';
import DateField from '../../components/DateField.js';
import GeolocationInput from '../../components/GeolocationInput';
import styled from 'styled-components';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  Button,
  FormInputWrapper,
  FormInputLabel,
  FormTextInput,
} from '../../components/CommonComponents.js';
import SimpleCard from '../../components/SimpleCard';
import { Link } from 'react-router-dom';
import { getAllStateAbbrv } from '../../utils/USAStates';
import { getUsername } from '../../utils/TokenUtils';
import { validatePremiseExists } from '../../utils/PremiseUtils';

const CsvCreateComponent = () => {
  return <>
    <SimpleCard
      title="CSV Creation"
      description="Use a CSV to create a report" 
    >
      <CsvButtonContainer>
        <Link to="/reports/sighting/csvCreate">
          <CsvButton>Create report from a csv</CsvButton>
        </Link>
      </CsvButtonContainer>
    </SimpleCard>
  </>
}

const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin></FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

const validateScraipeID = (scrapieId) => {
  const usStates = getAllStateAbbrv();
  return /^[A-Z]{2}\d{7}$/.test(scrapieId) && usStates.includes(scrapieId.substring(0, 2));
}

const checkForSpecial = (text) => {
  var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  if(format.test(text)){
    return true;
  } else {
    return false;
  }
}

const validateIsoNumber = (iso) => {
  return /^124000\d{9}$/.test(iso) || /^840000\d{9}$/.test(iso);
}

const validate = (form) => {
  const today = new Date();
  if(form.isoNumber  && !validateIsoNumber(form.isoNumber)){
    toast.error("Error: incorrect ISO number format");
    return false
  } 

  if(form.usScrapieId  && !validateScraipeID(form.usScrapieId)){
    toast.error("Error: incorrect US Scrapie ID format");
    return false
  } 
  
  if (form.tattooNumber){
    if (checkForSpecial(form.tattooNumber) ){
      toast.error("Error! incorrect tattoo number format");
      return false
    }
  }

  if (form.localMgmtNumber){
    if (checkForSpecial(form.localMgmtNumber) ){
      toast.error("Error! incorrect local management number format");
      return false
    }
  }

  if (form.date > today){
    toast.error("Error, please select a valid date");
    return false;
  } 

  if (!form.reportLocation){
    toast.error("Error: premise missing");
    return false
  }
  return true;
}

const createSightingReport = (data) => {
  return axios.post('/api/animalsighting', data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

const fetchPremises = async() => {
  try {
    const username = getUsername();
    const request = await axios.get(`/api/premises/user/${username}`);
    const fetchedPremises = request.data;
    return fetchedPremises;
  } catch(err) {
    throw new Error(`An error occurred fetching premises: ${err.message}`, err);
  }
}

const SightingReportCreateView = () => {
  const [load, setLoad] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [premises, setPremises] = useState([]);
  const [confirmPID, setConfirmPID] = useState(false);

  //form information that is used in the move log
  const [form, setForm] = useState({
    date: new Date(),
    location: "Manual Input",
  });

  useEffect(() => {
    fetchAndSetPremises();
    setLoad(false);
  }, []);

  const fetchAndSetPremises = async () => {
    const fetchedPremises = await fetchPremises();
    setPremises(fetchedPremises);
  }

  //text input for tags
  const isoNumberInput = [
		{
			name: 'Iso Number',
			hint: "Start with 124 for Canada, 840 for US. Followed by 12 digits.",
			id: 'isoNumber',
		}
  ]
  const usScrapieIdInput = [
    {
      name: 'US Scrapie Id',
			hint: 'US state abbreviation, followed by 7 digits',
			id: 'usScrapieId'
    }
  ]
  const localMgmtNumberInput = [
    {
      name: 'Local Management Number',
			id: 'localMgmtNumber'
    }
  ]
  const tattooNumberInput = [
    {
      name: 'Tattoo Number',
			id: 'tattooNumber'
    }
  ]
  const reporterInput = [
    {
      name: 'Reporter of Sighting',
			id: 'reporterName'
    }
  ]

  const dateField = {
    labelName: 'Date Sighted',
    id: 'date',
    onChange: (date) => dateChange(date, 'date'),
    value: form.date
  }

  const dateChange = (date, fieldName) => {
    setForm({
      ...form,
      [fieldName]: date
    })
  };

  //check that required form values are present
  const Enabled = (form) => {
    if (!submitting && 
      (form.isoNumber || form.tattooNumber || form.localMgmtNumber) &&
      form.reporterName &&
      form.location) {
       return true
    } else {
      return false;
    }
  };

  const createReport = () => {
    setSubmitting(true);
    toast.info("Submitting Report to the back end, please wait 30-60 seconds and you will be redirected");
    createSightingReport({
      isoNumber: form.isoNumber,
      localMgmtNumber: form.localMgmtNumber,
      tattooNumber: form.tattooNumber,
      usScrapieId: form.usScrapieId,
      location: form.reportLocation,
      reporterName: form.reporterName,
      date: form.date,
      geolocation: form.geolocation
    }).then(() =>{
      toast.success("Success!");
      setSubmitting(false);
      setRedirect(true);
    }).catch ((err) => {
      toast.error(err.response.data.error);
      setSubmitting(false);
    });
  }

  const handleSubmit = async() => {
    //set manual or selected PID
    if (!form.location || form.location === "Manual Input"){
      form.reportLocation = form.manualPid;
    } else {
      form.reportLocation = form.location;
    }
    
    if(confirmPID){
      if(!form.manualPid){
        toast.error("Error: premise missing");
        return;
      }
      form.reportLocation = form.manualPid;
      createReport();
    }
    else if (validate(form))
      if (form.location !== "Manual Input"){
      createReport();
      }
      else{
      //if the manually entered PID matches an existing premise ID, submit
      if(await validatePremiseExists(form.reportLocation)){
        createReport();
      }
      //show warning page if PID isn't in the system
      else{
        setConfirmPID(true);
      }
    }
  }

  const setGeolocation = () => {
		const onSuccess = (position) => {
			const location = [position.coords.longitude, position.coords.latitude];
			setForm({ ...form, geolocation: location });
		};

		const onError = () => {
			toast.info("Could not get the geolocation of your device");
		}

		if (!navigator.geolocation) {
			toast.info("Geolocation is not supported by this browser");
		} else {
			navigator.geolocation.getCurrentPosition(onSuccess, onError);
		}
	};

	const clearLocation = () => {
		setForm({ ...form, geolocation: null });
	}

  if (redirect) return <Redirect to='/reports/sighting/listView'/>
  return <>
    {!confirmPID ?
    <>
		<ReportContainer>
        {!load ? (
				<FormCard title={<>Create a sighting report</>} back>
          <CsvCreateComponent/>
          <SimpleCard 
            title="Tag Information"
            description="Enter the tag information of the spotted sheep. Note that at least one of the following elements must be provided: Iso Number, Local Management Number or Tattoo Number"
          ></SimpleCard>

          {isoNumberInput.map(i => <>
            <Input
              id={i.id}
              placeholder={i.hint}
              labelName={i.name}
              onChange={(e) => setForm({
                ...form,
                isoNumber: e.target.value
              })}
              value={form[i.id]}
            />
          </>)}

          {localMgmtNumberInput.map(i => <>
            <Input
              id={i.id}
              placeholder={i.hint}
              labelName={i.name}
              onChange={(e) => setForm({
                ...form,
                localMgmtNumber: e.target.value
              })}
              value={form[i.id]}
            />
          </>)}

          {tattooNumberInput.map(i => <>
            <Input
              id={i.id}
              placeholder={i.hint}
              labelName={i.name}
              onChange={(e) => setForm({
                ...form,
                tattooNumber: e.target.value
              })}
              value={form[i.id]}
            />
          </>)}

          {usScrapieIdInput.map(i => <>
            <Input
              id={i.id}
              placeholder={i.hint}
              labelName={i.name}
              onChange={(e) => setForm({
                ...form,
                usScrapieId: e.target.value
              })}
              value={form[i.id]}
            />
          </>)}

          <SimpleCard 
            title="Sighting Information"
          ></SimpleCard>

          <FormInputWrapper>
            <FormInputLabel>Premise<>&nbsp;<RequiredSpan>*</RequiredSpan></></FormInputLabel>
            <PremiseSelect
              value={form.location}
              onChange={(e) => {
                setForm({
                  ...form,
                  location: e.target.value,
                })
              }}
            >
              <option > Manual Input </option>
              {premises.map(p => <>
                <option
                  value={p.pid}
                  key={p._id}
                >{`${p.name} - ${p.pid}`}</option>
              </>)}
            </PremiseSelect>
          </FormInputWrapper>
          
          {form.location == "Manual Input" ? ( 
            <Input
              id='manualLocation'
              labelName='Manually enter a PID'
              onChange={(e) => setForm({
                ...form,
                manualPid: e.target.value
              })}
              value={form.manualPid}
            />
            ) : (<></>
          )}

          {reporterInput.map(i => <>
            <Input
              id={i.id}
              placeholder={i.hint}
              labelName={i.name}
              onChange={(e) => setForm({
                ...form,
                reporterName: e.target.value
              })}
              value={form[i.id]}
              required
            />
          </>)} 

          <DateField
            id={dateField.id}
            labelName={dateField.labelName}
            value={dateField.value}
            onChange={dateField.onChange}
            required
          />

          <GeolocationInput
            location={form.geolocation}
            getLocation={setGeolocation}
            clearLocation={clearLocation}
          />
    
          <Button type='button' onClick={handleSubmit} >
            Create
          </Button>

          {(submitting) ? <SubmittingSpinner />: <></>}
        </FormCard>

        ) : (
				<PageCard
					title='Processing your request ...'
					loader={<Loader type='Oval' color={agGreen} height={50} width={150} />}
				>
					<LoadingCard />
				</PageCard>
			)}
		</ReportContainer>
    </> : <>
    <PageCard
      line
      back
      title='Report a Sighting'
    >
      <SimpleCard
        title='Confirm Sighting PID'
      />
      <Text>
        <span style={{ color: 'red', fontSize: 'inherit', lineHeight: '150%' }}>Warning - </span>
          You have entered a PID that is not currently in our system, are you sure this is the correct PID?
          If not, you can change the PID you entered below. If the PID you entered is correct, just click &apos;Submit&apos; 
          to submit your report.
      </Text>

      <CenterDiv>
          <InputTitle>Premise ID</InputTitle>
          <FormTextInput
            id='manualLocation'
            name='manualLocation'
            value={form.manualPid}
            onChange={(e) => setForm({
              ...form,
              manualPid: e.target.value
            })}
            style={{ width: '30%' }}
          />
      </CenterDiv>
      
      <CenterDiv>
        <Button onClick={handleSubmit} disabled={submitting}>
          Submit
        </Button>
        {submitting && <SubmittingSpinner />}
      </CenterDiv>
    </PageCard>
    </>}
	</>
}

const ReportContainer = styled.div`
  width: fit-content;
  max-width: 700px;
	color: white;
	position: relative;
	margin: 0;
	padding: 10px;
	display: flex;
	flex-direction: column;
`;
const SubmittingDiv = styled.div`
  text-align: center
`;
const CsvButtonContainer = styled.div`
  width: 40%;
  display: inline-block;
  text-align: center;
  margin-top: 20px;
`
const CsvButton = styled.button`
  background-color: #17a737;
  border: none;
  border-radius: 10px;
  padding: 1rem;
  font-size: 1rem;
  color: white;
  cursor: pointer;
`
const PremiseSelect = styled.select`
  font-size: 1rem;
  padding: 8px;
  border-radius: 10px;
	background: #c2c6ca;
  font-size: 14px;
`
const RequiredSpan = styled.span`
  color: red;
`
const Text = styled.div`
  margin-left: 10px;
  margin-bottom: 10px;
  display: inline-block;
`
const CenterDiv = styled.div`
  text-align: center; 
  margin-top: 2rem;
`
const InputTitle = styled.div`
  margin-bottom: 10px;
`

export default SightingReportCreateView;