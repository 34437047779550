import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PageCard from '../../components/PageCard.js';
import axios from 'axios';
import DescriptionCard from '../../components/DescriptionCard.js';
import TableComponent from '../../components/TableComponent';
import TableComponentMini from '../../components/TableComponentMini';
import { deleteVehicleById } from '../../utils/VehicleUtils.js';
import { getUsername} from '../../utils/TokenUtils';
import { getStateNameFromAbbrv } from '../../utils/USAStates.js';
import { getProvinceNameFromAbbrv } from '../../utils/CAProvinces.js';

const VehiclesView = () => {
	const [vehicles, setVehicles] = useState([]);
	const [redirect, setRedirect] = useState('');
	const [windowSize, setWindowSize] = useState(0);
	const [load, setLoad] = useState(false);

	const fetchVehicles = async () => {
		const result = await axios.get(`/api/vehicles/user/${getUsername()}`);
		const transformedVehicles = result.data.map((v) => {
			const province = getProvinceNameFromAbbrv(v.provinceOrState);
			const state = getStateNameFromAbbrv(v.provinceOrState);
			return {
				id: v._id,
				name: v.name,
				province: province ? province : 'N/A',
				state: state ? state: 'N/A',
				licensePlateNum: v.licensePlateNum,
			};
		});
		setVehicles(transformedVehicles);
		setLoad(true);
	};

	useEffect(() => {
		const asyncFetch = async () => {
			await fetchVehicles();
		};
		asyncFetch();
	}, []);

	if (window.innerWidth !== windowSize) {
		setWindowSize(window.innerWidth);
		console.log(windowSize);
	}

	if (redirect !== '') {
		return <Redirect push to={redirect} />;
	}

	const deleteVehicle = async ({ id }) => {
		await deleteVehicleById(id);
		await fetchVehicles();
	};

	const viewVehicle = ({ id }) => {
		setRedirect(`/vehicles/${id}`);
	};

	const editVehicle = ({ id }) => {
		setRedirect(`/vehicles/${id}/update`);
	};

	const tableHeadings = [
		{ name: 'Vehicle Name', id: 'name' },
		{ name: 'Vehicle Identification', id: 'licensePlateNum' },
		{ name: 'Canadian Province/Territory', id: 'province' },
		{ name: 'U.S. State', id: 'state' },
	];

	const vehiclesFields = [
		{ name: 'Vehicle Name', id: 'name' },
		{ name: 'Vehicle Identification', id: 'licensePlateNum' },
		{ name: 'Canadian Province/Territory', id: 'province' },
		{ name: 'U.S. State', id: 'state' },
	];

	return (
		<PageCard title='Vehicles' to={`/vehicles/create`} back>
			<DescriptionCard page='vehicle' create={true}></DescriptionCard>
			{!load ? (
				<>Loading...</>
			) : (
				<>
					{windowSize < 1300 ? (
						<TableComponentMini
							fields={vehiclesFields}
							list={vehicles}
							deleteCB={deleteVehicle}
							viewCB={viewVehicle}
							editCB={editVehicle}
						/>
					) : (
						<TableComponent
							headings={tableHeadings}
							data={vehicles}
							view={{ cb: viewVehicle, title: 'View' }}
							edit={{ cb: editVehicle, title: 'Edit' }}
							remove={{ cb: deleteVehicle, title: 'Delete' }}
							actions
						/>
					)}
				</>
			)}
		</PageCard>
	);
};

export default VehiclesView;
