const axios = require('axios');

export const getBreeds = () => {
  return axios.get('/api/breed')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(`An error occurred fetching breeds`, err);
    });
}