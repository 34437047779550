// ContactRouter.js
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import EditEmail from '../views/Edit/EditEmail';
import EditNumber from '../views/Edit/EditNumber';
import EditName from '../views/Edit/EditName';
import EditPassword from '../views/Edit/EditPassword';

const EditRouter = () => <Switch>
  <Route exact path='/edit/email/:username' component={EditEmail} />
  <Route exact path='/edit/number/:username' component={EditNumber} />
  <Route exact path='/edit/name/:username' component={EditName} />
  <Route exact path='/edit/password/:username' component={EditPassword} />
</Switch>

export default EditRouter;