import React, { useState, useEffect } from 'react';
import PageCard from '../../components/PageCard';
import { getMoveLogs } from '../../utils/MoveUtils';
import { toast } from 'react-toastify';
import ViewEditDispose from '../../components/ViewEditDispose';
import ReportDescriptionCard from '../../components/ReportDescriptionCard.js';
import ReportListDateFilter from '../../components/ReportListDateFilter';

const moveInColumns = [
  {
    id: 'departurePremise',
    name: 'Departure Premise'
  },
  {
    id: 'destinationPremise',
    name: 'Destination Premise'
  },
  {
    id: 'animalTags',
    name: 'Moved In Animals'
  },
  {
    id: 'receivedTime',
    name: 'Date of Reception'
  },
  {
    id: 'actions',
    name: 'Actions'
  }
];

const convertToAmPm = (time) => {
  const hourMinute = time.split(":");
  let hr = +hourMinute[0];
  let min = +hourMinute[1];
  const ampm = hr >= 12 ? 'pm' : 'am';
  hr = hr % 12;
  hr = hr > 0 ? hr : 12; // 0 hr should be 12
  min = min < 10 ? '0'+min : min;
  return `${hr}:${min}${ampm}`;
};

const MoveInReportListView = () => {
  const [moveInReports, setMoveInReports] = useState([]);
  
  useEffect(() => {
    getMoveLogs().then((moveLogs) => {
      moveLogs.forEach(r => {
      r.actions = <ViewEditDispose 
        id={r._id}
        endpoint={'/reports/moveIn/'}
        hideDispose
        hideEdit
        hideReplace
      />;
      });
      const moveIn = (moveLogs.filter((log) => !log.moveOut)).reverse();
      setMoveInReports(moveIn.map(moveLog => ({
        ...moveLog,
        departurePremise: moveLog.departurePremise ? moveLog.departurePremise.name : '',
        destinationPremise: moveLog.destinationPremise ? moveLog.destinationPremise.name : '',
        departureTime: (new Date(moveLog.departureTime)).toISOString().split('T')[0],
        receivedTime: (new Date(moveLog.receivedTime)).toISOString().split('T')[0],
        unloadTime: moveLog.unloadTime ? convertToAmPm(moveLog.unloadTime) : '',
        
      })));
    }).catch((err) => {
      toast.error(err);
    });
  }, []);

  return <>
    <PageCard title="Move-In Reports" to='/reports/movein/new' line back maxContent>
    <ReportDescriptionCard page='move-in report'></ReportDescriptionCard>

      {moveInReports.length > 0 ? 
      <ReportListDateFilter 
        columns={moveInColumns}
        reports={moveInReports} 
      /> 
      : <></>}
    </PageCard>
  </>
};

export default MoveInReportListView;