import React from 'react';
import { Button } from '../components/CommonComponents.js';
import PageCard from '../components/PageCard.js';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const TransactionVerificationDashboard = () => {
  return <>
    <PageCard title='Transaction Verification' line back>

      <ExplanationDiv>
        <TitleDiv>Learn How We Verify Your Transactions</TitleDiv>
        The data submitted to our website is stored on a blockchain. This is a very secure way to store information. Simply put, 
        all of the information in a blockchain is stored in separate blocks, which each have an identifier called a hash. This hash 
        is unique to the block it was created for, similar to how your fingerprints are unique to you! Furthermore, this hash is based 
        on the data in the block and the hash of the previous block in the chain. Thus, any changes to the data can be detected as 
        changing the data in your block will result in a new hash being generated for the block, making any subsequent block's hashes 
        incorrect (since the hashes of those blocks are now based off of an older hash)! You can see the process of generating a hash and verify it yourself by going to any 
        detailed view of your reports and clicking &apos;Verify Transaction.&apos;
      </ExplanationDiv>

      <ButtonDiv>
      <Link to='/reports/dash'>
        <Button>Go to Reports</Button>
      </Link>
      </ButtonDiv>
    </PageCard>
  </>
}
export default TransactionVerificationDashboard;

const TitleDiv = styled.div`
  margin-bottom: 20px;
  line-height: 150%;
  font-size: 1.75rem;
  font-weight: bold;
`;
const ExplanationDiv = styled.div`
	padding: 1rem;
	background: grey;
	border-radius: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
  line-height: 150%;
  font-size: 1.25rem;
`;
const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`; 
