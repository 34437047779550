// SheepDetailView.js
import React, { useEffect, useState } from 'react';
import PageCard from '../../components/PageCard';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import SimpleCard from '../../components/SimpleCard';
import StripedTable from '../../components/StripedTable';
import { NAField } from '../../components/SheepReportComponents';
import ViewEditDispose from "../../components/ViewEditDispose";
import propTypes from 'prop-types';
import styled from 'styled-components';
import { getMedicalReportsForSheep } from '../../utils/MedicalReportUtils';
import { getMoveLogsForSheep } from '../../utils/MoveUtils';
import { getImportReportsForSheep } from '../../utils/ImportUtils';
import { getExportReportsForSheep } from '../../utils/ExportUtils';
import { getTransportReportsForSheep } from '../../utils/TransportUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileWord, faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import ViewTransfer from '../../components/ViewTransfer';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
library.add(faFilePdf);
library.add(faFileWord);
library.add(faFileAlt);

//get the transport reports for a specific sheep
export const getReplaceReportsForSheep = (id) => {
  return axios.get(`/api/sheep/tag/replace/sheep/${id}`)
    .then((res) => {
      return res.data;
     
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

//Get edit reports for a specific sheep
export const getEditReportsForSheep = (id) => {
  return axios.get(`api/sheep/edit/sheep/all/${id}`)
    .then((res) => {
      return res.data;
     
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

const medicalColumns = [
  {
    id: 'premiseID',
    name: 'Sheep Premise ID'
  },
  {
    id: 'treatmentReason',
    name: 'Reason for Treatment'
  },
  {
    id: 'firstTreatmentDate',
    name: 'First Treatment Date'
  },
  {
    id: 'finalTreatmentDate',
    name: 'Final Treatment Date'
  },
  {
    id: 'createdAt',
    name: 'Date Created'
  },
  {
    id: 'actions',
    name: 'Actions'
  }
];

const moveColumns = [
  {
    id: 'type',
    name: 'Type'
  },
  {
    id: 'departurePID',
    name: 'Departure Premise ID'
  },
  {
    id: 'destinationPID',
    name: 'Destination Premise ID'
  },
  {
    id: 'createdAt',
    name: 'Date Created'
  },
  {
    id: 'actions',
    name: 'Actions'
  }
];

const importColumns = [
  {
    id: 'exportingCountry',
    name: 'Exporting Country'
  },
  {
    id: 'vehicleIdentification',
    name: 'License Plate Number'
  },
  {
    id: 'createdAt',
    name: 'Date Created'
  },
  {
    id: 'actions',
    name: 'Actions'
  }
];

const exportColumns = [
  {
    id: 'type',
    name: 'Type'
  },
  {
    id: 'departurePID',
    name: 'Departure Premise ID'
  },
  {
    id: 'vehicleNumber',
    name: 'License Plate Number'
  },
  {
    id: 'createdAt',
    name: 'Date Created'
  },
  {
    id: 'actions',
    name: 'Actions'
  }
];

const transportColumns = [
  {
    id: 'departurePID',
    name: 'Departure Premise PID'
  },
  {
    id: 'destinationPID',
    name: 'Destination Premise PID'
  },
  {
    id: 'numberOfAnimals',
    name: 'Number of Sheep Transported',
  },
  {
    id: 'createdAt',
    name: 'Date Created'
  },
  {
    id: 'actions',
    name: 'Actions'
  }
];

const replaceColumns = [
  {
    id: 'oldIsoNumber',
    name: 'Old Identifier',
    required: true
  },
  {
    id: 'newIsoNumber',
    name: 'New Identifier',
    required: true
  },
  {
    id: 'actions',
    name: 'Actions'
  }
];

const editColumns = [
  {
    id: 'isoNumber',
    name: 'Sheep Identifier',
    required: true
  },
  {
    id: 'createdAt',
    name: 'Edited Date',
    required: true
  },
  {
    id: 'actions',
    name: 'Actions'
  }
];

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ShowUploadFormButton = styled.button`
  margin-left: 0.5em;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #abb8df;

  :hover {
    background-color: #a3b0d3;
  }
`;

const UploadForm = styled.div`
  margin: 0.25em 0 1em 0;
  background-color: rgba(155, 155, 155, 0.7);
  padding: 10px;
  border-radius: 10px;
`;

const UploadFormWarning = styled.div`
  width: 300px;
  margin: auto;
  color: #FFCC00;
`;

const UploadFormTextContainer = styled.div`
  margin: 1em;

  label {
    display: block;
    height: max-content;
  }

  input {
    display: block;
    border: none;
    border-bottom: 2px solid rgb(233, 233, 233);
    background: rgba(233, 233, 233, 0.5);
    border-radius: 8px 8px 0 0;
    width: 300px;
    height: 30px;
    font-size: 16px;
    cursor: text;
    caret-shape: block;
  
    :focus {
      outline: none;
      border-bottom: 4px solid rgb(255, 255, 255);
    }
  }
`;

const UploadFormFileContainer = styled.div`
  margin: 1em;
`;

const DocumentUploadButton = styled.button`
  border: none;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #D5DCF9;
  border-radius: 5px;
  font-size: 16px;

  :disabled {
    cursor: not-allowed;
  }

  :hover:not([disabled]) {
    color: grey;
  }
`;

const DocumentsContainer = styled.div`
  width: 100%;
  min-height: 100px; 
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DocumentContent = styled.div`
  width: 25%;
  margin: 0.3em 0;
  padding: 1em 2em;
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  border-radius: 5px;
  background-color: #D1D2F9;
  color: black;
  cursor: pointer;
  overflow-wrap: break-word;
  position: relative;

  span {
    margin: 0 10px;
  }

  :hover {
    background-color: #9394f1;
  }

  p {
    font-size: 16px;
    font-weight: 300;
  }

  .delete-icon {
    position: absolute;
    top: 3px;
    right: 3px;
    padding: 10px;
    border-radius: 100px;

    :hover {
      background: rgb(196, 48, 48);
    }
  }
`;

const NoDocumentContent = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-style: italic;
`;

const SubmittingDiv = styled.div`
  text-align: center
`;
const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin />
  </SubmittingDiv>
}

const fetchSheepInfo = async (id) => {
  try {
    const request = await axios.get(`/api/sheep/${id}?premise=true`);
    const sheepInfo = request.data;


    if (sheepInfo.birthdate){
      sheepInfo.birthdate = (new Date(sheepInfo.birthdate)).toISOString().split('T')[0];
    } else {
      sheepInfo.birthdate = <NAField />;
    }
    sheepInfo.createdAt = (new Date(sheepInfo.createdAt)).toISOString().split('T')[0];
    sheepInfo.tag.createdAt = (new Date(sheepInfo.tag.createdAt)).toISOString().split('T')[0];

    return sheepInfo;
  } catch (err){
    throw new Error(`An error occurred fetching sheep info: ${err.message}`);
  }
}

const fetchDocuments = async (isonum) => {
  try {
    const request = await axios.get(`/api/document?isonum=${isonum}`);
    return request.data;
  } catch (err) {
    throw new Error(`An error occurred fetching the related documents: ${err.message}`);
  }
}

const fetchMedicalReports = async (id) => {
  let fetchedReports = await getMedicalReportsForSheep(id);

  fetchedReports.forEach(r => {
      r.actions = <ViewEditDispose 
        id={r._id} 
        endpoint={'/reports/medical/'} 
        hideDispose
        hideEdit
        hideReplace
      />;
      r.createdAt = (new Date(r.createdAt)).toISOString().split('T')[0];
      r.firstTreatmentDate = (new Date(r.firstTreatmentDate)).toISOString().split('T')[0];
      r.finalTreatmentDate = (new Date(r.finalTreatmentDate)).toISOString().split('T')[0];
  });
  return (fetchedReports.reverse());
}

const fetchMoveLogs = async (id) => {
  let fetchedMoveLogs = await getMoveLogsForSheep(id);

  fetchedMoveLogs.forEach(r => {
    r.actions = <ViewEditDispose 
      id={r._id}
      endpoint={r.moveOut ? '/reports/moveOut/' : '/reports/moveIn/'}
      hideDispose
      hideEdit
      hideReplace
    />;
    r.createdAt = (new Date(r.createdAt)).toISOString().split('T')[0];
    r.type = r.moveOut ? 'Move-Out' : 'Move-In';
  })
  return (fetchedMoveLogs.reverse());
}

const fetchImportReports = async (id) => {
  let fetchedReports = await getImportReportsForSheep(id);

  fetchedReports.forEach(r => {
    r.actions = <ViewEditDispose 
      id={r._id}
      endpoint={'/reports/import/'}
      hideDispose
      hideEdit
      hideReplace
    />;
    r.createdAt = (new Date(r.createdAt)).toISOString().split('T')[0];
  })
  return (fetchedReports.reverse());
}

const fetchExportReports = async (id) => {
  let fetchedMoveLogs = await getExportReportsForSheep(id);

  fetchedMoveLogs.forEach(r => {
    r.actions = <ViewEditDispose 
      id={r._id}
      endpoint={'/reports/'}
      hideDispose
      hideEdit
      hideReplace
    />;
    r.createdAt = (new Date(r.createdAt)).toISOString().split('T')[0];
    r.type = r.isTemp ? 'Temporary' : 'Permanent';
  })
  return (fetchedMoveLogs.reverse());
}

const fetchTransportReports = async (id) => {
  let fetchedReports = await getTransportReportsForSheep(id);

  fetchedReports.forEach(r => {
    r.actions = <ViewTransfer 
      id={r._id} 
      endpoint={'/reports/transport/'} 
    />;
    r.createdAt = (new Date(r.createdAt)).toISOString().split('T')[0];
    r.numberOfAnimals = r.animals.length;
  });
  return (fetchedReports.reverse());
}

const fetchReplaceReports = async (id) => {

  let fetchedReport = await getReplaceReportsForSheep(id);

  fetchedReport.forEach(r => {
    r.actions = <ViewEditDispose 
      id={r._id} 
      endpoint={'/reports/replace/'}
      hideDispose
      hideEdit
      hideReplace
    />;
    r.reportDate = (new Date(r.reportDate)).toISOString().split('T')[0];

    if (
      r.location &&
      r.location.coordinates &&
      r.location.coordinates.length === 2
    ) {
      r.location = r.location.coordinates[1] + ", " + r.location.coordinates[0];
    } else {
      r.location = "";
    }
  });

  return (fetchedReport.reverse());
}

const fetchEditReports = async (id) => {

  let fetchedReport = await getEditReportsForSheep(id);

  fetchedReport.forEach(r => {
    r.actions = <ViewEditDispose 
      id={r._id} 
      endpoint={'/reports/sheepedit/'}
      hideDispose
      hideEdit
      hideReplace
    />;
    r.createdAt = (new Date(r.createdAt)).toISOString().split('T')[0];

    if (r.animal){
      if (r.animal.tag.isoNumber){
        r.isoNumber = r.animal.tag.isoNumber;
      } else if (r.animal.tag.tattooNumber){
        r.isoNumber = r.animal.tag.tattooNumber;
      } else if (r.animal.tag.localMgmtNumber){
        r.isoNumber = r.animal.tag.localMgmtNumber;
      }
    }
  });

  return (fetchedReport.reverse());
}

const Info = ({name, value}) => {
  return <div>
    <span>{name}:&nbsp;</span><span>{value}</span>
  </div>;
}

Info.propTypes = {
  name: propTypes.string,
  value: propTypes.any
}

const SheepDetailView = () => {
  const [sheepInfo, setSheepInfo] = useState();
  const [documents, setDocuments] = useState([]);
  const [medicalReports, setMedicalReports] = useState([]);
  const [moveReports, setMoveReports] = useState([]);
  const [importReports, setImportReports] = useState([]);
  const [exportReports, setExportReports] = useState([]);
  const [transportReports, setTransportReports] = useState([]);
  const [replaceReports, setReplaceReports] = useState([]);
  const [editReports, setEditReports] = useState([]);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [form, setForm] = useState({
    title: "",
    description: ""
  });
  const [documentToUpload, setDocumentToUpload] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [downloading, setDownloading] = useState(-1);
  const params = useParams();
  const id = params.id;

  useEffect(() =>  {
    return fetchSheepInfo(id)
      .then((fetchedSheepInfo) => {
        setSheepInfo(fetchedSheepInfo);
        if (!fetchedSheepInfo.tag.isoNumber) return;
        return fetchDocuments(fetchedSheepInfo.tag.isoNumber);
      })
      .then((fetchedDocuments) => {
        if (fetchedDocuments) setDocuments(fetchedDocuments);
        return fetchMedicalReports(id)
      })
      .then((fetchedMedicalReports) => {
        setMedicalReports(fetchedMedicalReports);
        return fetchMoveLogs(id)
      })
      .then((fetchedMoveLogs) => {
        setMoveReports(fetchedMoveLogs);
        return fetchImportReports(id)
      })
      .then((fetchedImportReports) => {
        setImportReports(fetchedImportReports);
        return fetchExportReports(id)
      })
      .then((fetchedExportReports) => {
        setExportReports(fetchedExportReports);
        return fetchTransportReports(id)
      })
      .then((fetchedTransportReports) => {
        setTransportReports(fetchedTransportReports);
        return fetchReplaceReports(id)
      })
      .then((fetchedReplaceReports) => {
        setReplaceReports(fetchedReplaceReports);
        return fetchEditReports(id);
      })
      .then((fetchedEditReports) => {
        setEditReports(fetchedEditReports);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);

  const uploadDocument = () => {
    if (!sheepInfo.tag.isoNumber) {
      toast.error("The sheep must have an ISO number to upload related documents");
      return;
    }

    setSubmitting(true);
    const formData = new FormData();
    formData.append("isonum", sheepInfo.tag.isoNumber);
    formData.append("document", documentToUpload);
    if (form.title !== "") formData.append("title", form.title);
    if (form.description !== "") formData.append("description", form.description);

    return axios.post('/api/document/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(() => {
      return fetchDocuments(sheepInfo.tag.isoNumber);
    })
    .then(fetchedDocuments => {
      if (fetchedDocuments) setDocuments(fetchedDocuments);
      setSubmitting(false);
      toast.success("Document uploaded successfully");
    })
    .catch(err => {
      setSubmitting(false);
      if (err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    });
  };

  const downloadDocument = (filename, fileidx) => {
    if (!sheepInfo.tag.isoNumber) {
      toast.error("The sheep must have an ISO number to download related documents");
      return;
    }

    setDownloading(fileidx);
    return axios({
      url: `/api/document/download/${sheepInfo.tag.isoNumber}/${filename}`,
      method: 'GET',
      responseType: 'arraybuffer'
    })
    .then(res => {
      const file = new Blob([res.data]);
      const url = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.click();
      link.remove();
      setDownloading(-1);
    })
    .catch(err => {
      setDownloading(-1);
      if (err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    });
  };

  const deleteDocument = (filename, fileidx) => {
    setDownloading(fileidx);
    return axios.delete(`/api/document/${sheepInfo.tag.isoNumber}/${filename}`)
      .then(() => {
        return fetchDocuments(sheepInfo.tag.isoNumber);
      })
      .then(fetchedDocuments => {
        if (fetchedDocuments) setDocuments(fetchedDocuments);
        setDownloading(-1);
      })
      .catch(err => {
        setDownloading(-1);
        toast.error(`Something went wrong with deleting this document: ${err}`);
      });
  };
  
  return <>
    <PageCard title="Sheep Details" back>
      {(sheepInfo) ? <>
        <SimpleCard title="Animal Information" size="md">
          <Info name="Created At" value={sheepInfo.createdAt} />
          <Info name="Date of Birth" value={sheepInfo.birthdate} />
          {(sheepInfo.gender) ? <><Info name="Gender" value={sheepInfo.gender} /></> :<></>}
          {(sheepInfo.subgender) ?
            <>
              <Info name="Sub Gender" value={sheepInfo.subgender} />
            </>:<></>
          }

        </SimpleCard>
        <SimpleCard title="Tag Information" size="md">
          <Info name="Created At" value={sheepInfo.tag.createdAt} />
          {(sheepInfo.tag.isoNumber) ? <>
            <Info name="Iso Number" value={sheepInfo.tag.isoNumber}/>
          </>:<></>}
          {(sheepInfo.tag.localMgmtNumber) ? <>
            <Info name="Local Management Number" value={sheepInfo.tag.localMgmtNumber} />

          </>:<></>}
          {(sheepInfo.tag.tattooNumber) ? <>
            <Info name="Tattoo Number" value={sheepInfo.tag.tattooNumber} />
          </>:<></>}
          {(sheepInfo.tag.usScrapieId) ? <>
            <Info name="US Scrapie ID" value={sheepInfo.tag.usScrapieId} />
          </>:<></>}
        </SimpleCard>
        {/* Operation Info */}
        <SimpleCard title="Operation Information" size="md">
          <Info name="Business Name" value={sheepInfo.premise.operation.businessName} />
        </SimpleCard>
        <SimpleCard title="Premise Information" size="md">          
          <Info name="Name"  value={sheepInfo.premise.name} />
          <Info name="Address" value={sheepInfo.premise.address} />
          <Info name="Municipality" value={sheepInfo.premise.municipality}/>
          <Info name="Postal Code" value={sheepInfo.premise.postalCode}/>
          <Info name="Province" value={sheepInfo.premise.province}/>
        </SimpleCard>
        
        <SimpleCard title="Associated Medical Reports" size="md">
          {medicalReports.length > 0 ? 
          <StripedTable  
            columns={medicalColumns}
            rows={medicalReports}
            ShowCount
            max={10}
            paginate
          />
          : <> There are no medical reports associated with this sheep <br></br><br></br></>}
        </SimpleCard> 
        
        <SimpleCard title="Associated Move-In & Move-Out Reports" size="md">
          {moveReports.length > 0 ? 
          <StripedTable  
            columns={moveColumns}
            rows={moveReports}
            ShowCount
            max={10}
            paginate
          />
          : <> There are no move reports associated with this sheep <br></br><br></br></>}
        </SimpleCard> 

        <SimpleCard title="Associated Import Reports" size="md">
          {importReports.length > 0 ? 
          <StripedTable  
            columns={importColumns}
            rows={importReports}
            ShowCount
            max={10}
            paginate
          />
          : <> There are no import reports associated with this sheep <br></br><br></br></>}
        </SimpleCard> 

        <SimpleCard title="Associated Export Reports" size="md">
          {exportReports.length > 0 ? 
          <StripedTable  
            columns={exportColumns}
            rows={exportReports}
            ShowCount
            max={10}
            paginate
          />
          : <> There are no export reports associated with this sheep <br></br><br></br></>}
        </SimpleCard> 

        <SimpleCard title="Associated Transport Reports" size="md">
          {transportReports.length > 0 ? 
          <StripedTable  
            columns={transportColumns}
            rows={transportReports}
            ShowCount
            max={10}
            paginate
          />
          : <> There are no transport reports associated with this sheep <br></br><br></br></>}
        </SimpleCard> 


        <SimpleCard title="Associated Tag Replacement Reports" size="md">
          {replaceReports.length > 0 ? 
          <StripedTable  
            columns={replaceColumns}
            rows={replaceReports}
            ShowCount
            max={10}
            paginate
          />
          : <> There are no tag replacement reports associated with this sheep <br></br><br></br></>}
        </SimpleCard> 

        <SimpleCard title="Associated Sheep Edit Reports" size="md">
          {editReports.length > 0 ? 
          <StripedTable  
            columns={editColumns}
            rows={editReports}
            ShowCount
            max={10}
            paginate
          />
          : <> There are no sheep edit reports associated with this sheep <br></br><br></br></>}
        </SimpleCard> 

        <SimpleCard 
          title={
            <TitleContainer>
              Related Documents
              <ShowUploadFormButton onClick={() => setShowUploadForm(!showUploadForm)}>
                {showUploadForm ? "Hide" : "Upload a document"}
              </ShowUploadFormButton>
            </TitleContainer>
          } 
          size="md"
        >
          <DocumentsContainer>
            {showUploadForm && (
              <UploadForm>
                <UploadFormWarning>
                  <p>Please do NOT upload confidential or sensitive documents here.</p>
                  <p>Attached documents will become visible to other users if this sheep is moved onto their premise.</p>
                </UploadFormWarning>

                <UploadFormTextContainer>
                  <label htmlFor='document-title'>
                    Title
                  </label>
                  <input 
                    id='document-title' 
                    type='text'
                    value={form['title']}
                    onChange={e => setForm({ ...form, title: e.target.value })}
                  />
                </UploadFormTextContainer>

                <UploadFormTextContainer>
                  <label htmlFor='document-description'>
                    Description
                  </label>
                  <input
                    id='document-description' 
                    type='text'
                    value={form['description']}
                    onChange={e => setForm({ ...form, description: e.target.value })}
                  />
                </UploadFormTextContainer>
                
                <UploadFormFileContainer>
                  <input type="file" onChange={(e) => setDocumentToUpload(e.target.files[0])}/>
                  <DocumentUploadButton 
                    onClick={uploadDocument}
                    disabled={!documentToUpload || submitting}
                  >
                    Upload
                    {submitting && <SubmittingSpinner />}
                  </DocumentUploadButton>
                </UploadFormFileContainer>
              </UploadForm>
            )}

            {documents.length > 0 ? (
              documents.map((doc, idx) => {
                let icon;
                if (/.*\.pdf$/.test(doc.document)) {
                  icon = faFilePdf;
                } else if (/.*\.doc.?$/.test(doc.document)) {
                  icon = faFileWord;
                } else {
                  icon = faFileAlt;
                }

                return (
                  <DocumentContent 
                    key={idx} 
                    onClick={() => downloadDocument(doc.document, idx)}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      className='delete-icon'
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteDocument(doc.document, idx);
                      }}
                    />
                    <FontAwesomeIcon icon={icon} size='2x' />
                    <span>{doc.title}</span>
                    <p>{doc.description}</p>
                    {downloading === idx && <SubmittingSpinner />}
                  </DocumentContent>
                );
              })
            ) : (
              <NoDocumentContent>
                There are no documents related to this sheep...
              </NoDocumentContent>
            )}
          </DocumentsContainer>
        </SimpleCard>
      </>: <>Loading</>}
    </PageCard>
  </>
};

export default SheepDetailView;