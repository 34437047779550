import React from 'react';
import { FetchAndSortDisposalReports } from '../../components/SheepReportComponents';
import SheepReportListComponent from '../../components/SheepReportListComponent';

const CarcassDisposalListView = () => {
  return <>
    <SheepReportListComponent
      title="Disposal Reports"
      name='disposal report'
      createLink='/sheep/dispose'
      fetchReportsFunction={FetchAndSortDisposalReports}
      detailEndpoint={'/reports/raw/'}
    />
  </>
}

export default CarcassDisposalListView;