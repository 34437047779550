import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import PageCard from '../../components/PageCard';
import { FilterSection, NAField } from '../../components/SheepReportComponents';
import SimpleCard from '../../components/SimpleCard';
import StripedTable from '../../components/StripedTable';
import ViewEditDispose from '../../components/ViewEditDispose';
import { getPremisesById } from '../../utils/PremiseUtils.js';

const GetSheepByPremise = async (premiseID) => {
  const endpoint = `/api/sheep?premiseID=${premiseID}`;
  try {
    const request = await axios.get(endpoint);
    const fetchedInfo = request.data;
    return fetchedInfo;
  } catch (err) {
    throw new Error(`An error occurred fetching sheep by operation: ${err.message}`);
  }
}
  
const SheepPremiseView = () => {
  const [sheep, setSheep] = useState([]);
  const [premise, setPremise] = useState({});
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
    gender: ''
  });
  const [tempFilter, setTempFilter] = useState({
    startDate: '',
    endDate: '',
    gender: ''
  });

  const params = useParams();
  const id = params.id;

  const fetchSheepAndTransform = async () => {
    try {
      let fetchedSheep = await GetSheepByPremise(id);
      const sheepEndpoint = '/sheep/'
      // Need to flatten sheep
      fetchedSheep.forEach(s => {
        s.actions = <ViewEditDispose
          hideEdit
          id={s._id}
          endpoint={sheepEndpoint}
         />;
        s.createdAt = (new Date(s.createdAt)).toISOString().split('T')[0];
        s.isoNumber = (s.tag.isoNumber) ? s.tag.isoNumber : <NAField />;
        s.localMgmtNumber = (s.tag.localMgmtNumber) ? s.tag.localMgmtNumber : <NAField />;
        s.tattooNumber = (s.tag.tattooNumber) ? s.tag.tattooNumber : <NAField />;
        if(!s.gender) s.gender = <NAField />;
        if(!s.subgender) s.subgender = <NAField />;
      });
      fetchedSheep = fetchedSheep.filter((sheep) => (
        !sheep.isExported &&
        !sheep.movedOut &&
        !sheep.onAbattoir
      ));
      setSheep(fetchedSheep);
    } catch (err) {
      toast.error(err);
    }
  }

  const fetchPremise = async () => {
    const fetchedPremise = await getPremisesById(id);
    setPremise(fetchedPremise);
  };

  useEffect(() => {
    fetchSheepAndTransform().then (() => {
      fetchPremise();
    })
  }, [])

  const handleApply = (e) => {
    e.preventDefault();
    const newFilter = {...tempFilter};
    setFilter(newFilter);
  }

  const handleClear = (e) => {
    e.preventDefault();
    const newFilter = {
      startDate: '',
      endDate: '',
      gender: ''
    };
    setTempFilter(newFilter);
    setFilter(newFilter);
  }

  let filterFields = [
    {
      id: 'startDate',
      name: 'Start Date',
      type: 'date',
      onChange: (e) => {
        updateTempFilter(e.target.value, 'startDate');
      },
      onClick: (e) => {
        e.preventDefault();
        updateTempFilter('', 'startDate');
      },
    },
    {
      id: 'endDate',
      name: 'End Date',
      type: 'date',
      onChange: (e) => {
        updateTempFilter(e.target.value, 'endDate');
      },
      onClick: () => {
        updateTempFilter('', 'endDate');
      }
    },
    {
      id: 'gender',
      name: 'Gender',
      dropdown: true,
      options: [{
          value: 'male',
          name: 'Male'
        },
        {
          value: 'female',
          name: 'Female'
        }],
      onChange: (e) => {
        updateTempFilter(e.target.value, 'gender');
      }
    }
  ]

  const columns = [
    {
      id: 'isoNumber',
      name: 'Isonumber'
    },
    {
      id: 'localMgmtNumber',
      name: 'Local Management Number'
    },
    {
      id: 'tattooNumber',
      name: 'Tattoo Number'
    },
    {
      id: 'gender',
      name: 'Gender'
    },
    {
      id: 'createdAt',
      name: 'Created At'
    },
    {
      id: 'actions',
      name: 'Actions'
    }
  ]

  const updateTempFilter = (updatedValue, filterID) => {
    const newFilter = {...tempFilter};
    newFilter[filterID] = updatedValue;
    if(filterID === 'startDate'){
      if(tempFilter.endDate === ''){
        newFilter.endDate = updatedValue;
      } else {
        const startDate = new Date(updatedValue);
        const endDate = new Date(tempFilter.endDate)
        if(startDate > endDate) newFilter.endDate = updatedValue;
      }
      if(updatedValue === '') newFilter.endDate = '';
    }

    if(filterID === 'endDate'){
      if(tempFilter.startDate === ''){ 
        newFilter.startDate = updatedValue;
      } else {
        const startDate = new Date(tempFilter.startDate);
        const endDate = new Date(updatedValue);
        if(endDate < startDate) newFilter.startDate = updatedValue;
      }

      if(updatedValue === '') newFilter.startDate = '';
    }
    setTempFilter(newFilter);
  }

  const filteredSheep = sheep.filter(s => {
    let filterResult = true;
    const filterKeys = Object.keys(filter);
    filterKeys.forEach(key => {
      if(filter[key] === '') return;
      if(key.includes('Date')) return;
      if(s[key] !== filter[key]) filterResult = false;
    });
    const creationDate = new Date(s['createdAt']);
    const startDate = new Date(filter.startDate);
    const endDate = new Date(filter.endDate);
    if(creationDate < startDate) return false;
    if(creationDate > endDate) return false;

    return filterResult;
  });

  return <>
    <PageCard 
      title={(premise.name) ? `View Sheep for Premise ${premise.name}` : 'Loading info...'} back>
      {/* Filter Section */}
      <SimpleCard
        title="Filters"
        size='md'
        description='Use filters to find a subset of sheep'
      >
        <FilterSection
          data={filterFields}
          onApply={handleApply}
          onClear={handleClear}
          values={tempFilter}
        />
      </SimpleCard>

      {/* Table Section */}
      <SimpleCard
        title="Sheep"
      >
        <StripedTable
          columns={columns}
          rows={filteredSheep}
          ShowFilters
          ShowCount
          max={25}
        />
      </SimpleCard>
    </PageCard>
  </>
}

export default SheepPremiseView;