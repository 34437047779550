import React, { useState } from 'react';
import FormCard from '../../components/FormCard.js';
import styled from 'styled-components';
import { parseCSV } from '../../utils/CSVUtils';
import StripedTable from '../../components/StripedTable';
import {
	FormContainer,
	FormInputWrapper,
	FormInputLabel,
	FormSelectInput,
	Text,
	Button,
	FormTextInput,
} from '../../components/CommonComponents';
import TempExportForm from './TempExportForm';
import { FormInputSubLabel } from '../../components/FormComponents.js';
import { toast } from 'react-toastify';
import XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

export default function TempExportCSVCreateView() {
	const [headings, setHeadings] = useState([]);
	const [data, setData] = useState([]);
	const [showMoreEntries, setShowMoreEntries] = useState(false);
	const [showAllColumns, setShowAllColumns] = useState(false);
	const [columnPage, setColumnPage] = useState(0);
	// name should be more descriptive
	const fields = [
		{ name: 'ISO Number', id: 'isoNumber' },
	];
	const [headers, setHeaders] = useState({});
	const [next, setNext] = useState(false);

	const fileUpload = (e) => {
		const file = e.target.files[0];
		if (!file) return;

		// csv file given, parse csv to get data
		if (/.*\.csv$/.test(file.name)) {
			parseCSV(file).then(rows => {
				setHeadings(Object.keys(rows[0]).map(heading => ({ id: heading, name: heading })));
				setData(rows.filter(row => !Object.values(row).every(val => val === null)));
			}).catch(err => {
				toast.error(err);
			});
		}
		// xls file given, convert xls to csv then parse it to get data
		else if (/.*\.xls.?$/.test(file.name)) {
			const reader = new FileReader();
			reader.onload = (event) => {
				const data = new Uint8Array(event.target.result);
				const workbook = XLSX.read(data, {type: 'array'});
				// currently reads only the first worksheet, may want to read all the worksheets
				const worksheet = workbook.Sheets[workbook.SheetNames[0]];
				const csv = XLSX.utils.sheet_to_csv(worksheet);
				parseCSV(csv).then(rows => {
					setHeadings(Object.keys(rows[0]).map(heading => ({ id: heading, name: heading })));
					setData(rows.filter(row => !Object.values(row).every(val => val === null)));
				}).catch(err => {
					toast.error(err);
				});
			}
			reader.readAsArrayBuffer(file);
		}
	};

	const preValidate = () => {
		// Check that either localmgmt or csip are set
		const csipSet = ((headers.isoNumber && headers.isoNumber !== '') || (headers.localMgmtNumber && headers.localMgmtNumber !== '')) ? true : false;
		if(!csipSet) return false;

		// Check that if date selected, format is selected
		const dateSet = (headers['birthdate'] && headers['birthdate'] !== '') ? true : false;
		const activeDateSet = (headers['activeDate'] && headers['activeDate'] !== '') ? true : false;
		const dateFormatSet = (headers.dateFormat && headers.dateFormat !== '') ? true : false;
		if((dateSet || activeDateSet) && !dateFormatSet) return false;

		// Check that ram and ewename are set if gender is set
		const genderSet = (headers.gender && headers.gender !== '') ? true : false;
		const ramNameSet = (headers.ramName && headers.ramName !== '') ? true : false;
		const eweNameSet = (headers.eweName && headers.eweName !== '') ? true : false;

	
		return true;
	}

	const removeEmptyVals = (obj) => {
		let result = {}, key;

		for(key in obj){
			if(obj[key] && obj[key] !== '') result[key] = obj[key];
		}

		return result;
	}

	const firstPage = columnPage === 0;
	const lastPage = columnPage + 1 >= Math.ceil(headings.length/5);
	const prevColumnPage = () => {
		if (!firstPage) {
			setColumnPage(columnPage - 1);
		}
	};
	const nextColumnPage = () => {
		if (!lastPage) {
			setColumnPage(columnPage + 1);
		}
	};

	const hints = [
		"ISO numbers should follow this format: 124000 or 840000 followed by 9 digits",
		" "
	];

	return (
		<>
			{next ? 
					<TempExportForm data={data} headers={removeEmptyVals(headers)} />
			:(
				<FormCard title='Create temp export report	' back>
					<FileContainer>
						<input type='file' accept='.csv,.xls*' onChange={(e) => {
								fileUpload(e);
								setColumnPage(0);
								setShowAllColumns(false);
								setShowMoreEntries(false);
							}} />
					</FileContainer>
					
					{headings.length == 0 && (
						hints.map((hint, idx) => (
							<FormHint key={idx}>
								{hint}
							</FormHint>
						))
					)}

					{headings.length > 0 && (
						<PreviewContainer>
							<Title>
								Preview:
								<div>
									<TitleButton onClick={() => setShowAllColumns(!showAllColumns)}>
										Show {showAllColumns ? 'limited' : 'all'} columns
									</TitleButton>
									<TitleButton onClick={() => setShowMoreEntries(!showMoreEntries)}>
										Show {showMoreEntries ? 'less' : 'more'} entries
									</TitleButton>
								</div>
							</Title>
							<PreviewTableContainer>
								{!showAllColumns &&
									<TableColumnArrow 
										className={firstPage && 'disabled'}
										icon={faChevronLeft}
										size='2x'
										onClick={prevColumnPage}
									/>
								}
								<StripedTable 
									columns={showAllColumns ? headings : headings.slice(5*columnPage, 5*(columnPage+1))}
									rows={showMoreEntries ? data.slice(0, 100) : data.slice(0, 5)}
								/>
								{!showAllColumns && 
									<TableColumnArrow
										className={lastPage && 'disabled'}
										icon={faChevronRight}
										size='2x'
										onClick={nextColumnPage}
									/>
								}
							</PreviewTableContainer>

							<Title>Headers:</Title>
							<p style={{color: 'white'}}>CSIP column must be specified</p>
							<HeaderForm>
								{fields.map((element) => (
									<FormInputWrapper key={element.id}>
										<FormInputLabel>{element.name}</FormInputLabel>
										<FormSelectInput
											value={headers[element.id]}
											onChange={(e) =>
												setHeaders({
													...headers,
													[element.id]: e.target.value,
												})
											}
										>
											<option key={'select'} hidden>Select</option>
											<option key={'na'} value={''}>N/A</option>
											{
												headings.map((heading) => (
													<option key={heading.id} value={heading.name}>
														{heading.name}
													</option>
												))}
										</FormSelectInput>
									</FormInputWrapper>
								))}
								<SubmitButton
									type='submit'
									onClick={(e) => {
										e.preventDefault();
										setNext(true);
									}}
									disabled={!preValidate()}
								>
									Select Headers
								</SubmitButton>
							</HeaderForm>
						</PreviewContainer>
					)}
				</FormCard>
			)}
		</>
	);
}

const FileContainer = styled.div`
	width: 500px;
	display: flex;
	padding: 20px 20px 10px;
	color: white;
`;

const FormHint = styled.label`
margin-top: 5px;
color: orange;
font-size: 12px;
padding: 0px 20px 0px;
`;

const PreviewContainer = styled.div`
	margin: 0 20px 10px;
`;

const PreviewTableContainer = styled.div`
	display: flex;
	gap: 5px;
	max-height: 400px;
	overflow-y: scroll;

	&::-webkit-scrollbar-thumb {
		background-color: grey;
	}
`;

const HeaderForm = styled(FormContainer)`
	height: fit-content;
	max-width: 500px;
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;

	> div {
		flex-basis: 40%;
	}
`;

const SubmitButton = styled(Button)`
	margin: auto;
`;

const Title = styled(Text)`
	color: white;
	font-size: 25px;
	text-align: left;
	margin: 10px 0;
	display: flex;
	justify-content: space-between;
`;

const TitleButton = styled.button`
	border: none;
	border-radius: 5px;
	padding: 8px;
	font-weight: 500;
	background-color: rgb(95, 95, 212);
	color: white;
	cursor: pointer;
	margin: 0 5px;

	:hover {
		background-color: rgb(121, 121, 219);
	}
`;

const TableColumnArrow = styled(FontAwesomeIcon)`
	color: rgb(217, 217, 217);
	cursor: pointer;
	border-radius: 5px;
	padding: 2px;

	&:hover {
		color: rgb(255, 255, 255);
		background-color: rgba(144, 144, 144, 0.3);
	}

	&.disabled {
		color: rgb(71, 71, 71);
		cursor: default;
	}

	&.disabled:hover {
		background: none;
	}
`;