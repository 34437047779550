import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import FormCard from '../../components/FormCard.js';
import SheepLostTagForm from '../../components/SheepLostTagForm.js';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import LoadingCard from '../../components/LoadingCard.js';
import PageCard from '../../components/PageCard.js';
import { agGreen } from '../../components/Colors.js';
import { getUsername } from '../../utils/TokenUtils';

import axios from 'axios';

const fetchBreeds = async() => {
  try {
    const request = await axios.get(`/api/breed`);
    const fetchedBreeds = request.data;
    return fetchedBreeds;
  } catch (err) {
    throw new Error(`An error occurred fetching breeds`, err);
  }
}

const fetchPremises = async() => {
  try {
    const username = getUsername();
    const request = await axios.get(`/api/premises/user/${username}`);
    const fetchedPremises = request.data;
    return fetchedPremises;
  } catch (err) {
    throw new Error(`An error occurred fetching premises`);
  }
}

export default function SheepLostTagView() {
	const [data, setData] = useState({ genders: [{ name: 'Male' }, { name: 'Female' }] });
	const [breeds, setBreeds] = useState([]);
	const [premises, setPremises] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [fieldErrors, setErrors] = useState({});
	// eslint-disable-next-line no-unused-vars
	const [redirect, setRedirect] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [load, setLoad] = useState();

  const fetchBreedsAndPremises = async() => {
    try {
      const fetchedBreeds = await fetchBreeds();
      const fetchedPremises = await fetchPremises();
      setBreeds(fetchedBreeds);
      setPremises(fetchedPremises);
    } catch (err){
      toast.error(err.message);
    }
  }

	useEffect(() => {
		// Getting all breeds
    fetchBreedsAndPremises();
    
		axios
			.get('/api/breed/')
			.then((res) => {
				setData({ ...data, breeds: res.data });
			})
			.catch((err) => {
			});
	}, []);

	if (redirect) {
		return <Redirect to='/sheep' push />;
	}

	return (
		<>
			{!load ? (
				<FormCard title='Create a new sheep' errors={fieldErrors} back>
					<SheepLostTagForm errors={fieldErrors} breeds={breeds} premises={premises} />
				</FormCard>
			) : (
				<PageCard
					title='Processing your request ...'
					loader={<Loader type='Oval' color={agGreen} height={50} width={150} />}
				>
					<LoadingCard />
				</PageCard>
			)}
		</>
	);
}
