import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

export default function FloatingShape({
  top,
  left,
  bottom,
  right,
  polygonPoints
}) {
  const svgRef = useRef();

  const FloatingContainer = styled.div`
    position: absolute;
    top: ${top};
    left: ${left};
    bottom: ${bottom};
    right: ${right};
  `;

  useEffect(() => {    
    if (svgRef.current) {
      const bbox = svgRef.current.getBBox();
      svgRef.current.setAttribute("width", bbox.x + bbox.width + bbox.x);
      svgRef.current.setAttribute("height", bbox.y + bbox.height + bbox.y);
    }
  });

  return (
    <FloatingContainer>
      <svg ref={svgRef}>
        <polygon
          points={polygonPoints}
          fill="rgb(0, 0, 0)"
          stroke="rgb(211, 211, 211)"
          strokeWidth="1.5px"
        />
      </svg>
    </FloatingContainer>
  );
}

FloatingShape.propTypes = {
  top: propTypes.number || propTypes.string,
  left: propTypes.number || propTypes.string,
  bottom: propTypes.number || propTypes.string,
  right: propTypes.number || propTypes.string,
  polygonPoints: propTypes.string 
}