import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import FormCard from '../../components/FormCard.js';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import LoadingCard from '../../components/LoadingCard.js';
import PageCard from '../../components/PageCard.js';
import { agGreen } from '../../components/Colors.js';
import { getUsername } from '../../utils/TokenUtils';
import Input from '../../components/Input.js';
import DateField from '../../components/DateField.js';
import TimeField from '../../components/TimeField.js';
import styled from 'styled-components';
import DropDown from '../../components/DropDown.js';
import axios from 'axios';
import { getPremisesForUser } from '../../utils/PremiseUtils';
import { getAllSheeps } from '../../utils/SheepUtils';
import { createMoveLog } from '../../utils/MoveUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	FormInputWrapper,
	FormInputLabel,
	FormTextInput,
  FormTextInputSelection,
	FormSelectInput,
  Button,
} from '../../components/CommonComponents';
import StripedTable from '../../components/StripedTable';
import SimpleCard from '../../components/SimpleCard';
import CsvCreateSection from '../../components/CsvCreateSection';
import GeolocationInput from '../../components/GeolocationInput';
import { createTransportLog } from '../../utils/TransportUtils';



const getAllOperation = () => {
  return axios.get('/api/operations/').then((res) => {
    return res.data;
  });
}

const getAllVehicles = () => {
  return axios.get('/api/vehicles').then((res) => {
    return res.data;
  });
}


const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin></FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

const SheepTableCheckbox = ({sheepId, checked, setAnimals}) => {
  return (
    <input type='checkbox' 
      checked={checked}
      onChange={() => {
        if (checked) {
          setAnimals(currAnimals => currAnimals.filter(animal => animal !== sheepId));
        } else {
          setAnimals(currAnimals => [...currAnimals, sheepId]);
        }
      }}
      style={{cursor: 'pointer'}}
    />
  );
};

const SheepAllTableCheckbox = ({sheepId, checked, setAnimals}) => {
  setAnimals(currAnimals => [...currAnimals, sheepId]);
  return (
    <input type='checkbox' 
      checked={checked}
      onChange={() => {
        if (checked) {
          setAnimals(currAnimals => currAnimals.filter(animal => animal !== sheepId));
        } else {
          setAnimals(currAnimals => [...currAnimals, sheepId]);
        }
      }}
      style={{cursor: 'pointer'}}
    />
  );
};

const selectSheepsColumn = [
  {
    id: 'check',
    name: 'Select' 
  },
  {
    id: 'isoNumber',
    name: 'ISO Number'
  },
  {
    id: 'localMgmtNumber',
    name: 'Local Management Number'
  },
  {
    id: 'tattooNumber',
    name: 'Tattoo Number'
  },
  {
    id: 'breed',
    name: 'Breed'
  },
  {
    id: 'gender',
    name: 'Gender'
  }
];

const MoveOutReportCreateView = () => {
  const [load, setLoad] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState(false);

  //form information that is used in the move log
  const [form, setForm] = useState({
    departurePremiseID: '',
    destinationPremiseID: '',
    destinationType: 'Abattoir',
    vehicleID: '',
    departureDate: new Date(),
    loadTime: new Date(),
    location: null
  });
  const [animals, setAnimals] = useState([]);
  const [transport, setTransport] = useState(false);

  //state vars for retrieving and displaying dropdown options
  const [myPremises, setMyPremises] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [allAnimals, setAllAnimals] = useState([]);
  const [availableAnimals, setAvailableAnimals] = useState([]);
  const [allOperations, setAllOperations] = useState([]);
  const [operationOptions, setOperationOptions] = useState([]);
  const transportOperationRef = useRef(null);


  const fetchVehicles = async() => {
    const username = getUsername();
    const result = await axios.get(`/api/vehicles/user/${username}`);
    
    const fetchedVehicleIDs = result.data.map(v => ({
      id: v.licensePlateNum,
      name: v.name
    }));

    setVehicles(fetchedVehicleIDs);
    return fetchedVehicleIDs;
  };

  useEffect(() => {
    getAllVehicles().then((cars => {

      //Filter out vehicles with null operations
      const availableCars = cars.filter(car => (
        car.operation != null
      ));

      //filter for only transporter vehicles
      const options = availableCars.filter(car => (
        car.operation.operationType == 'transporter'
      ));
      setAllOperations(options);
    }))

  }, []);

  useEffect(() => {
    getPremisesForUser(getUsername())
      .then((userPremises) => {
        setMyPremises(userPremises);
        return getAllSheeps(true)
          .then((allSheeps) => {
            setAllAnimals(allSheeps.map((sheep) => ({
              ...sheep,
              isoNumber: sheep.tag && sheep.tag.isoNumber,
              localMgmtNumber: sheep.tag && sheep.tag.localMgmtNumber,
              tattooNumber: sheep.tag && sheep.tag.tattooNumber,
            })));
            return fetchVehicles();
          })
          .then((fetchedVehicleIDs) => {
            setForm({
              ...form,
              departurePremiseID: userPremises.length > 0 ? userPremises[0].pid : '',
              vehicleID: fetchedVehicleIDs.length > 0 ? fetchedVehicleIDs[0].id : ''
            });
            setLoad(false);
          });
      })
      .catch(() => {
        toast.error("An error occurred while fetching the data. Please reload this page.");
      });
  }, []);

  useEffect(() => {
    const departurePremise = myPremises.find((premise) => premise.pid === form.departurePremiseID);
    if (departurePremise) {
      const premiseAnimals = allAnimals.filter((animal) => (
        animal.premise === departurePremise._id &&
        animal.isoNumber &&
        !animal.movedOut &&
        !animal.isExported &&
        (animal.active || animal.onAbattoir)
      ));
      setAvailableAnimals(premiseAnimals.map(sheep => ({
        ...sheep,
        check:<SheepTableCheckbox 
                sheepId={sheep._id}
                checked={animals.findIndex(animal => animal === sheep._id) !== -1} 
                setAnimals={setAnimals}
              />
      })));
    }
    setAnimals([]);
  }, [form.departurePremiseID]);

  // reload checkboxes
  useEffect(() => {
    setAvailableAnimals(currAvailableAnimals => {
      return currAvailableAnimals.map(sheep => ({
        ...sheep,
        check:<SheepTableCheckbox 
                sheepId={sheep._id}
                checked={animals.findIndex(animal => animal === sheep._id) !== -1} 
                setAnimals={setAnimals}
              />
      }));
    });
  }, [animals]);

  const premiseDropDown = {
    name: 'Departure Premise Name',
    id: 'departurePremiseID'
  };

  const vehicleDropDown = {
    name: 'Vehicle Name',
    id: 'vehicleID'
  }

  const destPremiseInput = [
    {
      name: 'Destination Premise ID',
      id: 'destinationPremiseID'
    }
  ]

  const validateTransporterInfo = () => {
    return false;
  }

  const dateField = {
    labelName: 'Departure Date',
    id: 'departureDate',
    onChange: (date) => dateChange(date, 'departureDate'),
    value: form.departureDate
  }

  const dateChange = (date, fieldName) => {
    setForm({
      ...form,
      [fieldName]: date
    })
  };

  const loadField = {
    labelName: 'Load Time',
    id: 'loadTime',
    onChange: (time) => {
      setForm({
        ...form,
        loadTime: time
      })
    },
    value: form.loadTime
  }

  //check that required form values are present
  const createBtnEnabled = () => {
    return (
      !submitting && 
      form.departurePremiseID &&
      form.destinationType &&
      form.vehicleID &&
      form.departureDate &&
      form.loadTime &&
      animals.length > 0
    );
  };

  const setGeolocation = () => {
		const onSuccess = (position) => {
			const location = [position.coords.longitude, position.coords.latitude];
			setForm({ ...form, location });
		};

		const onError = () => {
			toast.info("Could not get the geolocation of your device");
		}

		if (!navigator.geolocation) {
			toast.info("Geolocation is not supported by this browser");
		} else {
			navigator.geolocation.getCurrentPosition(onSuccess, onError);
		}
	};

	const clearLocation = () => {
		setForm({ ...form, location: null });
	}

  const handleSubmit = () => {
    if (form.departurePremiseID.trim() === form.destinationPremiseID.trim()){
      toast.error("The departure and destination premises cannot be the same");
      return;
    }
    
    setSubmitting(true);
    toast.info('Submitting move-out report to the server. This may take up to a minute.');
    createMoveLog({
      moveOut: true,
      departurePID: form.departurePremiseID,
      destinationPID: form.destinationPremiseID ? form.destinationPremiseID.trim() : null,
      destinationType: form.destinationType,
      animals: animals,
      vehicleNumber: form.vehicleID,
      departureTime: form.departureDate,
      loadTime: form.loadTime.getHours() + ":" + form.loadTime.getMinutes(),
      location: form.location,
      transporter: form.transporterOperation
    }).then(() =>{
      if (transport) {
        createTransportLog({
          departurePID: form.departurePremiseID,
          destinationPID: form.destinationPremiseID,
          animals: animals,
          vehicleNumber: form.vehicleID,
          departureDate: form.departureDate,
          departureTime: form.loadTime.getHours() + ":" + form.loadTime.getMinutes(),
          transporter: form.transporterOperation
        }).then(() => {
          if(form.transporterOperation){
            createTransportLog({
              departurePID: form.departurePremiseID,
              destinationPID: form.destinationPremiseID,
              animals: animals,
              vehicleNumber: form.vehicleID,
              departureDate: form.departureDate,
              departureTime: form.loadTime.getHours() + ":" + form.loadTime.getMinutes(),
              transporter: form.transporterOperation,
              forTransporter: true,
            }).then(() =>{
              setSubmitting(false);
              toast.success("Success: Report created");
              setRedirect(true);
            })
          } else {
            setSubmitting(false);
            toast.success("Success: Report created");
            setRedirect(true);
          }
        })
      } else{
        setSubmitting(false);
        toast.success("Success: Report created");
        setRedirect(true);
      }
    }).catch ((err) => {
      setSubmitting(false);
      toast.dismiss();
      toast.error(err);
    });
  }

  if (redirect) return <Redirect to="/reports/moveout"/>
  return (
		<ReportContainer>
        {!load ? (
				<FormCard title='Create a new move-out report' back>
          <CsvCreateSection 
            title="Bulk Create"
            description="Use a CSV to create move-out reports"
            link="/reports/moveout/new/csv"
            buttonText="Create reports from a CSV"
          />

          <SimpleCard
            title="Manual Create"
            description="Enter the following information about the move."
          />
          <DropDown
            id={premiseDropDown.id}
            name={premiseDropDown.name}
            onChange={(e) => {
              setForm({
                ...form,
                departurePremiseID: e.target.value
              });
            }}
            value={form[premiseDropDown.id]}
            options={
              myPremises.map(o => ({
                name: o.name,
                value: o.pid,
              }))
            }
            required
          />
          
          {destPremiseInput.map(i => <>
            <Input
              id={i.id}
              labelName={i.name}
              onChange={(e) => setForm({
                ...form,
                destinationPremiseID: e.target.value
              })}
              value={form[i.id]}
            />
          </>)}

          <RadioButtonContainer>
            Destination Type: <>&nbsp;<RequiredSpan>*</RequiredSpan></><br></br>
            <input 
              type='radio' 
              name='destType' 
              checked={form.destinationType === 'Abattoir'} 
              onChange={() => setForm({...form, destinationType: 'Abattoir'})} 
              style={{cursor: 'pointer'}}/>
						  Abattoir <br></br>
						<input 
              type='radio' 
              name='destType' 
              checked={form.destinationType === 'Community Pasture'} 
              onChange={() => setForm({...form, destinationType: 'Community Pasture'})} 
              style={{cursor: 'pointer'}}/>
						  Community Pasture <br></br>
            <input 
              type='radio' 
              name='destType' 
              checked={form.destinationType === 'Other'} 
              onChange={() => setForm({...form, destinationType: 'Other'})} 
              style={{cursor: 'pointer'}}/>
						  Other <br></br>
          </RadioButtonContainer>

          <SimpleCard 
            title='Select sheep to move'
            description='Only sheep with ISO numbers can be moved. If you do not see your sheep, make sure it has an ISO number.'
          >
          <StripedTable
              columns={selectSheepsColumn}
              rows={availableAnimals}
              max={10}
              paginate
            />
          </SimpleCard>

          <CenteredButtonDiv>
            <Button onClick={(e) => {
              e.preventDefault();
              setAvailableAnimals(animals => {
                return animals.map(sheep => ({
                  ...sheep,
                  check:<SheepTableCheckbox 
                          sheepId={sheep._id}
                          checked={true} 
                          setAnimals={setAnimals}
                        />
                }));
              });
              }}>Select All
            </Button>
              {(animals.length > 0) ? 
              <Button onClick={(e) => {
              e.preventDefault();
              setAnimals([]);
              }}>Clear All
            </Button>
            : <></>}
          </CenteredButtonDiv>

          <DropDown
            id={vehicleDropDown.id}
            name={vehicleDropDown.name}
            onChange={(e) => {
              setForm({
                ...form,
                vehicleID: e.target.value
              });
            }}
            value={form[vehicleDropDown.id]}
            options={
              vehicles.map(o => ({
                name: o.name,
                value: o.id,
              }))
            }
            required
          />

          <DateField
            id={dateField.id}
            labelName={dateField.labelName}
            value={dateField.value}
            onChange={dateField.onChange}
            required
          />

          <TimeField
            id={loadField.id}
            labelName={loadField.labelName}
            value={loadField.value}
            onChange={loadField.onChange}
            required
          />

          <GeolocationInput 
            location={form.location}
            getLocation={setGeolocation}
            clearLocation={clearLocation}
          />

          
          
          <RadioButtonContainer>
            Would you like for a corresponding transport report to be created? <br></br>
            <input 
              type='radio' 
              name='transport' 
              checked={transport} 
              onChange={() => setTransport(true)} 
              style={{cursor: 'pointer'}}/>
              Yes <br></br>
            <input 
              type='radio' 
              name='transport' 
              checked={!transport} 
              onChange={() => setTransport(false)} 
              style={{cursor: 'pointer'}}/>
              No <br></br>
          </RadioButtonContainer> 

          {allOperations.length >= 1 && transport? 
          <FormInputWrapper>
          <FormInputLabel>
            Transporter Info

          </FormInputLabel>
          
          <FormTextInput
            ref={transportOperationRef}
            onChange={(e) => {
              if (e.target.value === '') {
                setOperationOptions([]);
              } else {
                const options = allOperations;
                setOperationOptions(options);

                if (options.length === 1 && 
                    options[0].pid === transportOperationRef.current.value) {
                  setForm({ ...form, transporterOperation: options[0].pid });
                }
              }
            }}
          />
          
          <div style={{ maxHeight: '150px', overflowY: 'scroll', overflowX: 'hidden' }}>
            {operationOptions.map((operation, idx) => (
              <FormTextInputSelection 
                key={idx}
                onClick={() => {
                  setOperationOptions([]);
                  setForm({ ...form, transporterOperation: operation.operation.businessName});
                  transportOperationRef.current.value = operation.operation.businessName;
                }}
              >
                <h4 style={{ margin: 0 }}>{operation.licensePlateNum}</h4>
                <div>{operation.operation.businessName}</div>
              </FormTextInputSelection>
            ))}
          </div>
        </FormInputWrapper>
            : <></>}

          <Button type='button' disabled={!createBtnEnabled()} onClick={handleSubmit} >
            Create
          </Button>

          {(submitting) ? <SubmittingSpinner />: <></>}
        </FormCard>

        ) : (
				<PageCard
					title='Processing your request ...'
					loader={<Loader type='Oval' color={agGreen} height={50} width={150} />}
				>
					<LoadingCard />
				</PageCard>
			)}
		</ReportContainer>
	);
}

const ReportContainer = styled.div`
  width: fit-content;
  max-width: 700px;
	color: white;
	position: relative;
	margin: 0;
	padding: 10px;
	display: flex;
	flex-direction: column;
`;

const DestinationMessageContainer = styled.div`
  margin-bottom: 20px;
  margin-left: 25px;
  color: red;
`;

const RadioButtonContainer = styled.div`
  margin-bottom: 20px;
  margin-left: 25px;
`;

const SubmittingDiv = styled.div`
  text-align: center
`;

const CenteredButtonDiv = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

const RequiredSpan = styled.span`
  color: red;
`
export default MoveOutReportCreateView;