import React, { useState, useEffect } from 'react';
import PageCard from '../../components/PageCard';
import { getTransportLogs } from '../../utils/TransportUtils';
import ViewTransfer from '../../components/ViewTransfer';
import ReportDescriptionCard from '../../components/ReportDescriptionCard.js';
import ReportListDateFilter from '../../components/ReportListDateFilter';
import { sortReportsByDate } from '../../components/SheepReportComponents';


const columns = [
  {
    id: 'departurePID',
    name: 'Departure Premise PID'
  },
  {
    id: 'destinationPID',
    name: 'Destination Premise PID'
  },
  {
    id: 'numberOfAnimals',
    name: 'Number of Sheep Transported',
  },
  {
    id: 'createdAt',
    name: 'Date Created'
  },
  {
    id: 'actions',
    name: 'Actions'
  }
];

const TransportReportListView = () => {
  const [medicalReports, setMedicalReports] = useState([]);

  const fetchTransportReports = async () => {
    let fetchedReports = await getTransportLogs();

    fetchedReports.forEach(r => {
        r.actions = <ViewTransfer 
          id={r._id} 
          endpoint={'/reports/transport/'} 
        />;
        r.createdAt = (new Date(r.createdAt)).toISOString().split('T')[0];
        r.numberOfAnimals = r.animals.length;
    });
    setMedicalReports(sortReportsByDate(fetchedReports));
  }

  useEffect(() => {
    fetchTransportReports();
  }, []);

  return <>
    <PageCard title="Transport Reports" to='/reports/transport/create' line back>
      <ReportDescriptionCard page='transport report'></ReportDescriptionCard>

        {medicalReports.length > 0 ? 
        <ReportListDateFilter 
          columns={columns}
          reports={medicalReports} 
        /> 
        : <></>}
    </PageCard>
  </>
};

export default TransportReportListView;