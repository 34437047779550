import React, { useState, useEffect } from 'react';
import { 
	FormInputWrapper, 
	FormInputLabel, 
	FormTextInput, 
	FormSelectInput, 
	FormTextList, 
	FormContainer, 
	Button
} from '../../components/CommonComponents';
import { agGreen } from '../../components/Colors.js';
import FormCard from '../../components/FormCard.js';
import { Redirect } from 'react-router-dom';
import PageCard from '../../components/PageCard.js';
import Loader from 'react-loader-spinner';
import LoadingCard from '../../components/LoadingCard.js';
import GeolocationInput from '../../components/GeolocationInput';
import { getUsername } from '../../utils/TokenUtils';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function PremiseCreate() {
	const [form, setForm] = useState({
		pid: '',
		name: '',
		address: '',
		municipality: '',
		province: '',
		postalCode: '',
		description: '',
		operationName: '',
		location: null
	});
	const [redirected, setRedirected] = useState(false);
	const [fieldErrors, setFieldErrors] = useState({});
	const [id, setId] = useState();
	const [load, setLoad] = useState();
	const [operations, setOperations] = useState([]);
	const username = getUsername();

	const provinces = ['NL', 'PE', 'NS', 'NB', 'QC', 'ON', 'MB', 'SK', 'AB', 'BC', 'YT', 'NT', 'NU'];

	const account = [
		{ name: 'PID:', hint: 'ON3456789', id: 'pid' },
		{ name: 'Nickname:', hint: "John's Sheep Farm", id: 'name' },
		{ name: 'Address: ', hint: '3 CherryTree Street', id: 'address' },
		{ name: 'Municipality: ', hint: 'Highwater', id: 'municipality' },
		{ name: 'Province: ', hint: 'ON', id: 'province' },
		{ name: 'Postal Code: ', hint: 'B4P 9K3', id: 'postalCode' },
		{ name: 'Description: ', hint: 'Home to many sheep ...', id: 'description' },
		{ name: 'Operation: ', hint: 'hint', id: 'operationName'},
	];
	function getOperations(){
		console.log('getting operations');
		axios.get(`/api/operations/user/${username}`).then(
			(res) =>{
				console.log(res.data);
				setOperations(res.data);
			})
	}
	useEffect(() => {
		getOperations();
	}, []);

	async function post() {
		setLoad(true);
		await axios.post('/api/premises', form).then(
			(res) => {
				setId(res.data._id);
				setRedirected(true);
			},
			(err) => {
				const errArray = Object.values(err.response.data);
				errArray.forEach(error => {
					toast.error(error);
				});
			}
		);
		setLoad(false);
	}
	// run when submit button is clicked
	const submitPremise = (e) => {
		if (form.pid === '') {
			form.pid = null;
		}
		post();
		e.preventDefault();
	};

	const setGeolocation = () => {
		const onSuccess = (position) => {
			const location = [position.coords.longitude, position.coords.latitude];
			setForm({ ...form, location });
		};

		const onError = () => {
			toast.info("Could not get the geolocation of your device");
		}

		if (!navigator.geolocation) {
			toast.info("Geolocation is not supported by this browser");
		} else {
			navigator.geolocation.getCurrentPosition(onSuccess, onError);
		}
	};

	const clearLocation = () => {
		setForm({ ...form, location: null });
	}

	return (
		<>
		{!load ?
		<FormCard title='Create Premise' back='/premises' errors={fieldErrors}>
			<FormContainer onSubmit={submitPremise}>
				{account.map((element) => {
					if(element.id === 'province'){
						return (
							<FormInputWrapper key='province'>
							<FormInputLabel htmlFor='input'>Province:</FormInputLabel>
								<FormTextList>
									<FormSelectInput
										name='province'
										value={form['province']}
										onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}>
										<option hidden>Select one</option>
										{provinces.map(value => {
											return <option key={value}>{value}</option>
										})}
									</FormSelectInput>
								</FormTextList>
						</FormInputWrapper>);
					}else if(element.id === 'operationName'){
						return (
							<FormInputWrapper key={element.id}>
							<FormInputLabel htmlFor='input'>{element.name}</FormInputLabel>
								<FormTextList>
									<FormSelectInput
										name={element.id}
										value={form.operation}
										onChange={(e) => setForm({ ...form, operation: e.target.value })}>
										<option hidden>Select one</option>
										{operations.map((value) => {
											return <option key={value._id} value={value._id}>{value.businessName}</option>
										})}
									</FormSelectInput>
								</FormTextList>
						</FormInputWrapper>);
					}else{
						return (
							<FormInputWrapper key={element.id}>
							<FormInputLabel htmlFor='input'>{element.name}</FormInputLabel>
								<FormTextInput
									placeholder={element.hint}
									id='input'
									valid={true}
									name={element.id}
									value={form[element.id]}
									onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}>
								</FormTextInput>
							</FormInputWrapper>
						);
					}
				})}
				<GeolocationInput 
					location={form.location}
					getLocation={setGeolocation}
					clearLocation={clearLocation}
				/>
				<div style={{ textAlign: 'center' }}>
					<Button>Create</Button>
				</div>
			</FormContainer>
			{redirected ? <Redirect to={`/premises`} /> : null}
		</FormCard> :
		<PageCard title="Processing your request ..." loader={<Loader type="Oval" color={agGreen} height={50} width={150}/>}>
			<LoadingCard/>
		</PageCard>}
	</>
	);
}
