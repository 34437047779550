import React from 'react';
import { agGreen } from './Colors.js';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

const CreateButton = () => (
	<CreateSpan>
		<FontAwesomeIcon icon={faPlusCircle} color={agGreen} />
	</CreateSpan>
);

CreateButton.propTypes = {
  to: propTypes.any
}

const ReportDescriptionCard = ({ plural, page, create=true}) => (
	<DescriptionBox>
		<DescriptionTitle>Welcome to {plural || `${page}s`}</DescriptionTitle>
		<DescriptionContent>
			You can {create ? <>create and</> :<></>} view all of your {plural || `${page}s`} within this page!
			<br />
			<br />
			Each row in the table is an individual {page}. The View buttons underneath the 
			Actions column will allow you to see more details about the report.
			{create ? <> 
				<br/><br/><CreateButton/> : Click this button to create a report</> 
				: <></>
			}
		</DescriptionContent>
	</DescriptionBox>
);

ReportDescriptionCard.propTypes = {
  plural: propTypes.node,
  page: propTypes.node,
	create: propTypes.bool
};

export default ReportDescriptionCard;

const DescriptionBox = styled.div`
	background-color: grey;
	margin: 10px 0;
	border-radius: 10px;
	padding: 5px 10px 10px;
`;

const DescriptionTitle = styled.div`
	color: white;
	font-weight: bold;

	text-decoration: bold;
	font-size: 30px;
	margin-bottom: 10px;
`;

const DescriptionContent = styled.div`
	color: white;
`;

const CreateSpan = styled.span`
	margin-left: 10px;
`;