import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import FormCard from '../../components/FormCard.js';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import LoadingCard from '../../components/LoadingCard.js';
import PageCard from '../../components/PageCard.js';
import { agGreen } from '../../components/Colors.js';
import { getUsername } from '../../utils/TokenUtils';
import Input from '../../components/Input.js';
import DateField from '../../components/DateField.js';
import TimeField from '../../components/TimeField.js';
import styled from 'styled-components';
import DropDown from '../../components/DropDown.js';
import axios from 'axios';
import { getPremisesForUser } from '../../utils/PremiseUtils';
import { getAllSheeps } from '../../utils/SheepUtils';
import { createTransportLog } from '../../utils/TransportUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../components/CommonComponents';
import StripedTable from '../../components/StripedTable';
import SimpleCard from '../../components/SimpleCard';
import { createMoveLog } from '../../utils/MoveUtils';

const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin></FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

const SheepTableCheckbox = ({sheepId, checked, setAnimals}) => {
  return (
    <input type='checkbox' 
      checked={checked}
      onChange={() => {
        if (checked) {
          setAnimals(currAnimals => currAnimals.filter(animal => animal !== sheepId));
        } else {
          setAnimals(currAnimals => [...currAnimals, sheepId]);
        }
      }}
      style={{cursor: 'pointer'}} 
    />
  );
};

const selectSheepsColumn = [
  {
    id: 'check',
    name: 'Select' 
  },
  {
    id: 'isoNumber',
    name: 'ISO Number'
  },
  {
    id: 'localMgmtNumber',
    name: 'Local Management Number'
  },
  {
    id: 'tattooNumber',
    name: 'Tattoo Number'
  },
  {
    id: 'breed',
    name: 'Breed'
  },
  {
    id: 'gender',
    name: 'Gender'
  }
];

const TransportReportCreateView = () => {
  const [load, setLoad] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState(false);

  //form information that is used in the transport log
  const [form, setForm] = useState({
    departurePremiseID: '',
    destinationPremiseID: '',
    vehicleID: '',
    departureDate: new Date(),
    loadTime: new Date()
  });
  const [animals, setAnimals] = useState([]);
  const [moveout, setMoveout] = useState(false);

  //state vars for retrieving and displaying dropdown options
  const [myPremises, setMyPremises] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [allAnimals, setAllAnimals] = useState([]);
  const [availableAnimals, setAvailableAnimals] = useState([]);

  const fetchVehicles = async() => {
    const username = getUsername();
    const result = await axios.get(`/api/vehicles/user/${username}`);
    
    const fetchedVehicleIDs = result.data.map(v => ({
      id: v.licensePlateNum,
      name: v.name
    }));

    setVehicles(fetchedVehicleIDs);
    return fetchedVehicleIDs;
  };

  useEffect(() => {
    getPremisesForUser(getUsername())
      .then((userPremises) => {
        setMyPremises(userPremises);
        return getAllSheeps()
          .then((allSheeps) => {
            setAllAnimals(allSheeps.map((sheep) => ({
              ...sheep,
              isoNumber: sheep.tag.isoNumber,
              localMgmtNumber: sheep.tag.localMgmtNumber,
              tattooNumber: sheep.tag.tattooNumber,
            })));
            return fetchVehicles();
          })
          .then((fetchedVehicleIDs) => {
            setForm({
              ...form,
              departurePremiseID: userPremises.length > 0 ? userPremises[0].pid : '',
              vehicleID: fetchedVehicleIDs.length > 0 ? fetchedVehicleIDs[0].id : ''
            });
            setLoad(false);
          });
      })
      .catch(() => {
        toast.error("An error occurred while fetching the data. Please reload this page.");
      });
  }, []);

  useEffect(() => {
    const departurePremise = myPremises.find((premise) => premise.pid === form.departurePremiseID);
    if (departurePremise) {
      const premiseAnimals = allAnimals.filter((animal) => (
        animal.premise === departurePremise._id &&
        !animal.isExported && 
        animal.tag.isoNumber
      ));
      setAvailableAnimals(premiseAnimals.map(sheep => ({
        ...sheep,
        check:<SheepTableCheckbox 
                sheepId={sheep._id}
                checked={animals.findIndex(animal => animal === sheep._id) !== -1} 
                setAnimals={setAnimals}
              />
      })));
    }
    setAnimals([]);
  }, [form.departurePremiseID]);

  // reload checkboxes
  useEffect(() => {
    setAvailableAnimals(currAvailableAnimals => {
      return currAvailableAnimals.map(sheep => ({
        ...sheep,
        check:<SheepTableCheckbox 
                sheepId={sheep._id}
                checked={animals.findIndex(animal => animal === sheep._id) !== -1} 
                setAnimals={setAnimals}
              />
      }));
    });
  }, [animals]);

  const premiseDropDown = {
    name: 'Departure Premise Name',
    id: 'departurePremiseID'
  };

  const vehicleDropDown = {
    name: 'Vehicle Name',
    id: 'vehicleID'
  }

  const destPremiseInput = [
    {
      name: 'Destination Premise ID',
      id: 'destinationPremiseID'
    }
  ]

  const dateField = {
    labelName: 'Departure Date',
    id: 'departureDate',
    onChange: (date) => dateChange(date, 'departureDate'),
    value: form.departureDate
  }

  const dateChange = (date, fieldName) => {
    setForm({
      ...form,
      [fieldName]: date
    })
  };

  const loadField = {
    labelName: 'Load Time',
    id: 'loadTime',
    onChange: (time) => {
      setForm({
        ...form,
        loadTime: time
      })
    },
    value: form.loadTime
  }

  //check that required form values are present
  const createBtnEnabled = () => {
    return (
      !submitting && 
      form.departurePremiseID &&
      form.destinationPremiseID &&
      form.vehicleID &&
      form.departureDate &&
      form.loadTime &&
      animals.length > 0
    );
  };

  const handleSubmit = () => {
    if (form.departurePremiseID.trim() === form.destinationPremiseID.trim()){
      toast.error("The departure and destination premises cannot be the same");
      return;
    }
    
    setSubmitting(true);
    toast.info('Submitting transport report to the server. This may take up to a minute.');
    createTransportLog({
      departurePID: form.departurePremiseID,
      destinationPID: form.destinationPremiseID,
      animals: animals,
      vehicleNumber: form.vehicleID,
      departureDate: form.departureDate,
      departureTime: form.loadTime.getHours() + ":" + form.loadTime.getMinutes()
    }).then(() =>{
      if(moveout){
        createMoveLog({
          moveOut: true,
          departurePID: form.departurePremiseID,
          destinationPID: form.destinationPremiseID,
          animals: animals,
          vehicleNumber: form.vehicleID,
          departureTime: form.departureDate,
          loadTime: form.loadTime.getHours() + ":" + form.loadTime.getMinutes()
        }).then(() =>{
          setSubmitting(false);
          toast.success("Success: Report created");
          setRedirect(true);
        })
      }
      else{
        setSubmitting(false);
        toast.success("Success: Report created");
        setRedirect(true);
      }
    }).catch ((err) => {
      setSubmitting(false);
      toast.dismiss();
      toast.error(err);
    });
  }

  if (redirect) return <Redirect to="/reports/transport/list"/>
  return (
		<ReportContainer>
        {!load ? (
				<FormCard title='Create a new transport report' back>
      
        <InstructionContainer>
          Enter the following transport information.
        </InstructionContainer>
          <DropDown
            id={premiseDropDown.id}
            name={premiseDropDown.name}
            onChange={(e) => {
              setForm({
                ...form,
                departurePremiseID: e.target.value
              });
            }}
            value={form[premiseDropDown.id]}
            options={
              myPremises.map(o => ({
                name: o.name,
                value: o.pid,
              }))
            }
            required
          />
          
          {destPremiseInput.map(i => <>
            <Input
              id={i.id}
              labelName={i.name}
              onChange={(e) => setForm({
                ...form,
                destinationPremiseID: e.target.value
              })}
              value={form[i.id]}
              required
            />
          </>)}

          <SimpleCard 
            title='Select sheep to transport'
            description='Only sheep with ISO numbers can be transported. If you do not see your sheep, make sure it has an ISO number.'
          >
            <StripedTable
              columns={selectSheepsColumn}
              rows={availableAnimals}
              max={10}
              paginate
            />
          </SimpleCard>

          <DropDown
            id={vehicleDropDown.id}
            name={vehicleDropDown.name}
            onChange={(e) => {
              setForm({
                ...form,
                vehicleID: e.target.value
              });
            }}
            value={form[vehicleDropDown.id]}
            options={
              vehicles.map(o => ({
                name: o.name,
                value: o.id,
              }))
            }
            required
          />

          <DateField
            id={dateField.id}
            labelName={dateField.labelName}
            value={dateField.value}
            onChange={dateField.onChange}
            required
          />

          <TimeField
            id={loadField.id}
            labelName={loadField.labelName}
            value={loadField.value}
            onChange={loadField.onChange}
            required
          />

          <RadioButtonContainer>
            Would you like for a corresponding move-out report to be created? <br></br>
            <input 
              type='radio' 
              name='moveout' 
              checked={moveout} 
              onChange={() => setMoveout(true)} 
              style={{cursor: 'pointer'}} />
              Yes <br></br>
            <input 
              type='radio' 
              name='moveout' 
              checked={!moveout} 
              onChange={() => setMoveout(false)}
              style={{cursor: 'pointer'}} />
              No <br></br>
          </RadioButtonContainer> 

          <Button type='button' disabled={!createBtnEnabled()} onClick={handleSubmit} >
            Create
          </Button>

          {(submitting) ? <SubmittingSpinner />: <></>}
        </FormCard>

        ) : (
				<PageCard
					title='Processing your request ...'
					loader={<Loader type='Oval' color={agGreen} height={50} width={150} />}
				>
					<LoadingCard />
				</PageCard>
			)}
		</ReportContainer>
	);
}

const ReportContainer = styled.div`
  width: fit-content;
  max-width: 700px;
	color: white;
	position: relative;
	margin: 0;
	padding: 10px;
	display: flex;
	flex-direction: column;
`;

const SubmittingDiv = styled.div`
  text-align: center
`;

const InstructionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
`;

const RadioButtonContainer = styled.div`
  margin-bottom: 20px;
  margin-left: 25px;
`;

export default TransportReportCreateView;