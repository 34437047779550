import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import PageCard from '../../components/PageCard.js';
import { 
	getOperationById,
	deleteOperation,
} from '../../utils/OperationUtils.js';
import DetailTable from '../../components/DetailTable.js';
import { agGreen, deleteColor } from '../../components/Colors.js';

const OperationView = () => {
	const [operation, setOperation] = useState();
	const [redirect, setRedirect] = useState('');
	
	const params = useParams();
	const operationId = params.id;

	const fetchAndSetOperationId = async () => {
		let result = await getOperationById(operationId);
		const pids = result.premises.map(premise => " " + premise.pid);
		
		const transformedOperation = {
			name: result.businessName,
			type: result.operationType,
			phoneNumber: result.phoneNumber,
			sfcp: result.sfcp ? 'Yes' : 'No',
			cvsp: result.cvsp ? 'Yes' : 'No',
			pids: pids
		}

		setOperation(transformedOperation);
	}

	const operationFields = [
		{ name: 'Business Name', id: 'name' },
		{ name: 'Type', id: 'type' },
		{ name: 'Contact', id: 'phoneNumber' },
		{ name: 'CVSP', id: 'cvsp' },
		{ name: 'SFCP', id: 'sfcp' },
		{name: "Associated PIDs", id: 'pids'}
	];

	const buttons = [
		{ 
			name: 'Edit',
			onClick: () => setRedirect(`/operations/${operationId}/update`),
			color: agGreen,
		},
		{
			name: 'Delete',
			onClick: () => {
				deleteOperation(operationId);
				setRedirect('/operations');
			},
			color: deleteColor,
		},
	];

	useEffect(() => {
		if(!operation) fetchAndSetOperationId();
	}, []);

	if(redirect !== '') return <Redirect push to={redirect} />;
	
	return <>
		<PageCard  title='View Details' back>
			<DetailTable data={operation} fields={operationFields} buttons={buttons} />
		</PageCard>
	</>
}

export default OperationView;
