import React, { useState, useEffect } from 'react';
import PageCard from '../../components/PageCard';
import SimpleCard from '../../components/SimpleCard';
import styled from 'styled-components';
import { getExportReports } from '../../utils/ExportUtils';
import ViewEditDispose from '../../components/ViewEditDispose';
import ReportDescriptionCard from '../../components/ReportDescriptionCard.js';
import ReportListDateFilter from '../../components/ReportListDateFilter';

const tempExportColumns = [
  {
    id: 'departurePID',
    name: 'Departure Site',
    required: true
  },
  {
    id: 'destinationLocation',
    name: 'Export Country'
  },
  {
    id: 'animalTags',
    name: 'Moved Out Animals (Tag Info)',
    required: true
  },
  {
    id: 'arrivalTime',
    name: 'Arrival Date'
  },
  {
    id: 'departureTime',
    name: 'Date of Departure',
    required: true
  },
  {
    id: 'actions',
    name: 'Actions'
  }
];

const exportColumns = [
  {
    id: 'departurePID',
    name: 'Departure Site PID'
  },
  {
    id: 'destinationLocation',
    name: 'Export Location or PID'
  },
  {
    id: 'animalTags',
    name: 'Moved Out Animals (Tag Info)'
  },
  {
    id: 'departureTime',
    name: 'Departure Date'
  },
  {
    id: 'arrivalTime',
    name: 'Arrival Date (If Applicable)'
  },
  {
    id: 'vehicleNumber',
    name: 'Vehicle'
  },
  {
    id: 'actions',
    name: 'Actions'
  }
];

const ExportReportListView = () => {
  const [tempExport, setTempExportReports] = useState([]);
  const [exportReports, setExportReports] = useState([]);
  
  //if the destination PID is given, set that as the location so the table displays it
  const setPidAsLocation = (report) => {
    if (!report.destinationLocation || report.destinationLocation === ''){
      return (report.destinationPID);
    }
    else return (report.destinationLocation);
  }

  const fetchExportReports = async () => {
    let fetchedReport = await getExportReports();
    fetchedReport.forEach(r => {
        r.destinationLocation = setPidAsLocation(r);
        r.actions = <ViewEditDispose 
          id={r._id} 
          endpoint={'/reports/'} 
          hideDispose
          hideEdit
          hideReplace
        />;
        r.departureTime = (new Date(r.departureTime)).toISOString().split('T')[0];
        if(r.arrivalTime) r.arrivalTime = (new Date(r.arrivalTime)).toISOString().split('T')[0];
    });
    setTempExportReports((fetchedReport.filter((report) => report.isTemp)).reverse());
    setExportReports((fetchedReport.filter((report) => !report.isTemp)).reverse());
  }


  useEffect(() => {
    fetchExportReports();
  }, []);

  return <>
    <PageCard title="Permanent and Temporary Export Reports" line back>
      <ReportDescriptionCard page='export report'></ReportDescriptionCard>

      <SimpleCard title={
        <TitleContainer>
          Export Reports
        </TitleContainer>}
        to='/reports/importExport/export/manual'
        >
        {exportReports.length > 0 ? 
        <ReportListDateFilter 
          columns={exportColumns}
          reports={exportReports} 
        /> 
        : <></>}
      </SimpleCard>

      <SimpleCard title={
        <TitleContainer>
          Temporary Export Reports
        </TitleContainer>}
        to='/reports/importExport/temp'
        >
        {tempExport.length > 0 ? 
        <ReportListDateFilter 
          columns={tempExportColumns}
          reports={tempExport} 
        /> 
        : <></>}
      </SimpleCard>
    </PageCard>
  </>
};

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default ExportReportListView;