import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button } from '../../components/CommonComponents';
import { getUser } from '../../utils/UserUtils';
import PageCard from '../../components/PageCard';
import DetailTable from '../../components/DetailTable';
import { getOperationsForUser } from "../../utils/OperationUtils";
import SimpleCard from '../../components/SimpleCard';
import StripedTable from '../../components/StripedTable';
import propTypes from 'prop-types';
import styled from 'styled-components';

// TODO: Style the arrow in the select
// TODO: Fix the Select error wherein you cannot add the currently selected item
// TODO: Fix the dispatch method firing twice (kinda know why (?), but need remedy)

const operationColumns = [
  {
    id: 'businessName',
    name: 'Name'
  },
  {
    id: 'operationType',
    name: 'Type'
  }
];

const premiseColumns = [
  {
    id: 'name',
    name: 'Name'
  },
  {
    id: 'pid',
    name: 'PID'
  },
	{
    id: 'operation',
    name: 'Operation'
  }
];

const UserView = () => {
	const params = useParams();
	const username = params.username;
	const [user, setUser] = useState();
	const [operations, setOperations] = useState();
	const [premises, setPremises] = useState();

	const fetchUser = async() => {
		let u = await getUser(username);
		u.name = u.firstName + ' ' + u.lastName;
		u.email = (u.email) ? u.email : 'N/A';
		u.phone = (u.phone) ? u.phone : 'N/A';
		let transformedUser = {
			roleString: u.roles.map(r => r.role).join(', '),
			username: u.username,
			email: u.email,
			phone: u.phone,
			name: u.name
		};

		setUser(transformedUser);
	}

	const fetchOperations = async() => {
		const fetchedOperations = await getOperationsForUser(username);
		setOperations(fetchedOperations);
	}

	const setPremiseInfo = () => {
		let premiseList = [];

		operations.map((operation) => {
			for (let i=0; i<operation.premises.length; i++){
				const premiseInfo = {
					name: operation.premises[i].name,
					pid: operation.premises[i].pid,
					operation: operation.businessName,
				}
				premiseList.push(premiseInfo);
			}
		})
		setPremises(premiseList);
	}

	useEffect(() => {
		fetchUser().then(() => {
			fetchOperations()
		})
	}, []);

	useEffect(() => {
			if(operations) setPremiseInfo();
	}, [operations]);

	const userCols = [
		{name: 'Parameter', id: 'parameter'},
		{name: 'Value', id: 'value'},
		{name: 'Actions', id: 'actions'}
	]
	
	return <>
		<PageCard title={username} back>
			{user ? <>
				<Link to={`/edit/password/${username}`}>
					<Button style={{marginTop: '10px'}}>Update Password</Button>
				</Link>

				<SimpleCard title="User Information" size="md">
					<StripedTable 
							columns={userCols}
							rows={[
								{parameter: 'Name', value: user.name, actions: <Link to={`/edit/name/${username}`} ><FlatButton color="#fefdb1">Edit</FlatButton></Link>},
								{parameter: 'Email', value: user.email, actions: <Link to={`/edit/email/${username}`} ><FlatButton color="#fefdb1">Edit</FlatButton></Link>},
								{parameter: 'Phone Number', value: user.phone, actions: <Link to={`/edit/number/${username}`} ><FlatButton color="#fefdb1">Edit</FlatButton></Link>},
								{parameter: 'Roles', value: user.roleString, actions: <Link to={`/roles/${username}`} ><FlatButton color="#fefdb1">Edit</FlatButton></Link>}
							]}
					/>
				</SimpleCard>

				{/* Operations Table */}
				{operations && operations.length > 0 ? <>
					<SimpleCard 
						title='Associated Operations'
						size="md"
					>
						<StripedTable 
							columns={operationColumns}
							rows={operations}
						/>
					</SimpleCard>

					{/* Premises Table */}
					<SimpleCard 
						title='Associated Premises'
						size="md"
					>
						<StripedTable 
							columns={premiseColumns}
							rows={premises}
						/>
					</SimpleCard>
					</> :
					<NoOpDiv>This user has no associated operations or premises</NoOpDiv>}
			</> : <>Loading...</>}
		</PageCard>
	</>
}

const Info = ({name, value}) => {
  return <div>
    <span>{name}:&nbsp;</span><span>{value}</span>
  </div>;
}

Info.propTypes = {
  name: propTypes.string,
  value: propTypes.any
}

const HalfDiv = styled.div`
  width: 50%;
  float: left;
  display: inline-block;
`;

const NoOpDiv = styled.div`
  margin-left: 10px;
	font-weight: bold;
`;

const FlatButton = styled.button`
  color: white;
  background: none;
  border-radius: 10px;
  border-color: ${props => props.color};
  border-style: solid;
  outline: none;
  cursor: pointer;
`;

export default UserView;
