// PremiseView.js
import React, { useState, useEffect } from 'react';
import PageCard from '../../components/PageCard.js';
import { agGreen } from '../../components/Colors';
import DescriptionCard from '../../components/DescriptionCard.js';
import LoadingCard from '../../components/LoadingCard.js';
import Loader from 'react-loader-spinner';
import TableComponent from '../../components/TableComponent';
import TableComponentMini from '../../components/TableComponentMini';
import { Redirect } from 'react-router-dom';
import { getUsername } from '../../utils/TokenUtils.js';
import { toast } from 'react-toastify';

const axios = require('axios');

const PremiseView = () => {
	const [premises, setPremises] = useState([]);
	const [buttonDisable, setButtonDisable] = useState(false);
	const [load, setLoad] = useState(false);
	const [redirect, setRedirect] = useState('');
	const [windowSize, setWindowSize] = useState(0);
	const [admin, setAdmin] = useState(false);

	useEffect(() => {
		axios.get(`/api/premises/user/${getUsername()}`).then(async (res) => {
			setPremises(res.data.map(premise => {
				const location = premise.location;
				const opname = premise.operation.businessName;
				const description = premise.description;
				return {
					...premise,
					operation: opname,
					location: 
						(location && 
						 location.coordinates &&
						 location.coordinates.length === 2) ? 
						location.coordinates[1] + ", " + location.coordinates[0] : "N/A",
					description: (description == "") ? 'N/A' : description
				};
			}));
			setLoad(true);
		});
	}, []);

	const fetchCurrentRole = async() => {
		const temp = await axios.get('/api/users/role');
		return temp;
	}

	const fetchAndSetRoleInfo = async () => {
		try {
			let fetchedRole = await fetchCurrentRole();			
			if(fetchedRole.data == 'admin')
				setAdmin(true);
			else
				setAdmin(false);

		} catch (err) {
			
		}
	}

	useEffect(() => {
		fetchAndSetRoleInfo();
	  }, []);


	if (window.innerWidth !== windowSize) {
		setWindowSize(window.innerWidth);
	}

	if (redirect !== '') {
		return <Redirect push to={redirect} />;
	}

	async function deletePremises({ _id }) {
		setButtonDisable(true);
		
		await axios
		.delete(`/api/premises/${_id}`)
		.then(function (response) {
			setPremises(premises.filter((premise) => premise._id !== _id));
		})
		.catch(function (error) {
			if (error.message == 'Request failed with status code 400' ){
				toast.error(error.response.data.message);
			}
			
		});
		setButtonDisable(false);
	}

	const viewPremise = ({ _id }) => {
		setRedirect(`premises/${_id}`);
	};

	const editPremise = ({_id}) => {
		setRedirect(`/premises/update/${_id}`);
	}

	const tableHeadings = [
		{ name: 'PID', id: 'pid' },
		{ name: 'Nickname', id: 'name' },
		{ name: 'Municipality', id: 'municipality' },
		{ name: 'Province', id: 'province' },
		{ name: 'Location (Latitude, Longitude)', id: 'location' },
		{ name: 'Operation', id: 'operation'}
	];
	const premiseFields = [
		{ name: 'PID', id: 'pid' },
		{ name: 'Nickname', id: 'name' },
		{ name: 'Municipality', id: 'municipality' },
		{ name: 'Province', id: 'province' },
		{ name: 'Location (Latitude, Longitude)', id: 'location' },
		{ name: 'Operation', id: 'operation'}
	];

	return (
		<>
			{!buttonDisable ? (
				<PageCard title='Premises' to='/premises/create' back>
					<DescriptionCard page='premise' create={true}></DescriptionCard>
					{!load ? (
						<>Loading...</>
					) : ( 
						<>
							{admin ? (
								<>
									{windowSize < 1300 ? (
										<TableComponentMini
											fields={premiseFields}
											list={premises}
											deleteCB={deletePremises}
											viewCB={viewPremise}
											editCB={editPremise}
										/>
									) : (
										<TableComponent
											headings={tableHeadings}
											data={premises}
											view={{ cb: viewPremise, title: 'View' }}
											edit={{ cb: editPremise, title: 'Edit' }}
											remove={{ cb: deletePremises, title: 'Delete' }}
											actions
										/>
									)}
								</>
							) : (
								<>
									{windowSize < 1300 ? (
										<TableComponentMini
											fields={premiseFields}
											list={premises}
											viewCB={viewPremise}
											editCB={editPremise}
										/>
									) : (
										<TableComponent
											headings={tableHeadings}
											data={premises}
											view={{ cb: viewPremise, title: 'View' }}
											edit={{ cb: editPremise, title: 'Edit' }}
											actions
										/>
									)}
								</>								
							)}
						</>
					)}
				</PageCard>
			) : (
				<PageCard
					title='Processing your request ...'
					loader={<Loader type='Oval' color={agGreen} height={50} width={150} />}
				>
					<LoadingCard />
				</PageCard>
			)}
		</>
	);
};

export default PremiseView;
