import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { addAdminToOperation, addUserToOperation, getOperationById, removeAdminFromOperation, removeUserFromOperation } from "../../utils/OperationUtils";
import { PageCardContainer, PageHeader } from "../../components/PageComponents";
import { BackLink } from "../../components/CommonComponents";
import styled from 'styled-components';
import { Button } from "../../components/CommonComponents";
import SearchTable from "../../components/SearchTable";
import ViewEditDispose from "../../components/ViewEditDispose";
import { toast } from 'react-toastify';

const Info = ({name, value}) => {
  return <><span><strong>{name}:</strong>&nbsp;{value}</span><br /></>;
}

const UserAdd = ({operationId, refresh}) => {
  const [username, setUsername] = useState('')
  const onClick = async (e) => {
    e.preventDefault();
    try {
      await addUserToOperation(operationId, username);
    } catch (err) {
      console.log({err});
      toast.error(`${err.response.data.message}`);
    }
    refresh();
  }
  return <>
    <label>Enter Username:</label>&nbsp;
    <input value={username} onChange={e => setUsername(e.target.value)} />&nbsp;
    <button disabled={username == ''} onClick={onClick}>Add User</button>
  </>
}

const AdminAdd = ({users, operationId, refresh}) => {
  const [selectedUser, setSelectedUser] = useState()
  const onClick = async(e) => {
    e.preventDefault();
    try {
      await addAdminToOperation(operationId, selectedUser);
    } catch (err) {
      console.log({err})
    }

    refresh();
  }
  return <>
    <label>Select a user</label>&nbsp;
    <select value={selectedUser} onChange={e => setSelectedUser(e.target.value)}>
      <option hidden>Select...</option>
      {users.map(u => <>
        <option value={u.username}>
          {u.username}
        </option>
      </>)}
    </select>&nbsp;
    <button disabled={selectedUser == undefined} onClick={onClick}>Make admin</button>
  </>
}
const OperationDetail = () => {
  const [operation, setOperation] = useState();
  const params = useParams();
  const operationId = params.id;

  const fetchOperation = async() => {
    const op = await getOperationById(operationId);
    op.premises.forEach(p => p.actions = <ViewEditDispose
      id={p._id}
      endpoint="/premises/"
      hideDispose
      hideReplace
      hideViewMore
      editName='Manage'
    />)

    op.users.forEach(u => u.actions = <>
      <button onClick={async (e) => {
        e.preventDefault();
        await removeUserFromOperation(operationId, u.username);
        fetchOperation();
      }}>Remove</button>
    </>)

    op.admins.forEach(u => u.actions = <>
      <button onClick={async (e) => {
        e.preventDefault();
        await removeAdminFromOperation(operationId, u.username);
        fetchOperation();
      }}>Remove</button>
    </>)

    if(!op.admins) op.admins = [];
    console.log({op});
    setOperation(op);
  }

  useEffect(() => {
    fetchOperation();
  }, [operation != null])

  const premiseColumns = [{
      id: 'name',
      name: 'Name'
    },
    {
      id: 'pid',
      name: 'Premise ID'
    },
    {
      id: 'address',
      name: 'Address'
    },
    {
      id: 'actions',
      name: 'Actions'
    }
  ]

  const userColumns = [{
      id: 'username',
      name: 'Username'
    },
    {
      id: 'actions',
      name: 'Actions'
    }
  ]

  return <>
    {(operation != null) ? <PageCardContainer>
      <BackLink onClick={history.goBack}>&larr; Back</BackLink>
      <ContentContainer>
        <PageHeader>Details for {operation.businessName}</PageHeader>
        <ManageSpan>
          <Link to={`/operations/manage/${operationId}`}>
            <Button>Manage</Button>
          </Link>
        </ManageSpan>
      </ContentContainer>
      <hr />
      <LeftContainer>
        <OperationInformationDiv>
          <OpInfoTitle>Operation Information</OpInfoTitle>
          <hr />
          <Info name='Operation Type' value={operation.operationType} />
          <Info name='Phone Number' value={operation.phoneNumber} />
          <Info name='CVSP' value={(operation.cvsp) ? 'True' : 'False'} />
          <Info name='SFCP' value={(operation.sfcp) ? 'True' : 'False'} />
        </OperationInformationDiv>
        <OperationInformationDiv>
          <OpInfoTitle>Premises</OpInfoTitle>
          <hr />
          <SearchTable
            columns={premiseColumns}
            rows={operation.premises}
            count={10}
            hideFilter
            hideViewMore
          />
        </OperationInformationDiv>
      </LeftContainer>
      <RightContainer>
        <OperationInformationDiv>
          <OpInfoTitle>Users</OpInfoTitle>
          <hr />
          <SearchTable
            columns={userColumns}
            rows={operation.users}
            count={10}
            hideFilter
            hideViewMore
          />
          {/* Have add area */}
          <UserAdd operationId={operationId} refresh={fetchOperation} />
        </OperationInformationDiv>
        <OperationInformationDiv>
          <OpInfoTitle>Admins</OpInfoTitle>
          <hr />
          <SearchTable
            columns={userColumns}
            rows={operation.admins}
            count={10}
            hideFilter
            hideViewMore
          />
          {/* Have add area */}
          <AdminAdd operationId={operationId} users={operation.users} refresh={fetchOperation}/>
        </OperationInformationDiv>
      </RightContainer>
    </PageCardContainer> :
    <>Loading...</>}
  </>
}

const ContentContainer = styled.div`
  display: flex;
`;

const LeftContainer = styled.div`
  max-width: 60%;
  width: 100%;
  float: left;
  display: inline-block;
  height: 100%;
`;

const RightContainer = styled.div`
  width: 40%;
  display: inline-block;
  height: 100%;
`



const OperationInformationDiv = styled.div`
  min-height: 200;
  padding: 10px;
  margin: 5px;
`;

const OpInfoTitle = styled.span`
  font-size: 2em;
`

const ManageSpan = styled.span`
  margin-left: 10px;
`;

export default OperationDetail;