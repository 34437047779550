import React from 'react';
import { FetchSlaughterReports } from '../../components/SheepReportComponents';
import SheepReportListComponent from '../../components/SheepReportListComponent';

const SlaughterListView = () => {
  return <>
    <SheepReportListComponent
      title="Slaughter Reports"
      name='slaughter report'
      createLink='/sheep/dispose'
      fetchReportsFunction={FetchSlaughterReports}
      detailEndpoint={'/reports/raw/'}
     />
  </>
}

export default SlaughterListView;