import { Link } from "react-router-dom"
import styled from "styled-components"
import { agGreen, deleteColor, viewColor } from "./Colors"
import { Button } from "./CommonComponents"

const { default: PageCard } = require("./PageCard")

const DescriptionDiv = styled.div`
  margin: 1em 0.5em;
  font-size: 24px;
  width: 800px;
`

const DescriptionSection = ({description}) => {

  return <>
    <DescriptionDiv>
      {description}
    </DescriptionDiv>
  </>
}

const DecisionDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const getColor = (color) => {
  if(color == 'primary') return agGreen;
  if(color == 'secondary') return viewColor;
  if(color == 'danger') return deleteColor;
  return agGreen;
}

const BackToHelpDiv = styled.div`
  text-align: center;
  margin-top: 20px;
  
  a {
    color: white;
    font-size: 20px;
  }
`;

const DecisionSection = ({choices}) => {

  return <>
    {/* Centered buttons corresponding to the decisions */}
    <DecisionDiv>
      {choices.map(c => (c.link) ? <>
        <Link to={c.link}>
          <Button style={{backgroundColor: getColor(c.color)}}>{c.name}{(c.description) ? <> - {c.description}</>:<></>}</Button>
        </Link>
      </>:<>
        <Button style={{backgroundColor: getColor(c.color)}} onClick={() => {
          if(c.update) c.update();
        }}>{c.name}{(c.description)?<> - {c.description}</>:<></>}</Button>
      </>)}
    </DecisionDiv>
  </>;
}

const DecisionCard = ({decision}) => {

  return <>
    <PageCard title={decision.title} line maxContent back onBack={decision.onBack}>
      {(decision.images && decision.images.length > 0) ? <>
        {/* Display Images */}
      </>:<></>}
      {(decision.description) ? <>
        <DescriptionSection description={decision.description} />
      </>:<></>}
      {(decision.choices && decision.choices.length > 0) ? <>
        {/* Have the buttons at the bottom of the screen */}
        <DecisionSection choices={decision.choices} />
      </>:<></>}
      {decision.backToHelp && 
        <BackToHelpDiv>
          <Link to='/help'>Back to help</Link>
        </BackToHelpDiv>
      }
    </PageCard>
  </>;
}

export default DecisionCard;