import React, { useState, useEffect, useReducer } from 'react';
import { Redirect } from 'react-router-dom';
import PageCard from '../components/PageCard.js';

import styled from 'styled-components';
import { viewColor } from '../components/Colors.js';
import {
  FormTextList,
  FormTextListItem,
  FormSelectInput,
  EditButton,
} from '../components/CommonComponents';
import { StyledTableCurved } from '../components/TableComponents.js';
import axios from 'axios';
import propTypes from 'prop-types';

// TODO: This should NOT be in its own page, but on the main events page!!!

const eventsList = [
  { title: 'Animal Medical', path: 'animalmedical' },
  { title: 'Animal Sighting', path: 'animalsighting' },
  { title: 'Animal Export', path: 'animalexport' },
  { title: 'Animal Movement', path: 'move' },
];
const tableHeadings = [
  { name: 'Event Type', id: 'event' },
  { name: 'Date', id: 'createdAt' },
  { name: 'Transaction ID', id: 'txID' },
];

function reducer(state, action) {
  let mutatedState;

  switch (action.type) {
    case 'append': {
      const filtered = state.eventsList.filter((item) => item !== action.value);
      mutatedState = {
        ...state,
        search: { ...state.search, events: [...state.search.events, action.value] },
        eventsList: filtered,
      };
      break;
    }
    case 'remove': {
      const filtered = state.search.events.filter((item) => item !== action.value);
      mutatedState = {
        ...state,
        search: { ...state.search, events: filtered },
        eventsList: [...state.eventsList, action.value],
      };
      break;
    }
    case 'date': {
      // TODO: Find records given a date range
      break;
    }
    case 'txID': {
      mutatedState = { ...state, search: { ...state.search, txID: action.value } };
      break;
    }
    case 'init': {
      return action.payload;
    }
    default:
      throw new Error();
  }

  const filtered = mutatedState.logs.filter((log) => {
    if (mutatedState.search.events.length && !mutatedState.search.events.includes(log.event)) {
      return false;
    }
    if (
      mutatedState.search.txID &&
      !String(log.txID).toLowerCase().includes(action.value.toLowerCase())
    ) {
      return false;
    }
    return true;
  });
  return { ...mutatedState, filtered };
}

export default function LogsViewer() {
  // const [data, dispatch] = useReducer(reducer, {empty:true});
  const [data, dispatch] = useReducer(reducer, {});
  const [load, setLoad] = useState(false);
  const [redirect, setRedirect] = useState();

  useEffect(() => {
    const promises = [];
    for (const event of eventsList) {
      promises.push(axios.get(`/api/${event.path}`));
    }

    const logs = [];
    Promise.all(promises).then((res) => {
      // Note: Order is perserved in Promise.all
      for (let i = 0; i < eventsList.length; ++i) {
        res[i].data.map((x) => {
          x.event = eventsList[i].title;
          logs.push(x);
          return x;
        });
      }

      setLoad(true);
      const payload = {
        eventsList,
        logs,
        filtered: logs,
        empty: !logs.length,
        search: { events: [], txID: '' },
      };
      dispatch({
        type: 'init',
        payload,
      });
    });
  }, []);

  const DisplayData = ({ element }) => {
    return (
      <tr>
        {tableHeadings.map((heading) => (
          <td key={heading.id} style={{ height: '35px' }}>
            {element[heading.id]}
          </td>
        ))}
        <td>
          <EditButton bColor={viewColor} onClick={() => viewLog(element)}>
            View
          </EditButton>
        </td>
      </tr>
    );
  };

  DisplayData.propTypes = {
    element: propTypes.any
  }

  const viewLog = (element) => {
    setRedirect({ pathname: `/events/view/${element.txID}`, state: element });
  };

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <PageCard title='Log Viewer' back>
      {!load ? (
        <>Loading...</>
      ) : (
        <>
          {data.empty ? (
            <NoData>There seems to be nothing here.</NoData>
          ) : (
            <StyledTableCurved>
              <thead>
                <tr>
                  {tableHeadings.map((heading) => (
                    <th key={heading.id}>{heading.name}</th>
                  ))}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <FormTextList>
                      {data.search?.events?.map((option) => (
                        <FormTextListItem
                          remove
                          onClick={() => dispatch({ type: 'remove', value: option })}
                          key={option.id}
                        >
                          {option}
                        </FormTextListItem>
                      ))}
                      <FormSelectInput
                        onClick={(e) => {
                          if (e.detail === 0) dispatch({ type: 'append', value: e.target.value });
                        }}
                      >
                        <option hidden>Filter events</option>
                        {data.eventsList?.map((option) => (
                          <option key={option.id}>{option.title}</option>
                        ))}
                      </FormSelectInput>
                    </FormTextList>
                  </td>
                  <td></td>
                  <td>
                    <input
                      placeholder='Filter Transaction ID'
                      value={data.search?.txID}
                      onChange={(e) => dispatch({ type: 'txID', value: e.target.value })}
                    />
                  </td>
                </tr>
                {data.filtered?.map((element) => (
                  <DisplayData key={element._id} element={element} />
                ))}
              </tbody>
            </StyledTableCurved>
          )}
        </>
      )}
    </PageCard>
  );
}

const NoData = styled.div`
  background-color: white;
  color: black;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  text-align: center;
`;
