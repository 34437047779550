import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PageCard from './PageCard';
import SimpleCard from './SimpleCard';
import { getUsername } from '../utils/TokenUtils';
import { validatePremiseExists } from '../utils/PremiseUtils';
import axios from 'axios';
import { Button } from './CommonComponents.js';
import DropDown from './DropDown.js';
import DateField from './DateField.js';
import TimeField from './TimeField.js';
import Input from './Input.js';
import styled from 'styled-components';
import StripedTable from './StripedTable';
import { extractDataFromHeaders } from '../utils/CSVUtils';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import propTypes from 'prop-types';

const SubmittingDiv = styled.div`
  text-align: center
`;
const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin>

    </FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

const CheckBox = ({onChange, checked}) => {
  return <input 
    style={{cursor: 'pointer'}} 
    type='checkbox' 
    checked={checked} 
    onChange={onChange} />;
}

CheckBox.propTypes = {
  onChange: propTypes.func,
  checked: propTypes.bool
}

const bulkMoveOut = async (submissionObject) => {
  try {
    toast.info("Submitting move-out report to server. It may take up to several minutes.");
    await axios.post(`/api/move/bulk`, submissionObject);
    return;
  } catch(err) {
    throw err.response.data;
  }
}

const fetchPremises = async() => {
  try {
    const username = getUsername();
    const request = await axios.get(`/api/premises/user/${username}`);
    const fetchedPremises = request.data;
    return fetchedPremises;
  } catch(err) {
    throw new Error(`An error occurred fetching premises: ${err.message}`, err);
  }
};

const fetchVehicles = async() => {
  const username = getUsername();
  const result = await axios.get(`/api/vehicles/user/${username}`);
  const fetchedVehicleIDs = result.data.map(v => ({
    id: v.licensePlateNum,
    name: v.name
  }));
  return fetchedVehicleIDs;
};

const DestinationMessageContainer = styled.div`
  margin-bottom: 20px;
  margin-left: 25px;
  color: red;
`;

const RadioButtonContainer = styled.div`
  margin-bottom: 20px;
  margin-left: 25px;
`;

const CenteredButtonDiv = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

const RequiredSpan = styled.span`
  color: red;
`;

const CsvMoveOutSheepSelect = ({data, headers}) => {
  const [sheeps, setSheeps] = useState([]);
  const [selectedSheeps, setSelectedSheeps] = useState([]);
  const [premises, setPremises] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [form, setForm] = useState({
    departurePremiseID: '',
    destinationPremiseID: '',
    destinationType: 'Abattoir',
    vehicleID: '',
    departureDate: new Date(),
    loadTime: new Date()
  });
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState('');

  const submitEnabled = () => {
    return (
      form.departurePremiseID &&
      form.destinationType &&
      form.vehicleID &&
      form.departureDate &&
      form.loadTime &&
      selectedSheeps.length > 0
    );
  };
  
  useEffect(() => {
    setSheeps(extractDataFromHeaders(data, headers));
    return fetchPremises()
      .then(fetchedPremises => {
        setPremises(fetchedPremises);
        return fetchVehicles()
          .then(fetchedVehicles => {
            setVehicles(fetchedVehicles);
            setForm({
              ...form, 
              departurePremiseID: fetchedPremises.length > 0 ? fetchedPremises[0].pid : '',
              vehicleID: fetchedVehicles.length > 0 ? fetchedVehicles[0].id : ''
            });
          });
      })
      .catch(err => {
        toast.error(`An error occurred while fetching data: ${err}`);
      });
  }, [data, headers]);

  const dateField = {
    labelName: 'Departure Date',
    id: 'departureDate',
    onChange: (date) => {
      setForm({
        ...form,
        departureDate: date
      });
    },
    value: form.departureDate
  }

  const loadField = {
    labelName: 'Load Time',
    id: 'loadTime',
    onChange: (time) => {
      setForm({
        ...form,
        loadTime: time
      })
    },
    value: form.loadTime
  }

  const baseColumns = [
    {
      id: 'isonum',
      name: 'ISO Number'
    }
  ];

  const columns = [
    {
      id: 'check',
      name: '' 
    },
    ...baseColumns
  ];

  const handleSubmit = async(e) => {
    try {
      e.preventDefault();
      setSubmitting(true);

      // remove spaces and commas from the ISO numbers
      const formattedIsoNumbers = selectedSheeps.map(s => {
        return s.isonum.replace(/[\s|,]/g, '');
      });
      
      await bulkMoveOut({
        moveOut: true,
        departurePID: form.departurePremiseID,
        destinationPID: form.destinationPremiseID ? form.destinationPremiseID.trim() : null,
        destinationType: form.destinationType,
        animals: formattedIsoNumbers,
        vehicleNumber: form.vehicleID,
        departureTime: form.departureDate,
        loadTime: form.loadTime.getHours() + ":" + form.loadTime.getMinutes()
      });
      setRedirect('/sheep');
    } catch (err) {
      setSubmitting(false);
      toast.dismiss();
      if (!err.message){
        toast.error('Something went wrong with creating the bulk move-out');
      } else if (err.message.includes("ISO number") && err.message.includes("already exists")) {
        const dupIsoNumber = err.message.match(/[0-9]{15}/);
        toast.error(`An error occurred because the following ISO number is taken: ${dupIsoNumber}`);
      } else if (err.message.includes("ISO number") && err.message.includes("is duplicated")) {
        const dupIsoNumber = err.message.match(/[0-9]{15}/);
        toast.error(`An error occurred because the following ISO number is duplicated: ${dupIsoNumber}`);
      } else {
        toast.error(err.message);
      }
    }
  }

  const SelectAllSection = () => <CenteredButtonDiv>
    <Button onClick={(e) => {
      e.preventDefault();
      setSelectedSheeps(sheeps);
    }}>Select All</Button>
    {(selectedSheeps.length > 0) ? 
      <Button onClick={(e) => {
        e.preventDefault();
        setSelectedSheeps([]);
      }}>Clear All</Button>
    : <></>}
  </CenteredButtonDiv>;
  const sheepRowsWithCheck = sheeps.map(s => ({
    ...s,
    check: <CheckBox 
      checked={selectedSheeps.filter(selectedSheep => selectedSheep.dummyID === s.dummyID).length > 0}
      onChange={() => {
        const alreadySelected = selectedSheeps.filter(selectedSheep => selectedSheep.dummyID === s.dummyID).length > 0;
        if(alreadySelected){
          const newSelectedSheeps = selectedSheeps.filter(sheep => sheep.dummyID !== s.dummyID);
          setSelectedSheeps(newSelectedSheeps)
        } else {
          const newSelectedSheeps = [
            sheeps.filter(sheep => sheep.dummyID === s.dummyID)[0],
            ...selectedSheeps
          ];
          setSelectedSheeps(newSelectedSheeps);
        }
      }}
    />
  }));

  const selectedSheepColumns = [
    ...baseColumns,
    {
      id: 'remove',
      name: ''
    }
  ];

  const selectedSheepRowsWithRemove = selectedSheeps.map(s => ({
    ...s,
    remove: <button
      onClick={(e) => {
        e.preventDefault();
        const newSelectedSheep = selectedSheeps.filter(sheep => sheep.dummyID !== s.dummyID);
        setSelectedSheeps(newSelectedSheep);
      }}
      style={{cursor: 'pointer'}}
    >Remove</button>
  }))

  if(redirect !== '') return <Redirect to={redirect} />;
  return <div style={{ maxWidth: '700px' }}>
    <PageCard line back title='Bulk Move-Out'>
      <DropDown
        id='departurePremiseID'
        name='Departure Premise Name'
        onChange={(e) => {
          setForm({
            ...form,
            departurePremiseID: e.target.value
          });
        }}
        value={form.departurePremiseID}
        options={
          premises.map(o => ({
            name: o.name,
            value: o.pid,
          }))
        }
        required
      />
        
      <Input
        id='destinationPremiseID'
        labelName='Destination Premise ID'
        onChange={(e) => setForm({
          ...form,
          destinationPremiseID: e.target.value
        })}
        value={form.destinationPremiseID}
      />

      <RadioButtonContainer>
        Destination Type: <>&nbsp;<RequiredSpan>*</RequiredSpan></><br></br>
        <input 
          type='radio' 
          name='destType' 
          checked={form.destinationType === 'Abattoir'} 
          onChange={() => setForm({...form, destinationType: 'Abattoir'})} 
          style={{cursor: 'pointer'}}/>
          Abattoir <br></br>
        <input 
          type='radio' 
          name='destType' 
          checked={form.destinationType === 'Community Pasture'} 
          onChange={() => setForm({...form, destinationType: 'Community Pasture'})} 
          style={{cursor: 'pointer'}}/>
          Community Pasture <br></br>
        <input 
          type='radio' 
          name='destType' 
          checked={form.destinationType === 'Other'} 
          onChange={() => setForm({...form, destinationType: 'Other'})} 
          style={{cursor: 'pointer'}}/>
          Other <br></br>
      </RadioButtonContainer>

      <DropDown
        id='vehicleID'
        name='Vehicle Name'
        onChange={(e) => {
          setForm({
            ...form,
            vehicleID: e.target.value
          });
        }}
        value={form.vehicleID}
        options={
          vehicles.map(o => ({
            name: o.name,
            value: o.id,
          }))
        }
        required
      />

      <DateField
        id={dateField.id}
        labelName={dateField.labelName}
        value={dateField.value}
        onChange={dateField.onChange}
        required
      />

      <TimeField
        id={loadField.id}
        labelName={loadField.labelName}
        value={loadField.value}
        onChange={loadField.onChange}
        required
      />

      <SimpleCard title='Select Sheep'>
        <StripedTable
          columns={columns}
          rows={sheepRowsWithCheck}
          ShowCount
          max={10}
          paginate
        />
        <SelectAllSection />
      </SimpleCard>
      {(selectedSheeps.length > 0) && 
        <SimpleCard title='Sheep selected to create'>
          <StripedTable
            columns={selectedSheepColumns}
            rows={selectedSheepRowsWithRemove}
            max={10}
            paginate
            ShowCount
          />
          {submitEnabled() && <CenteredButtonDiv>

            <Button onClick={handleSubmit} disabled={submitting}>
              Submit {`${selectedSheeps.length}`} Sheep
            </Button>
            {submitting && <SubmittingSpinner />}
            
           </CenteredButtonDiv>
          }
        </SimpleCard>
      }
    </PageCard>
  </div>
}

CsvMoveOutSheepSelect.propTypes = {
  data: propTypes.arrayOf(propTypes.string),
  headers: propTypes.arrayOf(propTypes.string)
}

export default CsvMoveOutSheepSelect;