import React, { useEffect, useState } from 'react';
import { useParams, Link, Redirect } from 'react-router-dom';
import {
	FormContainer,
	Button,
} from '../../components/CommonComponents';
import styled from 'styled-components';
import FormCard from '../../components/FormCard.js';
import Input from '../../components/Input.js';
import {
	getPremisesById,
	updatePremise
} from '../../utils/PremiseUtils.js';
import { toast } from 'react-toastify';
import LoadingCard from '../../components/LoadingCard.js';
import PageCard from '../../components/PageCard.js';
import GeolocationInput from '../../components/GeolocationInput';
import Loader from 'react-loader-spinner';
import { agGreen } from '../../components/Colors.js';


export default function PremiseUpdateView() {
	const [form, setForm] = useState({});
	const [fieldErrors, setFieldErrors] = useState({});
	const [messages, setMessages] = useState([]);
	const [redirect, setRedirect] = useState(false);
	const [load, setLoad] = useState(false);
	const { id } = useParams();
	const formInputs = [
		{
			name: 'Nickname',
			hint: 'Name',
			id: 'name',
			required: true
		},
		{
			name: 'Address',
			hint: '',
			id: 'address',
			required: true
		},
		{
			name: 'Municipality',
			hint: '',
			id: 'municipality',
			required: true
		},
		{
			name: 'Postal Code',
			hint: '',
			id: 'postalCode',
			required: true
		},
		{
			name: 'Description',
			hint: '',
			id: 'description'
		},
	];

	const submitOperation = async (e) => {
		e.preventDefault();

		//validate postal code
		const postalRegEx = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z] ?\d[ABCEGHJ-NPRSTV-Z]\d$/
		if (!postalRegEx.test(form.postalCode.trim())) {
			toast.error('Please enter a valid postal code (make sure to use capital letters)');
			return;
		}

		try {
			const res = await updatePremise(form, id);
			setRedirect(true);
		} catch (err) {
			const temp = err.message;
			toast.error(temp);
		}
	
	}

	useEffect(() => {
		const fetchedPremise = async () => {
			let fetchedPremise = await getPremisesById(id);
			
			if (
				fetchedPremise.location && 
				Array.isArray(fetchedPremise.location.coordinates) &&
				fetchedPremise.location.coordinates.length === 2
			) {
				setForm({
					...form,
					...fetchedPremise,
					location: fetchedPremise.location.coordinates
				});
			} else {
				setForm({
					...form,
					...fetchedPremise
				});
			}
		};
		fetchedPremise();
	}, []);

	const setGeolocation = () => {
		const onSuccess = (position) => {
			const location = [position.coords.longitude, position.coords.latitude];
			setForm({ ...form, location });
		};

		const onError = () => {
			toast.info("Could not get the geolocation of your device");
		}

		if (!navigator.geolocation) {
			toast.info("Geolocation is not supported by this browser");
		} else {
			navigator.geolocation.getCurrentPosition(onSuccess, onError);
		}
	};

	const clearLocation = () => {
		setForm({ ...form, location: null });
	}

	if (redirect) {
		return <Redirect to="/premises"/>
	}

	return (
		<>
			<FormCard 
				title='Update a Premise' 
				errors={fieldErrors} 
				messages={messages}
				back
			>
			{!load ? (
				<FormContainer onSubmit={submitOperation}>
					{formInputs.map(i => <>
						<Input
							id={i.id}
							placeholder={i.hint}
							labelName={i.name}
							onChange={(e) => setForm({
								...form,
								[e.target.name]: e.target.value
							})}
							value={form[i.id]}
							required={i.required}
						/>
					</>)}

					<GeolocationInput
						location={form.location}
						getLocation={setGeolocation}
						clearLocation={clearLocation}
					/>

					<div style={{ textAlign: 'center' }}>
						<Button disabled={false}>Update</Button>
					</div>
				</FormContainer>
			
				) : (
					<PageCard
					title='Processing your request ...'
					loader={<Loader type='Oval' color={agGreen} height={50} width={150} />}
					>
						<LoadingCard />
					</PageCard>
				)}
				</FormCard>
		</>
	);
}

const StyledLink = styled(Link)`
	color: white;
`;
