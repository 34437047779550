// SheepReportDetailView.js
import React, { useEffect, useState } from 'react';
import PageCard from './PageCard';
import styled from 'styled-components';
import { useParams } from 'react-router';
import propTypes from 'prop-types';
import axios from 'axios';
import { NAField } from '../components/SheepReportComponents';
import StripedTable from './StripedTable';
import SimpleCard from '../components/SimpleCard';

const columns = [
  {
    id: 'isoNumber',
    name: 'Iso Number'
  },
  {
    id: 'localMgmtNumber',
    name: 'Local Management Number'
  },
  {
    id: 'tattooNumber',
    name: 'Tattoo Number'
  },
  {
    id: 'breed',
    name: 'Breed',
  },
  {
    id: 'gender',
    name: 'Gender',
  },
];

const getSheepReports = (id) => {
  return axios.get(`/api/sheep/log/${id}`)
    .then((res) => {
      return res.data;  
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

const getPremiseName = (id) => {
  return axios.get(`/api/premises/name/${id}`)
    .then((res) => {
      return res.data;  
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

const SheepReportDetailView = ({title, verifyLink, reportType}) => {
  const [premiseName, setPremiseName] = useState([]);
  const [sheepReport, setSheepReport]  = useState([]); 
  const [reportLocation, setReportLocation] = useState(null);
  const params = useParams();
  const id = params.id;
  
  useEffect(() => {
    fetchSheepReport();
  }, [])

  const fetchSheepReport = async () => {
    //Fetch The report info
    let fetchedReport = await getSheepReports(id);
    setSheepReport(fetchedReport);

    // if this report has a geolocation, save that to a state as well
    if (
      fetchedReport[0].location &&
      Array.isArray(fetchedReport[0].location.coordinates) &&
      fetchedReport[0].location.coordinates.length === 2
    ) {
      const longlat = fetchedReport[0].location.coordinates;
      setReportLocation([longlat[1], longlat[0]]);
    }

    //Use PID to find premise name
    let fetchedPremiseName = await getPremiseName(fetchedReport[0].pid);
    setPremiseName(fetchedPremiseName[0].name);
  }

  //Set NA field if empty
  if (sheepReport[0]){
    if(!sheepReport[0].isoNumber) sheepReport[0].isoNumber = <NAField />;
    if(!sheepReport[0].localMgmtNumber) sheepReport[0].localMgmtNumber = <NAField />;
    if(!sheepReport[0].tattooNumber) sheepReport[0].tattooNumber = <NAField />;
    if(!sheepReport[0].breed) sheepReport[0].breed = <NAField />;
  }
  return <>
    <PageCard
      line
      back
      title={`View ${title} Report`}
      verify={verifyLink}
    >
      {(sheepReport[0] && premiseName) ? <>

        {(reportType === 'disposal') ? <>
        
        {/* Disposal Info panel */}
        <SimpleCard title="Disposal Information" size="md">
          <HalfDiv>
            <Info 
              name="Disposal Date" 
              value={(new Date(sheepReport[0].createdAt)).toISOString().split('T')[0]} 
            />
          </HalfDiv>
          <HalfDiv>
            <Info name="Disposal Type" value={sheepReport[0].action} />
          </HalfDiv>
        </SimpleCard>
        <br></br></>
        :<>
        {/* Report Info panel */}
        <SimpleCard title="Report Information" size="md">
          <HalfDiv>
            <Info 
              name="Date of Creation" 
              value={(new Date(sheepReport[0].createdAt)).toISOString().split('T')[0]} 
            />
          </HalfDiv>
          <HalfDiv>
            <Info name="Action Taken" value={sheepReport[0].action} />
          </HalfDiv>
        </SimpleCard>
        <br></br></>
        }

        {reportLocation &&
          <>
          <SimpleCard title="Location" size="md">
            <HalfDiv>
              <Info name="Latitude" value={reportLocation[0]} />
            </HalfDiv>
            <HalfDiv>
              <Info name="Longitude" value={reportLocation[1]} />
            </HalfDiv>
          </SimpleCard>
          <br></br>
          </>
        }

        {/* Premise Info panel */}
        <SimpleCard title="Premise Information" size="md">
          <HalfDiv>
            <Info name="PID" value={sheepReport[0].pid} />
          </HalfDiv>
          <HalfDiv>
            <Info name="Name" value={premiseName} />
          </HalfDiv>
            
        </SimpleCard>
      </>: <>Loading</>}
      <br></br>

      {/* Sheep Info Table */}
      <SimpleCard 
        title={(reportType === 'disposal') ? 'Sheep Disposed' : 'Sheep'} 
        size="md"
      >
        <StripedTable 
          columns={columns}
          rows={sheepReport}
        />
      </SimpleCard>
    </PageCard>
  </>
}

const Info = ({name, value}) => {
  return <div>
    <span>{name}:&nbsp;</span><span>{value}</span>
  </div>;
}

Info.propTypes = {
  name: propTypes.string,
  value: propTypes.any
}

SheepReportDetailView.propTypes = {
  title: propTypes.string,
  verifyLink: propTypes.string
}

const HalfDiv = styled.div`
  width: 50%;
  float: left;
  display: inline-block;
`

export default SheepReportDetailView;