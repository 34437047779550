import { useState } from 'react';
import DecisionCard from '../../components/DecisionCard';

const ImportDecisionView = () => {
  const [currentId, setCurrentId] = useState('INIT');

  const EventDecisions = [
    {
      id: 'INIT',
      backToHelp: true,
      title: 'Importing Animals',
      description: "If you are importing animals, you can create the import report here.",
      choices: [
        {
          name: 'Create Import Report',
          link: '/reports/import/create/manual'
        }
      ]
    }
  ]

  const getDecision = (id) => {
    const decisions = EventDecisions.filter(d => d.id == id);
    if(decisions.length == 0) {
      setCurrentId('INIT');
      alert('Invalid decision id');
      return EventDecisions[0];
    }

    return decisions[0];
  }

  return <DecisionCard decision={getDecision(currentId)} />;
}

export default ImportDecisionView;