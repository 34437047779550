import Papa from 'papaparse';
const moment = require('moment');
import { v4 as uuidv4} from 'uuid';

// TODO: Could probably use the same parseSheep function, just conditionally set the preview & transform flags

// These configurations are custom picked for parsing the sheep CSV's. An equiv one can be made for other files
export const parseHeaders = (path, completeCB) => {
	Papa.parse(path, {
		download: true,
		header: true, // To allow retrieval by field name
		skipEmptyLines: true,
		complete: completeCB,
		preview: 1,
		error: (error) => {
			// This is an error to do with FileReader and not papaparse
			console.log(`An error has occurred during parsing: ${error}`);
		},
	});
};

export const parseSheep = (path, headers, completeCB) => {
	// Set ewename and ramname
	let eweName = '';
	let ramName = '';
	if(headers.gender) {
		eweName = headers.eweName;	
		ramName = headers.ramName;
	}
	Papa.parse(path, {
		download: true,
		header: true, // To allow retrieval by field name
		// worker: true, // TODO: Fix... So that the page stays reactive and papaparser works on its own thread
		skipEmptyLines: true,
		transformHeader: (header) => {
			const val = Object.keys(headers).find((key) => headers[key] === header);
			return val ? val : header;
		},
		transform: (value, header) => {
			// Clean any fields that require it
			if (header === 'isonum') {
				// In order to clean: Remove anything except numbers. Then from the left, take 15 numbers
				return value.replace(/[^\d]/g, '').substring(0, 15);
			}
			if (header === 'gender') {
				if(value === ramName) return 'Male';
				if(value === eweName) return 'Female';
				return '';
			}
			if (header === 'date') {
				const parsed = moment(value, headers.dateFormat);
				if (!parsed.isValid()) {
					// TODO: Redirect user, since format is wrong...
				}
				return parsed.format('LL');
			}
			return value;
		},
		complete: completeCB,
		error: (error) => {
			// This is an error to do with FileReader and not papaparse
			console.log(`An error has occurred during parsing: ${error}`);
		},
	});
};

const parseCSVPromise = (csv) => {
	return new Promise(resolve => {
		Papa.parse(csv, {
			dynamicTyping: true,
			skipEmptyLines: true,
			complete: (results) => {
				resolve(results.data);
			}
		});
	});
};

const reduce = reducer => row => row.reduce(reducer, {});

// parseCSV can take either a CSV file or a CSV string
export const parseCSV = (csv) => {
	return parseCSVPromise(csv).then(rows => {
		const headers = rows[0].map((header, idx) => header || ('Column ' + (idx + 1)));
		return rows.slice(1).map(reduce((acc, val, idx) => ({ ...acc, [headers[idx]]: val })));	
	}).catch(err => {
		throw err;
	});
};

const getCSVValueFromHeader = (row, header) => {
  const value = row[header];
  // value is empty, return null
  if (!value || value === "") {
    return null;
  }
  // value can be converted to number, return value as string after converting to number
  if (!isNaN(+value)) {
    return ""+(+value);
  }
  // value can be converted to number by removing commas, return value as string after converting to number
  const commalessValue = (""+value).replace(/,/g, '');
  if (!isNaN(+commalessValue)) {
    return ""+(+commalessValue);
  }
  // value cannot be converted to number in any way, return as is
  return value;
}

export const extractDataFromHeaders = (data, headers) => {
  let sheeps = [];

  // transform into sheep
  data.forEach(row => {
    let sheep = {};
    Object.keys(headers).forEach(key => {
      const value = getCSVValueFromHeader(row, headers[key]);
      if(value && value !== '') sheep[key] = value;
    });
    if(Object.keys(sheep).length > 0) {
      // Generate a dummy id
      const prefix = headers.csipPrefix;
      if(prefix && prefix !== '' && sheep.isoNumber && sheep.isoNumber !== '') sheep.isoNumber = `${prefix}${sheep.isoNumber}`;
      sheep.dummyID = uuidv4();
      sheeps.push(sheep);
    }
  });

  return sheeps;
}