import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../images/logo.png';
import { FormInputLabel, FormSelectInput } from '../components/CommonComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faBell,
  faUser,
  faHome,
  faSignOutAlt,
  faList,
  faLocationArrow,
  faSignInAlt,
  faUserPlus,
  faFolder
} from '@fortawesome/free-solid-svg-icons';
import { getRoles,
  getUsername
 } from '../utils/TokenUtils';
import axios from 'axios';
import { getUser } from '../utils/UserUtils';
import propTypes from 'prop-types';

const Navbar = ({ token, handleLogout, messages, selectedRole }) => { 
  const [roles, setRoles] = useState([]);
  const [form, setForm] = useState('');
  const [newNotification, setNewNotification] = useState(false);
  const [user, setUser] = useState({});
  
  useEffect(() => {
    if (token) setRoles(getRoles());
    if (token) fetchCurrentRole();
    if (token) checkUnread();
  }, [token]);

  const fetchCurrentRole = async() => {
    const temp = await axios.get('/api/users/role');
    setForm({
      ...form,
      role: temp.data
    })
  }

  const checkUnread = async() => {
    const unread = await axios.get('/api/notifications/check');
    setNewNotification(unread.data);
  }

  const fetchRoles = async(e) =>{
    e.preventDefault();
    const temp = await getUser(getUsername()); 
    setRoles(temp.roles);
  }

  
  if (user.roles){
    setRoles(user.roles);
  }
  const handleSubmit = async(e, data) => {
    e.preventDefault();
    try {
      const replaceInfo = {
        role: data
      };
      await axios.put('/api/users/role', replaceInfo);
      
    } catch(err){

    }
  }


  if (token) checkUnread();
  
  return (
    <Nav>
      <Left>
        <Img onClick={() => window.open('https://www.cansheep.ca/')} />
        <Title>
            <Link to={'/'} style={{ color: 'inherit', textDecoration: 'inherit', fontSize: token ? 'inherit' : '70px'}}>
              <span style={{ color: '#17A737' }}>Agro</span>Ledger
            </Link>
            {token && <OperationContainer>
              <SelectLabel htmlFor='input'>Selected Role:</SelectLabel>
              <FormSelectInput style={{ width: '50%' }} name='role' value={form['role']} 
                onClick = {(e) => {
                  fetchRoles(e);
                }}
                onChange={(e) => {
                  setForm({
                    ...form,
                    role: e.target.value
                    
                  })
                  handleSubmit(e, e.target.value);
                }}>
                  <option hidden > Choose a role </option>

                  {(roles) ? <>
                    {roles.map((r) => (
                    <option value={r.role} key={r.role}>{r.role}</option>
                  ))}
                  </>: <>Loading</>}
              </FormSelectInput>
          </OperationContainer>}
        </Title>
      </Left>
      <Right>
        <NavActions>        
          {token ? (
            <>
              <NavLinkContainer to='/'>
                <FontAwesomeIcon icon={faHome} color='gray' size='lg' />
                {' '}Home
              </NavLinkContainer>
              <NavLinkContainer to='/premises'>
                <FontAwesomeIcon icon={faLocationArrow} color='gray' size='lg' />
                {' '}Premises
              </NavLinkContainer>
              <NavLinkContainer to='/sheep'>
                <FontAwesomeIcon icon={faList} color='gray' size='lg' />
                {' '}Sheep
              </NavLinkContainer>
              <NavLinkContainer to='/reports/dash'>
                <FontAwesomeIcon icon={faFolder} color='gray' size='lg' />
                {' '}Reports
              </NavLinkContainer>
              <NavLinkContainer to={`/users/${getUsername()}`}>
                  <FontAwesomeIcon icon={faUser} color='gray' size='lg' />
                  {' '}Profile
              </NavLinkContainer>
              <NavLinkContainer to='/notifications'>
              
               {newNotification? (
                  <FontAwesomeIcon icon={faBell} color='orange' size='lg' />
                ) : (
                  <FontAwesomeIcon icon={faBell} color='grey' size='lg' />
                )}
                {messages > 0 && <MessageCount>{messages}</MessageCount>}
                {' '}Notifications
              </NavLinkContainer>
              <NavLinkContainer onClick = {handleLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} color='gray' size='lg'/>
                {' '}Logout
              </NavLinkContainer>
              
            </>
          ) : (
            <>
              <NavLinkContainer className = 'before' to = '/register'>
                <FontAwesomeIcon icon={faUserPlus} color='gray' size='lg'/>
                {' '}Register
              </NavLinkContainer>
              <NavLinkContainer className = 'before' to = '/login'>
                <FontAwesomeIcon icon={faSignInAlt} color='gray' size='lg'/>
                {' '}Login
              </NavLinkContainer>
            </>
          )}
        </NavActions>
        </Right>
    </Nav>
  );
};

Navbar.propTypes = {
  token: propTypes.any,
  handleLogout: propTypes.any,
  messages: propTypes.any,
  selectedRole: propTypes.any
};

const Nav = styled.div`
  padding: 0 10px;
  background: #F5DEB3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: max-content;
`;

const Right = styled.div`
  display: flex;
  justify-content: right;
`;

const Left = styled.div`
  display: flex;
  justify-content: left;  
`;

const Title = styled.div`
  width: 100%;
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  font-size: 60px;
  margin: 5px;
  margin-left: 10px;
`;

const Img = styled.img.attrs({
  src: logo,
})`
  height: 80px;
  margin-left: 10px;
  margin-right: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
  @media (max-width: 1000px) {
    position: static;
  }
`;

const NavActions = styled.div`
  display: flex;
  vertical-align: text-top;
  right: 0;
  align-items: center;
  clear: right;
  @media (max-width: 1000px) {
    position: static;
  }
`;

const NavLinkContainer = styled(Link)`
  margin: 10px;
  color: black;
  text-decoration: none;
  font-size: 17px;

  &:hover {
    font-weight: bold;
    font-size: 20px;
  }

  &.before{
    font-size: 25px;

    &:hover {
      font-weight: bold;
      font-size: 30px;
    }
  }
`;

const MessageCount = styled.span`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 15px;
  height: 15px;
  font-size: 12px;
  text-align: center;
  color: white;
  background-color: red;
  border-radius: 50%;
`;

const RoleSwitchButton = styled.button`
  background-color: #17a737;
  border: none;
  border-radius: 10px;
  padding: 8px;
  color: white;
  font-family: Inter-Regular;
	font-size: 15px;
	margin: 0 0 10px 10px;
`

const OperationContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 10px;
  margin-bottom: 10px;
  line-height: 20px;
`;

const SelectLabel = styled.label`
	font-family: Inter-Regular;
	font-size: 15px;
	margin: 5px;
`;

export default Navbar;
