import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import FormCard from '../../components/FormCard.js';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import LoadingCard from '../../components/LoadingCard.js';
import PageCard from '../../components/PageCard.js';
import { agGreen } from '../../components/Colors.js';
import { getUsername } from '../../utils/TokenUtils';
import Input from '../../components/Input.js';
import DateField from '../../components/DateField.js';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import DropDown from '../../components/DropDown.js';
import axios from 'axios';
import { getPremisesForUser } from '../../utils/PremiseUtils';
import { getAllSheeps } from '../../utils/SheepUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 	
  FormInputWrapper,
	FormInputLabel,
	FormSelectInput,
  Button,
} from '../../components/CommonComponents';
import StripedTable from '../../components/StripedTable';
import SimpleCard from '../../components/SimpleCard';
import GeolocationInput from '../../components/GeolocationInput';
import { createTempExportReport } from '../../utils/ExportUtils';
import { countries } from '../../utils/Countries';

const CsvCreateComponent = () => {
  return <>
    <SimpleCard
      title="CSV Creation"
      description="Use a CSV to create a report" 
    >
      <CsvButtonContainer>
        <Link to="/reports/importExport/bulk">
          <CsvButton>Create report from a csv</CsvButton>
        </Link>
      </CsvButtonContainer>
    </SimpleCard>
  </>
}

const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin></FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

const checkForSpecial = (text) => {
  var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  if(format.test(text)){
    return true;
  } else {
    return false;
  }
}

const validateForm = (form, animal) => {
  const arriveDate = new Date(form.arrivalDate);
  const departDate = new Date(form.departureDate);
  const dateNow = new Date();

  if (checkForSpecial(form.destinationPremiseID) ){
    toast.error("Error! incorrect PID format");
    return false
  } else if (departDate > dateNow) {
    toast.error("Error! Select a depart date that is not in the future")
    return false;
  } else if (!form.destinationPremiseID || form.destinationPremiseID == ''){
    toast.error("Error! Please enter a valid PID of the destination");
    return false; 
  } else if (animal.current.length < 1){
    toast.error("Error! Please select at least one sheep for export");
    return false; 
  } else if (arriveDate < departDate) {
    toast.error("Error! Invalid dates, please re-enter a valid date");
    return false;
  } else if (!form.vehicleID || form.vehicleID == ''){
    toast.error("Error! Please enter a valid vehicle Information");
    return false; 
  } else if (!form.location || form.location == ''){
    toast.error("Error! Please enter a location");
    return false; 
  } else {
    return true;
  }
}

const selectSheepsColumn = [
  {
    id: 'check',
    name: 'Select' 
  },
  {
    id: 'isoNumber',
    name: 'ISO Number'
  },
  {
    id: 'localMgmtNumber',
    name: 'Local Management Number'
  },
  {
    id: 'tattooNumber',
    name: 'Tattoo Number'
  },
  {
    id: 'breed',
    name: 'Breed'
  },
  {
    id: 'gender',
    name: 'Gender'
  }
];


const TempExportView = () => {
  const [load, setLoad] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState('');

  //form information that is used in the move log
  const [form, setForm] = useState({
    location: '',
    departurePremiseID: '',
    destinationPremiseID: '',
    destinationType: 'Abattoir',
    vehicleID: '',
    departureDate: new Date(),
    arrivalDate: new Date(),
    geolocation: null
  });
  const animals = useRef([]);
  const checked = useRef({});

  //state vars for retrieving and displaying dropdown options
  const [myPremises, setMyPremises] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [allAnimals, setAllAnimals] = useState([]);
  const [availableAnimals, setAvailableAnimals] = useState([]);


  const fetchVehicles = async() => {
    const username = getUsername();
    const result = await axios.get(`/api/vehicles/user/${username}`);
    
    const fetchedVehicleIDs = result.data.map(v => ({
      id: v.licensePlateNum,
      name: v.name
    }));

    setVehicles(fetchedVehicleIDs);
    return fetchedVehicleIDs;
  };

  useEffect(() => {
    getPremisesForUser(getUsername())
      .then((userPremises) => {
        setMyPremises(userPremises);
        return getAllSheeps()
          .then((allSheeps) => {
            setAllAnimals(allSheeps.map((sheep) => ({
              ...sheep,
              isoNumber: sheep.tag.isoNumber,
              localMgmtNumber: sheep.tag.localMgmtNumber,
              tattooNumber: sheep.tag.tattooNumber,
              check: <input type='checkbox' 
                checked={checked.current[sheep._id]}
                onChange={() => {
                  if (checked.current[sheep._id]) {
                    animals.current = animals.current.filter(animalId => animalId !== sheep._id);
                    checked.current[sheep._id] = false;
                  } else {
                    animals.current = [...animals.current, sheep._id];
                    checked.current[sheep._id] = true;
                  }
                }}
                style={{cursor: 'pointer'}} 
              />
            })));
            return fetchVehicles();
          })
          .then((fetchedVehicleIDs) => {
            setForm({
              ...form,
              departurePremiseID: userPremises.length > 0 ? userPremises[0].pid : '',
              vehicleID: fetchedVehicleIDs.length > 0 ? fetchedVehicleIDs[0].id : ''
            });
            setLoad(false);
          });
      })
      .catch(() => {
        toast.error("An error occurred while fetching the data. Please reload this page.");
      });
  }, []);

  useEffect(() => {
    const departurePremise = myPremises.find((premise) => premise.pid === form.departurePremiseID);
    if (departurePremise) {
      var premiseAll = allAnimals.filter((animal) => animal.premise === departurePremise._id);
      premiseAll = premiseAll.filter((animal) => !animal.isExported);
      setAvailableAnimals(premiseAll);
    }
    animals.current = [];
    checked.current = {};
  }, [form.departurePremiseID, allAnimals]);

  const premiseDropDown = {
    name: 'Departure Site',
    id: 'departurePremiseID'
  };

  const vehicleDropDown = {
    name: 'Vehicle Name',
    id: 'vehicleID'
  }

  const destPremiseInput = [
    {
      name: 'PID  of Destination Site',
      hint: '112334556778990',
      id: 'destinationPremiseID'
    }
  ]

  const dateField = {
    labelName: 'Departure Date',
    id: 'departureDate',
    onChange: (date) => dateChange(date, 'departureDate'),
    value: form.departureDate
  }

  const arrivalDateField = {
    labelName: 'Arrival Date',
    id: 'arrivalDate',
    onChange: (date) => dateChange(date, 'arrivalDate'),
    value: form.arrivalDate
  }

  const dateChange = (date, fieldName) => {
    setForm({
      ...form,
      [fieldName]: date
    })
  };

  const setGeolocation = () => {
		const onSuccess = (position) => {
			const geolocation = [position.coords.longitude, position.coords.latitude];
			setForm({ ...form, geolocation });
		};

		const onError = () => {
			toast.info("Could not get the geolocation of your device");
		}

		if (!navigator.geolocation) {
			toast.info("Geolocation is not supported by this browser");
		} else {
			navigator.geolocation.getCurrentPosition(onSuccess, onError);
		}
	};

	const clearLocation = () => {
		setForm({ ...form, geolocation: null });
	}

  const handleSubmit = () => {
    setSubmitting(true);
    if (validateForm(form,animals)){
      toast.info("Submitting temporary export report to the backend. It may take up to a minute. You will be redirected when the report has been created. Please stay on this page.");
      createTempExportReport({
        isTemp: true,
        departurePID: form.departurePremiseID,
        destinationPID: form.destinationPremiseID ? form.destinationPremiseID : null,
        destinationType: form.destinationType,
        animals: animals.current,
        vehicleNumber: form.vehicleID,
        departureTime: form.departureDate,
        arrivalTime: form.arrivalDate,
        location: form.location,
        geolocation: form.geolocation
      }).then(() => {
        toast.success("Success: Report created");
        setRedirect(`/sheep`);
      }).catch((err) => {
        setSubmitting(false);
        toast.error(`Backend error: ${err}`);
      });
    } else {
      setSubmitting(false);
    }
  }

  if (redirect) return <Redirect to={redirect}/>
  return (
		<ReportContainer>
        {!load ? (
				<FormCard title='Create a temporary export report' back>
          <CsvCreateComponent/>
          <SimpleCard 
            title="Temp Export Info"
  
          ></SimpleCard>
          <InstructionContainer>
            Enter the following information about the export.
          </InstructionContainer>
          <DropDown
            id={premiseDropDown.id}
            name={premiseDropDown.name}
            onChange={(e) => {
              setForm({
                ...form,
                departurePremiseID: e.target.value
              });
            }}
            value={form[premiseDropDown.id]}
            options={
              myPremises.map(o => ({
                name: o.name,
                value: o.pid,
              }))
            }
            required
          />
          <FormInputWrapper>
            <FormInputLabel>
              Location
              <>&nbsp;<RequiredSpan>*</RequiredSpan></>
            </FormInputLabel>
            <FormSelectInput
              value={form['location']}
              onChange={(e) => {
              
                setForm({
                  ...form,
                  location: e.target.value
                })
                console.log(form)
              }}
            > 
              <option hidden > Add a Country </option>
              {countries.map((country, idx) => (
                <option key={idx} value={country}>
                  {country}
                </option>
              ))}
            </FormSelectInput>
          </FormInputWrapper>


          {destPremiseInput.map(i => <>
            <Input
              id={i.id}
              placeholder={i.hint}
              labelName={i.name}
              onChange={(e) => setForm({
                ...form,
                destinationPremiseID: e.target.value
              })}
              value={form[i.id]}
              required
            />
          </>)}

          <RadioButtonContainer>
            Destination Type: <>&nbsp;<RequiredSpan>*</RequiredSpan></><br></br>
            <input 
              type='radio' 
              name='destType' 
              checked={form.destinationType === 'Abattoir'} 
              onChange={() => setForm({...form, destinationType: 'Abattoir'})} 
              style={{cursor: 'pointer'}} />
						  Abattoir <br></br>
						<input 
              type='radio' 
              name='destType' 
              checked={form.destinationType === 'Community Pasture'} 
              onChange={() => setForm({...form, destinationType: 'Community Pasture'})} 
              style={{cursor: 'pointer'}} />
						  Community Pasture <br></br>
            <input 
              type='radio' 
              name='destType' 
              checked={form.destinationType === 'Other'} 
              onChange={() => setForm({...form, destinationType: 'Other'})} 
              style={{cursor: 'pointer'}} />
						  Other <br></br>
          </RadioButtonContainer>

          <SimpleCard title='Select sheep to move'>
            <StripedTable
              columns={selectSheepsColumn}
              rows={availableAnimals}
            />
          </SimpleCard>

          <DropDown
            id={vehicleDropDown.id}
            name={vehicleDropDown.name}
            onChange={(e) => {
              setForm({
                ...form,
                vehicleID: e.target.value
              });
            }}
            value={form[vehicleDropDown.id]}
            options={
              vehicles.map(o => ({
                name: o.name,
                value: o.id,
              }))
            }
            required
          />

          <DateField
            id={dateField.id}
            labelName={dateField.labelName}
            value={dateField.value}
            onChange={dateField.onChange}
            required
          />

          <DateField
            id={arrivalDateField.id}
            labelName={arrivalDateField.labelName}
            value={arrivalDateField.value}
            onChange={arrivalDateField.onChange}
            required
          />

          <GeolocationInput
            location={form.geolocation}
            getLocation={setGeolocation}
            clearLocation={clearLocation}
          />

          <Button type='button' disabled={submitting} onClick={handleSubmit} >

            Create
          </Button>

          {(submitting) ? <SubmittingSpinner />: <></>}
        </FormCard>

        ) : (
				<PageCard
					title='Processing your request ...'
					loader={<Loader type='Oval' color={agGreen} height={50} width={150} />}
				>
					<LoadingCard />
				</PageCard>
			)}
		</ReportContainer>
	);
}

const ReportContainer = styled.div`
  width: fit-content;
  max-width: 700px;
	color: white;
	position: relative;
	margin: 0;
	padding: 10px;
	display: flex;
	flex-direction: column;
`;

const InstructionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
`;

const RadioButtonContainer = styled.div`
  margin-bottom: 20px;
  margin-left: 25px;
`;

const SubmittingDiv = styled.div`
  text-align: center
`;

const RequiredSpan = styled.span`
  color: red;
`

const CsvButtonContainer = styled.div`
  width: 40%;
  display: inline-block;
  text-align: center;
  margin-top: 20px;
`
const CsvButton = styled.button`
  background-color: #17a737;
  border: none;
  border-radius: 10px;
  padding: 1rem;
  font-size: 1rem;
  color: white;
  cursor: pointer
`
export default TempExportView;