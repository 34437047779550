// PIDRouter.js
import React from 'react';
import { Route } from 'react-router-dom';
import PremiseIDList from '../views/Premise/PremiseIDList';
import PremiseIDView from '../views/Premise/PremiseIDView';
import PremiseIDCreate from '../views/Premise/PremiseIDView';

const PIDRouter = () => <>
  <Route exact path='/pid' component={PremiseIDList} />
  <Route exact path='/pid/id/:id' component={PremiseIDView} />
  <Route exact path='/pid/create' component={PremiseIDCreate} />
</>

export default PIDRouter;
