import React, { useState } from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { agGreen} from './Colors.js'
const StripedTableDiv = styled.div`
`;

const StyledTable = styled.table`
  width: 100%;
  background-color: #424242;
  border-collapse: collapse;
  border: none;
  tr:nth-child(even) {
    background-color: #515151;
  }
  td:last-child {
  }
  th:last-child {
  }
`;

const StyledTh = styled.th`
  text-align: left;
  padding-bottom: .5rem;
  padding-left: .5rem;
  padding-top: .5rem;
  background-color: #17a737;
  width: 20ch;
`;

const StyledInputTh = styled.th`
  text-align: center;
  background-color: #17a737;
  padding-bottom: 0.5rem;
  text-align: left;
  width: 20ch;
 `;

const StyledTd = styled.td`
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: .5rem;
  padding-right: .5rem;
  width: 20ch;
`;

const StyledInput = styled.input`
  border-radius: 5px;
`;

const ShowingSpan = styled.div`
  margin-top: 0.5rem;
  font-size: 0.85rem;
  text-align: right;
`

const PageNumbers = styled.div`
  margin-top: 10px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;

  span {
    background-color: #333333;
    padding: 8px 16px;
    border: 1px solid #fff;

    &.active {
      color: black;
      background-color: ${agGreen};
      border: 1px solid ${agGreen};
    }

    &[disabled] {
      pointer-events: none;
    }

    &:hover:not(.active):not(.arrow):not([disabled]) {
      cursor: pointer;
      background-color: #fff;
      color: black;
    }

    &:first-of-type {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-of-type {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
`;

const PageButton = ({label, clickHandler, active}) => {
  return <>
    <span className={(active) ? 'active' : ''} onClick={clickHandler}>{label}</span>
  </>
}

PageButton.propTypes = {
  label: propTypes.any,
  clickHandler: propTypes.any,
  active: propTypes.any
};

const PageSelector = ({currentPage, totalPages, incHandler, decHandler, clickHandler}) => {
  const isLeftMost = currentPage === 1;
  const isRightMost = currentPage === totalPages;
  const getPagesToDisplay = () => {
    let pages = [];
    let i = currentPage - 1;
    for(i; i <= currentPage + 1; i++){
      if(i > 0 && i <= totalPages) pages.push(i)
    }
    // Check for first page
    if(!pages.includes(1)) pages.unshift(1);
    if(!pages.includes(totalPages) && totalPages > 0) pages.push(totalPages);
    return pages;
  } 

  const generatePageButtons = () => {
    const pages = getPagesToDisplay();
    return <>
      {pages.map(p => {
        const nextPage = p+1;
        const includeEllipsis = ((nextPage <= totalPages) && !pages.includes(nextPage))
        const active = p === currentPage;
        return <>
          {(active) ? 
            <PageButton
              label={p}
              clickHandler={() => clickHandler(p)}
              active
            />
          : <PageButton 
            label={p} 
            clickHandler={() => clickHandler(p)}
          />}
          {(includeEllipsis) ? <span className='arrow'>&hellip;</span> : <></>}
        </>
      })}
    </>
  }

  return <>
    <PageNumbers>
      {/* decrementor */}
      <span 
        disabled={isLeftMost} 
        onClick={() => decHandler()}
      >
        &larr;
      </span>
      {/* numbers */}
      {generatePageButtons()}
      {/* incrementor */}
      <span
        disabled={isRightMost}
        onClick={() => incHandler()}
      >
        &rarr;
      </span>
    </PageNumbers>
  </>;
}

PageSelector.propTypes = {
  currentPage: propTypes.number,
  totalPages: propTypes.number,
  incHandler: propTypes.any,
  decHandler: propTypes.any,
  clickHandler: propTypes.any
}

const StripedTable = ({rows, columns, ShowFilters, max, ShowCount, paginate}) => {
  var pageNumberBeforeMax 
  const [filters, setFilter] = useState(columns.map(c => ({
    name: c.id,
    filter: ''
  })));
  const [page, setPage] = useState(1);

  const incrementPage = () => {
    setPage(page + 1);
  }

  const decrementPage = () => {
    setPage(page -1);
  }

  const gotoPage = (p) => {
    setPage(p);
  }

  const getSliceForPage = (page) => {
    const start = (page - 1) * max;
    const end = page * max;
    const slice = rows.slice(start, end);
    return slice;
  }

  const getSliceForFilterPage = (page) => {
    const start = (page - 1) * max;
    const end = page * max;
    const slice = filteredByPage.slice(start, end);
    return slice;
  }

  const setFilterByName = (name, filter) => {
    const newFilters = [...filters]
    const indexToChange = newFilters.map(f => f.name).indexOf(name);

    newFilters[indexToChange].filter = filter;

    setFilter(newFilters);
  };

  if(!rows) rows = [];
  if(!columns) columns = [];

  let filteredRows = rows.filter(r => {
    let result = [];
    result = filters.map(f => {
      
      if(f.filter === '') return true;
      
      if(!r[f.name]){
        return false;
      }

      if (f.filter === 'male'){
        if (r.gender === 'female'){
          return false;
        }
      }

      if(typeof r[f.name] != 'string') {
        return false;
      }
      return r[f.name].toLowerCase().includes(f.filter.toLowerCase());
    });
    return !result.includes(false);
  });

  let filteredByPage = filteredRows;
  if(max){

    let isEmpty = true;

    filters.map(f =>{
      if(f.filter != '') isEmpty =  false;
    });


    pageNumberBeforeMax = filteredRows.length;
    if(paginate && isEmpty){
      filteredByPage = getSliceForPage(page);
    } else if (paginate){
      filteredByPage = getSliceForFilterPage(page)
    }
  }
  
  const ColumnContainsString = (columnId) => {
    let stringFound = false;
    rows.forEach(r => {
      if(typeof r[columnId] === 'string') stringFound = true;
    });

    return stringFound;
  }

  return <StripedTableDiv>
    <StyledTable>
      <thead>
      {/* Headers */}
      <tr>
        {columns.map(c => 
          <StyledTh key={c.id}>{c.name}</StyledTh>
        )}
      </tr>
      {(ShowFilters) ? 
      <tr>
        {columns.map(c =>
          (ColumnContainsString(c.id) === false) ?
          <StyledInputTh key={c.id}></StyledInputTh> :
          <StyledInputTh key={c.id}>
            <StyledInput 
              onChange={(e) => {
                setFilterByName(c.id, e.target.value);
              }}
              value={filters[c.id]}
            />
          </StyledInputTh>)}
      </tr>
      :<></>}
      </thead>
      <tbody>
      
      {filteredByPage.map((r, rowIdx) => {
        return <tr key={rowIdx}>
          {columns.map((c, colIdx) => {
            return <StyledTd key={`${rowIdx}-${colIdx}`}>
              {Array.isArray(r[`${c.id}`]) ? r[`${c.id}`].map((item, idx) => <div key={idx}>{item}</div>) : r[`${c.id}`]}
            </StyledTd>
          })}
        </tr>
      }
      )}

      </tbody>
    </StyledTable>
    {(ShowCount) ? <ShowingSpan>Showing {filteredRows.length} of {rows.length}</ShowingSpan> : <></>}
    {(paginate) ?
      <PageSelector  
        currentPage={page}
        totalPages={Math.ceil(pageNumberBeforeMax / max)}
        incHandler={incrementPage}
        decHandler={decrementPage}
        clickHandler={gotoPage}
      /> : <></>
      
    }
  </StripedTableDiv>
}

StripedTable.propTypes = {
  rows: propTypes.any,
  columns: propTypes.any,
  ShowFilters: propTypes.any,
  max: propTypes.any,
  ShowCount: propTypes.any,
  paginate: propTypes.any
}

export default StripedTable;