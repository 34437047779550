//SearchTable.js
import React, { useState } from 'react';
import StripedTable from './StripedTable';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';
import propTypes from 'prop-types';

const MoreButton = styled.button`
  background-color: #17a737;
  border: none;
  border-radius: 10px;
  padding: .5em;
  font-size: 1em;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  text-align: right;
  margin-top: 0.5em;
`;

const SearchBarContainer = styled.div`
  width: 100%;
  margin-bottom: 0.5rem;
`;

const filterRowsByCriteria = (rows, filterField, filter) => {
  if(!filter || filter === '') return rows;
  if(rows.length === 0) return rows;
  
  let field;
  
  return rows.filter((r) => {
    if (!r[`${filterField}`]) field = 'animalIdentifications';
    else field = filterField;
    
    for (let i =0; i < r[`${field}`].length; i++) {
      if ( r[`${field}`][i].includes(filter) ){
        return r[`${field}`][i].includes(filter);
      }
    }
  });
}

const MoveOutSearchTable = ({
  rows, 
  columns, 
  to, 
  placeholder, 
  filterField, 
  count,
  hideFilter,
  hideViewMore
}) => {
  const [filter, setFilter] = useState('');
  let filteredRows = filterRowsByCriteria(rows, filterField, filter);
  filteredRows = filteredRows.slice(0, count);
  let currentDisplayed = (filteredRows.length > count) ? count : filteredRows.length;
  
  
  return <>
    {/* SearchBar */}
    {(hideFilter) ? <></> : <SearchBarContainer>
      <SearchBar 
        value={filter} 
        placeholder={placeholder}
        onChange={(e) => {
          setFilter(e.target.value);
        }}
      />
    </SearchBarContainer> }
    {/* Striped Table */}
    <StripedTable 
      rows={filteredRows}
      columns={columns}
    />
    {/* Show remaining count */}
    <p>Showing {currentDisplayed} of {rows.length} results</p>
    {/* more button */}
    {(hideViewMore) ? <></> : 
    <ButtonContainer>
      <Link to={to}>
        <MoreButton>View More</MoreButton>
      </Link>
    </ButtonContainer>
    }
  </>;
};

MoveOutSearchTable.propTypes = {
  rows: propTypes.any,
  columns: propTypes.any,
  to: propTypes.any,
  placeholder: propTypes.any,
  filterField: propTypes.any,
  count: propTypes.any
}

export default MoveOutSearchTable;