// ExportReportDetailView.js
import React, { useEffect, useState } from 'react';
import PageCard from '../../components/PageCard';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import SimpleCard from '../../components/SimpleCard';
import propTypes from 'prop-types';
import styled from 'styled-components';
import StripedTable from '../../components/StripedTable';
import { NAField } from '../../components/SheepReportComponents';

const convertToAmPm = (time) => {
  const hourMinute = time.split(":");
  let hr = +hourMinute[0];
  let min = +hourMinute[1];
  const ampm = hr >= 12 ? 'pm' : 'am';
  hr = hr % 12;
  hr = hr > 0 ? hr : 12; // 0 hr should be 12
  min = min < 10 ? '0'+min : min;
  return `${hr}:${min}${ampm}`;
};

const detailExportColumns = [
  {
    id: 'isoNumber',
    name: 'ISO Number',
    required: true
},
  {
    id: 'localMgmtNumber',
    name: 'Local Management Number',
    required: true
  },
  {
    id: 'tattooNumber',
    name: 'Tattoo Number',
    required: true
  },
  {
    id: 'breed',
    name: 'Breed',
    required: true
  },
  {
    id: 'gender',
    name: 'Gender',
    required: true
  },
];

const getPremiseName = (id) => {
  return axios.get(`/api/premises/name/${id}`)
    .then((res) => {
      return res.data;  
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

const getVehicleName = (id) => {
  return axios.get(`/api/vehicles/name/${id}`)
    .then((res) => {
      return res.data;  
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

const fetchMoveOutInfo = async (id) => {
  try{
    const request = await axios.get(`/api/move/${id}`);
    return request.data;
  } catch (err){
    throw new Error(`An error occurred fetching sheep info: ${err.message}`);
  }
}

const Info = ({name, value}) => {
  return <div>
    <span>{name}:&nbsp;</span><span>{value}</span>
  </div>;
}

Info.propTypes = {
  name: propTypes.string,
  value: propTypes.any
}

const MoveOutReportDetailView = () => {
  const [moveInReportInfo, setMoveInReportInfo] = useState();
  const [moveInSheepInfo, setMoveInSheepInfo] = useState([]);

  const [departurePremiseName, setDeparturePremiseName] = useState([]);
  const [destpremiseName, setDestPremiseName] = useState([]);
  const [vehicleName, setVehicleName] = useState([]);

  const [reportLocation, setReportLocation] = useState(null);

  const params = useParams();
  const id = params.id;
  
  // fetchExportReportInfo
  const fetchAndSetMoveInReportInfo = async () => {
    try {

      let fetchedMoveOutReportInfo = await fetchMoveOutInfo(id);
 
      try {
        let fetchedDeparturePremiseName = await getPremiseName(fetchedMoveOutReportInfo.departurePID);
        setDeparturePremiseName(fetchedDeparturePremiseName[0].name);

      } catch{
        
      }

      try {
        let fetchedFirstDestPremiseName = await getPremiseName(fetchedMoveOutReportInfo.destinationPID);
        setDestPremiseName(fetchedFirstDestPremiseName[0].name);
      } catch{

      }

      try {
        let fetchedVehicle = await getVehicleName(fetchedMoveOutReportInfo.vehicle);
        setVehicleName(fetchedVehicle[0].name);
      } catch{

      }

      setMoveInSheepInfo(fetchedMoveOutReportInfo.animals);
      setMoveInReportInfo(fetchedMoveOutReportInfo);

      // if this report has a geolocation, save that to a state as well
      if (
        fetchedMoveOutReportInfo.location &&
        Array.isArray(fetchedMoveOutReportInfo.location.coordinates) &&
        fetchedMoveOutReportInfo.location.coordinates.length === 2
      ) {
        const longlat = fetchedMoveOutReportInfo.location.coordinates;
        setReportLocation([longlat[1], longlat[0]]);
      }

    } catch (err) {
      toast.error(err.message);
    }
  }

  useEffect(() =>  {
    fetchAndSetMoveInReportInfo();
  }, [])

  moveInSheepInfo.forEach(s => {
    s.isoNumber = (s.tag.isoNumber) ? s.tag.isoNumber : <NAField />;
    s.localMgmtNumber = (s.tag.localMgmtNumber) ? s.tag.localMgmtNumber : <NAField />;
    s.tattooNumber = (s.tag.tattooNumber) ? s.tag.tattooNumber : <NAField />;
    s.breed = (s.breed) ? s.breed : <NAField />;
    s.gender = (s.gender) ? s.gender : <NAField />;
  });
 
  return <>
  <PageCard title="Move-Out Report Details" verify={`/verify/moveout/${id}`} back>
    {(moveInReportInfo && departurePremiseName && destpremiseName) ? <>
      <SimpleCard title="Premise Information" size="md">
        {/* Location Panel*/}
        <OneThirdDiv>
          <Info name="Departure Premise PID" value={moveInReportInfo.departurePID} />  
        </OneThirdDiv>

        <OneThirdDiv>
          <Info name="Destination Premise PID" value={moveInReportInfo.destinationPID} />  
        </OneThirdDiv>

        <OneThirdDiv>
          <Info name="Destination Premise Type" value={moveInReportInfo.destinationType} />  
        </OneThirdDiv>

        <OneThirdDiv>
          <Info name="Departure Premise Name" value={departurePremiseName} />  
        </OneThirdDiv>
        
        <OneThirdDiv>
          <Info name="Destination Premise Name" value={destpremiseName} />  
        </OneThirdDiv>

      </SimpleCard>
      <br></br>
      <br></br>

      {/* Transport Sheep Info */}
      <SimpleCard title="Transported Sheep" size="md">
        <StripedTable  
            columns={detailExportColumns}
            rows={moveInSheepInfo}
            ShowCount
            max={10}
          />
      </SimpleCard>

      {/* Vehicle Date Info */}
      {(moveInReportInfo.vehicleNumber) ? <>
        <SimpleCard title="Vehicle Information" size="sm">
          <HalfDiv>
            <Info name="Name" value={vehicleName} />  
          </HalfDiv>
          <HalfDiv>
            <Info name="License Plate Number" value={moveInReportInfo.vehicleNumber} />  
          </HalfDiv>
        </SimpleCard>
      </>: <> </>}
      <br></br>

      {/* Receive Date Info */}
      {(moveInReportInfo.receivedTime) ? <>
        <SimpleCard title="Receive Date and Time" size="sm">
          <HalfDiv>
            <Info name="Name" value={moveInReportInfo.receivedTime} />  
          </HalfDiv>
          <HalfDiv>
            <Info name="License Plate Number" value={moveInReportInfo.unloadTime} />  
          </HalfDiv>
        </SimpleCard>
      </>: <> </>}
      <br></br>

      {/* Arrival Date Info */}
      {(moveInReportInfo.departureTime) ? <>
        <SimpleCard title="Departure Date" size="sm">
            <HalfDiv>
              <Info
                name="Date"
                value={moveInReportInfo.departureTime.split('T')[0]}
              />  
            </HalfDiv>
            <HalfDiv>
              <Info
                name="Load Time"
                value={convertToAmPm(moveInReportInfo.loadTime)}
              />  
            </HalfDiv>
        </SimpleCard>
      </>: <> </>}
      <br></br>

      {reportLocation &&
        <SimpleCard title="Location" size="sm">
          <HalfDiv>
            <Info name="Latitude" value={reportLocation[0]} />
          </HalfDiv>
          <HalfDiv>
            <Info name="Longitude" value={reportLocation[1]} />
          </HalfDiv>
        </SimpleCard>
      }

    </>: <>Loading</>}
  </PageCard>
</>
};


const OneThirdDiv = styled.div`
  width: 33%;
  float: left;
  display: inline-block;
`
const HalfDiv = styled.div`
  width: 50%;
  float: left;
  display: inline-block;
`

export default MoveOutReportDetailView;