import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import ContentEditable from 'react-contenteditable';
import { EditBox, EditKey } from '../../components/CommonComponents';
import { FormSelectInput, FormTextList } from '../../components/CommonComponents';
import PageCard from '../../components/PageCard.js';
import { rowColorA, rowColorB } from '../../components/Colors.js';
import { StyledTable } from '../../components/TableComponents.js';
import axios from 'axios';

export default function PremiseView() {
	const [premise, setPremise] = useState({
		pid: '',
		name: '',
		address: '',
		municipality: '',
		province: '',
		postalCode: '',
		description: '',
		location: ''
	});

	const [newPremise, setNewPremise] = useState({
		pid: '',
		name: '',
		address: '',
		municipality: '',
		province: '',
		postalCode: '',
		description: '',
		location: ''
	});

	const [operation, setOperation] = useState('');
	const [edit, setEdit] = useState(true);

	const provinces = ['NL', 'PE', 'NS', 'NB', 'QC', 'ON', 'MB', 'SK', 'AB', 'BC', 'YT', 'NT', 'NU'];

	let params = useParams();

	useEffect(() => {
		getPremise();
	}, []);

	// Getting premise data for specific premise
	async function getPremise() {
		await axios
			.get(`/api/premises/${params.id}`)
			.then(function (response) {
				if (response.data.pid === null) {
					response.data.pid = ' ';
				}
				const location = response.data.location;
				const description = response.data.description;
				const fetchedPremise = {
					...response.data,
					location:
						(location && location.coordinates && location.coordinates.length === 2) ? 
						location.coordinates[1] + ", " + location.coordinates[0] : "N/A",
					description: (description == "") ? 'N/A' : description
				};
				setPremise(fetchedPremise);
				setNewPremise(fetchedPremise);
				setOperation(fetchedPremise.operation.businessName);

			})
			.catch(function (error) {
				console.log(error);
				// setFieldErrors(error.response.data);
			});
	}

	const obj = [
		{
			title: 'PID:',
			newPremiseVal: newPremise.pid.toString(),
			premiseVal: premise.pid.toString(),
			id: 'pid',
		},
		{
			title: 'NICKNAME: ',
			newPremiseVal: newPremise.name,
			premiseVal: premise.name,
			id: 'name',
		},
		{
			title: 'ADDRESS: ',
			newPremiseVal: newPremise.address,
			premiseVal: premise.address,
			id: 'address',
		},
		{
			title: 'MUNICIPALITY: ',
			newPremiseVal: newPremise.municipality,
			premiseVal: premise.municipality,
			id: 'municipality',
		},
		{
			title: 'PROVINCE: ',
			newPremiseVal: newPremise.province,
			premiseVal: premise.province,
			id: 'province',
		},
		{
			title: 'POSTAL CODE: ',
			newPremiseVal: newPremise.postalCode,
			premiseVal: premise.postalCode,
			id: 'postalCode',
		},
		{
			title: 'DESCRIPTION: ',
			newPremiseVal: newPremise.description,
			premiseVal: premise.description,
			id: 'description',
		},
		{
			title: 'LOCATION: ',
			newPremiseVal: newPremise.location,
			premiseVal: premise.location,
			id: 'location'
		},
		{
			title: 'OPERATION: ',
			id: 'operation'
		}
	];

	function buttonContent() {
		// passes jsx of buttons to pagecard for better alignment
		return (
			<PageTitle>

			</PageTitle>
		);
	}

	return (
		<>
				<PageCard
					componentTitle={premise.name}
					buttonContent={buttonContent()}
					back
				>
					<EditInnerBox>
						<PremiseTable>
							<thead>
								<tr>
									<th>Parameters</th>
									<th>Values</th>
								</tr>
							</thead>
							<tbody>
								{obj.map((premise, index) => {
									if(premise.id === 'province'){
										return (
											<SpecialTr>
											<SpecialTd>
												<EditKey>{premise.title}</EditKey>
											</SpecialTd>
											<SpecialTd>
												<EditBox
													style={
														index % 2 === 0
															? { 'background-color': rowColorA }
															: { 'background-color': rowColorB }
													}
												>
												<FormTextList>
													<FormSelectInput  style={{"width": "200px"}}disabled={edit}
														name='province'
														value={premise.newPremiseVal}
														onChange={(e) => setNewPremise({ ...newPremise, [premise.id]: e.target.value })}>

														{provinces.map(value => {
															return <option key={value}>{value}</option>
														})}
													</FormSelectInput>
												</FormTextList>

												</EditBox>
											</SpecialTd>
										</SpecialTr>
									)}else if(premise.id === 'operation'){
										return (<SpecialTr>
											<SpecialTd>
												<EditKey>{premise.title}</EditKey>
											</SpecialTd>
											<SpecialTd>
												<EditBox
													style={
														index % 2 === 0
															? { 'background-color': rowColorA }
															: { 'background-color': rowColorB }
													}>
													<ContentEditable
														html={operation}
														data-column='item'
														disabled={true}
													/>
												</EditBox>
											</SpecialTd>
										</SpecialTr>
									)}else{
									return (<SpecialTr>
										<SpecialTd>
											<EditKey>{premise.title}</EditKey>
										</SpecialTd>
										<SpecialTd>
											<EditBox
												style={
													index % 2 === 0
														? { 'background-color': rowColorA }
														: { 'background-color': rowColorB }
												}
											>
												<ContentEditable
													html={premise.newPremiseVal}
													data-column='item'
													disabled={premise.id === "pid" ? true : edit}
													onChange={(e) => {
														var str = e.target.value;
														if (premise.id === 'pid' && e.target.value === '<br>') {
															str = ' ';
														} else if(e.target.value === '<br>'){
															str = '';
														}else{
															str = str.replace(/&nbsp;/g, ' ');
														}

														setNewPremise({ ...newPremise, [premise.id]: str });
													}}
												/>
											</EditBox>
										</SpecialTd>
									</SpecialTr>
								)}})}
							</tbody>
						</PremiseTable>
					</EditInnerBox>
				</PageCard>
		</>
	);
}

const PageTitle = styled.div`
	margin-left: 10px;
	position: inherit;
	color: white;
	display: flex;
	align-items: center;
`;

const EditInnerBox = styled.div`
	top: 120px;
	// margin-left: 100px;
	margin-left: 0px;
`;

const PremiseTable = styled(StyledTable)`
	table-layout:fixed;
`;

const SpecialTd = styled.td`
	// word-wrap: break-word;
	// overflow-wrap: break-word;
	white-space: nowrap

	// background-color: red;
`;

const SpecialTr = styled.tr`
	word-wrap: break-word;
`;