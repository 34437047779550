import React, { useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
	FormContainer,
	FormInputWrapper,
	FormInputLabel,
	FormTextInput,
	Button,
} from '../components/CommonComponents';
import FormCard from '../components/FormCard.js';
import { toast } from 'react-toastify';
import complexity from 'complexity';
import axios from 'axios';

const pwOptions = {
	uppercase: 1,
	digits: 1,
	min: 8
}

const  PasswordResetView = () => {
	const [form, setForm] = useState({
		password: '',
		rePassword: ''
	});
	const { resetToken } = useParams();
	const [fieldErrors, setFieldErrors] = useState([]);
	const [redirect, setRedirect] = useState(false);

	if (redirect) {
		return <Redirect to='/login' />;
	}

	const updateError = (password, rePassword) => {
		const passwordsMatchError = 'Passwords do not match!';
		const complexityError = 'Passwords must contain at least 8 characters, 1 uppercase letter and 1 number';

		// Check that passwords match
		var fieldErrorsCopy;
		if(password !== rePassword){
			if(!fieldErrors.includes(passwordsMatchError)){
				setFieldErrors([
					...fieldErrors,
					passwordsMatchError
				]);
			}
		} else {
			const index = fieldErrors.indexOf(passwordsMatchError);
			console.log({index});
			if(index > -1){
				fieldErrorsCopy = fieldErrors;
				fieldErrorsCopy.splice(index, 1);
				setFieldErrors(fieldErrorsCopy)
			}
		}

		if(!complexity.check(password, pwOptions)){
			if(!fieldErrors.includes(complexityError)){
				setFieldErrors([
					...fieldErrors,
					complexityError
				]);
			}
		} else {
			const index = fieldErrors.indexOf(complexityError);
			if(index > -1){
				fieldErrorsCopy = fieldErrors;
				fieldErrorsCopy.splice(index, 1);
				setFieldErrors(fieldErrorsCopy);
			}
		}
	}

	const verifyForm = (f) => {
		if(f.password === '' || f.rePassword === '') return false;
		if(f.password !== f.rePassword) return false;

		const strong = complexity.check(f.password, pwOptions);
		console.log({strong});
		return strong;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const body = {
				token: resetToken,
				password: form.password
			};
			console.log({body});

			// Post the request
			await axios.put('/api/users/passwordreset', body);

			toast.success('Password reset successful!');
			setRedirect(true);
		} catch (err) {
			toast.error(`An error occurred: ${err}`);
		}
		
	};

	return (
		<LoginContainer>
			<FormCard title='New Password' errors={fieldErrors}>
				<FormContainer>
					<FormInputWrapper key='password'>
						<FormInputLabel htmlFor='input'>
							New Password
						</FormInputLabel>
						<FormTextInput
							placeholder='Your new password'
							type='password'
							id='input'
							name='password'
							value={form.password}
							onChange={(e) => {
								setForm({
									...form,
									password: e.target.value
								});
								updateError(e.target.value, form.rePassword);
							}}
						/>
					</FormInputWrapper>
					<FormInputWrapper key='rePassword'>
						<FormInputLabel htmlFor='input'>
							Re-Enter Your New Password
						</FormInputLabel>
						<FormTextInput
							placeholder='re-enter your new password'
							type='password'
							id='input'
							name='rePassword'
							value={form.rePassword}
							onChange={(e) => {
								setForm({
									...form,
									rePassword: e.target.value
								});
								updateError(form.password, e.target.value);
							}}
						/>
					</FormInputWrapper>
					
					<Button type='submit' onClick={handleSubmit} disabled={!verifyForm(form)}>
						Reset Password
					</Button>
				</FormContainer>
			</FormCard>
		</LoginContainer>
	);
}

const LoginContainer = styled.div`
	margin: auto;
`;

export default PasswordResetView;
