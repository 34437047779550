import React, { useState, useEffect } from 'react';
import FormCard from '../../components/FormCard.js';
import Loader from 'react-loader-spinner';
import LoadingCard from '../../components/LoadingCard.js';
import PageCard from '../../components/PageCard.js';
import { agGreen } from '../../components/Colors.js';
import DateField from '../../components/DateField.js';
import styled from 'styled-components';
import {
	FormInputWrapper,
	FormInputLabel,
	FormTextInput,
	FormSelectInput,
  Button,
} from '../../components/CommonComponents';
import { getPremisesForUser } from '../../utils/PremiseUtils';
import { getUsername } from '../../utils/TokenUtils.js';
import { Redirect } from 'react-router';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { countries } from '../../utils/Countries';
import { createImportReport } from '../../utils/ImportUtils';
import ImportSheepForm from '../../components/ImportSheepForm';
import SimpleCard from '../../components/SimpleCard';
import GeolocationInput from '../../components/GeolocationInput';
import { Link } from 'react-router-dom';

const SubmittingDiv = styled.div`
  text-align: center
`;
const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin></FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
};

const CsvCreateComponent = () => {
  return <>
    <SimpleCard
      title="CSV Creation"
      description="Use a CSV to create a report" 
    >
      <CsvButtonContainer>
        <Link to="/reports/import/create/csv">
          <CsvButton>Create report from a csv</CsvButton>
        </Link>
      </CsvButtonContainer>
    </SimpleCard>
  </>
}

const ImportManualCreateView = () => {
  const [load, setLoad] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [sheepsToCreate, setSheepsToCreate] = useState([]);
  // states to store the information entered into the form
  const [form, setForm] = useState({
    exportingCountry: 'Canada',
    departureSite: '',
    abattoirPID: '',
    firstDestinationPID: '',
    dateOfArrival: new Date(),
    vehicleIdentification: '',
    location: null
  });
  const [animals, setAnimals] = useState([]);
  const [currAnimal, setCurrAnimal] = useState('');
  const [destinationPremise, setDestinationPremise] = useState(null);
  // states to store the dropdown options
  const [myPremises, setMyPremises] = useState([]);

  const createEnabled = () => {
    return (
      !submitting && 
      form.exportingCountry &&
      form.firstDestinationPID &&
      form.dateOfArrival &&
      form.vehicleIdentification &&
      animals.length > 0
    );
  };

  const setGeolocation = () => {
		const onSuccess = (position) => {
			const location = [position.coords.longitude, position.coords.latitude];
			setForm({ ...form, location });
		};

		const onError = () => {
			toast.info("Could not get the geolocation of your device");
		}

		if (!navigator.geolocation) {
			toast.info("Geolocation is not supported by this browser");
		} else {
			navigator.geolocation.getCurrentPosition(onSuccess, onError);
		}
	};

	const clearLocation = () => {
		setForm({ ...form, location: null });
	}

  const handleSubmit = () => {
    toast.info("Submitting the import report to the server. This may take up to a minute.")
    setSubmitting(true);
    setDestinationPremise(myPremises.find(premise => premise.pid === form.firstDestinationPID));
    createImportReport({
      exportingCountry: form.exportingCountry,
      departureSite: form.departureSite ? form.departureSite : null,
      abattoirPID: form.abattoirPID ? form.abattoirPID : null,
      firstDestinationPID: form.firstDestinationPID,
      dateOfArrival: form.dateOfArrival,
      animals: animals,
      vehicleIdentification: form.vehicleIdentification,
      location: form.location
    }).then((res) => {
      setSubmitting(false);
      if (Array.isArray(res.newSheeps) && res.newSheeps.length > 0) {
        setSheepsToCreate(res.newSheeps);
      }
      else setRedirect(true);
    }).catch((err) => {
      toast.error(err);
      setSubmitting(false);
    });
  };

  useEffect(() => {
    getPremisesForUser(getUsername())
      .then((premises) => {
        setMyPremises(premises);
        setForm({
          ...form,
          firstDestinationPID: premises.length > 0 ? premises[0].pid : '',
        });
        setLoad(false);
      })
      .catch(() => {
        toast.error("An error occurred while fetching the data. Please reload this page.");
      });
  }, []);

  if (redirect) return <Redirect to="/sheep"/>
  return (
		<ReportContainer>
      {!load ? 
        (sheepsToCreate.length > 0 ? 
        <FormCard title='Create the imported sheep'>
          <ImportSheepForm 
            premise={destinationPremise}
            sheepsToCreate={sheepsToCreate}
          />
        </FormCard>
        : 
        <FormCard title='Create a new import report' back>
          <CsvCreateComponent/>

          <SimpleCard 
              title="Manual Creation"
              description="Create a report manually by filling in the information below"
          />

          <FormInputWrapper>
            <FormInputLabel>
              Exporting Country
              <>&nbsp;<RequiredSpan>*</RequiredSpan></>
            </FormInputLabel>
            <FormSelectInput
              value={form['exportingCountry']}
              onChange={(e) => {
                setForm({
                  ...form,
                  exportingCountry: e.target.value
                })
              }}
            >
              {countries.map((country, idx) => (
                <option key={idx} value={country}>
                  {country}
                </option>
              ))}
            </FormSelectInput>
          </FormInputWrapper>

          <FormInputWrapper>
            <FormInputLabel>
              Departure Site
            </FormInputLabel>
            <FormTextInput 
              value={form['departureSite']}
              onChange={(e) => setForm({
                ...form,
                departureSite: e.target.value
              })}
            />
          </FormInputWrapper>

          <FormInputWrapper>
            <FormInputLabel>
              Abattoir Premise
            </FormInputLabel>
            <FormSelectInput
							value={form['abattoirPID']}
							onChange={(e) => setForm({
                ...form,
                abattoirPID: e.target.value
              })}
						>
              <option value=''></option>
              {myPremises.map((premise, idx) => (
                <option key={idx} value={premise.pid}>
                  {premise.name}
                </option>
              ))}
						</FormSelectInput>
          </FormInputWrapper>

          <FormInputWrapper>
            <FormInputLabel>
              First Destination Premise
              <>&nbsp;<RequiredSpan>*</RequiredSpan></>
            </FormInputLabel>
            <FormSelectInput
							value={form['firstDestinationPID']}
							onChange={(e) => setForm({
                ...form,
                firstDestinationPID: e.target.value
              })}
						>
              {myPremises.map((premise, idx) => (
                <option key={idx} value={premise.pid}>
                  {premise.name}
                </option>
              ))}
						</FormSelectInput>
          </FormInputWrapper>

          <DateField
            id="dateOfArrival"
            labelName="Date of Arrival"
            value={form.dateOfArrival}
            onChange={(date) => setForm({
              ...form,
              'dateOfArrival': date
            })}
            required
          />

          <FormInputWrapper>
            <FormInputLabel>
              Sheep to Import (ISO number)
              <>&nbsp;<RequiredSpan>*</RequiredSpan></>
            </FormInputLabel>
            {animals.map((animal, idx) => (
              <div key={idx} style={{ display: 'flex' }}>
                <FormTextInput value={animal} disabled style={{ marginBottom: '10px', flexGrow: 1 }}/>
                <RemoveSheepButton onClick={() => {
                  setAnimals([...animals.slice(0, idx), ...animals.slice(idx+1)]);
                }}>
                  X
                </RemoveSheepButton>
              </div>
            ))}
            <FormTextInput value={currAnimal} onChange={(e) => setCurrAnimal(e.target.value)} />
            <AddSheepButton onClick={() => {
              if (currAnimal.length > 0) {
                setAnimals([...animals, currAnimal]);
                setCurrAnimal('');  
              }
            }}>
              Add
            </AddSheepButton>
          </FormInputWrapper>

          <FormInputWrapper>
            <FormInputLabel>
              Vehicle Identifier (License Plate Number)
              <>&nbsp;<RequiredSpan>*</RequiredSpan></>
            </FormInputLabel>
            <FormTextInput 
              value={form["vehicleIdentification"]}
              onChange={(e) => {
                setForm({
                  ...form,
                  vehicleIdentification: e.target.value
                })
              }}/>
          </FormInputWrapper>

          <GeolocationInput
            location={form.location}
            getLocation={setGeolocation}
            clearLocation={clearLocation}
          />

          <Button type="button" disabled={!createEnabled()} onClick={handleSubmit}>
            Import
          </Button>
          {(submitting) ? <SubmittingSpinner />: <></>}
        </FormCard>
      ) : (
        <PageCard
          title='Processing your request ...'
          loader={<Loader type='Oval' color={agGreen} height={50} width={150} />}
        >
          <LoadingCard />
        </PageCard>
			)}
		</ReportContainer>
	);
}
const CsvButtonContainer = styled.div`
  width: 60%;
  display: inline-block;
  text-align: center;
  margin-top: 20px;
`
const CsvButton = styled.button`
  background-color: #17a737;
  border: none;
  border-radius: 10px;
  padding: 1rem;
  font-size: 1rem;
  color: white;
  cursor: pointer;
`

const ReportContainer = styled.div`
  width: fit-content;
	max-width: 700px;
	color: white;
	position: relative;
	margin: 0;
	padding: 10px;
	display: flex;
	flex-direction: column;
`;

const RequiredSpan = styled.span`
  color: red;
`;

const AddSheepButton = styled.button`
  margin: 10px auto;
  width: 80px;
  height: 30px;
  border: none;
  border-radius: 20px;
  background-color: rgb(59, 143, 59);
  cursor: pointer;

  :hover {
    color: white;
  }
`;

const RemoveSheepButton = styled.button`
  margin: 5px;
  height: 25px;
  width: 25px;
  border: none;
  border-radius: 100px;
  font-weight: 500;
  cursor: pointer;
  background-color: rgb(226, 68, 68);

  :hover {
    color: white;
  }
`;

export default ImportManualCreateView;