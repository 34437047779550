import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import FormCard from '../../components/FormCard.js';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import LoadingCard from '../../components/LoadingCard.js';
import PageCard from '../../components/PageCard.js';
import { agGreen } from '../../components/Colors.js';
import { getUsername } from '../../utils/TokenUtils';
import Input from '../../components/Input.js';
import DateField from '../../components/DateField.js';
import styled from 'styled-components';
import DropDown from '../../components/DropDown.js';
import axios from 'axios';
import { getPremisesForUser } from '../../utils/PremiseUtils';
import { getAllSheeps } from '../../utils/SheepUtils';
import { createExportReport } from '../../utils/ExportUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../components/CommonComponents';
import StripedTable from '../../components/StripedTable';
import SimpleCard from '../../components/SimpleCard';
import GeolocationInput from '../../components/GeolocationInput';
import { Link } from 'react-router-dom';

const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin></FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

const selectSheepsColumn = [
  {
    id: 'check',
    name: 'Select' 
  },
  {
    id: 'isoNumber',
    name: 'ISO Number'
  },
  {
    id: 'localMgmtNumber',
    name: 'Local Management Number'
  },
  {
    id: 'tattooNumber',
    name: 'Tattoo Number'
  },
  {
    id: 'breed',
    name: 'Breed'
  },
  {
    id: 'gender',
    name: 'Gender'
  }
];

const CsvCreateComponent = () => {
  return <>
    <SimpleCard
      title="CSV Creation"
      description="Use a CSV to create a report" 
    >
      <CsvButtonContainer>
        <Link to="/reports/importExport/export/csv">
          <CsvButton>Create report from a csv</CsvButton>
        </Link>
      </CsvButtonContainer>
    </SimpleCard>
  </>
}

const MoveOutReportCreateView = () => {
  const [load, setLoad] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [exportToCountry, setExportToCountry] = useState(false);

  //form information that is used in the move log
  const [form, setForm] = useState({
    departurePremiseID: '',
    destinationPremiseID: '',
    destinationLocation: '',
    vehicleID: '',
    departureDate: new Date(),
    arrivalDate: new Date(),
    location: null
  });
  const animals = useRef([]);
  const checked = useRef({});

  //state vars for retrieving and displaying dropdown options
  const [myPremises, setMyPremises] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [allAnimals, setAllAnimals] = useState([]);
  const [availableAnimals, setAvailableAnimals] = useState([]);

  const fetchVehicles = async() => {
    const username = getUsername();
    const result = await axios.get(`/api/vehicles/user/${username}`);
    
    const fetchedVehicleIDs = result.data.map(v => ({
      id: v.licensePlateNum,
      name: v.name
    }));

    setVehicles(fetchedVehicleIDs);
    return fetchedVehicleIDs;
  };

  useEffect(() => {
    getPremisesForUser(getUsername())
      .then((userPremises) => {
        setMyPremises(userPremises);
        return getAllSheeps()
          .then((allSheeps) => {
            setAllAnimals(allSheeps.map((sheep) => ({
              ...sheep,
              isoNumber: sheep.tag.isoNumber,
              localMgmtNumber: sheep.tag.localMgmtNumber,
              tattooNumber: sheep.tag.tattooNumber,
              check: <input type='checkbox' 
                checked={checked.current[sheep._id]}
                onChange={() => {
                  if (checked.current[sheep._id]) {
                    animals.current = animals.current.filter(animalId => animalId !== sheep._id);
                    checked.current[sheep._id] = false;
                  } else {
                    animals.current = [...animals.current, sheep._id];
                    checked.current[sheep._id] = true;
                  }
                }}
                style={{cursor: 'pointer'}}
              />
            })));
            return fetchVehicles();
          })
          .then((fetchedVehicleIDs) => {
            setForm({
              ...form,
              departurePremiseID: userPremises.length > 0 ? userPremises[0].pid : '',
              destinationPremiseID: userPremises.length > 0 ? userPremises[0].pid : '',
              vehicleID: fetchedVehicleIDs.length > 0 ? fetchedVehicleIDs[0].id : ''
            });
            setLoad(false);
          });
      })
      .catch(() => {
        toast.error("An error occurred while fetching the data. Please reload this page.");
      });
  }, []);

  useEffect(() => {
    const departurePremise = myPremises.find((premise) => premise.pid === form.departurePremiseID);
    if (departurePremise) {
      var premiseAll = allAnimals.filter((animal) => animal.premise === departurePremise._id);
      premiseAll = premiseAll.filter((animal) => !animal.isExported);
      setAvailableAnimals(premiseAll);
    }
    animals.current = [];
    checked.current = {};
  }, [form.departurePremiseID, allAnimals]);

  const departurePremiseDropDown = {
    name: 'Departure Premise Name',
    id: 'departurePremiseID'
  };

  const destinationPremiseDropDown = {
    name: 'Destination Premise Name',
    id: 'destinationPremiseID'
  };

  const vehicleDropDown = {
    name: 'Vehicle',
    id: 'vehicleID'
  };

  const destLocationInput = [
    {
      name: 'Destination (Country, State, County, Town or District)',
      hint: 'USA',
      id: 'destinationLocationID'
    }
  ];

  const departureDateField = {
    labelName: 'Departure Date',
    id: 'departureDate',
    onChange: (date) => dateChange(date, 'departureDate'),
    value: form.departureDate
  };

  const arrivalDateField = {
    labelName: 'Arrival Date',
    id: 'arrivalDate',
    onChange: (date) => dateChange(date, 'arrivalDate'),
    value: form.arrivalDate
  };

  const dateChange = (date, fieldName) => {
    setForm({
      ...form,
      [fieldName]: date
    })
  };

  const setGeolocation = () => {
		const onSuccess = (position) => {
			const location = [position.coords.longitude, position.coords.latitude];
			setForm({ ...form, location });
		};

		const onError = () => {
			toast.info("Could not get the geolocation of your device");
		}

		if (!navigator.geolocation) {
			toast.info("Geolocation is not supported by this browser");
		} else {
			navigator.geolocation.getCurrentPosition(onSuccess, onError);
		}
	};

	const clearLocation = () => {
		setForm({ ...form, location: null });
	}

  const handleSubmit = () => {
    if (exportToCountry){
      const dateNow = new Date();

      if (dateNow < form.departureDate){
        toast.error("The departure date cannot be in the future");
        return;
      }
      if (form.destinationLocation === ''){
        toast.error("Please enter a destination location");
        return;
      }
      if (animals.current.length < 1){
        toast.error("Please select at least one sheep to export");
        return;
      }

      setSubmitting(true);
      toast.info("Submitting export report to the backend. It may take up to a minute. You will be redirected when the report has been created. Please stay on this page.");
      createExportReport({
        departurePID: form.departurePremiseID,
        destinationLocation: form.destinationLocation,
        animals: animals.current,
        departureTime: form.departureDate,
        vehicleNumber: form.vehicleID,
        location: form.location
      }).then(() =>{
        setSubmitting(false);
        toast.success("Success: Report created");
        setRedirect(true);
      }).catch ((err) => {
        setSubmitting(false);
        toast.error(err);
      });
    }
    else{
      if (form.departureDate > form.arrivalDate) {
        toast.error("The departure date cannot be later than the arrival date");
        return;
      }
      if (form.departurePremiseID === form.destinationPremiseID){
        toast.error("You cannot export to the same premise");
        return;
      }

      setSubmitting(true);
      toast.info("Submitting export report to the backend. It may take up to a minute. You will be redirected when the report has been created. Please stay on this page.");
      createExportReport({
        departurePID: form.departurePremiseID,
        destinationPID: form.destinationPremiseID,
        animals: animals.current,
        departureTime: form.departureDate,
        arrivalTime: form.arrivalDate,
        vehicleNumber: form.vehicleID,
        location: form.location
      }).then(() =>{
        setSubmitting(false);
        toast.success("Success: Report created");
        setRedirect(true);
      }).catch ((err) => {
        setSubmitting(false);
        toast.error(err);
      });
    }
  };

  if (redirect) return <Redirect to='/sheep'/>
  return (
		<ReportContainer>
      <FormCard title='Create a new export report' back>
        {!load ? (
          <>
            <CsvCreateComponent/>
            <SimpleCard 
              title="Manual Creation"
              description="Create a report manually by filling in the information below"
            />
              <RadioButtonContainer>
                Where are you exporting to?<>&nbsp;<RequiredSpan>*</RequiredSpan></><br></br>
                <input 
                  type='radio' 
                  name='destType' 
                  checked={!exportToCountry} 
                  onChange={() => setExportToCountry(false)} 
                  style={{cursor: 'pointer'}}/>
                  Within Canada <br></br>
                <input 
                  type='radio' 
                  name='destType' 
                  checked={exportToCountry} 
                  onChange={() => setExportToCountry(true)} 
                  style={{cursor: 'pointer'}}/>
                  Outside of Canada <br></br>
              </RadioButtonContainer>

              <InstructionContainer>
                Enter the following information about the export.
              </InstructionContainer>

              {exportToCountry ? (
              <> 
                <DropDown
                  id={departurePremiseDropDown.id}
                  name={departurePremiseDropDown.name}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      departurePremiseID: e.target.value
                    });
                  }}
                  value={form[departurePremiseDropDown.id]}
                  options={
                    myPremises.map(o => ({
                      name: o.name,
                      value: o.pid,
                    }))
                  }
                  required
                />
                
                {destLocationInput.map(i => <>
                    <Input
                      id={i.id}
                      placeholder={i.hint}
                      labelName={i.name}
                      onChange={(e) => setForm({
                        ...form,
                        destinationLocation: e.target.value
                      })}
                      value={form[i.id]}
                      required
                    />
                  </>
                )}

                <SimpleCard title='Select sheep to move'>
                  <StripedTable
                    columns={selectSheepsColumn}
                    rows={availableAnimals}
                    max={10}
                    paginate
                  />
                </SimpleCard>

                <DateField
                  id={departureDateField.id}
                  labelName={departureDateField.labelName}
                  value={departureDateField.value}
                  onChange={departureDateField.onChange}
                  required
                />

                <DropDown
                  id={vehicleDropDown.id}
                  name={vehicleDropDown.name}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      vehicleID: e.target.value
                    });
                  }}
                  value={form[vehicleDropDown.id]}
                  options={
                    vehicles.map(o => ({
                      name: o.name,
                      value: o.id,
                    }))
                  }
                  required
                />

                <GeolocationInput 
                  location={form.location}
                  getLocation={setGeolocation}
                  clearLocation={clearLocation}
                />

                <Button type='button' disabled={submitting} onClick={handleSubmit} >
                  Create
                </Button>

                {(submitting) ? <SubmittingSpinner />: <></>}
                
              </>) : (
              <> 
                <DropDown
                  id={departurePremiseDropDown.id}
                  name={departurePremiseDropDown.name}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      departurePremiseID: e.target.value
                    });
                  }}
                  value={form[departurePremiseDropDown.id]}
                  options={
                    myPremises.map(o => ({
                      name: o.name,
                      value: o.pid,
                    }))
                  }
                  required
                />
                <DropDown
                  id={destinationPremiseDropDown.id}
                  name={destinationPremiseDropDown.name}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      destinationPremiseID: e.target.value
                    });
                  }}
                  value={form[destinationPremiseDropDown.id]}
                  options={
                    myPremises.map(o => ({
                      name: o.name,
                      value: o.pid,
                    }))
                  }
                  required
                />

                <SimpleCard title='Select sheep to move'>
                  <StripedTable
                    columns={selectSheepsColumn}
                    rows={availableAnimals}
                    max={10}
                    paginate
                  />
                </SimpleCard>

                <DateField
                  id={departureDateField.id}
                  labelName={departureDateField.labelName}
                  value={departureDateField.value}
                  onChange={departureDateField.onChange}
                  required
                />
                <DateField
                  id={arrivalDateField.id}
                  labelName={arrivalDateField.labelName}
                  value={arrivalDateField.value}
                  onChange={arrivalDateField.onChange}
                  required
                />

                <DropDown
                  id={vehicleDropDown.id}
                  name={vehicleDropDown.name}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      vehicleID: e.target.value
                    });
                  }}
                  value={form[vehicleDropDown.id]}
                  options={
                    vehicles.map(o => ({
                      name: o.name,
                      value: o.id,
                    }))
                  }
                  required
                />

                <GeolocationInput 
                  location={form.location}
                  getLocation={setGeolocation}
                  clearLocation={clearLocation}
                />

                <Button type='button' disabled={submitting} onClick={handleSubmit} >
                  Create
                </Button>

                {(submitting) ? <SubmittingSpinner />: <></>}
              
              </>)}
          </>
          ) : (
          <PageCard
            title='Processing your request ...'
            loader={<Loader type='Oval' color={agGreen} height={50} width={150} />}
          >
            <LoadingCard />
          </PageCard>
        )}
      </FormCard>
		</ReportContainer>
	);
}
const CsvButtonContainer = styled.div`
  width: 40%;
  display: inline-block;
  text-align: center;
  margin-top: 20px;
`
const CsvButton = styled.button`
  background-color: #17a737;
  border: none;
  border-radius: 10px;
  padding: 1rem;
  font-size: 1rem;
  color: white;
`

const ReportContainer = styled.div`
  width: fit-content;
  max-width: 700px;
	color: white;
	position: relative;
	margin: 0;
	padding: 10px;
	display: flex;
	flex-direction: column;
`;

const InstructionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 15px;
  margin-left: 10px;
`;

const RadioButtonContainer = styled.div`
  margin-bottom: 20px;
  margin-left: 25px;
`;

const SubmittingDiv = styled.div`
  text-align: center
`;

const RequiredSpan = styled.span`
  color: red;
`
export default MoveOutReportCreateView;