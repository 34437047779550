// SheepSight.js
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PageCard from '../../components/PageCard';
import SimpleCard from '../../components/SimpleCard';
import { getUsername } from '../../utils/TokenUtils';
import axios from 'axios';
import Input from '../../components/Input.js';
import DateField from '../../components/DateField.js';
import {
  Button,
  FormInputWrapper,
  FormInputLabel,
  FormTextInput,
  FormSelectInput,
} from '../../components/CommonComponents.js';
import styled from 'styled-components';
import StripedTable from '../../components/StripedTable';
import { extractDataFromHeaders } from '../../utils/CSVUtils';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import propTypes from 'prop-types';
import { validatePremiseExists } from '../../utils/PremiseUtils';

const validate = (form) => {
  const today = new Date();

  if (!form.reportLocation){
    toast.error("Error: premise missing");
    return false
  }

  if(!form.reporterName){
    toast.error("Error: reporter missing");
    return false
  } 

  if(!form.reportDate){
    toast.error("Error: date missing");
    return false
  } 

  if(form.sheep.length == 0){
    toast.error("Error: no sheep selected");
    return false
  } 


  if (form.reportDate > today){
    toast.error("Error, please select a valid date");
    return false;
  } 

  return true;
}


const SubmittingDiv = styled.div`
  text-align: center
`;
const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin>

    </FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

const CheckBox = ({onChange, checked}) => {
  return <input 
    style={{cursor: 'pointer'}} 
    type='checkbox' 
    checked={checked} 
    onChange={onChange} />;
}

CheckBox.propTypes = {
  onChange: propTypes.any,
  checked: propTypes.any
}

const bulkSheepSubmit = async (submissionObject) => {
  try {
    toast.info("Submitting sighting to the backend. It may take up to a minute. You will be redirected when the sheep have been created. Please stay on this page.");
    await axios.post(`/api/animalsighting/bulk`, submissionObject);
    toast.success('Report created successfully');
    return;
  } catch(err) {
    throw err.response.data;
  }
}

const fetchPremises = async() => {
  try {
    const username = getUsername();
    const request = await axios.get(`/api/premises/user/${username}`);
    const fetchedPremises = request.data;
    return fetchedPremises;
  } catch(err) {
    throw new Error(`An error occurred fetching premises: ${err.message}`, err);
  }
}

const SheepSight = ({data, headers}) => {
  const [sheeps, setSheeps] = useState([]);
  const [selectedSheeps, setSelectedSheeps] = useState([]);
  const [premises, setPremises] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState('');
  const [confirmPID, setConfirmPID] = useState(false);
  const [form, setForm] = useState({
    location: "Manual Input",
    date: new Date()
  });

  const fetchAndSetPremises = async () => {
    const fetchedPremises = await fetchPremises();
    setPremises(fetchedPremises);
  }

  useEffect(() => {
    setSheeps(extractDataFromHeaders(data, headers));
    fetchAndSetPremises();
  }, [data, headers]);

  const baseColumns = [
    {
      id: 'isoNumber',
      name: 'Isonumber'
    },
    {
      id: 'localMgmtNumber',
      name: 'Local Management Number'
    },
    {
      id: 'gender',
      name: 'Gender'
    },
    {
      id: 'subgender',
      name: 'Sub Gender'
    },
    {
      id: 'birthdate',
      name: 'DOB'
    },
    {
      id: 'activeDate',
      name: 'ActiveDate',
    }
  ];

  const reporterInput = [
    {
      name: 'Reporter of Sighting',
			id: 'reporterName'
    }
  ]

  const manualLocationInput = [
    {
      name: 'Manually enter a PID',
			id: 'manualLocation'
    }
  ]

  const dateField = {
    labelName: 'Date Sighted',
    id: 'date',
    onChange: (date) => dateChange(date, 'date'),
    value: form.date
  }

  const columns = [
    {
      id: 'check',
      name: '' 
    },
    ...baseColumns
  ];

  const handleSubmit = async(e) => {
    try {
      e.preventDefault();
      
      const submissionObject = {
        sheep: selectedSheeps,
        reporterName: form.reporterName,
        reportDate: form.date,
        reportLocation: '',
      }
      
      if (!form.location || form.location === "Manual Input"){
        submissionObject.reportLocation = form.manualPid
      } else {
        submissionObject.reportLocation = form.location
      }
      
      if(confirmPID){
        if(!form.manualPid){
          toast.error("Error: premise missing");
          return;
        }
        submissionObject.reportLocation = form.manualPid;

        setSubmitting(true);
        await bulkSheepSubmit(submissionObject);
        setRedirect('/reports/sighting/listView');
      }
      else if (validate(submissionObject)){
        if (form.location !== "Manual Input"){
          setSubmitting(true);
          await bulkSheepSubmit(submissionObject);
          setRedirect('/reports/sighting/listView');
        }
        else{
          //if the manually entered PID matches an existing premise ID, submit
          if(await validatePremiseExists(submissionObject.reportLocation)){
            setSubmitting(true);
            await bulkSheepSubmit(submissionObject);
            setRedirect('/reports/sighting/listView');
          }
          //show warning page if PID isn't in the system
          else{
            setConfirmPID(true);
          }
        }
      }
    } catch (err) {
      setSubmitting(false);
      if (err.message.includes("ISO number") && err.message.includes("already exists")) {
        const dupIsoNumber = err.message.match(/[0-9]{15}/);
        toast.error(`An error occurred because the following ISO number is taken: ${dupIsoNumber}`);
      } else if (err.message.includes("ISO number") && err.message.includes("is duplicated")) {
        const dupIsoNumber = err.message.match(/[0-9]{15}/);
        toast.error(`An error occurred because the following ISO number is duplicated: ${dupIsoNumber}`);
      } else {
        toast.error(err.message);
      }
    }
  }

  const dateChange = (date, fieldName) => {
    setForm({
      ...form,
      [fieldName]: date
    })
  };

  const SelectAllSection = () => <CenterDiv>
    <Button onClick={(e) => {
      e.preventDefault();
      setSelectedSheeps(sheeps);
    }}>Select All</Button>
    {(selectedSheeps.length > 0) ? 
      <Button onClick={(e) => {
        e.preventDefault();
        setSelectedSheeps([]);
      }}>Clear All</Button>
    : <></>}
  </CenterDiv>;

  const sheepRowsWithCheck = sheeps.map(s => ({
    ...s,
    check: <CheckBox 
      checked={selectedSheeps.filter(selectedSheep => selectedSheep.dummyID === s.dummyID).length > 0}
      onChange={() => {
        const alreadySelected = selectedSheeps.filter(selectedSheep => selectedSheep.dummyID === s.dummyID).length > 0;
        if(alreadySelected){
          const newSelectedSheeps = selectedSheeps.filter(sheep => sheep.dummyID !== s.dummyID);
          setSelectedSheeps(newSelectedSheeps)
        } else {
          const newSelectedSheeps = [
            sheeps.filter(sheep => sheep.dummyID === s.dummyID)[0],
            ...selectedSheeps
          ];
          setSelectedSheeps(newSelectedSheeps);
        }
      }}
    />
  }));

  const selectedSheepColumns = [
    ...baseColumns,
    {
      id: 'remove',
      name: ''
    }
  ];

  const selectedSheepRowsWithRemove = selectedSheeps.map(s => ({
    ...s,
    remove: <button
      onClick={(e) => {
        e.preventDefault();
        const newSelectedSheep = selectedSheeps.filter(sheep => sheep.dummyID !== s.dummyID);
        setSelectedSheeps(newSelectedSheep);
      }}
      style={{cursor: 'pointer'}}
    >Remove</button>
  }))

  if(redirect !== ''){
    return <Redirect to={redirect} />;
  }

  return <>
    <PageCard
      line
      back
      title='Report a Sighting'
    >

      {!confirmPID ? 
      <>
        <SimpleCard
          title='Select Sighted Sheep'
        >
        <StripedTable
          columns={columns}
          rows={sheepRowsWithCheck}
          ShowCount
          max={10}
          paginate
        />
        <SelectAllSection />
        </SimpleCard>
        {(selectedSheeps.length > 0) ? 
          <SimpleCard
            title='Sheep selected to create'
          >
          <StripedTable
            columns={selectedSheepColumns}
            rows={selectedSheepRowsWithRemove}
            max={10}
            paginate
            ShowCount
          />
          </SimpleCard> : <></> }

          <SimpleCard
          title='Enter Sighting Information'
          >
          </SimpleCard>

          <HalfDiv>
            <FormInputWrapper>
              <FormInputLabel>Premise</FormInputLabel>
              <FormSelectInput
                value={form['location']}
                onChange={(e) => {
                
                  setForm({
                    ...form,
                    location: e.target.value,
                  })
                }}
              > 
                <option > Manual Input </option>
                {premises.map((o) => (
                  <option key={o.name} value={o.pid}>
                    {`${o.name} - ${o.pid}`}
                  </option>
                ))}
              </FormSelectInput>
            </FormInputWrapper>
          </HalfDiv>

          {form.location == "Manual Input" ? (
              <HalfDiv>
              {manualLocationInput.map(i => <>
                <Input
                  id={i.id}
                  placeholder={i.hint}
                  labelName={i.name}
                  onChange={(e) => setForm({
                    ...form,
                    manualPid: e.target.value
                  })}
                  value={form[i.id]}
                  required
                />
              </>)} 
              </HalfDiv>
            ) : (
              <></>
            )}

          <FullDiv>
            {reporterInput.map(i => <>
              <Input
                id={i.id}
                placeholder={i.hint}
                labelName={i.name}
                onChange={(e) => setForm({
                  ...form,
                  reporterName: e.target.value
                })}
                value={form[i.id]}
                required
              />
            </>)} 
          </FullDiv>
          <FullDiv>
            <DateField
              id={dateField.id}
              labelName={dateField.labelName}
              value={dateField.value}
              onChange={dateField.onChange}
              required
            />
          </FullDiv>
          <FullDiv>
            <CenterDiv>

              <Button onClick={handleSubmit} disabled={submitting}>
                Submit {`${selectedSheeps.length}`} Sheep
              </Button>
              {submitting && <SubmittingSpinner />}

            </CenterDiv>
          </FullDiv>
        </>:<>
        <SimpleCard
          title='Confirm Sighting PID'
        />
        <Text>
          <span style={{ color: 'red', fontSize: 'inherit', lineHeight: '150%' }}>Warning - </span>
            You have entered a PID that is not currently in our system, are you sure this is the correct PID?
            If not, you can change the PID you entered below. If the PID you entered is correct, just click &apos;Submit&apos; 
            to submit your report.
        </Text>

        <CenterDiv>
            <InputTitle>Premise ID</InputTitle>
            <FormTextInput
              id='manualLocation'
              name='manualLocation'
              value={form.manualPid}
              onChange={(e) => setForm({
                ...form,
                manualPid: e.target.value
              })}
              style={{ width: '30%' }}
            />
        </CenterDiv>
        
        <CenterDiv>
          <Button onClick={handleSubmit} disabled={submitting}>
            Submit
          </Button>
          {submitting && <SubmittingSpinner />}
        </CenterDiv>
        </>
      }
    </PageCard>
  </>
}

const HalfDiv = styled.div`
  width: 50%;
  float: left;
  display: inline-block;
`
const FullDiv = styled.div`
  width: 100%;
  float: left;
  display: inline-block;
`
const Text = styled.div`
  margin-left: 10px;
  margin-bottom: 10px;
  display: inline-block;
`
const CenterDiv = styled.div`
  text-align: center; 
  margin-top: 2rem;
`
const InputTitle = styled.div`
  margin-bottom: 10px;
`

SheepSight.propTypes = {
  data: propTypes.arrayOf(propTypes.string),
  headers: propTypes.arrayOf(propTypes.string)
}

export default SheepSight;