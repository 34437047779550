import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  FormContainer,
  FormInputWrapper,
  FormInputLabel,
  FormTextInput,
  Button,
} from '../components/CommonComponents';
import FormCard from '../components/FormCard.js';
import axios from 'axios';
import { toast } from 'react-toastify';

const ForgotPasswordView = () => {
  const [email, setEmail] = useState('');

  const [redirect, setRedirect] = useState('');

  const canSubmit = () => {
    if (email === '') return false;
    return true;
  }

  const postForgottenPassword = async (address) => {
    const body = { email: address };
    return await axios.post(`/api/users/reset`, body);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await postForgottenPassword(email);
      toast.success('Password reset request sent!');
      setRedirect('/login');
    } catch (err) {
      toast.error(`Couldn't reset password: ${err}`);
    }
  }

  if (redirect !== '') return <Redirect to={redirect} push />;



  return (
    <FormCard title='Forgot Password' back>
      <FormContainer>
        <FormInputWrapper>
          <FormInputLabel htmlFor='input'>Email</FormInputLabel>
          <FormTextInput
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormInputWrapper>
        <Button type='submit' disabled={!canSubmit()} onClick={async (e) => await handleSubmit(e)}>
          Submit
				</Button>
      </FormContainer>
    </FormCard>
  );
}

export default ForgotPasswordView;
