import React, { useState, useEffect } from 'react';
import PageCard from '../../components/PageCard';
import { getMoveLogs } from '../../utils/MoveUtils';
import { toast } from 'react-toastify';
import ReportDescriptionCard from '../../components/ReportDescriptionCard.js';
import ViewEditDispose from '../../components/ViewEditDispose';
import ReportListDateFilter from '../../components/ReportListDateFilter';

const moveOutColumns = [
  {
    id: 'departurePremise',
    name: 'Departure Premise'
  },
  {
    id: 'destinationPremise',
    name: 'Destination Premise'
  },
  {
    id: 'animalTags',
    name: 'Moved Out Animals'
  },
  {
    id: 'departureTime',
    name: 'Date of Departure'
  },
  {
    id: 'actions',
    name: 'Actions'
  }
];

const convertToAmPm = (time) => {
  const hourMinute = time.split(":");
  let hr = +hourMinute[0];
  let min = +hourMinute[1];
  const ampm = hr >= 12 ? 'pm' : 'am';
  hr = hr % 12;
  hr = hr > 0 ? hr : 12; // 0 hr should be 12
  min = min < 10 ? '0'+min : min;
  return `${hr}:${min}${ampm}`;
};

const MoveOutReportListView = () => {
  const [moveOutReports, setMoveOutReports] = useState([]);
  
  useEffect(() => {
    getMoveLogs().then((moveLogs) => {
        moveLogs.forEach(r => {
        r.actions = <ViewEditDispose 
          id={r._id}
          endpoint={'/reports/moveOut/'}
          hideDispose
          hideEdit
          hideReplace
        />;
      });
      const moveOut = (moveLogs.filter((log) => log.moveOut)).reverse();
      setMoveOutReports(moveOut.map(moveLog => ({
        ...moveLog,
        departurePremise: moveLog.departurePremise ? moveLog.departurePremise.name : '',
        destinationPremise: moveLog.destinationPremise ? moveLog.destinationPremise.name : '',
        departureTime: (new Date(moveLog.departureTime)).toISOString().split('T')[0],
        loadTime: moveLog.loadTime ? convertToAmPm(moveLog.loadTime) : ''
      })));
    }).catch((err) => {
      toast.error(err);
    });
  }, []);

  return <>
    <PageCard title="Move-Out Reports" to='/reports/moveout/new' line back maxContent>
    <ReportDescriptionCard page='move-out report'></ReportDescriptionCard>

      {moveOutReports.length > 0 ? 
      <ReportListDateFilter 
        columns={moveOutColumns}
        reports={moveOutReports} 
        filterField= "identifier"
      /> 
      : <></>}
    </PageCard>
  </>
};

export default MoveOutReportListView;