import React, { useEffect, useState } from 'react';
import PageCard from '../../components/PageCard';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import SimpleCard from '../../components/SimpleCard';
import propTypes from 'prop-types';
import styled from 'styled-components';
import StripedTable from '../../components/StripedTable';
import { NAField } from '../../components/SheepReportComponents';

const convertToAmPm = (time) => {
  const hourMinute = time.split(":");
  let hr = +hourMinute[0];
  let min = +hourMinute[1];
  const ampm = hr >= 12 ? 'pm' : 'am';
  hr = hr % 12;
  hr = hr > 0 ? hr : 12; // 0 hr should be 12
  min = min < 10 ? '0'+min : min;
  return `${hr}:${min}${ampm}`;
};

const detailExportColumns = [
  {
    id: 'isoNumber',
    name: 'ISO Number',
    required: true
  },
  {
    id: 'localMgmtNumber',
    name: 'Local Management Number',
    required: true
  },
  {
    id: 'tattooNumber',
    name: 'Tattoo Number',
    required: true
  },
  {
    id: 'breed',
    name: 'Breed',
    required: true
  },
  {
    id: 'gender',
    name: 'Gender',
    required: true
  },
];

const getPremiseName = (id) => {
  return axios.get(`/api/premises/name/${id}`)
    .then((res) => {
      return res.data;  
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

const fetchMoveInInfo = async (id) => {
  try{
    const request = await axios.get(`/api/move/${id}`);
    return request.data;
  } catch (err){
    throw new Error(`An error occurred fetching sheep info: ${err.message}`);
  }
}

const Info = ({name, value}) => {
  return <div>
    <span>{name}:&nbsp;</span><span>{value}</span>
  </div>;
}

Info.propTypes = {
  name: propTypes.string,
  value: propTypes.any
}

const MoveInReportDetailView = () => {
  const [moveInReportInfo, setMoveInReportInfo] = useState();
  const [moveInSheepInfo, setMoveInSheepInfo] = useState([]);

  const [departurePremiseName, setDeparturePremiseName] = useState([]);
  const [destpremiseName, setDestPremiseName] = useState([]);

  const [reportLocation, setReportLocation] = useState(null);

  const params = useParams();
  const id = params.id;
  
  // fetchExportReportInfo
  const fetchAndSetMoveInReportInfo = async () => {
    try {

      let fetchedMoveInReportInfo = await fetchMoveInInfo(id);
 
      let fetchedDeparturePremiseName = await getPremiseName(fetchedMoveInReportInfo.departurePID);
      setDeparturePremiseName(fetchedDeparturePremiseName[0].name);

      let fetchedFirstDestPremiseName = await getPremiseName(fetchedMoveInReportInfo.destinationPID);
      setDestPremiseName(fetchedFirstDestPremiseName[0].name);

      setMoveInSheepInfo(fetchedMoveInReportInfo.animals);
      setMoveInReportInfo(fetchedMoveInReportInfo);

      // if this report has a geolocation, save that to a state as well
      if (
        fetchedMoveInReportInfo.location &&
        Array.isArray(fetchedMoveInReportInfo.location.coordinates) &&
        fetchedMoveInReportInfo.location.coordinates.length === 2
      ) {
        const longlat = fetchedMoveInReportInfo.location.coordinates;
        setReportLocation([longlat[1], longlat[0]]);
      }

    } catch (err) {
      toast.error(err.message);
    }
  }

  useEffect(() =>  {
    fetchAndSetMoveInReportInfo();
  }, [])

  moveInSheepInfo.forEach(s => {
    s.isoNumber = (s.tag.isoNumber) ? s.tag.isoNumber : <NAField />;
    s.localMgmtNumber = (s.tag.localMgmtNumber) ? s.tag.localMgmtNumber : <NAField />;
    s.tattooNumber = (s.tag.tattooNumber) ? s.tag.tattooNumber : <NAField />;
    s.breed = (s.breed) ? s.breed : <NAField />;
    s.gender = (s.gender) ? s.gender : <NAField />;
  });
 
  return <>
  <PageCard title="Move-In Report Details" verify={`/verify/movein/${id}`} back>
    {(moveInReportInfo && departurePremiseName && destpremiseName) ? <>
      <SimpleCard title="Premise Information" size="md">
        <HalfDiv>
          <Info name="Departure Premise PID" value={moveInReportInfo.departurePID} />  
        </HalfDiv>

        <HalfDiv>
          <Info name="Destination Premise PID" value={moveInReportInfo.destinationPID} />  
        </HalfDiv>

        <HalfDiv>
          <Info name="Departure Premise Name" value={departurePremiseName} />  
        </HalfDiv>
        
        <HalfDiv>
          <Info name="Destination Premise Name" value={destpremiseName} />  
        </HalfDiv>

      </SimpleCard>
      <br></br>
      <br></br>

      <SimpleCard title="Transported Sheep" size="md">
        <StripedTable  
            columns={detailExportColumns}
            rows={moveInSheepInfo}
            ShowCount
            max={10}
          />
      </SimpleCard>

      {moveInReportInfo.departureTime &&
        <SimpleCard title="Departure Date" size="sm">
            <Info
              name="Date"
              value={moveInReportInfo.departureTime.split('T')[0]}
            />
        </SimpleCard>
      }
      <br></br>

      {moveInReportInfo.receivedTime &&
        <SimpleCard title="Receive Date" size="sm">
          <HalfDiv>
            <Info
              name="Date"
              value={moveInReportInfo.receivedTime.split('T')[0]}
            />  
          </HalfDiv>
          <HalfDiv>
            <Info
              name="Unload Time" 
              value={convertToAmPm(moveInReportInfo.unloadTime)}
            />  
          </HalfDiv>
        </SimpleCard>
      }
      <br></br>

      {reportLocation &&
        <SimpleCard title="Location" size="sm">
          <HalfDiv>
            <Info name="Latitude" value={reportLocation[0]} />
          </HalfDiv>
          <HalfDiv>
            <Info name="Longitude" value={reportLocation[1]} />
          </HalfDiv>
        </SimpleCard>
      }

    </>: <>Loading</>}
  </PageCard>
</>
};

const HalfDiv = styled.div`
  width: 50%;
  float: left;
  display: inline-block;
`
export default MoveInReportDetailView;